import { Grid, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../../app/store/configureStore";
import { getReferenceDataAsync } from "../../../../../app/slices/referenceDataSlice";
import { PlannedActivities } from "./PlannedActivities";
import { getCurrentYearAsync, getPlanDetailsAsync } from "../planSlice";
import { useParams } from "react-router-dom";
import { PcsPdpStatus } from "../../../../../app/models/staticData/pcsPdpStatus";
import { PdpStatusEnum } from "../../../models/pdpStatusEnum";
import { ViewPlannedActivities } from "../viewPlan/VewPlannedActivities";
import LoadingComponent from "../../../../../app/layout/LoadingComponent";
import { useMsal } from "@azure/msal-react";
import { UserClaims } from "../../../../../app/enums/userClaims";
import { MESSAGE_PROCESSING_DETAILS } from "../../../../../app/utils/constant";

export function PlanDetails() {
    const { enrolmentYearId } = useParams<{ enrolmentYearId: string }>();

    const dispatch = useAppDispatch();

    const { pcsPdpStatuses } = useAppSelector(state => state.ReferenceData);
    const { currentYear, enrolmentYearDetails } = useAppSelector(state => state.plan);

    const methods = useForm();
    const { accounts } = useMsal();

    useEffect(() => {
        if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
            setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
        }
    }, [accounts]);

    const [personId, setPersonId] = useState('');
    const [pcsPdpStatus, setPcsPdpStatus] = useState<PcsPdpStatus>();
    const [pageLoading, setPageLoading] = useState(false);

    useEffect(() => {
        if ((pcsPdpStatuses === null || pcsPdpStatuses?.length === 0) &&
            !enrolmentYearDetails && !currentYear &&
            personId !== "") {

            setPageLoading(true);

            const allPromise = async () => await Promise.all([
                dispatch(getReferenceDataAsync()),
                dispatch(getCurrentYearAsync()),
                dispatch(getPlanDetailsAsync({ personId, enrolmentYearId }))
            ]);

            allPromise().finally(() => {
                setPageLoading(false);
            });
        }
    }, [enrolmentYearDetails, pcsPdpStatuses, currentYear, personId, dispatch]);

    useEffect(() => {
        if (pcsPdpStatuses && enrolmentYearDetails) {
            setPcsPdpStatus(pcsPdpStatuses
                .find(x => x.id === enrolmentYearDetails.pcsPdpReviewStatusId));
        }
    }, [pcsPdpStatuses, enrolmentYearDetails]);

    const isCurrentYearPlan = () => {
        return enrolmentYearDetails?.year === currentYear;
    }

    const canUpdatePlannedActivities = () => {
        return (pcsPdpStatus?.code === PdpStatusEnum[PdpStatusEnum.DT] ||
            pcsPdpStatus?.code === PdpStatusEnum[PdpStatusEnum.NS]) && isCurrentYearPlan();
    }

    if (pageLoading === true) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        <FormProvider {...methods}>
            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>

                <Grid container rowSpacing={4}>

                    <Grid item xs={12}>
                        {canUpdatePlannedActivities() === true &&
                            <PlannedActivities />
                        }
                        {canUpdatePlannedActivities() === false &&
                            <ViewPlannedActivities />
                        }
                    </Grid>
                </Grid>
            </Paper>
        </FormProvider >
    </>
}
