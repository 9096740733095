import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { WorkDetailsDto } from "../models/pcsEnrolment/workDetails/workDetailsDto";

interface WorkDetailsState {
    workDetails: WorkDetailsDto | null;
    workDetailsStateStatus: ApiRequestStatus;
    saveWorkDetailsStatus: ApiRequestStatus;
}

const initialState: WorkDetailsState = {
    workDetails: null,
    workDetailsStateStatus: ApiRequestStatus.Idle,
    saveWorkDetailsStatus: ApiRequestStatus.Idle
}

export const getWorkDetailsState = createAsyncThunk<WorkDetailsDto, string>(
    'register/getWorkDetailsState',
    async (personId, thunkAPI) => {
        try {
            return agent.PcsRegistration.getWorkDetails(personId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const saveWorkDetailsStateAsync = createAsyncThunk<void,
    { workDetailsDto: WorkDetailsDto, personId: string }>(
        'register/saveWorkDetailsStateAsync',
        async (workDetails, thunkAPI) => {
            try {
                return agent.PcsRegistration.submitWorkDetails(
                    workDetails.personId, workDetails.workDetailsDto);
            } catch (error: any) {
                return thunkAPI.rejectWithValue({ error: error.data });
            }
        }
    )

export const workDetailsSlice = createSlice({
    name: 'register',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(getWorkDetailsState.pending, (state) => {
            state.workDetailsStateStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getWorkDetailsState.fulfilled, (state, action) => {
            state.workDetailsStateStatus = ApiRequestStatus.Fulfilled;
            state.workDetails = action.payload;
        });
        builder.addCase(getWorkDetailsState.rejected, (state) => {
            state.workDetailsStateStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(saveWorkDetailsStateAsync.pending, (state) => {
            state.saveWorkDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(saveWorkDetailsStateAsync.fulfilled, (state, action) => {
            state.workDetails = action.meta.arg.workDetailsDto;
            state.saveWorkDetailsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(saveWorkDetailsStateAsync.rejected, (state) => {
            state.saveWorkDetailsStatus = ApiRequestStatus.Rejected;
        });
    })
})
