import { Box, Grid, Typography } from "@mui/material";

export function PractitionerDetails() {

    return <>
        <Box sx={{ width: '100%' }}>
            <Grid container rowSpacing={4}>

                <Grid item xs={12}></Grid>

                <Grid item xs={12}><Typography variant='h6'>Practitioner details</Typography></Grid>

                <Grid item xs={2}>
                    Name
                </Grid>

                <Grid item xs={2}>

                </Grid>

                <Grid item xs={8}></Grid>

                <Grid item xs={2}>
                    Title
                </Grid>

                <Grid item xs={2}>

                </Grid>

                <Grid item xs={8}></Grid>

                <Grid item xs={2}>
                    IMC Number
                </Grid>

                <Grid item xs={2}>

                </Grid>

                <Grid item xs={8}></Grid>

            </Grid>
        </Box>
    </>
}