import "yup-phone-lite";
import {
  PersonalDetailsValidationSchema, PostalAddressValidationSchema,
  LocationAndPreferencesValidationSchema, DeclarationValidationSchema
}
  from '../../../app/models/membership/validationSchemas';

export const AffiliateValidationSchemas = [
  PersonalDetailsValidationSchema,
  PostalAddressValidationSchema,
  LocationAndPreferencesValidationSchema,
  DeclarationValidationSchema
]
