import * as yup from 'yup';
import "yup-phone-lite";
import {
  LocationValidationSchema, PersonalDetailsValidationSchema, PostalAddressValidationSchema
} from '../../../app/models/membership/validationSchemas';
import MembershipFormModel from '../../../app/models/membership/membershipFormModel';

const {
  formField: {
    LevelOfStudyOrTraining,
    StatusAffiliateMemberPathway,
    HaveNotSuccessfullyPassedMrcsPartBExam,
    ReasonToBecomeAffiliateMemberOfRCSI
  }
} = MembershipFormModel;

export const affiliateAndAssociateValidationSchemas = [
  PersonalDetailsValidationSchema,
  PostalAddressValidationSchema,
  LocationValidationSchema,
  yup.object().shape({
    [LevelOfStudyOrTraining.name]: yup.string().required(`${LevelOfStudyOrTraining.requiredErrorMsg}`),
    [StatusAffiliateMemberPathway.name]: yup.string().required(`${StatusAffiliateMemberPathway.requiredErrorMsg}`),
    [HaveNotSuccessfullyPassedMrcsPartBExam.name]: yup.array().min(1, HaveNotSuccessfullyPassedMrcsPartBExam.requiredErrorMsg)
      .of(yup.string().required(HaveNotSuccessfullyPassedMrcsPartBExam.requiredErrorMsg)).required(),
    [ReasonToBecomeAffiliateMemberOfRCSI.name]: yup.string().required(`${ReasonToBecomeAffiliateMemberOfRCSI.requiredErrorMsg}`)
  })
]
