import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../../app/api/agent";
import { FileDetailsParam } from "./models/fileDetailsParam";
import { ApiRequestStatus } from "../../app/enums/apiRequestStatus";
import { FileDto } from "../../app/models/membership/fileDto";

interface DocumentDetailsState {
    files: FileDto[] | null;
    fileDetails: FormData | null;
    status: ApiRequestStatus;
    downloadStatus: ApiRequestStatus;
    uploadStatus: ApiRequestStatus;
    deleteStatus: ApiRequestStatus;
}

const initialState: DocumentDetailsState = {
    files: null,
    fileDetails: null,
    status: ApiRequestStatus.Idle,
    downloadStatus: ApiRequestStatus.Idle,
    uploadStatus: ApiRequestStatus.Idle,
    deleteStatus: ApiRequestStatus.Idle,
}

export const getFilesAsync = createAsyncThunk<FileDto[]>(
    'document/getFilesAsync',
    async (_, thunkAPI) => {
        try {
            return agent.Documents.getFiles();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const downloadFileAsync = createAsyncThunk<FormData, FileDetailsParam>(
    'document/downloadFileAsync',
    async (downloadFileParam, thunkAPI) => {
        try {
            return agent.Documents.getFile(downloadFileParam.personId, downloadFileParam.fileName);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const documentSlice = createSlice({
    name: 'document',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(getFilesAsync.pending, (state) => {
            state.status = ApiRequestStatus.Pending;
        });

        builder.addCase(getFilesAsync.fulfilled, (state, action) => {
            state.files = action.payload;
            state.status = ApiRequestStatus.Fulfilled;
        });

        builder.addCase(getFilesAsync.rejected, (state) => {
            state.status = ApiRequestStatus.Rejected;
        });

        builder.addCase(downloadFileAsync.pending, (state) => {
            state.downloadStatus = ApiRequestStatus.Pending;
        });

        builder.addCase(downloadFileAsync.fulfilled, (state, action) => {
            state.downloadStatus = ApiRequestStatus.Fulfilled;
        });

        builder.addCase(downloadFileAsync.rejected, (state) => {
            state.downloadStatus = ApiRequestStatus.Rejected;
        });
    })
})
