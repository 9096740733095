import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import agent from "../../../../app/api/agent";
import { ApiRequestStatus } from "../../../../app/enums/apiRequestStatus";
import { PlannedActivity } from "../../models/plannedActivity";
import { WorkPostDto } from "../../models/workPostDto";
import { ReviewerDetails } from "../../models/reviewerDetails";
import { PcsEnrolmentYear } from "../../models/pcsEnrolmentYear";
import { PcsPlanDetailsParameters } from "../../models/pcsPlanDetailsParameters";
import { CancelPlanParameters } from "../../models/cancelPlanParameters";
import { SummaryDto } from "../../../../app/models/summary/summaryDto";
import { ReviewerDetailsDto } from "../../models/reviewerDetailsDto";
import { DomainSummaryDto } from "../../models/domainSummaryDto";
import { ConfirmReviewerDetailsParameters } from "../../models/confirmReviewerDetailsParameters";
import { RevieweeCommentDto } from "../../models/revieweeCommentDto";

interface PlanState {
    currentWorkDetails: WorkPostDto | null;
    isPlannedActivityUpdated: boolean | null;
    enrolmentYearDetails: PcsEnrolmentYear | null;
    summaryData: SummaryDto[] | null;
    currentYear: number | null;
    reviewerDetails: ReviewerDetailsDto | null;
    activitiesAcrossDomains: DomainSummaryDto | null;
    planStateStatus: ApiRequestStatus;
}

const initialState: PlanState = {
    currentWorkDetails: null,
    isPlannedActivityUpdated: null,
    enrolmentYearDetails: null,
    summaryData: null,
    currentYear: null,
    reviewerDetails: null,
    activitiesAcrossDomains: null,
    planStateStatus: ApiRequestStatus.Idle
}

export interface PCSSummaryDto {
    summaryData: SummaryDto[][] | null;
}

export const getWorkPostAsync = createAsyncThunk<WorkPostDto[]>(
    'plan/getWorkPostAsync',
    async (_, thunkAPI) => {
        try {
            return agent.PcsPlan.getWorkPost();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getCreditSummaryAsync = createAsyncThunk<PCSSummaryDto, string>(
    'plan/getCreditSummaryAsync',
    async (personId, thunkAPI) => {
        try {
            return agent.SixYearSummary.summaries(personId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getActivitiesAcrossDomainsAsync = createAsyncThunk<DomainSummaryDto>(
    'plan/getActivitiesAcrossDomainsAsync',
    async (_, thunkAPI) => {
        try {
            return agent.PcsPlan.getActivitiesAcrossDomains();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getPlanDetailsAsync = createAsyncThunk<PcsEnrolmentYear, PcsPlanDetailsParameters>(
    'plan/getPlanDetailsAsync',
    async (pcsPlanDetailsParameters, thunkAPI) => {
        try {
            return agent.PcsPlan.getPlanDetails(pcsPlanDetailsParameters.personId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const searchReviewerByImcNumberAsync = createAsyncThunk<ReviewerDetailsDto, string>(
    'plan/searchReviewerByImcNumberAsync',
    async (imcNumber, thunkAPI) => {
        try {
            return agent.PcsPlan.searchReviewerByImcNumber(imcNumber);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const searchReviewerByPersonIdAsync = createAsyncThunk<ReviewerDetailsDto, string>(
    'plan/searchReviewerByPersonIdAsync',
    async (personId, thunkAPI) => {
        try {
            return agent.PcsPlan.searchReviewerByPersonId(personId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const updatePlannedActivityAsync = createAsyncThunk<boolean, PlannedActivity>(
    'plan/updatePlannedActivityAsync',
    async (plannedActivity, thunkAPI) => {
        try {
            return agent.PcsPlan.updatePlannedActivity(plannedActivity);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const confirmReviewerDetailsAsync = createAsyncThunk<boolean, ConfirmReviewerDetailsParameters>(
    'plan/confirmReviewerDetailsAsync',
    async (confirmReviewerDetailsParameters, thunkAPI) => {
        try {
            return agent.PcsPlan.confirmReviewerDetails(confirmReviewerDetailsParameters);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const submitReviewerDetailsAsync = createAsyncThunk<boolean, ReviewerDetails>(
    'plan/submitReviewerDetailsAsync',
    async (reviewerDetails, thunkAPI) => {
        try {
            return agent.PcsPlan.submitReviewerDetails(reviewerDetails);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const saveRevieweeCommentsAsync = createAsyncThunk<boolean, RevieweeCommentDto>(
    'plan/saveRevieweeCommentsAsync',
    async (revieweeComment, thunkAPI) => {
        try {
            return agent.PcsPlan.saveRevieweeComment(revieweeComment);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const cancelPlanAsync = createAsyncThunk<boolean, CancelPlanParameters>(
    'plan/cancelPlanAsync',
    async (cancelPlanParameters, thunkAPI) => {
        try {
            return agent.PcsPlan.cancelPlan(cancelPlanParameters);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getCurrentYearAsync = createAsyncThunk<number>(
    'plan/getCurrentYearAsync',
    async (_, thunkAPI) => {
        try {
            return agent.PcsPlan.getCurrentYear();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const planSlice = createSlice({
    name: 'plan',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(getWorkPostAsync.fulfilled, (state, action) => {
            state.currentWorkDetails = action.payload[0];
            state.planStateStatus = ApiRequestStatus.Fulfilled;
        });

        builder.addCase(getCreditSummaryAsync.fulfilled, (state, action) => {
            let summaryData: SummaryDto[] = [];
            if (action.payload.summaryData && action.payload.summaryData !== null && action.payload.summaryData.length > 0) {
                for (let j = 0; j < 8; j++) {
                    summaryData.push(action.payload.summaryData[1][j]);
                }
            }
            state.summaryData = summaryData;
            state.planStateStatus = ApiRequestStatus.Fulfilled;
        });

        builder.addCase(updatePlannedActivityAsync.fulfilled, (state, action) => {
            state.isPlannedActivityUpdated = action.payload;
            state.planStateStatus = ApiRequestStatus.Fulfilled;
        });

        builder.addCase(getPlanDetailsAsync.fulfilled, (state, action) => {
            let enrolmentYears = action.payload as unknown as PcsEnrolmentYear[];
            if (enrolmentYears !== undefined) {
                let enrolmentYear = enrolmentYears.find(x => x.enrolmentYearId === action.meta.arg.enrolmentYearId);
                if (enrolmentYear && enrolmentYear !== null) {
                    state.enrolmentYearDetails = enrolmentYear;
                }
            }
            state.planStateStatus = ApiRequestStatus.Fulfilled;
        });

        builder.addCase(getCurrentYearAsync.fulfilled, (state, action) => {
            state.currentYear = action.payload;
            state.planStateStatus = ApiRequestStatus.Fulfilled;
        });

        builder.addCase(getActivitiesAcrossDomainsAsync.fulfilled, (state, action) => {
            state.activitiesAcrossDomains = action.payload;
            state.planStateStatus = ApiRequestStatus.Fulfilled;
        });

        builder.addCase(submitReviewerDetailsAsync.fulfilled, (state, action) => {
            state.planStateStatus = ApiRequestStatus.Fulfilled;
        });

        builder.addCase(cancelPlanAsync.fulfilled, (state, action) => {
            state.reviewerDetails = null;
            state.planStateStatus = ApiRequestStatus.Fulfilled;
        });

        builder.addMatcher(isAnyOf(submitReviewerDetailsAsync.fulfilled,
            saveRevieweeCommentsAsync.fulfilled), (state) => {
                state.planStateStatus = ApiRequestStatus.Fulfilled;
            });

        builder.addMatcher(isAnyOf(searchReviewerByImcNumberAsync.fulfilled,
            searchReviewerByPersonIdAsync.fulfilled), (state, action) => {
                state.reviewerDetails = action.payload;
                state.planStateStatus = ApiRequestStatus.Fulfilled;
            });

        builder.addMatcher(isAnyOf(getWorkPostAsync.pending,
            getCreditSummaryAsync.pending,
            updatePlannedActivityAsync.pending,
            getPlanDetailsAsync.pending,
            cancelPlanAsync.pending,
            getCurrentYearAsync.pending,
            searchReviewerByImcNumberAsync.pending,
            searchReviewerByPersonIdAsync.pending,
            getActivitiesAcrossDomainsAsync.pending,
            confirmReviewerDetailsAsync.pending,
            submitReviewerDetailsAsync.pending,
            saveRevieweeCommentsAsync.pending), (state) => {
                state.planStateStatus = ApiRequestStatus.Pending;
            });

        builder.addMatcher(isAnyOf(getWorkPostAsync.rejected,
            getCreditSummaryAsync.rejected,
            updatePlannedActivityAsync.rejected,
            getPlanDetailsAsync.rejected,
            cancelPlanAsync.rejected,
            getCurrentYearAsync.rejected,
            searchReviewerByImcNumberAsync.rejected,
            searchReviewerByPersonIdAsync.rejected,
            getActivitiesAcrossDomainsAsync.rejected,
            confirmReviewerDetailsAsync.rejected,
            submitReviewerDetailsAsync.rejected,
            saveRevieweeCommentsAsync.rejected), (state) => {
                state.planStateStatus = ApiRequestStatus.Rejected;
            });
    })
})
