import { FormHelperText, Grid } from "@mui/material";
import { useState } from "react";
import { UseControllerProps, useController } from "react-hook-form";
import AppInformationText from "../../../app/components/AppInformationText";
import { CheckBoxItem } from "../../../app/models/common/checkBoxItem";

interface Props extends UseControllerProps {
    option: CheckBoxItem;
    information_text?: string;
}

const AppPetitionForElectionCheckbox = (props: Props) => {
    const { option, information_text } = props;
    const { fieldState, field } = useController({ ...props });
    const [, setValue] = useState(field.value || []);

    return (
        <>
            <Grid container>

                <Grid item xs={11} sm={11}>
                    <div key={option.id}>
                        <Grid container>
                            <Grid item xs={11.5}>
                                <b>Petition for election:</b>
                                <br></br>
                                <br></br>
                                I request that my name be placed before the Council of the Royal College of Surgeons in Ireland (RCSI) for election as a Fellow or
                                Member of the College, having passed the necessary examinations.
                                <br></br>
                                <br></br>
                                <b>To be deemed eligible for election to the College at an RCSI Council meeting, please tick the Petition for election box.</b>
                            </Grid>

                            <Grid item xs={0.5} sx={{ alignSelf: "end" }}>
                                <input
                                    {...field}
                                    onChange={(e) => {
                                        if (e.target.checked === true &&
                                            !field.value?.includes(option.id)) {
                                            field.value.push(option.id);
                                        }
                                        else {
                                            field.value = field.value.filter((e: string) => e !== option.id);
                                        }
                                        field.onChange(field.value);
                                        setValue(field.value);
                                    }}
                                    key={option.id}
                                    type="checkbox"
                                    checked={field.value === undefined ? false : field.value?.includes(option.id)}
                                    value={option.value}
                                />
                            </Grid>
                        </Grid>
                        <br></br>
                    </div>
                </Grid>

                <Grid item xs={1} sx={{ alignSelf: "end" }}>
                    <AppInformationText information_text={information_text} />
                </Grid>

                <Grid item xs={11} sm={12}>
                    {fieldState.error &&
                        <FormHelperText sx={{ color: 'primary.main' }}>{fieldState.error.message}</FormHelperText>}
                </Grid>

            </Grid>
        </>
    );
};

export default AppPetitionForElectionCheckbox;