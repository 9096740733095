import { useFormContext } from "react-hook-form";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { VolunteeringInterest } from "./common/volunteeringInterest";
import { SwitchButtonItem } from "../common/switchButtonItem";
import { VolunteeringInterestType } from "../staticData/volunteeringInterestType";
import AppSwitchField from "./common/AppSwitchField";

interface Props {
    volunteeringInterestTypes: VolunteeringInterestType[] | null;
    volunteeringInterests: VolunteeringInterest[] | null
}

export default function VolunteeringInterests(props: Props) {
    const { volunteeringInterestTypes, volunteeringInterests } = props;
    const methods = useFormContext();
    const { control } = methods;

    function selectPropsFromVolunteeringInterestType(
        volunteeringInterestType: VolunteeringInterestType) {

        const switchButtonItem: SwitchButtonItem = {
            id: volunteeringInterestType.id,
            value: volunteeringInterestType.value,
            comment: '',
            checked: false,
            canHaveComment: volunteeringInterestType.canHaveComment
        }

        return switchButtonItem;
    }

    const [volunteeringInterestOptions, setVolunteeringInterestOptions] = useState<SwitchButtonItem[] | undefined>(undefined);

    useEffect(() => {
        if (volunteeringInterests) {
            const volunteeringInterestData: SwitchButtonItem[] = [];

            volunteeringInterestTypes?.map((item) => {
                let volunteeringInterest = volunteeringInterests.find(x => x.volunteeringInterestTypeId === item.id);
                let volunteeringInterestType = volunteeringInterestTypes.find(x => x.id === item.id);

                volunteeringInterestData.push({
                    id: item.id,
                    checked: volunteeringInterest ? volunteeringInterest.optedIn : false,
                    comment: (volunteeringInterest && volunteeringInterest.comment !== null)
                        ? volunteeringInterest.comment : '',
                    value: item.value,
                    canHaveComment: volunteeringInterestType ? volunteeringInterestType?.canHaveComment : false
                });
            });

            setVolunteeringInterestOptions(volunteeringInterestData);
        }
        else if (volunteeringInterestTypes) {
            setVolunteeringInterestOptions(volunteeringInterestTypes?.map(selectPropsFromVolunteeringInterestType))
        }
    }, [volunteeringInterestTypes, volunteeringInterests])


    return <>
        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={4} sx={{ marginTop: '-1%' }}>
                    <Typography variant='subtitle1' sx={{ fontWeight: "500" }}>
                        Volunteering interests
                    </Typography>
                </Grid>

                <Grid item xs={1.5} sm={1} sx={{ marginTop: '-1%' }}>
                    <img src={`${String(process.env.REACT_APP_STORAGE_ACCOUNT_URL)}/images/select-switch.PNG`}
                        style={{ 'height': '25px' }} />
                </Grid>

                <Grid item xs={1.5} md={1} sm={1.5} sx={{ marginTop: '-1%' }}>
                    <Typography variant='body1' sx={{ fontWeight: "500" }}>
                        Opt-in
                    </Typography>
                </Grid>

                <Grid item xs={0.5}></Grid>

                <Grid item xs={1.5} sm={1} sx={{ marginTop: '-1%' }}>
                    <img src={`${String(process.env.REACT_APP_STORAGE_ACCOUNT_URL)}/images/not-select-switch.PNG`}
                        style={{ 'height': '25px' }} />
                </Grid>

                <Grid item xs={2} md={1} sm={1.5} sx={{ marginTop: '-1%' }}>
                    <Typography variant='body1' sx={{ fontWeight: "500" }}>
                        Opt-out
                    </Typography>
                </Grid>

                <Grid item xs={1} md={3} sm={3}></Grid>

                <Grid item xs={12}>
                    {volunteeringInterestOptions &&
                        <AppSwitchField control={control} options={volunteeringInterestOptions}
                            name="VolunteeringInterests" />}
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>
    </>
}