import * as yup from 'yup';
import "yup-phone-lite";
import MembershipFormModel from '../../membership/membershipFormModel';
import { countryCodes } from '../../../utils/util';
import { CountryCode } from 'libphonenumber-js/types';
import { MESSAGE_NOT_A_VALUE } from '../../../utils/constant';

const {
  formField: {
    PreferredPhoneNumber,
    LocationCountry,
    CountrySubdivision,
    Discipline,
    AreaOfPracticeMoreDetails
  }
} = MembershipFormModel;

export const VolunteeringValidationSchemas = [
  yup.object().shape({

  }),
  yup.object().shape({
    [PreferredPhoneNumber.name]: yup.string().phone(countryCodes as CountryCode[], `${PreferredPhoneNumber.typeError}`)
      .required(`${PreferredPhoneNumber.requiredErrorMsg}`),
    [LocationCountry.name]: yup.string().required(`${LocationCountry.requiredErrorMsg}`),
    [CountrySubdivision.name]: yup.string().test(`${CountrySubdivision.name}`,
      `${CountrySubdivision.requiredErrorMsg}`,
      function (countrySubdivision) {
        if (countrySubdivision === MESSAGE_NOT_A_VALUE) {
          return true;
        }
        if (countrySubdivision === "") {
          return false;
        }
        return true;
      }),
    [Discipline.name]: yup.string().required(`${Discipline.requiredErrorMsg}`),
    [AreaOfPracticeMoreDetails.name]: yup.string().when([Discipline.name],
      ([discipline], schema) => {
        return schema.test(`${AreaOfPracticeMoreDetails.name}`,
          `${AreaOfPracticeMoreDetails.requiredErrorMsg}`,
          function (areaOfPracticeMoreDetails) {
            if (areaOfPracticeMoreDetails === MESSAGE_NOT_A_VALUE) {
              return true;
            }
            if (discipline !== "" && areaOfPracticeMoreDetails === "") {
              return false;
            }
            return true;
          });
      }),
  })
]
