import { Box, Button, Grid, Typography } from "@mui/material";
import pcsPlanFormModel from "../../../common/pcsPlanFormModel";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { PcsPdpStatus } from "../../../../../app/models/staticData/pcsPdpStatus";
import { useAppDispatch, useAppSelector } from "../../../../../app/store/configureStore";
import LoadingComponent from "../../../../../app/layout/LoadingComponent";
import { getReferenceDataAsync } from "../../../../../app/slices/referenceDataSlice";
import {
    getWorkPostAsync, getPlanDetailsAsync, searchReviewerByPersonIdAsync,
    getCurrentYearAsync, cancelPlanAsync, getCreditSummaryAsync, getActivitiesAcrossDomainsAsync
} from "../planSlice";
import AppLabelField from "../../../../../app/components/AppLabelField";
import { useFormContext } from "react-hook-form";
import { PdpStatusEnum } from "../../../models/pdpStatusEnum";
import { ViewReviewer } from "./ViewReviewer";
import { toast } from "react-toastify";
import { API_REQUEST_FULLFILLED, MESSAGE_PLAN_CANCELED, MESSAGE_PROCESSING_DETAILS } from "../../../../../app/utils/constant";
import { CancelPlanParameters } from "../../../models/cancelPlanParameters";
import { PlannedActivitiesHeader } from "../PlannedActivitiesHeader";
import { ViewFeedback } from "./ViewFeedback";
import { ApiRequestStatus } from "../../../../../app/enums/apiRequestStatus";
import { useMsal } from "@azure/msal-react";
import { UserClaims } from "../../../../../app/enums/userClaims";

export function ViewPlannedActivities() {

    const methods = useFormContext();
    const { reset } = methods;

    const { External, WorkBasedLearning, PracticeReview } = pcsPlanFormModel.formField;

    const { enrolmentYearId } = useParams<{ enrolmentYearId: string }>();

    const { accounts } = useMsal();

    useEffect(() => {
        if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
            setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
        }
    }, [accounts]);

    const [personId, setPersonId] = useState('');
    const [pcsPdpStatus, setPcsPdpStatus] = useState<PcsPdpStatus>();
    const [pageLoading, setPageLoading] = useState(false);

    const dispatch = useAppDispatch();

    const { workPostLevels, titles, pcsPdpStatuses } =
        useAppSelector(state => state.ReferenceData);
    const { currentYear, currentWorkDetails, reviewerDetails, enrolmentYearDetails,
        summaryData, activitiesAcrossDomains, planStateStatus } = useAppSelector(state => state.plan);

    useEffect(() => {
        if (personId !== "") {

            setPageLoading(true);

            const allPromise = async () => await Promise.all([
                dispatch(getReferenceDataAsync()),
                dispatch(getWorkPostAsync()),
                dispatch(getPlanDetailsAsync({ personId, enrolmentYearId })),
                dispatch(getCurrentYearAsync()),
                dispatch(getCreditSummaryAsync(personId)),
                dispatch(getActivitiesAcrossDomainsAsync())
            ]);

            allPromise().finally(() => {
                setPageLoading(false);
            });
        }
    }, [personId, enrolmentYearId, dispatch]);

    useEffect(() => {
        if (pcsPdpStatuses && enrolmentYearDetails) {
            setPcsPdpStatus(pcsPdpStatuses.find(x => x.id === enrolmentYearDetails.pcsPdpReviewStatusId));
        }
    }, [pcsPdpStatuses, enrolmentYearDetails]);

    useEffect(() => {
        if (enrolmentYearDetails) {
            reset({
                [External.name]: enrolmentYearDetails.pdpExternal,
                [WorkBasedLearning.name]: enrolmentYearDetails.pdpWorkBasedLearning,
                [PracticeReview.name]: enrolmentYearDetails.pdpPracticeReview,
            })
        }
    }, [External.name, PracticeReview.name, WorkBasedLearning.name,
        enrolmentYearDetails, reset]);

    const canAddReviewDetails = () => {
        return isCurrentYearPlan() &&
            (pcsPdpStatus?.code === PdpStatusEnum[PdpStatusEnum.SD]
                || pcsPdpStatus?.code === PdpStatusEnum[PdpStatusEnum.RRI]
                || pcsPdpStatus?.code === PdpStatusEnum[PdpStatusEnum.RRC]
                || pcsPdpStatus?.code === PdpStatusEnum[PdpStatusEnum.RRD]);
    }

    const canViewReviewerDetails = () => {
        return (pcsPdpStatus?.code === PdpStatusEnum[PdpStatusEnum.RP]
            || pcsPdpStatus?.code === PdpStatusEnum[PdpStatusEnum.UR]
            || pcsPdpStatus?.code === PdpStatusEnum[PdpStatusEnum.RD]);
    }

    const canCancelReviewRequest = () => {
        return (isCurrentYearPlan() &&
            (pcsPdpStatus?.code === PdpStatusEnum[PdpStatusEnum.UR]
                || pcsPdpStatus?.code === PdpStatusEnum[PdpStatusEnum.RP]));
    }

    const isCurrentYearPlan = () => {
        return enrolmentYearDetails?.year === currentYear;
    }

    const cancelPlan = () => {
        let cancelPlanObject: CancelPlanParameters = { enrolmentYearId: enrolmentYearId };
        dispatch(cancelPlanAsync(cancelPlanObject)).then((response: any) => {
            if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                dispatch(getPlanDetailsAsync({ personId, enrolmentYearId })).then((response) => {
                    if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                        toast.success(MESSAGE_PLAN_CANCELED);
                    }
                });
            }
        });;
    }

    useEffect(() => {
        if (enrolmentYearDetails !== null && reviewerDetails === null &&
            enrolmentYearDetails.pdpReviewerPersonId !== null && canViewReviewerDetails()) {
            dispatch(searchReviewerByPersonIdAsync(enrolmentYearDetails?.pdpReviewerPersonId));
        }
    }, [enrolmentYearDetails, reviewerDetails, pcsPdpStatus, dispatch]);

    if (pageLoading === true ||
        planStateStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        <PlannedActivitiesHeader
            pcsPdpStatus={pcsPdpStatus} currentWorkDetails={currentWorkDetails}
            isCurrentYearPlan={isCurrentYearPlan()} workPostLevels={workPostLevels} summaryData={summaryData}
            activitiesAcrossDomains={activitiesAcrossDomains} currentYear={currentYear} />

        <Box sx={{ width: '100%' }}>
            <Grid container rowSpacing={4}>

                <Grid item xs={12}></Grid>

                <Grid item xs={12}>
                    <AppLabelField value={enrolmentYearDetails?.pdpExternal ?? ''}
                        label={External.label} />
                </Grid>

                <Grid item xs={12}>
                    <AppLabelField value={enrolmentYearDetails?.pdpWorkBasedLearning ?? ''}
                        label={WorkBasedLearning.label} />
                </Grid>

                <Grid item xs={12}>
                    <AppLabelField value={enrolmentYearDetails?.pdpPracticeReview ?? ''}
                        label={PracticeReview.label} />
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>

        </Box>

        {canViewReviewerDetails() &&
            <Box sx={{ width: '100%' }}>
                <Grid container rowSpacing={4}>

                    <Grid item xs={12}>
                        <Typography variant='h6'>Reviewer details</Typography>
                    </Grid>

                    <ViewReviewer title={titles?.find(x => x.id === reviewerDetails?.titleId)?.value}
                        reviewerDetails={reviewerDetails} />

                </Grid>
            </Box>
        }

        {pcsPdpStatus?.code === PdpStatusEnum[PdpStatusEnum.RD] === true &&
            <ViewFeedback enrolmentYearDetails={enrolmentYearDetails} />
        }

        {isCurrentYearPlan() === true &&
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>

                <Grid container rowSpacing={4}>

                    <Grid item xs={12}></Grid>

                    {canAddReviewDetails() &&
                        <Grid item xs={9} sm={5} sx={{ display: 'flex', justifyContent: 'start' }}>
                            <Button variant="contained" color="primary"
                                component={Link} to={`/pcsReviewerDetails/${enrolmentYearId}`}>
                                Add reviewer
                            </Button>
                        </Grid>
                    }

                    {canCancelReviewRequest() === true &&
                        <Grid item xs={4} sm={3} sx={{ display: 'flex', justifyContent: 'start' }}>
                            <Button variant="contained" color="primary" onClick={() => cancelPlan()}>
                                Cancel review request
                            </Button>
                        </Grid>
                    }

                    {pcsPdpStatus?.code === PdpStatusEnum[PdpStatusEnum.RD] === true &&
                        <Grid item xs={4} sm={3} sx={{ display: 'flex', justifyContent: 'start' }}>
                            <Button variant="contained" color="primary"
                                component={Link} to={`/pcsplanfeedback/${enrolmentYearId}`}>
                                Edit my comments
                            </Button>
                        </Grid>
                    }

                </Grid>
            </Box>
        }
    </>
}