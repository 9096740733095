import { Country } from "../../app/models/staticData/country";
import { FamMembershipType } from "../../app/models/staticData/famMembershipType";
import { FAMDetails } from "../../app/models/membership/famDetails";
import { PaymentRedirectProps } from "../../app/models/membership/paymentRedirectProps";
import { getPaymentIdFromBasketAsync, getPaymentRedirectAsync, getPaymentSummaryAsync } from "../../app/slices/paymentSlice";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import { API_REQUEST_FULLFILLED, MESSAGE_PROCESSING_DETAILS } from "../../app/utils/constant";
import { ApiRequestStatus } from "../../app/enums/apiRequestStatus";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useEffect, useState } from "react";
import { basketAsync, nextYearEnrolBasketAsync } from "../../app/slices/famEnrolmentSlice";
import MembershipRenewalPaymentSummary from "./MembershipRenewalPaymentSummary";

interface Props {
    famDetails: FAMDetails | null;
    countries: Country[] | null;
    famMembershipTypes: FamMembershipType[] | null;
    payInAdvance: boolean | undefined;
}

export default function PaymentAndSummary({ famDetails, countries, famMembershipTypes, payInAdvance }: Props) {
    const dispatch = useAppDispatch();

    const { basketId, basketIdStatus, nextYearBasketIdStatus } = useAppSelector(state => state.famEnrolment);
    const { getPaymentRedirectStatus, paymentDetails, getPaymentByPaymentIdStatus,
        paymentId, paymentsByPersonStatus } = useAppSelector(state => state.payment);

    const [pageLoading, setPageLoading] = useState<boolean>(false);

    useEffect(() => {
        if (payInAdvance === true) {
            dispatch(nextYearEnrolBasketAsync());
        }
        else {
            dispatch(basketAsync());
        }
    }, [dispatch, payInAdvance]);

    useEffect(() => {
        if ((basketIdStatus === ApiRequestStatus.Fulfilled || nextYearBasketIdStatus === ApiRequestStatus.Fulfilled)
            && basketId !== null) {
            dispatch(getPaymentIdFromBasketAsync(basketId)).then((paymentIdFromBasketResponse: any) => {
                if (paymentIdFromBasketResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                    dispatch(getPaymentSummaryAsync(paymentIdFromBasketResponse.payload));
                }
            });
        }
    }, [dispatch, basketIdStatus, nextYearBasketIdStatus]);

    const paymentOnClickHandler = () => {
        if (paymentId && paymentId !== null) {
            setPageLoading(true);
            let paymentRedirectProps: PaymentRedirectProps = {
                cancelUrl: `${String(process.env.REACT_APP_REDIRECT_URI)}${String(process.env.REACT_APP_MEMBERSHIP_RENEWAL_URI)}/${window.location.search}&cancelPayment=true`,
                paymentId: paymentId,
                successUrl: `${String(process.env.REACT_APP_REDIRECT_URI)}${String(process.env.REACT_APP_PAYMENT_SUCCESS_URI)}/${window.location.search}&retry-url=${String(process.env.REACT_APP_MEMBERSHIP_RENEWAL_URI)}`
            };
            dispatch(getPaymentRedirectAsync(paymentRedirectProps)).then((paymentRedirectResponse: any) => {
                window.location.replace(paymentRedirectResponse.payload);
            });
        }
    }

    if (pageLoading === true
        || basketIdStatus === ApiRequestStatus.Pending
        || paymentsByPersonStatus === ApiRequestStatus.Pending
        || getPaymentRedirectStatus === ApiRequestStatus.Pending
        || getPaymentByPaymentIdStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        {famDetails !== null && paymentDetails && countries && famMembershipTypes && <>
            <MembershipRenewalPaymentSummary paymentDetails={paymentDetails} famDetails={famDetails}
                countries={countries} famMembershipTypes={famMembershipTypes}
                paymentOnClickHandler={paymentOnClickHandler} retryUrl={process.env.REACT_APP_MEMBERSHIP_RENEWAL_URI} />
        </>}
    </>
}