import { Box, Button, Grid } from "@mui/material";
import AppTextareaInput from "../../../../../app/components/AppTextareaInput";
import { useForm } from "react-hook-form";
import pcsPlanFormModel from "../../../common/pcsPlanFormModel";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/store/configureStore";
import { getReferenceDataAsync } from "../../../../../app/slices/referenceDataSlice";
import { getWorkPostAsync, getPlanDetailsAsync, cancelPlanAsync, updatePlannedActivityAsync, getCreditSummaryAsync, getActivitiesAcrossDomainsAsync }
    from "../planSlice";
import { PcsPdpStatus } from "../../../../../app/models/staticData/pcsPdpStatus";
import LoadingComponent from "../../../../../app/layout/LoadingComponent";
import { PdpStatusEnum } from "../../../models/pdpStatusEnum";
import { CancelPlanParameters } from "../../../models/cancelPlanParameters";
import { toast } from "react-toastify";
import { PlannedActivity } from "../../../models/plannedActivity";
import { yupResolver } from "@hookform/resolvers/yup";
import { plannedActivitiesValidationSchema } from "../../../common/pcsPlanValidationSchema";
import { PlannedActivitiesHeader } from "../PlannedActivitiesHeader";
import { API_REQUEST_FULLFILLED, MESSAGE_DETAILS_SAVED, MESSAGE_PLAN_CANCELED, MESSAGE_PROCESSING_DETAILS } from "../../../../../app/utils/constant";
import { ApiRequestStatus } from "../../../../../app/enums/apiRequestStatus";
import { useMsal } from "@azure/msal-react";
import { UserClaims } from "../../../../../app/enums/userClaims";

export function PlannedActivities() {

    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(plannedActivitiesValidationSchema)
    });

    const { control, reset, getValues, trigger } = methods;
    const { External, WorkBasedLearning, PracticeReview } =
        pcsPlanFormModel.formField;

    const { enrolmentYearId } = useParams<{ enrolmentYearId: string }>();

    const { accounts } = useMsal();

    useEffect(() => {
        if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
            setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
        }
    }, [accounts]);

    const [personId, setPersonId] = useState('');
    const [pcsPdpStatus, setPcsPdpStatus] = useState<PcsPdpStatus>();
    const [pageLoading, setPageLoading] = useState(false);

    const dispatch = useAppDispatch();

    const { workPostLevels, pcsPdpStatuses } = useAppSelector(state => state.ReferenceData);
    const { currentYear, currentWorkDetails, enrolmentYearDetails, summaryData,
        activitiesAcrossDomains, planStateStatus } = useAppSelector(state => state.plan);

    useEffect(() => {
        if (personId !== "") {

            setPageLoading(true);

            const allPromise = async () => await Promise.all([
                dispatch(getReferenceDataAsync()),
                dispatch(getWorkPostAsync()),
                dispatch(getPlanDetailsAsync({ personId, enrolmentYearId })),
                dispatch(getCreditSummaryAsync(personId)),
                dispatch(getActivitiesAcrossDomainsAsync())
            ]);

            allPromise().finally(() => {
                setPageLoading(false);
            });
        }
    }, [personId, enrolmentYearId, dispatch]);

    useEffect(() => {
        if (pcsPdpStatuses && enrolmentYearDetails) {
            setPcsPdpStatus(pcsPdpStatuses
                .find(x => x.id === enrolmentYearDetails.pcsPdpReviewStatusId));
        }
    }, [pcsPdpStatuses, enrolmentYearDetails]);

    useEffect(() => {
        if (enrolmentYearDetails) {
            reset({
                [External.name]: enrolmentYearDetails.pdpExternal,
                [WorkBasedLearning.name]: enrolmentYearDetails.pdpWorkBasedLearning,
                [PracticeReview.name]: enrolmentYearDetails.pdpPracticeReview,
            })
        }
    }, [External.name, PracticeReview.name, WorkBasedLearning.name,
        enrolmentYearDetails, reset]);

    const canUpdateActivities = () => {
        return (isCurrentYearPlan() && (pcsPdpStatus?.code === PdpStatusEnum[PdpStatusEnum.NS]
            || pcsPdpStatus?.code === PdpStatusEnum[PdpStatusEnum.DT]));
    }

    const isCurrentYearPlan = () => {
        return enrolmentYearDetails?.year === currentYear;
    }

    const cancelPlan = () => {
        let cancelPlanObject: CancelPlanParameters = { enrolmentYearId: enrolmentYearId };
        dispatch(cancelPlanAsync(cancelPlanObject)).then((response: any) => {
            if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                dispatch(getPlanDetailsAsync({ personId, enrolmentYearId })).then((response) => {
                    if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                        toast.success(MESSAGE_PLAN_CANCELED);
                    }
                });
            }
        });;
    }

    const savePlannedActivities = async (isSubmitted: boolean) => {
        const isStepValid = await trigger();

        if (isStepValid) {
            let plannedActivity = mapToPlannedActivitiesDto(isSubmitted);
            dispatch(updatePlannedActivityAsync(plannedActivity)).then((response: any) => {
                if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                    dispatch(getPlanDetailsAsync({ personId, enrolmentYearId })).then((response) => {
                        if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                            toast.success(MESSAGE_DETAILS_SAVED);
                        }
                    });
                }
            });
        }
    };

    const mapToPlannedActivitiesDto = (isSubmitted: boolean) => {
        const data: PlannedActivity = {
            external: getValues([External.name]).toString(),
            workBasedLearning: getValues([WorkBasedLearning.name]).toString(),
            practiceReview: getValues([PracticeReview.name]).toString(),
            enrolmentYearId: enrolmentYearId,
            submit: isSubmitted
        }
        return data;
    }

    if (pageLoading === true ||
        planStateStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        <PlannedActivitiesHeader
            pcsPdpStatus={pcsPdpStatus} currentWorkDetails={currentWorkDetails}
            isCurrentYearPlan={isCurrentYearPlan()} workPostLevels={workPostLevels} summaryData={summaryData}
            activitiesAcrossDomains={activitiesAcrossDomains} currentYear={currentYear} />

        <Box sx={{ width: '100%' }}>

            <Grid container rowSpacing={4}>

                <Grid item xs={12}>
                    <AppTextareaInput
                        control={control}
                        name={External.name}
                        label={External.label}
                    />
                </Grid>

                <Grid item xs={12}>
                    <AppTextareaInput
                        control={control}
                        name={WorkBasedLearning.name}
                        label={WorkBasedLearning.label}
                    />
                </Grid>

                <Grid item xs={12}>
                    <AppTextareaInput
                        control={control}
                        name={PracticeReview.name}
                        label={PracticeReview.label}
                    />
                </Grid>

            </Grid>
        </Box>

        {isCurrentYearPlan() === true &&
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>

                <Grid container rowSpacing={4}>

                    <Grid item xs={12}></Grid>

                    {pcsPdpStatus?.code === PdpStatusEnum[PdpStatusEnum.RP] &&
                        <Grid item xs={4} sm={3} sx={{ display: 'flex', justifyContent: 'start' }}>
                            <Button variant="contained" color="primary" onClick={() => cancelPlan()}>
                                Cancel review request
                            </Button>
                        </Grid>
                    }

                    {canUpdateActivities() === true &&
                        <>
                            <Grid item xs={7} sm={2} sx={{ display: 'flex', justifyContent: 'atsrt' }}>
                                <Button variant="contained" color="primary" onClick={() => savePlannedActivities(false)}>
                                    Save as a Draft
                                </Button>
                            </Grid>

                            <Grid item xs={0.5}></Grid>

                            <Grid item xs={4.5} sm={2} sx={{ display: 'flex', justifyContent: 'start' }}>
                                <Button variant="contained" color="primary" onClick={() => savePlannedActivities(true)}>
                                    Submit
                                </Button>
                            </Grid>
                        </>
                    }

                    <Grid item xs={1}></Grid>

                </Grid>
            </Box>
        }
    </>
}