import { Box, Paper, Grid, Typography } from "@mui/material";
import BackToRequests from "./BackToRequests";

export default function RequestToWithdrawPcs() {
    return <>
        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ ml: '2%', mt: '0%', width: '96%' }}>

                <Grid item xs={12}>
                    <BackToRequests />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='h4'>Request to withdraw</Typography>
                </Grid>

                <Grid item xs={12}></Grid>
            </Grid>
        </Box>
    </>
}