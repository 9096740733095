import { Box, Grid, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import AppSelectList from '../../app/components/AppSelectList';
import { useFormContext } from 'react-hook-form';
import registrationFormModel from './common/registrationFormModel';
import { DropdownItem } from '../../app/models/common/dropdownItem';
import AppTextInput from '../../app/components/AppTextInput';
import { PersonalDetailsDto } from '../../app/models/person/personalDetailsDto';
import { Country } from '../../app/models/staticData/country';
import { Institution } from '../../app/models/staticData/institution';
import { InstitutionAward } from '../../app/models/staticData/institutionAward';
import { EducationalDetailsDto } from '../../app/models/person/educationalDetailsDto';

interface Props {
  countries: Country[];
  institutions: Institution[];
  institutionAwards: InstitutionAward[];
  educationalDetails: EducationalDetailsDto;
  personalDetails: PersonalDetailsDto;
}

export default function EducationalDetails({ countries, institutions, institutionAwards, educationalDetails, personalDetails }: Props) {

  const methods = useFormContext();
  const { control, reset } = methods;

  const {
    personalDetailsFormField: {
      DOB
    },
    educationalDetailsFormField: {
      QualificationCountry,
      PrimaryMedicalDegreeCollege,
      QualificationYear,
      OtherQualifications
    } } = registrationFormModel;

  const getQualificationYears = (startYear: number) => {
    let lastYear: number = new Date().getFullYear();
    let years: DropdownItem[] = [];

    while (startYear <= lastYear) {
      const qualificationYear = {
        id: startYear.toString(),
        value: startYear.toString(),
      };
      years.push(qualificationYear);
      startYear++;
    }
    return years;
  }

  const [qualificationYears,] = useState<DropdownItem[] | null>(
    getQualificationYears((new Date(personalDetails?.dateOfBirth!).getFullYear() + 21)));

  useEffect(() => {
    if (personalDetails !== undefined && personalDetails !== null
      && educationalDetails !== undefined && educationalDetails !== null
      && countries && countries?.length > 0 && institutions && institutions?.length > 0
      && institutionAwards && institutionAwards?.length > 0) {

      let institutionId = institutionAwards.find(x => x.id === educationalDetails?.institutionAwardId)?.educationInstitutionId;
      let countryId = institutions.find(x => x.id === institutionId)?.countryId;

      reset({
        [QualificationCountry.name]: countryId,
        [PrimaryMedicalDegreeCollege.name]: institutionId,
        [QualificationYear.name]: educationalDetails?.graduationYear ?? '',
        [DOB.name]: personalDetails.dateOfBirth
      })
    }
  }, [personalDetails, educationalDetails, countries, institutions, institutionAwards, reset]);

  return (
    <>
      <Box sx={{ minWidth: "100%" }} component={Paper}>
        <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

          <Grid item xs={12}>
            <AppSelectList control={control}
              name={QualificationCountry.name}
              label={QualificationCountry.label}
              items={countries} />
          </Grid>

          <Grid item xs={12}>
            <AppSelectList control={control}
              name={PrimaryMedicalDegreeCollege.name}
              label={PrimaryMedicalDegreeCollege.label}
              items={institutions} />
          </Grid>

          <Grid item xs={12}>
            <AppSelectList control={control}
              name={QualificationYear.name}
              label={QualificationYear.label}
              items={qualificationYears ?? []} />
          </Grid>

          <Grid item xs={12}>
            <AppTextInput control={control} multiline
              name={OtherQualifications.name}
              label={OtherQualifications.label} />
          </Grid>

        </Grid>
      </Box>
    </>
  );
}
