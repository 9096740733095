import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { AppPathEnum } from '../../app/enums/appPathEnum';

interface Props {
    title: string;
    description: string;
    redirectUrl: AppPathEnum;
}

export default function MediaCard(props: Props) {
    return (
        <Link style={{ textDecoration: 'none', display: 'block', maxWidth: 345 }} to={`${props.redirectUrl}${window.location.search}`}>
            <Card sx={{ maxWidth: 345, background: '#f3f3f3', height: '225px' }}>
                <CardContent sx={{ height: '96px' }}>
                    <Typography gutterBottom variant="h5" component="span">
                        {props.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ marginTop: '12px' }}>
                        {props.description}
                    </Typography>
                </CardContent>
                <CardActions sx={{ float: 'right', marginTop: '77px', marginRight: '10px' }}>
                    <div style={{
                        padding: '12px',
                        background: `url(${String(process.env.REACT_APP_STORAGE_ACCOUNT_URL)}/images/sprite01.svg) 98.71794871794872% 72.12389380530973% no-repeat`
                    }}></div>
                </CardActions>
            </Card>
        </Link>
    );
}