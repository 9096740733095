import { Box, Paper, Grid, Typography, Button } from "@mui/material";
import BackToRequests from "./BackToRequests";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { CheckBoxItem } from "../../app/models/common/checkBoxItem";
import AppDeclarationCheckbox from "./models/AppDeclarationCheckbox";
import RequestFormModel from "./models/requestFormModel";
import { AcessCardAlumniValidationSchema } from "./models/requestValidationSchemas";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { RequestTypesEnum } from "../../app/enums/requestTypesEnum";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { CreateRequestTaskCommand } from "../../app/models/request/createRequestTaskCommand";
import { submitRequestAsync } from "../../app/slices/requestSlice";
import { useAppDispatch } from "../../app/store/configureStore";
import { API_REQUEST_FULLFILLED, MESSAGE_PROCESSING_DETAILS, MESSAGE_SUBMIT } from "../../app/utils/constant";
import SuccessResponse from "./models/SuccessResponse";

export default function AccessCardAlumni() {
    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(AcessCardAlumniValidationSchema)
    });
    const { control, trigger, reset, formState: { isDirty } } = methods;
    const formObj = methods.watch();

    const {
        AcessCardAlumni: {
            AcessCardAlumniAreTheDetailsCorrect
        }
    } = RequestFormModel;

    const [declarationOption,] = useState<CheckBoxItem>({
        id: AcessCardAlumniAreTheDetailsCorrect.name,
        value: ''
    });

    const dispatch = useAppDispatch();

    const [pageLoading, setPageLoading] = useState(false);
    const [isRequestSubmitted, setIsRequestSubmitted] = useState(false);

    const MapToRequestDetails = () => {
        let requestObj: CreateRequestTaskCommand = {
            requestTypeCode: RequestTypesEnum.RCSICampusAccessCardAlumni.toString(),
            details: `${AcessCardAlumniAreTheDetailsCorrect.labelText}: ${formObj.AcessCardAlumniAreTheDetailsCorrect}`,
            subject: ''
        };

        return requestObj;
    }

    const successMessage = 'Your request has been queued, a member of the team will be in contact shortly.';

    const handleSave = async () => {
        const isStepValid = await trigger();

        if (isStepValid) {
            if (isDirty === true) {
                setPageLoading(true);
                dispatch(submitRequestAsync(MapToRequestDetails())).then((requestResponse: any) => {
                    if (requestResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                        reset({
                            [AcessCardAlumniAreTheDetailsCorrect.name]: false
                        });
                        setPageLoading(false);
                        setIsRequestSubmitted(true);
                        toast.success(successMessage);
                    }
                });
            }
        }
    };

    if (pageLoading === true) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    if (isRequestSubmitted === true) {
        return <SuccessResponse message={successMessage} />
    }

    return <>
        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ ml: '2%', mt: '0%', width: '96%' }}>

                <Grid item xs={12}>
                    <BackToRequests />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='h4'>RCSI campus access card</Typography>
                </Grid>

                <Grid item xs={12} sx={{ marginRight: '2%', marginBottom: '2%' }}>
                    <Typography variant='subtitle1'>
                        We are happy to review requests from alumni for a campus access card.
                        The campus access card provides access to the 1784 café, the Dispensary coffee shop
                        and provides access to the library in 26 York Street (subject to
                        restrictions at peak times). The campus access card does not extend to
                        study spaces outside of the library, nor does it provide access to the
                        gym or sports facilities.
                        <br></br>
                        <br></br>
                        Please note that alumni who request a campus access card must accept the
                        terms and conditions of the Alumni Campus Access Policy and RCSI retains the right to
                        revoke access at any point.
                    </Typography>
                </Grid>
            </Grid>
        </Box>

        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12}>
                    <AppDeclarationCheckbox
                        control={control}
                        name={AcessCardAlumniAreTheDetailsCorrect.name}
                        label={AcessCardAlumniAreTheDetailsCorrect.label}
                        option={declarationOption} />
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Grid container rowSpacing={4}>
                <Grid item xs={12}></Grid>

                <Grid item xs={8}></Grid>

                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        {MESSAGE_SUBMIT}
                    </Button>
                </Grid>

                <Grid item xs={1}></Grid>
            </Grid>
        </Box>
    </>
}