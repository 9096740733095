import { Box, Grid, Typography } from "@mui/material";
import { formatDateWithMonthName } from "../../../../app/utils/util";
import { WorkPostDto } from "../../models/workPostDto";
import { WorkPostLevel } from "../../../../app/models/staticData/workPostLevel";

interface Props {
    currentWorkDetails: WorkPostDto | null;
    workPostLevels: WorkPostLevel[] | null;
}

export function WorkDetails({ currentWorkDetails, workPostLevels }: Props) {

    return <>
        <Box sx={{ width: '100%', backgroundColor: '#e7e7e7', padding: '25px' }}>

            <Grid container rowSpacing={4}>

                <Grid item xs={12}>
                    <Typography variant='h6'>Current work details</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='subtitle2'>To Update work details please go to 'My profile' on the portal</Typography>
                </Grid>

                <Grid item xs={5} sm={4}>
                    Start date
                </Grid>

                <Grid item xs={7} sm={8}>
                    {currentWorkDetails?.startDate === undefined ? "" :
                        new Date(currentWorkDetails.startDate).getFullYear() === 0 ? "" :
                            formatDateWithMonthName(new Date(currentWorkDetails.startDate))}
                </Grid>

                <Grid item xs={5} sm={4}>
                    Post Level
                </Grid>

                <Grid item xs={7} sm={8}>
                    {currentWorkDetails?.workPostLevelId === undefined ? "" : workPostLevels?.find(x => x.id === currentWorkDetails?.workPostLevelId)?.value}
                </Grid>

                <Grid item xs={5} sm={4}>
                    Name of employer
                </Grid>

                <Grid item xs={7} sm={8}>
                    {currentWorkDetails?.employerId}
                </Grid>

            </Grid>
        </Box>
    </>
}