import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiRequestStatus } from "../../../../app/enums/apiRequestStatus";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useAppDispatch, useAppSelector } from "../../../../app/store/configureStore";
import { getValueFromId } from "../../../../app/utils/util";
import { CPDRecordStatusChange } from "../../models/CPDRecordStatusChange";
import { getCpdStatusHistoryAsync } from "../../viewCpdSlice";
import { CpdStatus } from "../../../../app/models/staticData/cpdStatus";
import { getReferenceDataAsync } from "../../../../app/slices/referenceDataSlice";

export function RecordStatusHistory() {
    const { creditRecordId } = useParams<{ creditRecordId: string }>();

    const dispatch = useAppDispatch();
    const { cpdRecordStatuses } = useAppSelector(state => state.ReferenceData);
    const { cpdStatusHistory, cpdStateStatus } = useAppSelector(state => state.cpd);
    const [statuses, setStatuses] = useState<CpdStatus[]>([]);

    useEffect(() => {
        if (!cpdStatusHistory) {
            dispatch(getCpdStatusHistoryAsync(creditRecordId))
        }
    }, [cpdStatusHistory, creditRecordId, dispatch]);

    useEffect(() => {
        if (!cpdRecordStatuses) {
            dispatch(getReferenceDataAsync());
        }
    }, [cpdRecordStatuses, dispatch]);

    useEffect(() => {
        if (cpdRecordStatuses) {
            setStatuses(cpdRecordStatuses);
        }
    }, [cpdRecordStatuses]);

    if (cpdStateStatus !== ApiRequestStatus.Fulfilled) {
        <LoadingComponent message="Loading CPD details.." />
    }

    return <>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell align="left">Changed by</TableCell>
                        <TableCell align="left">Status</TableCell>
                        <TableCell align="left">Note</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {cpdStatusHistory?.map((row: CPDRecordStatusChange) => (
                        <TableRow
                            key={row.creditRecordId}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell>
                                {row.date &&
                                    (new Date(row.date).getDate() + '/' +
                                        (new Date(row.date).getMonth() + 1) + '/' +
                                        new Date(row.date).getFullYear())
                                        }
                            </TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left">
                                {getValueFromId(statuses, row.cpdRecordStatusId)}
                            </TableCell>
                            <TableCell align="left">{row.note}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </>
}
