import { useHistory } from "react-router-dom";
import { AppPathEnum } from "../../app/enums/appPathEnum";
import { Button } from "@mui/material";

export default function BackToRequests() {
    const history = useHistory();

    return <Button variant="contained" color="primary"
        onClick={() => history.push(`${AppPathEnum.REQUEST}${window.location.search}`)}>
        Back to Requests
    </Button>
}