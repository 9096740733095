import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { CreateRequestTaskCommand } from "../models/request/createRequestTaskCommand";
import { toast } from "react-toastify";
import { history } from "../..";

interface requestState {
    isRequestSubmitted: boolean | null;
    submitRequestStatus: ApiRequestStatus;
}

const initialState: requestState = {
    isRequestSubmitted: null,
    submitRequestStatus: ApiRequestStatus.Idle
}

export const submitRequestAsync = createAsyncThunk<boolean, CreateRequestTaskCommand>(
    'requestSlice/submitRequestAsync',
    async (requestCommand, thunkAPI) => {
        try {
            return agent.Requests.submitRequestAsync(requestCommand);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const requestSlice = createSlice({
    name: 'requestSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(submitRequestAsync.pending, (state) => {
            state.submitRequestStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(submitRequestAsync.fulfilled, (state, action) => {
            state.isRequestSubmitted = action.payload;
            state.submitRequestStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(submitRequestAsync.rejected, (state) => {
            const errorMessage = `There's been a problem associating your contact details with this request. Please contact us at RCSIPortal@rcsi.com.`;
            toast.error(errorMessage);
            history.push({ pathname: `/server-error/${errorMessage}` });
            state.submitRequestStatus = ApiRequestStatus.Rejected;
        });
    })
})
