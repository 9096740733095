import { ComponentType } from "react";
import { Route, RouteComponentProps, RouteProps } from "react-router";
import { toast } from "react-toastify";
import { AppPathEnum } from "../enums/appPathEnum";
import { SpaMetadatas } from "../utils/spaMetadatas";
import Unauthorized from "../errors/Unauthorized";
import { useMsal } from "@azure/msal-react";
import { IdTokenClaims } from "../models/account/idTokenClaims";
import { useHistory } from "react-router-dom";
import { NOT_AUTHORISED_TO_ACCESS_THIS_AREA } from "../utils/constant";

interface Props extends RouteProps {
  component: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
  path: AppPathEnum;
}

export default function PrivateRoute({ component: Component, path, ...rest }: Props) {

  const { instance } = useMsal();
  const userClaims = instance.getAllAccounts()[0]?.idTokenClaims as unknown as IdTokenClaims;
  const userRoles = userClaims.extension_portalcrm_userType.split(',');

  const history = useHistory();

  const roleFilter = () => {
    const allowerRoles = SpaMetadatas.find(x => x.name === path)?.allowedRoles;

    return (userRoles.length === 0 ||
      userRoles.some(role => allowerRoles?.includes(role)) === false) === true;
  }

  return (
    <Route {...rest} render={props => {

      if (path === AppPathEnum.MEMBERSHIP_ONBOARDING
        && userClaims?.extension_portalcrm_isMemberOnboarding === false
        && userClaims?.extension_portalcrm_isFellowOnboarding === false) {
        toast.error(NOT_AUTHORISED_TO_ACCESS_THIS_AREA);
        return <Unauthorized message={NOT_AUTHORISED_TO_ACCESS_THIS_AREA} />
      }
      else if (path === AppPathEnum.MEMBERSHIP_RENEWAL) {
        if (userClaims?.extension_portalcrm_isMemberOnboarding === true
          || userClaims?.extension_portalcrm_isFellowOnboarding === true) {
          history.push(`${AppPathEnum.MEMBERSHIP_ONBOARDING}${window.location.search}`);
        }
        if (userClaims?.extension_portalcrm_isTransferOnboarding === true) {
          history.push(`${AppPathEnum.MEMBERSHIP_TRANSFER}${window.location.search}`);
        }
        else if (userClaims?.extension_portalcrm_isAffiliateOnboarding === true) {
          history.push(`${AppPathEnum.AFFILIATE_ASSOCIATE}${window.location.search}`);
        }
      }
      else if (path === AppPathEnum.MEMBERSHIP_TRANSFER
        && userClaims?.extension_portalcrm_userType === "") {
        return <Component {...props} />
      }
      else if (path === AppPathEnum.REQUEST) {
        return <Component {...props} />
      }
      else if (roleFilter()) {
        toast.error(NOT_AUTHORISED_TO_ACCESS_THIS_AREA);
        return <Unauthorized message={NOT_AUTHORISED_TO_ACCESS_THIS_AREA} />
      }

      return <Component {...props} />
    }}
    />
  );
}
