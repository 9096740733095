import { Box, Grid, Paper, Typography } from "@mui/material";
import PaymentUnsuccessful from "./PaymentUnsuccessful";

export default function PaymentResponse() {
    const queryParams = new URLSearchParams(window.location.search);
    const responseCode = queryParams.get("Rc");
    const retryUrl = queryParams.get("retry-url");

    return (
        <>
            <Box sx={{ minWidth: "100%" }} component={Paper}>

                <Grid container rowSpacing={4} sx={{ margin: '2%' }}>

                    {responseCode !== "C" && <>

                        <Grid item xs={12}>
                            <Typography variant='h4'>Payment successful</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>
                                Thank you for your payment. Once you receive an email confirming payment,
                                please logout and login again to access your receipt and member benefits.
                            </Typography>
                        </Grid>

                        <Grid item xs={12}></Grid>

                    </>}
                </Grid>

                {responseCode === "C" && retryUrl !== null && retryUrl !== '' &&
                    <Grid container rowSpacing={4} sx={{ marginLeft: '2%', marginTop: '2%' }}>
                        <PaymentUnsuccessful retryUrl={retryUrl} />
                    </Grid>
                }

            </Box>
        </>
    )
}