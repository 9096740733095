import { Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { GetColorCode } from "../../../../app/utils/util";
import { useEffect, useState } from "react";
import React from "react";
import AppInformationText from "../../../../app/components/AppInformationText";
import { ActivitiesAcrossDomainsHelpText } from "../../common/ActivitiesAcrossDomainsHelpText";
import { DomainSummaryDto } from "../../models/domainSummaryDto";

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiPaper-root": {
            width: "unset",
            marginLeft: "0"
        }
    },
    alignCenter: {
        textAlign: 'center'
    },
    gridItem: {
        textAlign: 'center',
        paddingTop: '16px'
    }
}));

interface Props {
    activitiesAcrossDomains: DomainSummaryDto | null;
    currentYear: number | null
}

export function ActivitiesAcrossDomains(props: Props) {
    const { activitiesAcrossDomains, currentYear } = props;
    const classes = useStyles();

    const [uniqueYears, setUniqueYears] = useState<number[]>();
    const [hasActivityAcrossDomains, setHasActivityAcrossDomains] = useState<boolean>(false);

    useEffect(() => {
        if (activitiesAcrossDomains !== null && currentYear !== null && setUniqueYears !== null) {

            let years: number[] = [];

            for (let year = currentYear - 1; year >= currentYear - 3; year--) {
                if (activitiesAcrossDomains && activitiesAcrossDomains?.summaryData &&
                    activitiesAcrossDomains?.summaryData?.filter(x => x.year === year).length > 0) {
                    years.push(year);
                }
            }
            setUniqueYears(years);

            if (years.length > 0) {
                setHasActivityAcrossDomains(true);
            }
        }
    }, [currentYear, activitiesAcrossDomains]);

    const getActivity = (year: number, domainCode: string) => {
        let domainId = activitiesAcrossDomains?.domains?.find(x => x.code === domainCode)?.id;
        return activitiesAcrossDomains?.summaryData?.find(x => x.year === year && x.domainId === domainId);
    }

    const setColor = (value: number | undefined) => {
        if (value === 0) return GetColorCode("RED");
        return GetColorCode("WHITE");
    }

    return (
        <>
            {hasActivityAcrossDomains &&
                <div className={classes.root}>
                    <Grid container rowSpacing={4}>

                        <Grid item xs={12}></Grid>

                        <Grid item xs={7} md={3} sm={5}>
                            <Typography variant='h6'>Activities across domains</Typography>
                        </Grid>

                        <Grid item xs={1} md={1} sm={1} sx={{ marginTop: '-7px' }}>
                            <AppInformationText information_text={ActivitiesAcrossDomainsHelpText()} placement={"top"} />
                        </Grid>

                        <Grid item xs={4} md={8} sm={6}></Grid>
                    </Grid>


                    <Box sx={{ flexGrow: 1, backgroundColor: "#616161", minWidth: "650px" }} component={Paper}>
                        <Grid container component={Paper} sx={{
                            backgroundColor: '#616161',
                            color: "#fff",
                            padding: 2,
                            textAlign: 'left',
                            width: '100%',
                            minWidth: '1100px'
                        }} columns={18}>
                            <Grid item xs={2} className={classes.alignCenter}>
                                PCS Year
                            </Grid>

                            <Grid item xs={2} className={classes.alignCenter}>
                                Clinical Skills
                            </Grid>

                            <Grid item xs={2} className={classes.alignCenter}>
                                Collaborative & Teamwork
                            </Grid>

                            <Grid item xs={2} className={classes.alignCenter}>
                                Communication & Interpersonal Skills
                            </Grid>

                            <Grid item xs={2} className={classes.alignCenter}>
                                Management (including Self Management)
                            </Grid>

                            <Grid item xs={2} className={classes.alignCenter}>
                                Patient Safety & Quality of Patient Care
                            </Grid>

                            <Grid item xs={2} className={classes.alignCenter}>
                                Professionalism
                            </Grid>

                            <Grid item xs={2} className={classes.alignCenter}>
                                Relating to Patients
                            </Grid>

                            <Grid item xs={2} className={classes.alignCenter}>
                                Scholarship
                            </Grid>
                        </Grid>

                        {uniqueYears && uniqueYears.map((year: number, index: number) => (
                            <React.Fragment key={index}>

                                <Grid container component={Paper} columns={18}
                                    sx={{
                                        width: '100%',
                                        minWidth: '1100px'
                                    }}>

                                    <Grid item xs={2}>
                                        <Grid className={classes.gridItem} sx={{
                                            color: 'primary.main'
                                        }}>
                                            {`${year} - ${year + 1}`}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={2} sx={{ padding: 1 }}>
                                        <Grid className={classes.gridItem} sx={{
                                            backgroundColor: setColor(getActivity(year, "CS")?.total),
                                            textAlign: 'center',
                                            padding: 1
                                        }}>
                                            {getActivity(year, "CS")?.total}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={2} sx={{ padding: 1 }}>
                                        <Grid className={classes.gridItem} sx={{
                                            backgroundColor: setColor(getActivity(year, "C&T")?.total),
                                            textAlign: 'center',
                                            padding: 1
                                        }}>
                                            {getActivity(year, "C&T")?.total}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={2} sx={{ padding: 1 }}>
                                        <Grid className={classes.gridItem} sx={{
                                            backgroundColor: setColor(getActivity(year, "CIS")?.total),
                                            textAlign: 'center',
                                            padding: 1
                                        }}>
                                            {getActivity(year, "CIS")?.total}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={2} sx={{ padding: 1 }}>
                                        <Grid className={classes.gridItem} sx={{
                                            backgroundColor: setColor(getActivity(year, "MGMT")?.total),
                                            textAlign: 'center',
                                            padding: 1
                                        }}>
                                            {getActivity(year, "MGMT")?.total}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={2} sx={{ padding: 1 }}>
                                        <Grid className={classes.gridItem} sx={{
                                            backgroundColor: setColor(getActivity(year, "PSQPC")?.total),
                                            textAlign: 'center',
                                            padding: 1
                                        }}>
                                            {getActivity(year, "PSQPC")?.total}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={2} sx={{ padding: 1 }}>
                                        <Grid item className={classes.gridItem} sx={{
                                            backgroundColor: setColor(getActivity(year, "PFM")?.total),
                                            textAlign: 'center',
                                            padding: 1
                                        }}>
                                            {getActivity(year, "PFM")?.total}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={2} sx={{ padding: 1 }}>
                                        <Grid item className={classes.gridItem} sx={{
                                            backgroundColor: setColor(getActivity(year, "SCP")?.total),
                                            textAlign: 'center',
                                            padding: 1
                                        }}>
                                            {getActivity(year, "RP")?.total}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={2} sx={{ padding: 1 }}>
                                        <Grid item className={classes.gridItem} sx={{
                                            backgroundColor: setColor(getActivity(year, "SCP")?.total),
                                            textAlign: 'center',
                                            padding: 1
                                        }}>
                                            {getActivity(year, "SCP")?.total}
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </React.Fragment>
                        ))
                        }
                    </Box>
                </div >
            }
        </>
    )
}