const pcsPlanFormModel = {
    formId: 'CpdForm',
    formField: {
      StartDate: {
        name: 'StartDate',
        label: 'Start Date'
      },
      PostLevel: {
        name: 'PostLevel',
        label: 'Post Level'
      },
      NameOfEmployer: {
        name: 'NameOfEmployer',
        label: 'Name of Employer'
      },
      AnnualPlanStatus: {
        name: 'AnnualPlanStatus',
        label: 'Annual plan status'
      },
      External: {
        name: 'External',
        label: 'External*',
        requiredErrorMsg: 'External is required'
      },
      WorkBasedLearning: {
        name: 'WorkBasedLearning',
        label: 'Work Based Learning*',
        requiredErrorMsg: 'Work Based Learning is required'
      },
      PracticeReview: {
        name: 'PracticeReview',
        label: 'Practice Review*',
        requiredErrorMsg: 'Practice Review is required'
      },
      FirstName: {
        name: 'FirstName',
        label: 'First name*',
        requiredErrorMsg: 'First name is required'
      },
      Surname: {
        name: 'Surname',
        label: 'Surname*',
        requiredErrorMsg: 'Surname is required'
      },
      Title: {
        name: 'Title',
        label: 'Title*',
        requiredErrorMsg: 'Title is required'
      },
      IMCNumber: {
        name: 'IMCNumber',
        label: 'IMC Number*',
        requiredErrorMsg: 'IMC number is required'
      },
      EmailAddress: {
        name: 'EmailAddress',
        label: 'Email Address',
        expression: /^(?=.{1,64}@.{4,48}$)([a-zA-Z0-9'_-]+([\.][a-zA-Z0-9'_-]+)*@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z0-9]+([-]+[a-zA-Z0-9]+)*\.)+(?=.{2,})([a-zA-Z]+([-]*[a-zA-Z]+)*))))$/,
        typeError: 'Email Address is not valid'
      },
      ReviewerComments: {
        name: 'ReviewerComments',
        label: 'Reviewer Feedback*',
        requiredErrorMsg: 'Reviewer Comments are required'
      },
      PractionerComments: {
        name: 'PractionerComments',
        label: 'My Comments*'
      }
    }
  };
  
  export default pcsPlanFormModel;