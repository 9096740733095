import { StyleSheet, View, Text, Font } from '@react-pdf/renderer';
import { monthNames, weekDayNames } from '../../app/utils/util';
import { FAMDetails } from '../../app/models/membership/famDetails';
import { MemberType } from '../../app/enums/memberType';
import { FAMOnboarding } from '../../app/models/membership/famOnboarding';
import { FamMembershipType } from '../../app/models/staticData/famMembershipType';

Font.register({
    family: 'Open Sans',
    fonts: [
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf', fontWeight: 700 },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf', fontWeight: 800 }
    ]
});

const styles = StyleSheet.create({
    image: {
        height: 60,
        width: 60,
        alignContent: 'flex-start'
    },
    page: {
        flexDirection: "column",
        padding: 25
    },
    table: {
        fontSize: 10,
        width: 550,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignContent: "stretch",
        flexWrap: "nowrap",
        alignItems: "stretch"
    },
    row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignContent: "stretch",
        flexWrap: "nowrap",
        alignItems: "stretch",
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 35,
        fontSize: 11
    },
    headerText: {
        fontSize: 11
    },
    boldText: {
        fontFamily: 'Open Sans',
        fontWeight: 800
    },
    width10: {
        width: '10%'
    },
    center: {
        alignContent: 'center',
        width: "40%"
    },
    right: {
        alignContent: 'flex-end',
        width: "30%"
    },
    left: {
        alignContent: 'flex-start'
    }
});

interface Props {
    famMembershipTypes: FamMembershipType[] | null;
    famOnboardingDetails: FAMOnboarding | null;
    currentYear: number | null;
    famDetails: FAMDetails | null;
}

export function Body({ famMembershipTypes, famOnboardingDetails, currentYear, famDetails }: Props) {

    return (
        <>
            <View style={styles.row}></View>

            <View style={styles.table}>
                <Text style={styles.row}>
                    {weekDayNames[new Date().getDay()]}{','}
                    {" "} {new Date().getDate()} {monthNames[new Date().getMonth()]} {new Date().getFullYear()}
                </Text>
            </View>

            <View style={styles.row}></View>

            <View style={styles.table}>
                <Text style={[styles.row, styles.boldText]}>
                    RCSI
                    {famMembershipTypes?.find(x => x.id === famDetails?.membershipTypeId)?.value === MemberType.FELLOW ?
                        " Fellowship " : " Membership "}
                    Confirmation - {famOnboardingDetails?.nameOnCert}{"."}
                </Text>
            </View>

            <View style={styles.row}></View>

            <View style={styles.table}>
                <Text style={[styles.row]}>
                    To whom it may concern,
                </Text>
            </View>

            <View style={styles.table}>
                <Text style={[styles.row]}>
                    This is to confirm that
                    {" "}{famOnboardingDetails?.nameOnCert} is a
                    {" "}{famMembershipTypes?.find(x => x.id === famDetails?.membershipTypeId)?.value} of RCSI
                    (Royal College of Surgeons in Ireland) and is considered In Good Standing for the current subscription
                    year (1 May {currentYear} - 30 April {currentYear && currentYear + 1}).
                </Text>
            </View>

            <View style={styles.table}>
                <Text style={[styles.row]}>
                    If you have any further queries, please do not hesitate to contact the Fellows and
                    Members Office by emailing fellows@rcsi.com.
                </Text>
            </View>

            <View style={styles.row}></View>

            <View style={styles.table}>
                <Text style={[styles.row]}>
                    Yours sincerely,{"\n"}{"\n"}
                    RCSI Fellows & Members Team
                </Text>
            </View>
        </>
    )
}
