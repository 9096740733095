import { Box, Button, Grid, Typography } from "@mui/material";
import { FamMembershipSubscription } from "../../../app/models/membership/famMembershipSubscription";
import React from "react";
import { Link } from "react-router-dom";
import { formatDateMonthName } from "../../../app/utils/util";
import { Commitment } from "../../../app/models/donation/commitment";
import { Fund } from "../../../app/models/staticData/fund";
import { RecurrencePeriod } from "../../../app/models/staticData/recurrencePeriod";

interface Props {
    famMembershipSubscriptions: FamMembershipSubscription[] | null;
    donationDetails: Commitment[] | null;
    funds: Fund[] | null;
    recurrencePeriods: RecurrencePeriod[] | null;
    heyCentricUrl: string | null;
}

export function MyRecurringPayments({ famMembershipSubscriptions, donationDetails,
    funds, recurrencePeriods, heyCentricUrl }: Props) {
    return (
        <>
            <Box sx={{ width: '100%' }}>

                <Grid container rowSpacing={4}>

                    <Grid item xs={12}></Grid>

                    <Grid item xs={12}>
                        <Typography variant='h6'>Manage recurring payment & card details </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <b>Subscription name</b>
                    </Grid>

                    <Grid item xs={2}>
                        <b>Expiry date</b>
                    </Grid>

                    <Grid item xs={4} sm={3}><b>Recurrence period</b></Grid>

                    <Grid item xs={2} sm={3}><b>Amount</b></Grid>

                    {famMembershipSubscriptions?.filter(x => x.active === true).map((item: FamMembershipSubscription) => (
                        <React.Fragment key={item.famDetailsId}>
                            <Grid item xs={4} className="wordWrap">
                                Fellow/Member annual membership subscription
                            </Grid>

                            <Grid item xs={2} className="wordWrap">
                                {item.expiryDate && formatDateMonthName(new Date(item.expiryDate))}
                            </Grid>

                            <Grid item xs={4} sm={3} className="wordWrap">Annual</Grid>

                            <Grid item xs={2} sm={3} className="wordWrap">Membership rate</Grid>
                        </React.Fragment>
                    ))}

                    {donationDetails?.filter(x => x.active === true).map((item: Commitment) => (
                        <React.Fragment key={item.commitmentId}>
                            <Grid item xs={4} className="wordWrap">
                                {funds?.find(x => x.id === item.fundId)?.value}
                            </Grid>

                            <Grid item xs={2} className="wordWrap">
                                {item.expiryDate && formatDateMonthName(new Date(item.expiryDate))}
                            </Grid>

                            <Grid item xs={4} sm={3} className="wordWrap">
                                {recurrencePeriods?.find(x => x.id === item.recurrencePeriodId)?.value}
                            </Grid>

                            <Grid item xs={2} sm={3} className="wordWrap">{item.amount}</Grid>
                        </React.Fragment>
                    ))}

                    <Grid item xs={12}></Grid>

                    {((famMembershipSubscriptions && famMembershipSubscriptions.length > 0) ||
                        (donationDetails && donationDetails.length > 0)) &&
                        <>
                            <Grid item xs={12}>
                                <Typography variant="body1" sx={{ color: 'primary.main' }}>
                                    <b>Note</b> - By clicking on the 'Manage My Subscriptions' button below you will be directed to a 3<sup>rd</sup> party site.
                                    <b> Please login to this portal with your preferred email address.</b>
                                    <br />
                                    <i>
                                        (If you are unsure which email this is please check your preferred email address
                                        on <a href={`${String(process.env.REACT_APP_UPDATE_PROFILE_URI)}${window.location.search}`} style={{ color: 'primary.main' }}>your profile</a>.)
                                        Location of practice determines membership rate.
                                    </i>
                                </Typography>
                            </Grid>

                            <Grid item xs={4}>

                                {heyCentricUrl && <Button variant="contained" color="primary" href={heyCentricUrl}>
                                    <Link target="_blank" style={{ color: 'white', textDecoration: 'unset' }}
                                        to={{ pathname: heyCentricUrl }}>Manage my subscriptions</Link>
                                </Button>}

                            </Grid>

                            <Grid item xs={8}></Grid>
                        </>
                    }
                </Grid>
            </Box>
        </>
    );
}