import { PcsPlanStatus } from "../models/pcsPlanStatus"

export function PcsPlanStatuses(statusCode: string) {
    const pcsPlanStatuses: PcsPlanStatus[] = [
        { code: 'NS', value: 'Not Started - No plan details have been saved to date.' },
        { code: 'DT', value: 'Draft - Plan has started, but has not been submitted.' },
        { code: 'SD', value: 'Submitted - You have successfully submitted your annual plan and received 5 credits (see 6 year summary)' },
        { code: 'RV', value: 'Reviewer Validation (optional) - The reviewer details you have submitted are in the process of being validated by the PCS Office.' },
        { code: 'RP', value: 'Review Request Pending (optional)  - Your review request has been sent and is awaiting review.' },
        { code: 'UR', value: 'Under Review (optional) - Reviewer has accepted your request to review your plan.' },
        { code: 'RRD', value: 'Review Request Declined (optional) - Reviewer has declined your request to review your plan.' },
        { code: 'RRI', value: 'Review Request Invalid (optional) - please add correct reviewer to review your plan.' },
        { code: 'RRC', value: 'Review Request Cancelled (optional) - Review request has been cancelled.' },
        { code: 'RD', value: 'Reviewed (optional) - Your plan has been reviewed and you have received an additional credit.' },
    ];

    return <>
        {pcsPlanStatuses.find(x => x.code === statusCode)?.value}
    </>
}