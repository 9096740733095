import { Box, Paper, Grid, Typography, Button } from "@mui/material";
import SubscriptionFeesDue from "./SubscriptionFeesDue";
import { PaymentDto } from "../payment/paymentDto";
import { Country } from "../staticData/country";
import { FamMembershipType } from "../staticData/famMembershipType";
import { FAMDetails } from "./famDetails";
import MembershipFormModel from "./membershipFormModel";
import PaymentUnsuccessful from "../../components/PaymentUnsuccessful";
import { SpaName } from "../../enums/spaName";
import { MembershipTypeEnum } from "../../enums/MembershipTypeEnum";
import { CountryCodeEnum } from "../../enums/countryCodeEnum";
import { CurrencyCodeIconEnum } from "../../enums/currencyCodeIconEnum";
import { AppPathEnum } from "../../enums/appPathEnum";
import { useHistory } from "react-router-dom";

interface Props {
    basketId: string | null;
    paymentDetails: PaymentDto | null;
    famDetails: FAMDetails;
    countries: Country[];
    famMembershipTypes: FamMembershipType[];
    retryUrl: string | undefined;
    membershipType: string | undefined;
    paymentOnClickHandler: (e: React.MouseEvent<HTMLButtonElement>) => void;
    SpaName: SpaName;
}

export default function MembershipPaymentSummary(props: Props) {
    const {
        formField: {
            MembershipType,
            MembershipCountry
        }
    } = MembershipFormModel;

    const history = useHistory();

    const { paymentDetails, famDetails, countries, famMembershipTypes, retryUrl, membershipType } = props;

    const payByDirectDebit = async () => {
        let previousSpa = "";

        if (props.SpaName === SpaName.AFFILIATE) {
            previousSpa = AppPathEnum.AFFILIATE_ASSOCIATE;
        }
        else if (props.SpaName === SpaName.MEMBER_ONBOARDING) {
            previousSpa = AppPathEnum.MEMBERSHIP_ONBOARDING;
        }
        else if (props.SpaName === SpaName.MEMBER_TRANSFER) {
            previousSpa = AppPathEnum.MEMBERSHIP_TRANSFER;
        }
        history.push(`${AppPathEnum.MEMBERSHIP_DIRECT_DEBIT}${window.location.search}&cancelUrl=${previousSpa}&basketId=${props.basketId}`);
    }

    return <>
        <Box sx={{ flexGrow: 1 }} component={Paper}>

            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                {((new URLSearchParams(window.location.search)).get("cancelPayment") === undefined || (new URLSearchParams(window.location.search)).get("cancelPayment") !== 'true') &&
                    <>
                        <Grid item xs={12}>
                            <Typography variant='h4'>Payment summary</Typography>
                        </Grid>

                        <Grid item xs={12}></Grid>

                        <Grid item xs={6} md={4} sm={3} sx={{
                            color: 'black', textAlign: 'start',
                            marginBottom: '3px', paddingTop: '1.5% !important', paddingBottom: '1.5%'
                        }}>
                            <b>{MembershipType.label}</b>
                        </Grid>

                        <Grid item xs={1} md={1} sm={1}></Grid>

                        <Grid item xs={5} md={7} sm={8} sx={{ paddingTop: '1.5% !important', paddingBottom: '1.5%' }}>
                            {membershipType}
                        </Grid>

                        <Grid item xs={6} md={4} sm={3} sx={{
                            color: 'black', textAlign: 'start',
                            marginBottom: '3px', paddingTop: '1.5% !important', paddingBottom: '1.5%'
                        }}>
                            <b>{MembershipCountry.label}</b>
                        </Grid>

                        <Grid item xs={1} md={1} sm={1}></Grid>

                        <Grid item xs={5} md={7} sm={8} sx={{ paddingTop: '1.5% !important', paddingBottom: '1.5%' }}>
                            {famDetails.countryOfPracticeId === null ? '' : countries.find(x => x.id ===
                                famDetails.countryOfPracticeId)?.value}
                        </Grid>

                        <Grid item xs={11.5} sm={11.5} sx={{ marginTop: "2%", backgroundColor: "#b9b9b9", padding: "1%" }}>
                            <SubscriptionFeesDue paymentDetails={paymentDetails} />
                        </Grid>

                        <Grid item xs={11.5} sx={{ fontStyle: 'italic' }}>
                            {(famMembershipTypes.find(x => x.id === famDetails.membershipTypeId)?.code === MembershipTypeEnum.FELLOW ||
                                famMembershipTypes.find(x => x.id === famDetails.membershipTypeId)?.code === MembershipTypeEnum.MEMBER) &&
                                countries.find(x => x.id === famDetails.countryOfPracticeId)?.alpha3Code === CountryCodeEnum.IRELAND &&
                                <>
                                    <Typography variant='subtitle1'>Professional Competence Scheme (PCS) Surgery</Typography>
                                    <br></br>
                                    <Typography variant='subtitle1'>If you are a Member or Fellow and practice in the Republic of Ireland, you are entitled
                                        to a {CurrencyCodeIconEnum.EUR}150 discount on your Professional Competence Scheme (PCS) fee if you pay
                                        before 1 July of the current PCS year. *The PCS & Membership Year runs from
                                        1st May - 30th April each year.
                                    </Typography>
                                </>
                            }
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>
                                Please note that by clicking on the below you will be brought to a
                                third party system for payment processing.
                            </Typography>
                        </Grid>

                        {props.SpaName !== SpaName.AFFILIATE &&
                            <Grid item xs={12}>
                                <Typography variant='subtitle1'>
                                    Please note: If you sign up to recurring payments, your annual membership subscriptions
                                    will be taken automatically every year. This will maintain your 'In Good Standing' status.
                                </Typography>
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" onClick={props.paymentOnClickHandler}>
                                Pay by card
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" onClick={payByDirectDebit}>
                                Pay by direct debit
                            </Button>
                        </Grid>

                        <Grid item xs={12}></Grid>

                    </>
                }

                {(new URLSearchParams(window.location.search)).get("cancelPayment") === 'true' && retryUrl && <PaymentUnsuccessful retryUrl={retryUrl} />}

            </Grid>
        </Box >
    </>
}