import { Box, Grid, Paper, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import AppSelectList from "../../components/AppSelectList";
import AppTextareaInput from "../../components/AppTextareaInput";
import { MapCountriesToDropdownItems } from "../../slices/convertStaticDataToDropdownItems";
import { PersonalDetailsDto } from "../person/personalDetailsDto";
import { Country } from "../staticData/country";
import MembershipFormModel from "./membershipFormModel";

interface Props {
    countries: Country[] | null;
    personalDetails: PersonalDetailsDto | null;
}

export default function MemberPostalAddress(props: Props) {
    const { countries, personalDetails } = props;
    const methods = useFormContext();
    const { control, reset } = methods;

    const {
        formField: {
            PostalAddressLines,
            PostalAddressCountry
        }
    } = MembershipFormModel;

    useEffect(() => {
        if (personalDetails !== undefined && personalDetails !== null && countries && countries?.length > 0) {

            reset({
                [PostalAddressLines.name]: (personalDetails.postalAddress === null ||
                    personalDetails.postalAddress === undefined) ? '' : personalDetails.postalAddress,
                [PostalAddressCountry.name]: (personalDetails.postalAddressCountryId === null ||
                    personalDetails.postalAddressCountryId === undefined) ? '' : personalDetails.postalAddressCountryId
            })
        }
    }, [personalDetails, countries, reset]);

    return <>
        <Box sx={{ width: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12} sx={{ marginTop: '-1%' }}>
                    <Typography variant='subtitle1' sx={{ fontWeight: "500" }}>Postal address</Typography>
                    <Typography variant="subtitle1" sx={{ color: 'primary.main' }}>
                        {PostalAddressLines.information_text}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppTextareaInput control={control} name={PostalAddressLines.name}
                        label={PostalAddressLines.label} />
                </Grid>

                <Grid item xs={12}>
                    <AppSelectList
                        control={control}
                        name={PostalAddressCountry.name}
                        label={PostalAddressCountry.label}
                        items={MapCountriesToDropdownItems(countries ?? [])}
                    />
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>
    </>
}