import * as yup from 'yup';
import membershipFormModel from '../../../app/models/membership/membershipFormModel';
import { PersonalDetailsValidationSchema, PostalAddressValidationSchema } from '../../../app/models/membership/validationSchemas';
import { MESSAGE_NOT_A_VALUE } from '../../../app/utils/constant';
import { UserRole } from '../../../app/enums/userRole';

const {
  formField: {
    SurgeonsScopeMagazine,
    TheSurgeonJournal,
    AlumniMagazinePreference,
    LocationCountry,
    CountrySubdivision,
    CurrentUserRoles
  }
} = membershipFormModel;

export const updateProfileValidationSchemas = [
  PersonalDetailsValidationSchema,
  PostalAddressValidationSchema,
  yup.object().shape({
    [LocationCountry.name]: yup.string().required(`${LocationCountry.requiredErrorMsg}`),
    [CountrySubdivision.name]: yup.string().test(`${CountrySubdivision.name}`,
      `${CountrySubdivision.requiredErrorMsg}`,
      function (countrySubdivision) {
        if (countrySubdivision === MESSAGE_NOT_A_VALUE) {
          return true;
        }
        if (countrySubdivision === "") {
          return false;
        }
        return true;
      }),
    [AlumniMagazinePreference.name]: yup.number().when([CurrentUserRoles.name], ([currentUserRoles]: string[]) => {
      if (currentUserRoles.includes(UserRole.ALUMNI) || currentUserRoles.includes(UserRole.ALUMNI_BAH)) {
        return yup.string().required(`${AlumniMagazinePreference.requiredErrorMesssag}`);
      }
      return yup.string().notRequired();
    }),

    [SurgeonsScopeMagazine.name]: yup.number().when([CurrentUserRoles.name], ([currentUserRoles]: string[]) => {
      if (currentUserRoles.includes(UserRole.FAM_FELLOW) || currentUserRoles.includes(UserRole.FAM_MEMBER)) {
        return yup.string().required(`${SurgeonsScopeMagazine.requiredErrorMesssag}`);
      }
      return yup.string().notRequired();
    }),

    [TheSurgeonJournal.name]: yup.number().when([CurrentUserRoles.name], ([currentUserRoles]: string[]) => {
      if (currentUserRoles.includes(UserRole.FAM_FELLOW) || currentUserRoles.includes(UserRole.FAM_MEMBER)) {
        return yup.string().required(`${TheSurgeonJournal.requiredErrorMesssag}`);
      }
      return yup.string().notRequired();
    }),
  })]