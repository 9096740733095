import { Box, Paper, Grid, Typography } from "@mui/material";

interface Props {
    header: string;
    body: string;
}

export default function SuccessResponseMessage({ header, body }: Props) {

    return <>
        <Box sx={{ minWidth: "100%" }} component={Paper}>

            <Grid container rowSpacing={4}>

                <Grid item xs={12}></Grid>

                <Grid item xs={12} sx={{ marginLeft: '2%' }}>
                    <Typography variant='h4'>{header}</Typography>
                </Grid>

                <Grid item xs={12} sx={{ marginLeft: '2%', marginRight: '2%', marginBottom: '2%' }}>
                    <Typography variant='subtitle1'>{body}</Typography>
                </Grid>

            </Grid>
        </Box>
    </>
}
