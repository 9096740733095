import { FormHelperText, Grid } from "@mui/material";
import { useState } from "react";
import { UseControllerProps, useController } from "react-hook-form";
import AppInformationText from "../../../app/components/AppInformationText";
import { CheckBoxItem } from "../../../app/models/common/checkBoxItem";

interface Props extends UseControllerProps {
    option: CheckBoxItem;
    information_text?: string;
}

const AppDeclarationCheckbox = (props: Props) => {
    const { option, information_text } = props;
    const { fieldState, field } = useController({ ...props });
    const [, setValue] = useState(field.value || []);

    return (
        <>
            <Grid container>

                <Grid item xs={11} sm={11}>
                    <div key={option.id}>
                        <Grid container>
                            <Grid item xs={11.5}>
                                <b>Declaration:</b>
                                <br></br>
                                <br></br>
                                I do solemnly and sincerely declare and promise that I will observe and be obedient to the Statutes, Bye-Laws
                                and Ordinances of the Royal College of Surgeons in Ireland, and that I will to the utmost of my power,
                                endeavour to promote the reputation, honour and dignity of the said College. I promise to place the welfare of
                                my patients above all else. I promise to be respectful of my fellow healthcare professionals and will readily
                                offer them my assistance and support. I further promise to continue to learn and teach and maintain my competence,
                                for the benefit of my patients, trainees and the society in which I serve.
                                <br></br>
                                <br></br>
                                <b>By ticking this box, you confirm that you agree to abide by the College's Statutes, Bye-laws, and Ordinances of Membership.</b>
                            </Grid>

                            <Grid item xs={0.5} sx={{ alignSelf: "end" }}>
                                <input
                                    {...field}
                                    onChange={(e) => {
                                        if (e.target.checked === true &&
                                            !field.value?.includes(option.id)) {
                                            field.value.push(option.id);
                                        }
                                        else {
                                            field.value = field.value.filter((e: string) => e !== option.id);
                                        }
                                        field.onChange(field.value);
                                        setValue(field.value);
                                    }}
                                    key={option.id}
                                    type="checkbox"
                                    checked={field.value === undefined ? false : field.value?.includes(option.id)}
                                    value={option.value}
                                />
                            </Grid>
                        </Grid>
                        <br></br>
                    </div>
                </Grid>

                <Grid item xs={1} sx={{ alignSelf: "end" }}>
                    <AppInformationText information_text={information_text} />
                </Grid>

                <Grid item xs={11} sm={12}>
                    {fieldState.error &&
                        <FormHelperText sx={{ color: 'primary.main' }}>{fieldState.error.message}</FormHelperText>}
                </Grid>

            </Grid>
        </>
    );
};

export default AppDeclarationCheckbox;