import { useMsal } from "@azure/msal-react";
import { UserRole } from "../../app/enums/userRole";
import { IdTokenClaims } from "../../app/models/account/idTokenClaims";
import AffiliateMembershipRenewForm from "./AffiliateMembershipRenewForm";
import NonAffiliateMembershipRenewalForm from "./NonAffiliateMembershipRenewalForm";

export default function MembershipRenewForm() {

  const { instance } = useMsal();
  const userRoles = (instance.getActiveAccount()?.idTokenClaims as unknown as IdTokenClaims).extension_portalcrm_userType.split(',');

  return (
    <>
      {userRoles.filter(x => x === UserRole.FAM_AFFILIATE).length > 0 &&
        <AffiliateMembershipRenewForm />
      }

      {userRoles.filter(x => x === UserRole.FAM_AFFILIATE).length === 0 &&
        <NonAffiliateMembershipRenewalForm />
      }
    </>
  );
};
