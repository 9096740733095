import { Box, Paper, Grid, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import AppTextInput from "../../app/components/AppTextInput";
import MembershipFormModel from "../../app/models/membership/membershipFormModel";
import { FAMOnboarding } from "../../app/models/membership/famOnboarding";

interface Props {
    famOnboardingDetails: FAMOnboarding[] | null;
}

export default function OnboardingInformation({ famOnboardingDetails }: Props) {
    const methods = useFormContext();
    const { reset } = methods;

    const {
        formField: {
            NameOnCertificate
        }
    } = MembershipFormModel;

    useEffect(() => {
        reset({
            [NameOnCertificate.name]: famOnboardingDetails === null ?
                '' : famOnboardingDetails[0]?.nameOnCert
        })
    }, [reset]);

    return <>
        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12} sx={{ marginTop: '-1%' }}>
                    <Typography variant='subtitle1' sx={{ fontWeight: "500" }}>Onboarding information</Typography>
                </Grid>

                <Grid item xs={11}>
                    <Typography variant="subtitle1" sx={{ color: 'primary.main' }}>
                        {NameOnCertificate.information_text}
                    </Typography>
                </Grid>

                <Grid item xs={1}></Grid>

                <Grid item xs={12}>
                    <AppTextInput name={NameOnCertificate.name} label={NameOnCertificate.label} />
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>
    </>
}