import { Box, Grid, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import AppTextInput from "../../../app/components/AppTextInput";
import membershipFormModel from "../../../app/models/membership/membershipFormModel";

export function PCSDetails() {
    const {
        IMCNumber,
        RegType,
        Scheme,
        EngagedInMedicine,
        RegistrationSpecialty,
        Subspecialty,
        EducationDetails,
        WorkDetails
    } = membershipFormModel.formField;

    const { control } = useFormContext();

    return (
        <>
            <Box sx={{ width: '100%' }}>

                <Typography variant="h4" gutterBottom sx={{ mb: 4 }}></Typography>

                <Grid container rowSpacing={4}>
                    <Grid item xs={12}>
                        <Typography variant='subtitle1'>Please enter your PCS details.</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <AppTextInput control={control} name={IMCNumber.name} label={IMCNumber.label} />
                    </Grid>

                    <Grid item xs={12}>
                        <AppTextInput control={control} name={RegType.name} label={RegType.label} />
                    </Grid>

                    <Grid item xs={12}>
                        <AppTextInput control={control} name={Scheme.name} label={Scheme.label} />
                    </Grid>

                    <Grid item xs={12}>
                        <AppTextInput control={control} name={EngagedInMedicine.name} label={EngagedInMedicine.label} />
                    </Grid>

                    <Grid item xs={12}>
                        <AppTextInput control={control} name={RegistrationSpecialty.name} label={RegistrationSpecialty.label} />
                    </Grid>

                    <Grid item xs={12}>
                        <AppTextInput control={control} name={Subspecialty.name} label={Subspecialty.label} />
                    </Grid>

                    <Grid item xs={12}>
                        <AppTextInput control={control} name={EducationDetails.name} label={EducationDetails.label} />
                    </Grid>

                    <Grid item xs={12}>
                        <AppTextInput control={control} name={EducationDetails.name} label={EducationDetails.label} />
                    </Grid>

                    <Grid item xs={12}>
                        <AppTextInput control={control} name={WorkDetails.name} label={WorkDetails.label} />
                    </Grid>

                    <Grid item xs={12}>
                        <AppTextInput control={control} name={WorkDetails.name} label={WorkDetails.label} />
                    </Grid>

                </Grid>
            </Box>
        </>
    );
}