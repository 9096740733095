import { Box, Paper, Grid, Typography, Button } from "@mui/material";
import BackToRequests from "./BackToRequests";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { CheckBoxItem } from "../../app/models/common/checkBoxItem";
import AppDeclarationCheckbox from "./models/AppDeclarationCheckbox";
import RequestFormModel from "./models/requestFormModel";
import AppTextareaInput from "../../app/components/AppTextareaInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { ClassNotesValidationSchema } from "./models/requestValidationSchemas";
import { toast } from "react-toastify";
import { RequestTypesEnum } from "../../app/enums/requestTypesEnum";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { CreateRequestTaskCommand } from "../../app/models/request/createRequestTaskCommand";
import { submitRequestAsync } from "../../app/slices/requestSlice";
import { useAppDispatch } from "../../app/store/configureStore";
import { API_REQUEST_FULLFILLED, MESSAGE_PROCESSING_DETAILS, MESSAGE_SUBMIT } from "../../app/utils/constant";
import SuccessResponse from "./models/SuccessResponse";

export default function ClassNotes() {
    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(ClassNotesValidationSchema)
    });
    const { control, trigger, reset, formState: { isDirty } } = methods;
    const formObj = methods.watch();

    const {
        ClassNotes: {
            PersonalProfessionalUpdates,
            ClassNotesAreTheDetailsCorrect
        }
    } = RequestFormModel;

    const [declarationOption,] = useState<CheckBoxItem>({
        id: ClassNotesAreTheDetailsCorrect.name,
        value: ''
    });

    const dispatch = useAppDispatch();

    const [pageLoading, setPageLoading] = useState(false);
    const [isRequestSubmitted, setIsRequestSubmitted] = useState(false);

    const MapToRequestDetails = () => {
        let requestObj: CreateRequestTaskCommand = {
            requestTypeCode: RequestTypesEnum.SubmitClassNotes.toString(),
            details: `${PersonalProfessionalUpdates.label}: ${formObj.PersonalProfessionalUpdates}\r\n${ClassNotesAreTheDetailsCorrect.labelText}: ${formObj.ClassNotesAreTheDetailsCorrect}`,
            subject: ''
        };

        return requestObj;
    }

    const successMessage = 'Thank you so much for sharing your news, please be advised the Alumni team will not publish your update without contacting you in advance.';

    const handleSave = async () => {
        const isStepValid = await trigger();

        if (isStepValid) {
            if (isDirty === true) {
                setPageLoading(true);
                dispatch(submitRequestAsync(MapToRequestDetails())).then((requestResponse: any) => {
                    if (requestResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                        reset({
                            [PersonalProfessionalUpdates.name]: '',
                            [ClassNotesAreTheDetailsCorrect.name]: false
                        });
                        setPageLoading(false);
                        setIsRequestSubmitted(true);
                        toast.success(successMessage);
                    }
                });
            }
        }
    };

    if (pageLoading === true) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    if (isRequestSubmitted === true) {
        return <SuccessResponse message={successMessage} />
    }

    return <>
        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ ml: '2%', mt: '0%', width: '96%' }}>

                <Grid item xs={12}>
                    <BackToRequests />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='h4'>Submit class notes</Typography>
                </Grid>

                <Grid item xs={12} sx={{ marginRight: '2%', marginBottom: '2%' }}>
                    <Typography variant='subtitle1'>
                        We are always delighted to hear from alumni and take great pleasure
                        in sharing your good news with the wider community via our eNews and
                        Alumni Magazine. Submit your personal or professional updates here.
                    </Typography>
                </Grid>
            </Grid>
        </Box>

        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12}>
                    <AppTextareaInput
                        control={control}
                        name={PersonalProfessionalUpdates.name}
                        label={PersonalProfessionalUpdates.label}
                    />
                </Grid>

                <Grid item xs={12}>
                    <AppDeclarationCheckbox
                        control={control}
                        name={ClassNotesAreTheDetailsCorrect.name}
                        label={ClassNotesAreTheDetailsCorrect.label}
                        option={declarationOption} />
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Grid container rowSpacing={4}>
                <Grid item xs={12}></Grid>

                <Grid item xs={8}></Grid>

                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        {MESSAGE_SUBMIT}
                    </Button>
                </Grid>

                <Grid item xs={1}></Grid>
            </Grid>
        </Box>
    </>
}