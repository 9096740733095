import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { PaymentProductProps } from '../common/paymentProductProps';
import { PaymentDto } from '../../../app/models/payment/paymentDto';
import { Currency } from '../../../app/models/staticData/currency';
import { CurrencyCodeEnum } from '../../../app/enums/currencyCodeEnum';
import { CurrencyCodeIconEnum } from '../../../app/enums/currencyCodeIconEnum';

const borderColor = 'Black'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        borderBottomColor: borderColor,
        borderBottomWidth: 1,
        borderLeftColor: borderColor,
        borderLeftWidth: 1,
        alignItems: 'center',
        height: 24,
        fontSize: 9
    },
    item: {
        width: '70%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: '20px',
        alignItems: 'flex-start'
    },
    amount: {
        width: '30%',
        paddingLeft: '20px',
        alignItems: 'center'
    }
});

interface Props {
    paymentProducts: PaymentProductProps[];
    paymentDetails: PaymentDto | null;
    currencies: Currency[] | null;
}

const ReceiptTableRow = ({ paymentProducts, paymentDetails, currencies }: Props) => {
    return (
        <>
            {paymentProducts?.map((paymentProduct: PaymentProductProps) => (
                <View style={styles.row} key={paymentProduct.year.toString()}>
                    <Text style={styles.item}>{paymentProduct.year}</Text>
                    <Text style={styles.amount}>

                        {paymentDetails?.currencyId === currencies?.find(x => x.code === CurrencyCodeEnum.GBP)?.id && CurrencyCodeIconEnum.UK}

                        {paymentDetails?.currencyId === currencies?.find(x => x.code === CurrencyCodeEnum.EUR)?.id && CurrencyCodeIconEnum.EUR}
                        {(paymentProduct.amount ?? 0).toFixed(2)}
                    </Text>
                </View>
            ))}
        </>
    )
};

export default ReceiptTableRow;