import "yup-phone-lite";
import * as yup from 'yup';
import DirectDebitFormModel from "./directDebitFormModel";
import { MESSAGE_NOT_A_VALUE } from "../../utils/constant";
import * as ibantools from 'ibantools';

const {
  formField: {
    FirstName,
    Surname,
    BIC,
    IBAN,
    TypeOfDirectDebit,
    PaymentFrequency,
    Declaration
  }
} = DirectDebitFormModel;

const paymentFrequencyValidationSchema = yup.string().required(`${PaymentFrequency.requiredErrorMsg}`)
  .when([TypeOfDirectDebit.name],
    ([typeOfDirectDebit], schema) => {
      return schema.test(`${PaymentFrequency.name}`,
        `${PaymentFrequency.requiredErrorMsg}`,
        function (paymentFrequency) {
          if (paymentFrequency === MESSAGE_NOT_A_VALUE) {
            return true;
          }
          if (typeOfDirectDebit !== "" && paymentFrequency === "") {
            return false;
          }
          return true;
        });
    });

export const MembershipDirectDebitValidationSchemas = yup.object().shape({
  [FirstName.name]: yup.string().required(`${FirstName.requiredErrorMsg}`),
  [Surname.name]: yup.string().required(`${Surname.requiredErrorMsg}`),
  [BIC.name]: yup.string().test('validateBic',
    `${BIC.incorrectBicErrorMsg}`,
    function (item) {

      if (item === "") return true;

      return item !== undefined && ibantools.isValidBIC(item);
    }
  ),
  [IBAN.name]: yup.string().required(`${IBAN.requiredErrorMsg}`).test(
    'validateIbanNumber',
    `${IBAN.incorrectIbanErrorMsg}`,
    function (item) {
      return ibantools.validateIBAN(item).valid;
    }
  ),
  [Declaration.name]: yup.array().min(1, Declaration.requiredErrorMsg).of(yup.string()
    .required(Declaration.requiredErrorMsg)).required()
});

export const DonationDirectDebitValidationSchemas = yup.object().shape({
  [TypeOfDirectDebit.name]: yup.string().required(`${TypeOfDirectDebit.requiredErrorMsg}`),
  [PaymentFrequency.name]: paymentFrequencyValidationSchema
}).concat(MembershipDirectDebitValidationSchemas);
