import { Box, Grid, InputLabel, Typography } from "@mui/material";
import pcsPlanFormModel from "../../../common/pcsPlanFormModel";
import AppLabelField from "../../../../../app/components/AppLabelField";
import { PcsEnrolmentYear } from "../../../models/pcsEnrolmentYear";

interface Props {
    enrolmentYearDetails: PcsEnrolmentYear | null;
}

export function ViewFeedback({ enrolmentYearDetails }: Props) {

    const { ReviewerComments, PractionerComments } = pcsPlanFormModel.formField;

    return <>
        <Box sx={{ width: '100%' }}>
            <Grid container rowSpacing={4}>

                <Grid item xs={12}></Grid>

                <Grid item xs={12}>
                    <Typography variant='h6'>Feedback</Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppLabelField value={enrolmentYearDetails?.pdpReviewerComments ?? ''}
                        label={ReviewerComments.label} />
                </Grid>

                <Grid item xs={12}>
                    <AppLabelField value={enrolmentYearDetails?.pdpRevieweeComments ?? ''}
                        label={PractionerComments.label} />
                </Grid>

            </Grid>
        </Box>
    </>
}