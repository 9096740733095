import { Box, Button, Grid, Step, StepLabel, Stepper } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Location from '../../app/models/membership/LocationAndPreferencesDetails';
import { useAppDispatch, useAppSelector } from '../../app/store/configureStore';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { ApiRequestStatus } from '../../app/enums/apiRequestStatus';
import { getPersonalDetailsAsync, updatePersonalDetailsStateAsync } from '../../app/slices/personalDetailsSlice';
import { getReferenceDataAsync } from '../../app/slices/referenceDataSlice';
import {
  API_REQUEST_FULLFILLED, MESSAGE_BACK_TO_PREVIOUS_STEP, MESSAGE_NEXT, MESSAGE_PROCESSING_DETAILS,
  MESSAGE_SAVE_AND_CONTINUE, MESSAGE_DETAILS_SAVED, MESSAGE_NOT_A_VALUE
} from '../../app/utils/constant';
import { toast } from 'react-toastify';
import { PersonalDetailsDto } from '../../app/models/person/personalDetailsDto';
import MembershipRenewalHeader from './MembershipRenewalHeader';
import { MembershipRenewalStepEnum } from './common/membershipRenewalStepEnum';
import { NonAffiliateValidationSchemas } from './common/nonAffiliateValidationSchemas';
import MemberPersonalDetails from '../../app/models/membership/MemberPersonalDetails';
import MemberPostalAddress from '../../app/models/membership/MemberPostalAddress';
import { getCurrentYearAsync, getFAMDetails, getFamSubscriptionsAsync, updateFAMDetails } from '../../app/slices/famSlice';
import { LocationPreferences } from '../../app/models/membership/famDetails';
import { submitFamTransferDetailsAsync, getFamTransferDetailsAsync } from '../../app/slices/famTransferSlice';
import PaymentAndSummary from './PaymentAndSummary';
import { useMsal } from '@azure/msal-react';
import { UserClaims } from '../../app/enums/userClaims';
import { getEnrolmentYearsAsync } from '../../app/slices/famEnrolmentSlice';
import Unauthorized from '../../app/errors/Unauthorized';
import { getAffiliateOnboardingDetailsAsync } from '../../app/slices/affiliateOnboardingSlice';
import { useHistory } from 'react-router-dom';
import { AppPathEnum } from '../../app/enums/appPathEnum';
import { ALREADY_PAID_FOR_CURRENT_YEAR, ALREADY_PAID_FOR_CURRENT_YEAR_AND_YEAR_IN_ADVANCE, EXCEMPTION_USER_MESSAGE, RECURRING_PAYMENT_EXISTS } from './common/messages';
import { IdTokenClaims } from '../../app/models/account/idTokenClaims';
import PayInAdvance from './PayInAdvance';

export default function NonAffiliateMembershipRenewalForm() {

  const { instance } = useMsal();
  const idTokenClaimsObject = (instance.getActiveAccount()?.idTokenClaims as unknown as IdTokenClaims);

  const steps = [MembershipRenewalStepEnum.PersonalDetails, MembershipRenewalStepEnum.PostalAddress,
  MembershipRenewalStepEnum.Location, MembershipRenewalStepEnum.PaymentAndSummary];

  const history = useHistory();

  const queryParams = new URLSearchParams(window.location.search);
  const payInAdvance = Boolean(queryParams.get("payInAdvance"));

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <MemberPersonalDetails titles={titles} genders={genders} countries={countries} disciplines={disciplines}
          areasOfPractice={areasOfPractice} specialities={specialities} personalDetails={personalDetails} />
      case 1:
        return <MemberPostalAddress countries={countries} personalDetails={personalDetails} />
      case 2:
        return <Location countries={countries} countrySubdivisions={countrySubdivisions} famDetails={famDetails}
          publicationPreferences={publicationPreferences} personalDetails={personalDetails} />
      case 3:
        return <PaymentAndSummary famDetails={famDetails} countries={countries} famMembershipTypes={famMembershipTypes} payInAdvance={payInAdvance} />
      default:
        throw new Error('Unknown step');
    }
  }

  const [activeStep, setActiveStep] = useState(0);

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(NonAffiliateValidationSchemas[activeStep])
  });

  const { trigger, formState: { isDirty } } = methods;
  const formObj = methods.watch();

  const { accounts } = useMsal();

  useEffect(() => {
    if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
      setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
    }
  }, [accounts]);

  const [personId, setPersonId] = useState('');

  const dispatch = useAppDispatch();

  const { titles, genders, countries, countrySubdivisions, specialities, disciplines, areasOfPractice,
    educationInstitutions, publicationPreferences, royalColleges, famMembershipTypes } = useAppSelector(state => state.ReferenceData);
  const { personalDetails, updatePersonalDetailsStatus } = useAppSelector(state => state.personalDetail);
  const { famTransferDetails, getFamTransferDetailsStatus, submitFamTransferDetailsStatus } = useAppSelector(state => state.famTransfer);
  const { famDetails, currentYear, updateFamDetailsStatus, famMembershipSubscriptions } = useAppSelector(state => state.famDetails);
  const { famEnrolmentYears } = useAppSelector(state => state.famEnrolment);
  const { affiliateOnboardingDetails } = useAppSelector(state => state.affiliateOnboardingDetails);

  const [pageLoading, setPageLoading] = useState(false);
  const [canRedirectToFamTransfer, setCanRedirectToFamTransfer] = useState<boolean | undefined>();
  const [canRedirectToAffiliateOnboarding, setCanRedirectToAffiliateOnboarding] = useState<boolean | undefined>();
  const [canRedirectToFamOnboarding, setCanRedirectToFamOnboarding] = useState<boolean | undefined>();

  useEffect(() => {
    if (personId !== "" && (personalDetails === null || personalDetails === undefined) &&
      (famDetails === null || famDetails === undefined) &&
      (titles === null || titles?.length === 0) &&
      (genders === null || genders?.length === 0) &&
      (countries === null || countries?.length === 0) &&
      (countrySubdivisions === null || countrySubdivisions?.length === 0) &&
      (specialities === null || specialities?.length === 0) &&
      (disciplines === null || disciplines?.length === 0) &&
      (areasOfPractice === null || areasOfPractice?.length === 0) &&
      (publicationPreferences === null || publicationPreferences?.length === 0) &&
      (educationInstitutions === null || educationInstitutions?.length === 0) &&
      (royalColleges === null || royalColleges?.length === 0) && famEnrolmentYears === null &&
      famDetails === null && currentYear === null && affiliateOnboardingDetails === null &&
      famTransferDetails === null && famMembershipSubscriptions === null) {

      setPageLoading(true);

      const pageLoadPromise = async () => await Promise.all([
        dispatch(getReferenceDataAsync()),
        dispatch(getPersonalDetailsAsync(personId)),
        dispatch(getFAMDetails(personId)),
        dispatch(getCurrentYearAsync()),
        dispatch(getEnrolmentYearsAsync()),
        dispatch(getFamTransferDetailsAsync()),
        dispatch(getAffiliateOnboardingDetailsAsync()),
        dispatch(getFamSubscriptionsAsync())
      ]);

      pageLoadPromise().finally(() => {
        setPageLoading(false);
      });
    }
  }, [personId, personalDetails, titles, genders, countries, countrySubdivisions, specialities, disciplines,
    areasOfPractice, publicationPreferences, educationInstitutions, royalColleges, famEnrolmentYears,
    famDetails, currentYear, affiliateOnboardingDetails, famTransferDetails, pageLoading, famMembershipSubscriptions, dispatch]);

  useEffect(() => {
    if (idTokenClaimsObject.extension_portalcrm_isTransferOnboarding === true) {
      setCanRedirectToFamTransfer(true);
    }
    else {
      setCanRedirectToFamTransfer(false);
    }
  }, [canRedirectToFamTransfer, idTokenClaimsObject]);

  useEffect(() => {
    if (canRedirectToFamTransfer === false &&
      idTokenClaimsObject.extension_portalcrm_isAffiliateOnboarding === true) {
      setCanRedirectToAffiliateOnboarding(true);
    }
    else {
      setCanRedirectToAffiliateOnboarding(false);
    }
  }, [canRedirectToFamTransfer, canRedirectToAffiliateOnboarding, idTokenClaimsObject]);

  useEffect(() => {
    if (canRedirectToAffiliateOnboarding === false &&
      (idTokenClaimsObject.extension_portalcrm_isFellowOnboarding === true ||
        idTokenClaimsObject.extension_portalcrm_isMemberOnboarding === true)) {
      setCanRedirectToFamOnboarding(true);
    }
    else {
      setCanRedirectToFamOnboarding(false);
    }
  }, [canRedirectToAffiliateOnboarding, canRedirectToFamOnboarding, idTokenClaimsObject]);

  function MapToPersonalDetails() {
    const data: PersonalDetailsDto = {
      ...personalDetails, id: personId,
      titleId: formObj.Title,
      middleName: personalDetails?.middleName === formObj.MiddleName ? null : formObj.MiddleName,
      prefferedName: formObj.PreferredName,
      genderId: formObj.Gender,
      nationalityId: formObj.CountryOfNationality,
      dateOfBirth: formObj.DateOfBirth,
      mobile: formObj.PreferredPhoneNumber,
      disciplineId: formObj.Discipline,
      areaOfPracticeId: formObj.AreaOfPracticeMoreDetails === MESSAGE_NOT_A_VALUE ? '' : formObj.AreaOfPracticeMoreDetails
    }
    return data;
  }

  function MapToPostalAddressDetails() {
    const data: PersonalDetailsDto = {
      ...personalDetails, id: personId,
      postalAddress: formObj.PostalAddressLines,
      postalAddressCountryId: formObj.PostalAddressCountry
    }
    return data;
  }

  function MapToLocationDetails() {
    const data: PersonalDetailsDto = {
      ...personalDetails, id: personId,
      locationCountryId: formObj.LocationCountry,
      locationCountrySubdivisionId: formObj.CountrySubdivision === MESSAGE_NOT_A_VALUE ? '' : formObj.CountrySubdivision
    }
    return data;
  }

  const MapToFamDetails = () => {
    let famDetailsObj = {
      ...famDetails,
      scopePreferenceId: formObj.SurgeonsScopeMagazine,
      surgeonJournalPreferenceId: formObj.TheSurgeonJournal,
      personId: personId
    };

    return famDetailsObj;
  }

  const savePublicationPreferences = (locationPreferencesDto: LocationPreferences) => {
    dispatch(updateFAMDetails(locationPreferencesDto))
      .then((updateFAMDetailsResponse: any) => {
        if (updateFAMDetailsResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
          dispatch(updatePersonalDetailsStateAsync(MapToLocationDetails())).then((personalDetailsResponse: any) => {
            if (personalDetailsResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
              setPageLoading(true);

              const pageLoadPromise = async () => await Promise.all([
                dispatch(getFAMDetails(personId)),
                dispatch(getPersonalDetailsAsync(personId))
              ]);

              pageLoadPromise().finally(() => {
                setActiveStep(prevActiveStep => prevActiveStep + 1);
                toast.success(MESSAGE_DETAILS_SAVED);
                setPageLoading(false);
              });
            }
          });
        }
      });
  }

  const savePersonalDetails = (personalDetailsDto: PersonalDetailsDto) => {
    dispatch(updatePersonalDetailsStateAsync(personalDetailsDto)).then((response: any) => {
      if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
        dispatch(getPersonalDetailsAsync(personId)).then(() => {
          setActiveStep(prevActiveStep => prevActiveStep + 1);
          toast.success(MESSAGE_DETAILS_SAVED);
        });
      }
    });
  }

  const submitMembershipRenewalDetails = () => {
    let famExamId = famTransferDetails?.famExamId ? famTransferDetails?.famExamId : '';
    dispatch(submitFamTransferDetailsAsync(famExamId)).then((response: any) => {
      if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
        dispatch(getFamTransferDetailsAsync()).then(() => {
          setActiveStep(prevActiveStep => prevActiveStep + 1);
          toast.success(MESSAGE_DETAILS_SAVED);
        });
      };
    });
  }

  const handleNext = async () => {

    const isStepValid = await trigger();

    if (isStepValid) {
      if (isDirty === true) {
        switch (activeStep) {
          case 0:
            savePersonalDetails(MapToPersonalDetails());
            break;
          case 1:
            savePersonalDetails(MapToPostalAddressDetails());
            break;
          case 2:
            savePublicationPreferences(MapToFamDetails());
            break;
          case 3:
            submitMembershipRenewalDetails();
            break;
          default:
            return "not a valid step";
        }
      }
      else if (isDirty === false) {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      }
    }
  }

  async function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  if (pageLoading === true || updatePersonalDetailsStatus === ApiRequestStatus.Pending
    || getFamTransferDetailsStatus === ApiRequestStatus.Pending
    || submitFamTransferDetailsStatus === ApiRequestStatus.Pending
    || updateFamDetailsStatus === ApiRequestStatus.Pending) {
    return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
  }

  if (famDetails?.exemptionTypeId && famDetails?.exemptionTypeId !== null && famDetails?.exemptionTypeId !== "") {
    return <Unauthorized message={EXCEMPTION_USER_MESSAGE} />
  }

  if (famMembershipSubscriptions && famMembershipSubscriptions?.length > 0 &&
    famMembershipSubscriptions.filter(x => x.active === true).length > 0 &&
    famMembershipSubscriptions.filter(x => x.paused === false).length > 0) {
    return <Unauthorized message={RECURRING_PAYMENT_EXISTS} />
  }

  if (payInAdvance !== true) {
    if (famEnrolmentYears && famEnrolmentYears?.length > 0 && currentYear !== null &&
      famEnrolmentYears.find(x => x.membershipTypeId === famDetails?.membershipTypeId && x.year === (currentYear + 1))) {
      return <Unauthorized message={ALREADY_PAID_FOR_CURRENT_YEAR_AND_YEAR_IN_ADVANCE} />
    }

    if (famEnrolmentYears && famEnrolmentYears?.length > 0 && currentYear !== null &&
      famEnrolmentYears.find(x => x.membershipTypeId === famDetails?.membershipTypeId && x.year === currentYear)) {
      return <PayInAdvance message={ALREADY_PAID_FOR_CURRENT_YEAR} />
    }
  }

  if (canRedirectToFamTransfer === true) {
    history.push(`${AppPathEnum.MEMBERSHIP_TRANSFER}${window.location.search}`);
  }
  else if (canRedirectToAffiliateOnboarding === true) {
    history.push(`${AppPathEnum.AFFILIATE_ASSOCIATE}${window.location.search}`);
  }
  else if (canRedirectToFamOnboarding === true) {
    history.push(`${AppPathEnum.MEMBERSHIP_ONBOARDING}${window.location.search}`);
  }

  return (
    <FormProvider {...methods}>
      <MembershipRenewalHeader />

      <br></br>

      <Stepper activeStep={activeStep} orientation={isMobile === true ? "vertical" : "horizontal"}>
        {steps.map((label) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <br></br>

      {activeStep !== 0 && (
        <Button variant="contained" onClick={_handleBack}>
          {MESSAGE_BACK_TO_PREVIOUS_STEP}
        </Button>
      )}

      {activeStep !== steps.length &&
        <>
          {getStepContent(activeStep)}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Grid container rowSpacing={4}>
              <Grid item xs={12}></Grid>
              <Grid item xs={8}></Grid>
              <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end' }}>
                {activeStep < steps.length - 1 &&
                  <Button variant="contained" color="primary" onClick={handleNext}>
                    {isDirty === true ? MESSAGE_SAVE_AND_CONTINUE : MESSAGE_NEXT}
                  </Button>
                }
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          </Box>
        </>
      }
    </FormProvider >
  );
};
