import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { useLocation } from "react-router";
import { useParams } from "react-router-dom";

export default function ServerError() {
    const { state } = useLocation<any>();
    const { errorMessage } = useParams<{ errorMessage: string | undefined }>();

    return <>
        <Box sx={{ flexGrow: 1 }} component={Paper}>

            <Grid container rowSpacing={4} sx={{ mt: "0", mr: "2%", ml: "2%" }}>

                <Grid item xs={12}>
                    {state?.error ? (
                        <>
                            <Typography variant='h3' color='error' gutterBottom>{state.error.title}</Typography>
                            <Divider />
                            <Typography>{state.error.detail || 'Internal server error'}</Typography>
                        </>
                    ) : (
                        <Typography variant='h5' gutterBottom>Server Error</Typography>
                    )}
                    {errorMessage !== undefined ? <><br></br> {decodeURI(errorMessage)} </> : ''}
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>
    </>
}