import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { CreateDirectDebitMandateCommand } from "../models/directDebit/createDirectDebitMandateCommand";
import { DirectDebitMandate } from "../models/directDebit/directDebitMandate";

interface directDebitState {
    reference: string | null;
    directDebitMandate: DirectDebitMandate | null;
    directDebitMandates: DirectDebitMandate[] | null;
    submitDirectDebitDetailsStatus: ApiRequestStatus;
    getDirectDebitMandateByIdStatus: ApiRequestStatus;
    getDirectDebitMandatesStatus: ApiRequestStatus;
}

const initialState: directDebitState = {
    reference: null,
    directDebitMandate: null,
    directDebitMandates: null,
    submitDirectDebitDetailsStatus: ApiRequestStatus.Idle,
    getDirectDebitMandateByIdStatus: ApiRequestStatus.Idle,
    getDirectDebitMandatesStatus: ApiRequestStatus.Idle
}

export const submitDirectDebitDetailsAsync = createAsyncThunk<string, CreateDirectDebitMandateCommand>(
    'directDebitSlice/submitDirectDebitDetailsAsync',
    async (createDirectDebitMandateCommand, thunkAPI) => {
        try {
            return agent.DirectDebit.submitDirectDebitDetailsAsync(createDirectDebitMandateCommand);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getDirectDebitMandateByIdAsync = createAsyncThunk<DirectDebitMandate, string>(
    'directDebitSlice/getDirectDebitMandateByIdAsync',
    async (id, thunkAPI) => {
        try {
            return agent.DirectDebit.getDirectDebitMandateByIdAsync(id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getDirectDebitMandatesAsync = createAsyncThunk<DirectDebitMandate[], void>(
    'directDebitSlice/getDirectDebitMandatesAsync',
    async (_, thunkAPI) => {
        try {
            return agent.DirectDebit.getDirectDebitMandatesAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const directDebitSlice = createSlice({
    name: 'directDebitSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(submitDirectDebitDetailsAsync.pending, (state) => {
            state.submitDirectDebitDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(submitDirectDebitDetailsAsync.fulfilled, (state, action) => {
            state.reference = action.payload;
            state.submitDirectDebitDetailsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(submitDirectDebitDetailsAsync.rejected, (state) => {
            state.submitDirectDebitDetailsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getDirectDebitMandateByIdAsync.pending, (state) => {
            state.getDirectDebitMandateByIdStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getDirectDebitMandateByIdAsync.fulfilled, (state, action) => {
            state.directDebitMandate = action.payload;
            state.getDirectDebitMandateByIdStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(getDirectDebitMandateByIdAsync.rejected, (state) => {
            state.getDirectDebitMandateByIdStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getDirectDebitMandatesAsync.pending, (state) => {
            state.getDirectDebitMandatesStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getDirectDebitMandatesAsync.fulfilled, (state, action) => {
            state.directDebitMandates = action.payload;
            state.getDirectDebitMandatesStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(getDirectDebitMandatesAsync.rejected, (state) => {
            state.getDirectDebitMandatesStatus = ApiRequestStatus.Rejected;
        });
    })
})
