import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = 'Black'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderColor: borderColor,
        backgroundColor: '#DE1834',
        color: 'white',
        borderWidth: 1,
        alignItems: 'center',
        textAlign: 'center',
        height: 24,
        fontSize: 12
    },
    item: {
        width: '70%',
        paddingRight: 8,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        fontStyle: 'bold'
    },
    amount: {
        width: '30%',
        fontStyle: 'bold'
    }
});

const ReceiptTableHeader = () => {
    return (
        <View style={styles.container}>
            <Text style={styles.item}>Item</Text>
            <Text style={styles.amount}>Amount</Text>
        </View>
    )
};

export default ReceiptTableHeader;