import { PDFDownloadLink } from '@react-pdf/renderer';
import { PdfDocument } from './PdfDocument';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { useState, useEffect } from 'react';
import { getReferenceDataAsync } from '../../app/slices/referenceDataSlice';
import { useAppDispatch, useAppSelector } from '../../app/store/configureStore';
import { getCurrentYearAsync } from '../pcsPlan/view/planDetails/planSlice';
import { getFAMDetails } from '../../app/slices/famSlice';
import { useMsal } from '@azure/msal-react';
import { UserClaims } from '../../app/enums/userClaims';
import { getFamExamsAsync } from '../../app/slices/famSlice';
import { ApiRequestStatus } from '../../app/enums/apiRequestStatus';
import { MESSAGE_PROCESSING_DETAILS } from '../../app/utils/constant';

export default function InGoodStanding() {

  const dispatch = useAppDispatch();

  const { accounts } = useMsal();

  useEffect(() => {
    if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
      setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
    }
  }, [accounts]);

  const [personId, setPersonId] = useState('');
  const [pageLoading, setPageLoading] = useState(true);

  const { famMembershipTypes } = useAppSelector(state => state.ReferenceData);
  const { currentYear } = useAppSelector(state => state.plan);
  const { famOnboardingDetails, getFamOnboardingDetailsStatus, famDetails } = useAppSelector(state => state.famDetails);

  useEffect(() => {
    if (personId !== "" && currentYear === null
      && (famDetails === null || famDetails === undefined)
      && (famOnboardingDetails === null || famOnboardingDetails === undefined)
      && (famMembershipTypes === null || famMembershipTypes?.length === 0)) {

      setPageLoading(true);

      const pageLoadPromise = async () => await Promise.all([
        dispatch(getReferenceDataAsync()),
        dispatch(getFamExamsAsync()),
        dispatch(getCurrentYearAsync()),
        dispatch(getFAMDetails(personId))
      ]);

      pageLoadPromise().finally(() => {
        setPageLoading(false);
      });
    }
  }, [personId, currentYear, famDetails, famOnboardingDetails, famMembershipTypes, dispatch]);

  if (pageLoading === true || getFamOnboardingDetailsStatus === ApiRequestStatus.Pending) {
    return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
  }

  return <>
    Our Records show that you are <b>In Good Standing</b> for the current membership year.
    You can generate a Letter of Good Standing {" "}
    <PDFDownloadLink document={<PdfDocument famMembershipTypes={famMembershipTypes}
      famOnboardingDetails={famOnboardingDetails && famOnboardingDetails[0]} currentYear={currentYear} famDetails={famDetails} />}
      fileName="RCSI_Letter_of_Good_Standing_Year.pdf">
      {({ loading }) =>
        loading === true ? 'Loading document...' : 'here'}
    </PDFDownloadLink>{". "}
    A Letter of Good Standing can be used to confirm your Good Standing status with RCSI for
    the current subscription year, your entitlement to use the FRCSI or MRCSI post-nominals
    and to verify your award.
  </>
}