import { Box, Paper, Grid, Typography } from "@mui/material";
import { FamOnboardingStatus } from "../../app/models/staticData/famOnboardingStatus";
import { MemberTransferStatusCodeEnum } from "../../app/enums/memberTransferStatusCodeEnum";

interface Props {
    famOnboardingStatus: FamOnboardingStatus | undefined;
}

export default function MembershipTransferHeader({ famOnboardingStatus }: Props) {

    return <>
        <Box sx={{ minWidth: "100%" }} component={Paper}>

            <Grid container rowSpacing={4}>

                <Grid item xs={12} sx={{ marginLeft: '2%', marginTop: '2%' }}>
                    <Typography variant='h4'>Membership transfer of RCSI</Typography>
                </Grid>

                <Grid item xs={12} sx={{ marginLeft: '2%', marginRight: '2%', marginBottom: '2%' }}>
                    <Typography variant='subtitle1'>
                        {(!famOnboardingStatus || famOnboardingStatus.code === MemberTransferStatusCodeEnum.Pending) &&
                            <>
                                Thank you for your interest in transferring your membership to RCSI. This pathway is
                                 most commonly used for those who may have achieved MRCS with another royal 
                                 college, but who now wish to affiliate with RCSI.

                                <br></br>
                                <br></br>

                                Please complete the application below.
                            </>
                        }

                        {famOnboardingStatus?.code === MemberTransferStatusCodeEnum.Submitted &&
                            <>
                                Thank you. We have received your application. Please check your email for next steps.
                            </>
                        }

                        {famOnboardingStatus?.code === MemberTransferStatusCodeEnum.Paid &&
                            <>
                                Thank you for your application please check your email for next steps.
                            </>
                        }

                        {famOnboardingStatus?.code === MemberTransferStatusCodeEnum.Declined &&
                            <>
                                Your application has been declined. You should have received communications from the RCSI 
                                Membership team with more details. If you have not, please contact us at <a href="mailto:fellows@rcsi.com">fellows@rcsi.com</a>.
                            </>
                        }

                        {famOnboardingStatus?.code === MemberTransferStatusCodeEnum.Ratified &&
                            <>
                                Your application has now been ratified. You should now have access to the 
                                RCSI Members and Fellows Portal.
                            </>
                        }

                        {famOnboardingStatus?.code === MemberTransferStatusCodeEnum.Conferred &&
                            <>
                                You have been conferred. You should now have access to the RCSI Members and Fellows Portal.
                            </>
                        }
                    </Typography>
                </Grid>

            </Grid>
        </Box>
    </>
}
