import * as yup from 'yup';
import "yup-phone-lite";
import { DeclarationValidationSchema, PersonalDetailsValidationSchema, PostalAddressValidationSchema, LocationAndPreferencesValidationSchema }
  from '../../../app/models/membership/validationSchemas';

export const NonAffiliateValidationSchemas = [
  PersonalDetailsValidationSchema,
  PostalAddressValidationSchema,
  LocationAndPreferencesValidationSchema,
  DeclarationValidationSchema,
  yup.object().shape({

  })
]
