import { Box, Paper, Grid, Typography } from "@mui/material";

export default function MembershipRenewalHeader() {

    return <>
        <Box sx={{ minWidth: "100%" }} component={Paper}>

            <Grid container rowSpacing={4}>

                <Grid item xs={12} sx={{ marginLeft: '2%', marginTop: '2%' }}>
                    <Typography variant='h4'>Membership renewal</Typography>
                </Grid>

                <Grid item xs={12} sx={{ marginLeft: '2%', marginRight: '2%', marginBottom: '2%' }}>
                    <Typography variant='subtitle1'>
                        We are grateful to all RCSI Fellows and Members who continue to pay their annual
                        subscription and support the College and a future generation of surgeons.

                        <br></br>
                        <br></br>

                        You can maintain your Good Standing with the College by completing this form and making
                        a payment for the relevant subscription.
                    </Typography>
                </Grid>

            </Grid>
        </Box>
    </>
}
