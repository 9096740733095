import { FormControl, InputLabel, Select, MenuItem, FormHelperText, Grid } from "@mui/material";
import { useController, UseControllerProps } from "react-hook-form";
import AppInformationText, { AppInformationHtmlText } from "./AppInformationText";
import { DropdownItem } from "../models/common/dropdownItem";

interface Props extends UseControllerProps {
    label: string;
    items: DropdownItem[];
    information_text?: string;
    html_information_text?: React.ReactNode;
    disabled?: boolean;
}

export default function AppSelectList(props: Props) {
    const { fieldState, field } = useController({ ...props, defaultValue: '' });
    
    return (
        <>
            <Grid container rowSpacing={4}>

                <Grid item xs={11}>
                    <FormControl fullWidth error={!!fieldState.error}>
                        <InputLabel>{props.label}</InputLabel>
                        <Select
                            {...field}
                            {...props}
                            className='rcsiSelectField'
                            sx={props.disabled === true ? {backgroundColor: '#f1f1f1', color: '#858282'} : {}}
                        >
                            {props.items.map((item: DropdownItem, index: number) => (
                                <MenuItem value={item.id} key={index}>{item.value}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{fieldState.error?.message}</FormHelperText>
                    </FormControl>
                </Grid>

                {props.information_text &&
                    <Grid item xs={1}>
                        <AppInformationText information_text={props.information_text} />
                    </Grid>}

                {props.html_information_text &&
                    <Grid item xs={1}>
                        <AppInformationHtmlText information_text={props.html_information_text} />
                    </Grid>}

            </Grid>
        </>
    )
}