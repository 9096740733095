import { Box, Paper, Grid, Typography, Button } from "@mui/material";
import BackToRequests from "./BackToRequests";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { CheckBoxItem } from "../../app/models/common/checkBoxItem";
import RequestFormModel from "./models/requestFormModel";
import AppDeclarationCheckbox from "./models/AppDeclarationCheckbox";
import { PastestValidationSchema } from "./models/requestValidationSchemas";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { RequestTypesEnum } from "../../app/enums/requestTypesEnum";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { CreateRequestTaskCommand } from "../../app/models/request/createRequestTaskCommand";
import { submitRequestAsync } from "../../app/slices/requestSlice";
import { useAppDispatch } from "../../app/store/configureStore";
import { API_REQUEST_FULLFILLED, MESSAGE_PROCESSING_DETAILS, MESSAGE_SUBMIT } from "../../app/utils/constant";
import SuccessResponse from "./models/SuccessResponse";

export default function PasTest() {
    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(PastestValidationSchema)
    });
    const { control, trigger, reset, formState: { isDirty } } = methods;
    const formObj = methods.watch();

    const {
        PasTest: {
            PasTestAreTheDetailsCorrect
        }
    } = RequestFormModel;

    const [declarationOption,] = useState<CheckBoxItem>({
        id: PasTestAreTheDetailsCorrect.name,
        value: ''
    });

    const dispatch = useAppDispatch();

    const [pageLoading, setPageLoading] = useState(false);
    const [isRequestSubmitted, setIsRequestSubmitted] = useState(false);

    const MapToRequestDetails = () => {
        let requestObj: CreateRequestTaskCommand = {
            requestTypeCode: RequestTypesEnum.PasTestDiscountCode.toString(),
            details: `${PasTestAreTheDetailsCorrect.labelText}: ${formObj.PasTestAreTheDetailsCorrect}`,
            subject: ''
        };

        return requestObj;
    }

    const successMessage = `A member of the RCSI Fellows and Members Team will contact you directly with your unique discount code for PasTest.`;

    const handleSave = async () => {
        const isStepValid = await trigger();

        if (isStepValid) {
            if (isDirty === true) {
                setPageLoading(true);
                dispatch(submitRequestAsync(MapToRequestDetails())).then((requestResponse: any) => {
                    if (requestResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                        reset({
                            [PasTestAreTheDetailsCorrect.name]: false
                        });
                        setPageLoading(false);
                        setIsRequestSubmitted(true);
                        toast.success(successMessage);
                    }
                });
            }
        }
    };

    if (pageLoading === true) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    if (isRequestSubmitted === true) {
        return <SuccessResponse message={successMessage} />
    }

    return <>
        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ ml: '2%', mt: '0%', width: '96%' }}>

                <Grid item xs={12}>
                    <BackToRequests />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='h4'>PasTest discount code</Typography>
                </Grid>

                <Grid item xs={12} sx={{ marginRight: '2%', marginBottom: '2%' }}>
                    <Typography variant='subtitle1'>
                        RCSI has partnered with PasTest to offer discounted access to their
                        MRCS Part A, MRCS Part B and Medical Student Finals resources.
                        <br></br>
                        <br></br>
                        All RCSI Affiliate Members are entitled to a 20% discount off the
                        PasTest online subscription from RCSI.
                    </Typography>
                </Grid>
            </Grid>
        </Box>

        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12}>
                    <AppDeclarationCheckbox
                        control={control}
                        name={PasTestAreTheDetailsCorrect.name}
                        label={PasTestAreTheDetailsCorrect.label}
                        option={declarationOption} />
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Grid container rowSpacing={4}>
                <Grid item xs={12}></Grid>

                <Grid item xs={8}></Grid>

                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        {MESSAGE_SUBMIT}
                    </Button>
                </Grid>

                <Grid item xs={1}></Grid>
            </Grid>
        </Box>
    </>
}