export enum MemberTransferStatusCodeEnum {
    FmOnboarding = "FMOB",
    Pending = "FMPEN",
    Submitted = "FMSUBMIT",
    RequiredFurtherDetails = "FMRFD",
    Approved = "FMAPP",
    Paid = "FMPAID",
    Declined = "FMDCLD",
    Ratified = "FMRTFD",
    Conferred = "FMCFRD"
}