export enum RequestTypesEnum {
    AbsenceFromPractice = "AbsenceFromPractice",
    RequestToWithdraw = "RequestToWithdraw",
    FMLinkedIn = "FMLinkedIn",
    RCSICampusAccessCardFellowsAndMembers = "RCSICampusAccessCardFellowsAndMembers",
    RCSICampusAccessCardAlumni = "RCSICampusAccessCardAlumni",
    PasTestDiscountCode = "PasTestDiscountCode",
    Merchandise = "Merchandise",
    ReductionsAndExemptions = "ReductionsAndExemptions",
    SubmitClassNotes = "SubmitClassNotes",
    RequestAlumniTour = "RequestAlumniTour",
    ContactClassmate = "ContactClassmate",
    AlumniYearbook = "AlumniYearbook",
    RequestPortalAccess = "RequestPortalAccess",
    ChangeOfName = "ChangeOfName",
    VolunteeringRequest = "VolunteeringRequest"
}