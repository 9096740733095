import { Box, Paper, Grid, Typography } from "@mui/material";
import { AppPathEnum } from "../../app/enums/appPathEnum";
import MediaCard from "../request/MediaCard";

export default function DonationDashboard() {

    const donationSpas = [
        {
            title: 'All Donations', description: 'Please select if you wish to donate to the Royal College of Surgeons in Ireland.',
            url: AppPathEnum.DONATION_IRELAND
        },
        {
            title: 'USA Only', description: 'Please select if you wish to donate to the Friends of the Royal College of Surgeons in Ireland, Inc.',
            url: AppPathEnum.DONATION_USA
        },
        {
            title: 'UK Only', description: 'Please select if you wish to donate to the Friends of RCSI.',
            url: AppPathEnum.DONATION_UK
        }
    ];

    return <>
        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ ml: '1.75%', mt: '0%' }}>

                <Grid item xs={12}>
                    <Typography variant='h4'>Donations</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='subtitle1'>
                        Depending on where you live, please select one of the below options 
                        to access our secure online donation form.
                    </Typography>
                </Grid>

                {donationSpas.map((item) => (
                    <Grid key={item.title} item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <MediaCard title={item.title}
                            description={item.description}
                            redirectUrl={item.url} />
                    </Grid>
                ))}

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>
    </>
}
