import * as yup from 'yup';
import pdpFormModel from './pdpFormModel';

const {
  formField: {
    External,
    WorkBasedLearning,
    PracticeReview,
    EmailAddress,
    ReviewerConfirmation
  }
} = pdpFormModel;

export const pdpValidationSchema = yup.object().shape({
  [External.name]: yup.string().required(`${External.requiredErrorMsg}`),
  [WorkBasedLearning.name]: yup.string().required(`${WorkBasedLearning.requiredErrorMsg}`),
  [PracticeReview.name]: yup.string().required(`${PracticeReview.requiredErrorMsg}`),
  [EmailAddress.name]: yup.string().matches(EmailAddress.expression, `${EmailAddress.typeError}`),
  [ReviewerConfirmation.name]: yup.array().min(1, ReviewerConfirmation.requiredErrorMsg).of(yup.string().required(ReviewerConfirmation.requiredErrorMsg))
    .required(ReviewerConfirmation.requiredErrorMsg)
})
