import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { EducationalDetailsDto } from "../models/person/educationalDetailsDto";
import { RegistrationDetailsDto } from "../models/pcsEnrolment/registrationDetailsDto";
import { RegistrationDto } from "../models/pcsEnrolment/registrationDto";

interface RegisterState {
    registrationDetails: RegistrationDetailsDto | null;
    educationalDetails: EducationalDetailsDto | null,
    registration: RegistrationDto | null;
    registerStateStatus: ApiRequestStatus;
    saveRegistrationDetailsStatus: ApiRequestStatus;
}

const initialState: RegisterState = {
    registrationDetails: null,
    educationalDetails: null,
    registration: null,
    registerStateStatus: ApiRequestStatus.Idle,
    saveRegistrationDetailsStatus: ApiRequestStatus.Idle
}

export const getRegistrationDetailsAsync = createAsyncThunk<RegistrationDto, string>(
    'pcsRegistrationDetails/getRegistrationDetailsAsync',
    async (personId, thunkAPI) => {
        try {
            return agent.PcsRegistration.getRegistrationDetails(personId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const saveRegistrationDetailsAsync = createAsyncThunk(
    'pcsRegistrationDetails/saveRegistrationDetailsAsync',
    async ({registrationDetails, personId}: 
        {registrationDetails: RegistrationDetailsDto, personId: string}, thunkAPI) => {
        try {
            return agent.PcsRegistration.submitRegistrationDetails(personId, registrationDetails);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const registrationDetailsSlice = createSlice({
    name: 'registrationDetailsSlice',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder => {
        builder.addCase(getRegistrationDetailsAsync.pending, (state) => {
            state.registerStateStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getRegistrationDetailsAsync.fulfilled, (state, action) => {
            state.registerStateStatus = ApiRequestStatus.Fulfilled;
            state.registrationDetails = action.payload;
            
            let educationalDetails: EducationalDetailsDto = {
                id: action.payload.id,
                qualificationCountryId: action.payload.qualificationCountryId,
                qualificationInstitutionId: action.payload.qualificationInstitutionId,
                graduationYear: action.payload.graduationYear?.toString(),
                otherQualifications: action.payload.otherQualifications,
                institutionAwardId: action.payload.institutionAwardId,
                personId: action.payload.personId
            }
            state.educationalDetails = educationalDetails;
            state.registration = action.payload;
        });
        builder.addCase(getRegistrationDetailsAsync.rejected, (state) => {
            state.registerStateStatus = ApiRequestStatus.Rejected;
        });
        
        builder.addCase(saveRegistrationDetailsAsync.pending, (state) => {
            state.saveRegistrationDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(saveRegistrationDetailsAsync.fulfilled, (state, action) => {
            state.registrationDetails = action.meta.arg.registrationDetails;
            state.saveRegistrationDetailsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(saveRegistrationDetailsAsync.rejected, (state) => {
            state.saveRegistrationDetailsStatus = ApiRequestStatus.Rejected;
        });
    })
})
