import { Paper, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { MyRecurringPayments } from "./MyRecurringPayments";
import { useAppDispatch, useAppSelector } from "../../../app/store/configureStore";
import { getPaymentsAsync, getSelfServiceAsync } from "../../../app/slices/paymentSlice";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import MyReceipts from "./MyReceipts";
import { getPersonalDetailsAsync } from "../../../app/slices/personalDetailsSlice";
import { getReferenceDataAsync } from "../../../app/slices/referenceDataSlice";
import { PaymentStatus } from "../../../app/enums/paymentStatus";
import { useMsal } from "@azure/msal-react";
import { UserClaims } from "../../../app/enums/userClaims";
import { getFamSubscriptionsAsync } from "../../../app/slices/famSlice";
import { getDonationDetailsAsync } from "../../../app/slices/donationSlice";
import { MESSAGE_PROCESSING_DETAILS } from "../../../app/utils/constant";
import { MyDirectDebits } from "./MyDirectDebits";

export function MyReceiptsForm() {
    const MY_RECEIPTS = 'My receipts';
    const MY_RECURRING_PAYMENTS = 'My Recurring Card Payments';
    const MY_DIRECT_DEBITS = 'My Direct Debits';

    const tabs = [MY_RECEIPTS, MY_RECURRING_PAYMENTS, MY_DIRECT_DEBITS];

    const { accounts } = useMsal();

    useEffect(() => {
        if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
            setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
        }
    }, [accounts]);

    const [personId, setPersonId] = useState('');
    const [pageLoading, setPageLoading] = useState(false);

    function getStepContent(step: string) {
        switch (step) {
            case MY_RECEIPTS:
                return <MyReceipts titles={titles} countries={countries} personalDetails={personalDetails} funds={funds} currencies={currencies}
                    paymentsByPerson={paymentsByPerson?.filter(x => x.paymentStatusId === paymentStatuses?.find(x => x.code === PaymentStatus.Paid)?.id) ?? []}
                    paymentMethods={paymentMethods} />
            case MY_RECURRING_PAYMENTS:
                return <MyRecurringPayments famMembershipSubscriptions={famMembershipSubscriptions}
                    donationDetails={donationDetails} funds={funds} heyCentricUrl={heyCentricUrl}
                    recurrencePeriods={recurrencePeriods} />
            case MY_DIRECT_DEBITS:
                return <MyDirectDebits products={products} />
            default:
                throw new Error('Unknown step');
        }
    }

    const [activeStep, setActiveStep] = useState(0);

    const methods = useForm({
        mode: 'all'
    });

    const dispatch = useAppDispatch();

    const { titles, countries, paymentMethods, paymentStatuses, funds, recurrencePeriods, currencies, products } =
        useAppSelector(state => state.ReferenceData);
    const { famMembershipSubscriptions } = useAppSelector(state => state.famDetails);
    const { paymentsByPerson, heyCentricUrl } = useAppSelector(state => state.payment);
    const { personalDetails } = useAppSelector(state => state.personalDetail);
    const { donationDetails } = useAppSelector(state => state.donations);

    useEffect(() => {
        if (!paymentsByPerson && personId !== null && personId !== ''
            && (personalDetails === null || personalDetails === undefined)
            && (titles === null || titles?.length === 0)
            && (countries === null || countries?.length === 0)
            && (paymentMethods === null || paymentMethods?.length === 0)
            && (paymentStatuses === null || paymentStatuses?.length === 0)
            && (products === null || products?.length === 0)
            && (donationDetails === null || donationDetails === undefined)
            && famMembershipSubscriptions === null) {
            setPageLoading(true);

            const pageLoadPromise = async () => await Promise.all([
                dispatch(getReferenceDataAsync()),
                dispatch(getPaymentsAsync()),
                dispatch(getPersonalDetailsAsync(personId)),
                dispatch(getFamSubscriptionsAsync()),
                dispatch(getSelfServiceAsync()),
                dispatch(getDonationDetailsAsync())
            ]);

            pageLoadPromise().finally(() => {
                setPageLoading(false);
            });
        }
    }, [paymentsByPerson, personalDetails, titles, countries, paymentMethods, paymentStatuses, products, personId, donationDetails, dispatch]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveStep(newValue);
    };

    if (pageLoading === true) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        <FormProvider {...methods}>
            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                <Typography component="h1" variant="h4">
                    My Receipts and Recurring payments
                </Typography>

                <br></br>

                <Tabs value={activeStep}
                    onChange={handleChange}
                    textColor="primary"
                    indicatorColor="primary"
                    sx={{
                        '& .MuiTabs-flexContainer': {
                            flexWrap: 'wrap',
                        },
                    }}>
                    {tabs.map((label: string, inedex: number) => (
                        <Tab key={label} value={inedex} label={label} />
                    ))}
                </Tabs>

                {getStepContent(tabs[activeStep])}
            </Paper>
        </FormProvider >
    </>
}
