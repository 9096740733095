import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { VolunteeringInterest } from "../models/volunteering/common/volunteeringInterest";
import agent from "../api/agent";
import { VolunteeringInterestDto } from "../models/volunteering/common/volunteeringInterestDto";
import { ApiRequestStatus } from "../enums/apiRequestStatus";

interface volunteeringInterestsSliceState {
    volunteeringInterests: VolunteeringInterest[] | null;
    getVolunteeringInterestsStatus: ApiRequestStatus;
    updateVolunteeringInterestsStatus: ApiRequestStatus;
}

const initialState: volunteeringInterestsSliceState = {
    volunteeringInterests: null,
    getVolunteeringInterestsStatus: ApiRequestStatus.Idle,
    updateVolunteeringInterestsStatus: ApiRequestStatus.Idle
}

export const getVolunteeringInterestsAsync = createAsyncThunk<VolunteeringInterest[]>(
    'volunteeringInterests/getVolunteeringInterestsAsync',
    async (_, thunkAPI) => {
        try {
            return agent.VolunteeringInterests.getVolunteeringInterestsAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const updateVolunteeringInterestsAsync = createAsyncThunk<boolean, VolunteeringInterestDto[]>(
    'volunteeringInterests/updateVolunteeringInterestsAsync',
    async (volunteeringInterestDto, thunkAPI) => {
        try {
            return agent.VolunteeringInterests.updateVolunteeringInterestsAsync(volunteeringInterestDto);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const volunteeringSlice = createSlice({
    name: 'volunteeringSlice',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder => {
        builder.addCase(getVolunteeringInterestsAsync.pending, (state) => {
            state.getVolunteeringInterestsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getVolunteeringInterestsAsync.fulfilled, (state, action) => {
            state.getVolunteeringInterestsStatus = ApiRequestStatus.Fulfilled;
            state.volunteeringInterests = action.payload;
        });
        builder.addCase(getVolunteeringInterestsAsync.rejected, (state) => {
            state.getVolunteeringInterestsStatus = ApiRequestStatus.Rejected;
        });

        
        builder.addCase(updateVolunteeringInterestsAsync.pending, (state) => {
            state.updateVolunteeringInterestsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(updateVolunteeringInterestsAsync.fulfilled, (state, action) => {
            state.updateVolunteeringInterestsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(updateVolunteeringInterestsAsync.rejected, (state) => {
            state.updateVolunteeringInterestsStatus = ApiRequestStatus.Rejected;
        });
    })
})
