import { Box, Paper, Grid, Typography } from "@mui/material";
import { FamOnboardingStatus } from "../../app/models/staticData/famOnboardingStatus";
import { AffiliateStatusCodeEnum } from "../../app/enums/affiliateStatusCodeEnum";
import { useMsal } from "@azure/msal-react";
import { IdTokenClaims } from "../../app/models/account/idTokenClaims";

interface Props {
    famAffiliateOnboardingStatus: FamOnboardingStatus | undefined;
}

export default function AffiliateHeader({ famAffiliateOnboardingStatus }: Props) {
    const { instance } = useMsal();
    const userRoles = (instance.getActiveAccount()?.idTokenClaims as unknown as IdTokenClaims).extension_portalcrm_userType.split(',');
    return <>
        <Box sx={{ minWidth: "100%" }} component={Paper}>

            <Grid container rowSpacing={4}>

                <Grid item xs={12} sx={{ marginLeft: '2%', marginTop: '2%' }}>
                    <Typography variant='h4'>AFFILIATE MEMBER OF RCSI</Typography>
                </Grid>

                <Grid item xs={12} sx={{ marginLeft: '2%', marginRight: '2%', marginBottom: '2%' }}>
                    <Typography variant='subtitle1'>
                        {(userRoles[0] === "" || (famAffiliateOnboardingStatus === undefined || famAffiliateOnboardingStatus.code === AffiliateStatusCodeEnum.Pending)) &&
                            <>
                                <b>We recommend that you read about all our categories of membership online before progressing further: </b>
                                <a target="_blank" href="https://www.rcsi.com/surgery/membership">https://www.rcsi.com/surgery/membership</a>.

                                <br></br>
                                <br></br>

                                There are currently three categories of membership:

                                <ul>
                                    <li>
                                        <u>Affiliate Membership</u> is an affiliation category for those who are not Fellows or Members of the College,
                                        but are considering a career in surgery in the future or preparing for their membership exams. It is for
                                        medical students, interns, foundation doctors and surgical trainees. <b>Please note:</b> If you are already enrolled
                                        as an MRCS exam candidate you will receive affiliate membership automatically (please do not submit an
                                        application form). If you have already passed the MRCS Part B exam you are not eligible for Affiliate Membership.
                                    </li>
                                    <li>
                                        <u>Membership</u> is a category open to those who have passed their MRCS Part A and Part B exams or are transferring
                                        from another Royal College
                                    </li>
                                    <li>
                                        <u>Fellowship</u> is a category open to those who have passed their Fellowship Examination or are seeking a Fellowship Ad Eundem.
                                    </li>
                                </ul>

                                You should only complete the application below if you are interested in RCSI Affiliate Membership. More information on all categories of
                                membership is available online here: <a target="_blank" href="https://www.rcsi.com/surgery/membership">https://www.rcsi.com/surgery/membership</a>.
                            </>
                        }

                        {famAffiliateOnboardingStatus && famAffiliateOnboardingStatus.code === AffiliateStatusCodeEnum.Submitted &&
                            <p>
                                Thank you for your application. Please check your email for next steps. Please note - you will not have
                                full access to the Affiliate Portal and associated resources/benefits until you are approved as an
                                Affiliate Member by the Affiliate Member Approval Committee, which meets at the end of each month.
                            </p>
                        }

                        {famAffiliateOnboardingStatus && famAffiliateOnboardingStatus.code === AffiliateStatusCodeEnum.Complete &&
                            <p>
                                Your application has now been approved. You should now have access to
                                the Affiliate Members Portal.
                            </p>
                        }

                        {famAffiliateOnboardingStatus && famAffiliateOnboardingStatus.code === AffiliateStatusCodeEnum.Declined &&
                            <p>
                                Your application has been processed and has been declined.
                                You should have received communications from the Membership Team,
                                if you have not, please contact the RCSI Membership team.
                            </p>
                        }
                    </Typography>
                </Grid>

            </Grid>
        </Box>
    </>
}
