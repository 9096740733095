import { Box, Paper, Grid, Typography } from "@mui/material";
import AppSelectList from "../../app/components/AppSelectList";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { RoyalCollege } from "../../app/models/staticData/royalCollege";
import { MapRoyalCollegesToDropdownItems } from "../../app/slices/convertStaticDataToDropdownItems";
import AppTextInput from "../../app/components/AppTextInput";
import MembershipFormModel from "../../app/models/membership/membershipFormModel";
import { FAMOnboarding } from "../../app/models/membership/famOnboarding";

interface Props {
    royalColleges: RoyalCollege[] | null;
    famTransferDetails: FAMOnboarding | null;
}

export default function TransferInformation({ royalColleges, famTransferDetails }: Props) {
    const methods = useFormContext();
    const { control, reset } = methods;

    const {
        formField: {
            WhichRoyalCollegeAreYouTransferringFrom,
            NameOnCertificate
        }
    } = MembershipFormModel;

    useEffect(() => {
        reset({
            [WhichRoyalCollegeAreYouTransferringFrom.name]: famTransferDetails?.royalCollegeId,
            [NameOnCertificate.name]: famTransferDetails?.nameOnCert ?? ''
        })
    }, [famTransferDetails, reset]);

    return <>
        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12} sx={{ marginTop: '-1%' }}>
                    <Typography variant='subtitle1' sx={{ fontWeight: "500" }}>Transfer information</Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppSelectList
                        control={control}
                        name={WhichRoyalCollegeAreYouTransferringFrom.name}
                        label={WhichRoyalCollegeAreYouTransferringFrom.label}
                        information_text={WhichRoyalCollegeAreYouTransferringFrom.information_text}
                        items={MapRoyalCollegesToDropdownItems(royalColleges ?? [])}
                    />
                </Grid>

                <Grid item xs={12}></Grid>

                <Grid item xs={11}>
                    <Typography variant="subtitle1" sx={{ color: 'primary.main' }}>
                        {NameOnCertificate.information_text}
                    </Typography>
                </Grid>

                <Grid item xs={1}></Grid>

                <Grid item xs={12}>
                    <AppTextInput name={NameOnCertificate.name} label={NameOnCertificate.label} />
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>
    </>
}