import { toast } from "react-toastify";
import CancelIcon from '@mui/icons-material/Cancel';

export default function AppToasterWithErrorMessages(errorMessages: string[]) {
    toast.error(
        <>
            <ul>
                {errorMessages.map((item: string, index: number) => (
                    <li key={index}>{item}</li>
                ))}
            </ul>
        </>
        , { icon: <CancelIcon />, style: { width: '500px' } })
}
