import { SpaMetadata } from '../models/common/spaMetadata';
import { AppPathEnum } from '../enums/appPathEnum';

export const SpaMetadatas: SpaMetadata[] = [{
    name: AppPathEnum.ALUMNI_VOLUNTEERING,
    path: AppPathEnum.ALUMNI_VOLUNTEERING,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_ALUMNI_VOLUNTEERING?.split(',')
},
{
    name: AppPathEnum.DONATION_DASHBOARD,
    path: AppPathEnum.DONATION_DASHBOARD,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_DONATION?.split(',')
},
{
    name: AppPathEnum.DONATION_REGION,
    path: AppPathEnum.DONATION_REGION,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_DONATION_USA?.split(',')
},
{
    name: AppPathEnum.PCS_EVENT_APPROVAL,
    path: AppPathEnum.PCS_EVENT_APPROVAL,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_EVENT_APPROVAL?.split(',')
},
{
    name: AppPathEnum.PCS_PLAN_REVIEW,
    path: AppPathEnum.PCS_PLAN_REVIEW,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_PCS_PLAN_REVIEW?.split(',')
},
{
    name: AppPathEnum.PCS_VIEW_CPD_RECORDS,
    path: AppPathEnum.PCS_VIEW_CPD_RECORDS,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_VIEW_CPD?.split(',')
},
{
    name: AppPathEnum.PCS_VIEW_CPD_RECORDS_CREDIT_RECORD_ID,
    path: AppPathEnum.PCS_VIEW_CPD_RECORDS_CREDIT_RECORD_ID,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_VIEW_CPD?.split(',')
},
{
    name: AppPathEnum.PCS_ADD_CPD_RECORD,
    path: AppPathEnum.PCS_ADD_CPD_RECORD,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_ADD_CPD?.split(',')
},
{
    name: AppPathEnum.PCS_UPDATE_CPD_RECORD_CREDIT_RECORD_ID,
    path: AppPathEnum.PCS_UPDATE_CPD_RECORD_CREDIT_RECORD_ID,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_ADD_CPD?.split(',')
},
{
    name: AppPathEnum.PCS_CLONE_CPD_RECORD_CREDIT_RECORD_ID,
    path: AppPathEnum.PCS_CLONE_CPD_RECORD_CREDIT_RECORD_ID,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_ADD_CPD?.split(',')
},
{
    name: AppPathEnum.PCS_RE_ENROLL,
    path: AppPathEnum.PCS_RE_ENROLL,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_RE_ENROL?.split(',')
},
{
    name: AppPathEnum.PCS_SUMMARY,
    path: AppPathEnum.PCS_SUMMARY,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_SIX_YEAR_SUMMARY?.split(',')
},
{
    name: AppPathEnum.PCS_PLAN,
    path: AppPathEnum.PCS_PLAN,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_PCS_PLAN?.split(',')
},
{
    name: AppPathEnum.PCS_VIEW_PLANNED_ACTIVITIES,
    path: AppPathEnum.PCS_VIEW_PLANNED_ACTIVITIES,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_PCS_PLAN?.split(',')
},
{
    name: AppPathEnum.PCS_REVIEWER_DETAILS_ENROLMENT_YEAR_ID,
    path: AppPathEnum.PCS_REVIEWER_DETAILS_ENROLMENT_YEAR_ID,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_PCS_PLAN_REVIEW?.split(',')
},
{
    name: AppPathEnum.PCS_PLAN_DETAILS_ENROLMENT_YEAR_ID,
    path: AppPathEnum.PCS_PLAN_DETAILS_ENROLMENT_YEAR_ID,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_PCS_PLAN?.split(',')
},
{
    name: AppPathEnum.PCS_PLAN_FEEDBACK_ENROLMENT_YEAR_ID,
    path: AppPathEnum.PCS_PLAN_FEEDBACK_ENROLMENT_YEAR_ID,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_PCS_PLAN?.split(',')
},
{
    name: AppPathEnum.PCS_REGISTRATION,
    path: AppPathEnum.PCS_REGISTRATION,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_REGISTRATION?.split(',')
},
{
    name: AppPathEnum.MEMBERSHIP_ONBOARDING,
    path: AppPathEnum.MEMBERSHIP_ONBOARDING,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_MEMBERSHIP_ONBOARDING?.split(',')
},
{
    name: AppPathEnum.MEMBERSHIP_TRANSFER,
    path: AppPathEnum.MEMBERSHIP_TRANSFER,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_MEMBERSHIP_TRANSFER?.split(',')
},
{
    name: AppPathEnum.AFFILIATE_ASSOCIATE,
    path: AppPathEnum.AFFILIATE_ASSOCIATE,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_AFFILIATE_ASSOCIATE?.split(',')
},
{
    name: AppPathEnum.MEMBERSHIP_VOLUNTEERING,
    path: AppPathEnum.MEMBERSHIP_VOLUNTEERING,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_MEMBERSHIP_VOLUNTEERING?.split(',')
},
{
    name: AppPathEnum.MEMBERSHIP_RENEWAL,
    path: AppPathEnum.MEMBERSHIP_RENEWAL,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_MEMBERSHIP_RENEWAL?.split(',')
},
{
    name: AppPathEnum.UPDATE_PROFILE,
    path: AppPathEnum.UPDATE_PROFILE,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_UPDATE_PROFILE?.split(',')
},
{
    name: AppPathEnum.MY_RECEIPTS,
    path: AppPathEnum.MY_RECEIPTS,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_MY_RECEIPTS?.split(',')
},
{
    name: AppPathEnum.MY_DOCUMENTS,
    path: AppPathEnum.MY_DOCUMENTS,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_MY_DOCUMENTS?.split(',')
},
{
    name: AppPathEnum.ACCESS_REQUEST,
    path: AppPathEnum.ACCESS_REQUEST,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_ACCESS_REQUEST?.split(',')
},
{
    name: AppPathEnum.REQUEST,
    path: AppPathEnum.REQUEST,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_REQUEST?.split(',')
},
{
    name: AppPathEnum.MEMBER_IN_GOOD_STANDING,
    path: AppPathEnum.MEMBER_IN_GOOD_STANDING,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_MEMBERS_IN_GOOD_STANDINGS?.split(',')
},
{
    name: AppPathEnum.RequestPortalAccess,
    path: AppPathEnum.RequestPortalAccess,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_Request_Portal_Access?.split(',')
},
{
    name: AppPathEnum.Reductions_And_Exemptions,
    path: AppPathEnum.Reductions_And_Exemptions,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_REDUCTIONS_AND_EXEMPTIONS?.split(',')
},
{
    name: AppPathEnum.MERCHANDISE,
    path: AppPathEnum.MERCHANDISE,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_MERCHANDISE?.split(',')
},
{
    name: AppPathEnum.LINKEDIN,
    path: AppPathEnum.LINKEDIN,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_LINKEDIN?.split(',')
},
{
    name: AppPathEnum.PASTEST,
    path: AppPathEnum.PASTEST,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_PASTEST?.split(',')
},
{
    name: AppPathEnum.CLASS_NOTES,
    path: AppPathEnum.CLASS_NOTES,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_CLASS_NOTES?.split(',')
},
{
    name: AppPathEnum.TOURS,
    path: AppPathEnum.TOURS,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_TOURS?.split(',')
},
{
    name: AppPathEnum.CONTACT_CLASSMATES,
    path: AppPathEnum.CONTACT_CLASSMATES,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_CONTACT_CLASSMATES?.split(',')
},
{
    name: AppPathEnum.YEAR_BOOK,
    path: AppPathEnum.YEAR_BOOK,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_YEAR_BOOK?.split(',')
},
{
    name: AppPathEnum.ACCESS_CARD_ALUMNI,
    path: AppPathEnum.ACCESS_CARD_ALUMNI,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_ACCESS_CARD_ALUMNI?.split(',')
},
{
    name: AppPathEnum.ACCESS_CARD_FELLOW_AND_MEMBER,
    path: AppPathEnum.ACCESS_CARD_FELLOW_AND_MEMBER,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_ACCESS_CARD_FELLOW_AND_MEMBER?.split(',')
},
{
    name: AppPathEnum.ABSENCE_FROM_PRACTICE_PCS,
    path: AppPathEnum.ABSENCE_FROM_PRACTICE_PCS,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_ABSENCE_FROM_PRACTICE_PCS?.split(',')
},
{
    name: AppPathEnum.REQUEST_TO_WITHDRAW_PCS,
    path: AppPathEnum.REQUEST_TO_WITHDRAW_PCS,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_REQUEST_TO_WITHDRAW_PCS?.split(',')
},
{
    name: AppPathEnum.VOLUNTEER_REQUEST,
    path: AppPathEnum.VOLUNTEER_REQUEST,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_VOLUNTEER_REQUEST?.split(',')
},
{
    name: AppPathEnum.CHANGE_OF_NAME,
    path: AppPathEnum.CHANGE_OF_NAME,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_CHANGE_OF_NAME?.split(',')
},
{
    name: AppPathEnum.DONATIONS_DIRECT_DEBIT,
    path: AppPathEnum.DONATIONS_DIRECT_DEBIT,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_DONATIONS_DIRECT_DEBIT?.split(',')
},
{
    name: AppPathEnum.MEMBERSHIP_DIRECT_DEBIT,
    path: AppPathEnum.MEMBERSHIP_DIRECT_DEBIT,
    allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_MEMBERSHIP_DIRECT_DEBIT?.split(',')
}
];
