import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { PaymentProductProps } from '../common/paymentProductProps';
import { PaymentDto } from '../../../app/models/payment/paymentDto';
import { Currency } from '../../../app/models/staticData/currency';
import { CurrencyCodeEnum } from '../../../app/enums/currencyCodeEnum';
import { CurrencyCodeIconEnum } from '../../../app/enums/currencyCodeIconEnum';

const borderColor = 'Black'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: borderColor,
        borderBottomWidth: 1,
        borderLeftColor: borderColor,
        borderLeftWidth: 1,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        alignItems: 'center',
        height: 24,
        fontSize: 9
    },
    description: {
        width: '70%',
        textAlign: 'right',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
    },
    total: {
        width: '30%',
        paddingLeft: '20px',
        alignItems: 'center'
    }
});

interface Props {
    paymentProducts: PaymentProductProps[];
    paymentDetails: PaymentDto | null;
    currencies: Currency[] | null;
}

const ReceiptTableFooter = ({ paymentProducts, paymentDetails, currencies }: Props) => {
    return (
        <View style={styles.row}>
            <Text style={styles.description}>TOTAL</Text>
            <Text style={styles.total}>

                {paymentDetails?.currencyId === currencies?.find(x => x.code === CurrencyCodeEnum.GBP)?.id && CurrencyCodeIconEnum.UK}

                {paymentDetails?.currencyId === currencies?.find(x => x.code === CurrencyCodeEnum.EUR)?.id && CurrencyCodeIconEnum.EUR}

                {paymentProducts.reduce((sum, current) => sum + (current.amount === null ? 0 : current.amount), 0).toFixed(2)}
            </Text>
        </View>
    )
};

export default ReceiptTableFooter;