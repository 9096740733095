import { Box, Button, Grid, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import EducationalDetails from './EducationalDetails';
import _ from 'lodash';
import { registrationValidationSchema } from './common/registrationValidationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { EducationalDetailsDto } from '../../app/models/person/educationalDetailsDto';
import { useAppDispatch, useAppSelector } from '../../app/store/configureStore';
import { WorkDetailsDto } from '../../app/models/pcsEnrolment/workDetails/workDetailsDto';
import { API_REQUEST_FULLFILLED, MESSAGE_BACK_TO_PREVIOUS_STEP, MESSAGE_NEXT, MESSAGE_PROCESSING_DETAILS, MESSAGE_REGISTER, MESSAGE_SAVE_AND_CONTINUE, MESSAGE_SUBMIT } from '../../app/utils/constant';
import { getPersonalDetailsAsync, updatePersonalDetailsStateAsync } from '../../app/slices/personalDetailsSlice';
import { PersonalDetailsDto } from '../../app/models/person/personalDetailsDto';
import { getReferenceDataAsync } from '../../app/slices/referenceDataSlice';
import { ApiRequestStatus } from '../../app/enums/apiRequestStatus';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { getRegistrationDetailsAsync, saveRegistrationDetailsAsync } from '../../app/slices/registrationDetailsSlice';
import { getEducationalDetailsAsync, saveEducationalDetailsAsync } from '../../app/slices/educationalDetailsSlice';
import { RegistrationDetailsDto } from '../../app/models/pcsEnrolment/registrationDetailsDto';
import { useMsal } from '@azure/msal-react';
import { UserClaims } from '../../app/enums/userClaims';
import { saveWorkDetailsStateAsync } from '../../app/slices/workDetailsSlice';
import Payment from './Payment';
import PersonalDetails from './PersonalDetails';
import RegistrationDetails from './RegistrationDetails';
import WorkDetails from './WorkDetails';
import Header from './Header';

export default function ReEnrol() {
  const steps = ['Registration details', 'Personal details', 'Educational details', 'Work details', 'Payment'];

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return registrationTypes && specialities && schemes &&
          <Box>
            <RegistrationDetails registrationDetails={registrationDetails}
              registrationTypes={registrationTypes} specialities={specialities}
              schemes={schemes} />
          </Box>;
      case 1:
        return titles && genders && countries && personalDetails &&
          <Box>
            <PersonalDetails titles={titles} genders={genders} countries={countries}
              personalDetails={personalDetails} />
          </Box>
      case 2:
        return countries && educationInstitutions && institutionAwards && educationalDetails && personalDetails &&
          <Box>
            <EducationalDetails countries={countries} institutions={educationInstitutions}
              institutionAwards={institutionAwards} educationalDetails={educationalDetails} personalDetails={personalDetails} />
          </Box>
      case 3:
        return <Box><WorkDetails /></Box>
      case 4:
        return <Box><Payment /></Box>
      default:
        throw new Error('Unknown step');
    }
  }

  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = registrationValidationSchema[activeStep];
  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(currentValidationSchema)
  });
  const { trigger, formState: { isDirty } } = methods;
  const form = methods.watch();

  const [compiledForm, setCompiledForm] = useState({});

  const { accounts } = useMsal();

  useEffect(() => {
    if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
      setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
    }
  }, [accounts]);

  const [personId, setPersonId] = useState('');

  const dispatch = useAppDispatch();

  const { titles, genders, countries, countrySubdivisions, specialities, disciplines, areasOfPractice, schemes,
    educationInstitutions, institutionAwards, registrationTypes, staticDataStateStatus } = useAppSelector(state => state.ReferenceData);
  const { registrationDetails, saveRegistrationDetailsStatus } = useAppSelector(state => state.register);
  const { personalDetails, updatePersonalDetailsStatus } = useAppSelector(state => state.personalDetail);
  const { educationalDetails, saveEducationalDetailsStatus, updateEducationalDetailsStatus } = useAppSelector(state => state.educationalDetails);

  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    if (personId !== "" && (personalDetails === null || personalDetails === undefined) &&
      (titles === null || titles?.length === 0) &&
      (genders === null || genders?.length === 0) &&
      (countries === null || countries?.length === 0) &&
      (specialities === null || specialities?.length === 0) &&
      (educationInstitutions === null || educationInstitutions?.length === 0) &&
      (institutionAwards === null || institutionAwards?.length === 0)) {

      setPageLoading(true);

      const pageLoadPromise = async () => await Promise.all([
        dispatch(getReferenceDataAsync()),
        dispatch(getRegistrationDetailsAsync(personId)),
        dispatch(getPersonalDetailsAsync(personId)),
        dispatch(getEducationalDetailsAsync())
      ]);

      pageLoadPromise().finally(() => {
        setPageLoading(false);
      });
    }
  }, [personId, personalDetails, titles, genders, countries, countrySubdivisions, specialities,
    disciplines, areasOfPractice, educationInstitutions, institutionAwards, dispatch]);

  const handleNext = async () => {
    const isStepValid = await trigger();

    if (isStepValid) {

      switch (activeStep) {
        case 0:
          let registrationDetails = MapToRegistrationDetailsDto(form);
          setCompiledForm({ ...compiledForm, registrationDetails: form });
          dispatch(saveRegistrationDetailsAsync({ registrationDetails, personId })).then((response: any) => {
            if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
              setActiveStep(prevActiveStep => prevActiveStep + 1);
            }
          });
          break;
        case 1:
          let personalDetailsDto = MapToPersonalDetailsDto(form);
          setCompiledForm({ ...compiledForm, personalDetails: form });
          personalDetailsDto.id = personId;
          dispatch(updatePersonalDetailsStateAsync(personalDetailsDto)).then((response: any) => {
            if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
              setActiveStep(prevActiveStep => prevActiveStep + 1);
            }
          });
          break;
        case 2:
          let educationalDetailsDto = MapToEducationalDetailsDto(form);
          setCompiledForm({ ...compiledForm, educationalDetails: form });
          setActiveStep(prevActiveStep => prevActiveStep + 1);
          dispatch(saveEducationalDetailsAsync(educationalDetailsDto)).then((response: any) => {
            if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
              setActiveStep(prevActiveStep => prevActiveStep + 1);
            }
          });
          break;
        case 3:
          let workDetailsDto = MapToWorkDetailsDto(form);
          setCompiledForm({ ...compiledForm, workDetails: form });
          dispatch(saveWorkDetailsStateAsync({ workDetailsDto, personId: personId })).then((response: any) => {
            if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
              setActiveStep(prevActiveStep => prevActiveStep + 1);
            }
          });
          break;
        default:
          return "not a valid step";
      }
    }
  };

  async function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  function MapToRegistrationDetailsDto(values: any) {
    const data: RegistrationDetailsDto = {
      imcNumber: values.IMCNumber,
      registrationTypeId: values.RegistrationType,
      schemeId: values.Scheme,
      specialtyId: values.Speciality,
      engagedInPracticeOfMedicine: values.EngagedInPracticeOfMedicine === '1',
      subSpeciality: values.SubSpeciality,
      declaration: values.UserConfirmation.length > 0
    }
    return data;
  }

  function MapToPersonalDetailsDto(values: any) {
    const data: PersonalDetailsDto = {
      titleId: values.Title,
      genderId: values.Gender,
      nationalityId: values.Nationality,
      firstName: values.FirstName,
      lastName: values.LastName,
      dateOfBirth: values.DOB,
      mobile: values.MobileNumber
    }
    return data;
  }

  function MapToEducationalDetailsDto(values: any) {
    const data: EducationalDetailsDto = {
      id: educationalDetails?.id,
      personId: personId,
      qualificationCountryId: form.QualificationCountry,
      qualificationInstitutionId: form.PrimaryMedicalDegreeCollege,
      graduationYear: form.QualificationYear,
      otherQualifications: form.OtherQualifications,
      institutionAwardId: form.InstitutionAwardId
    }
    return data;
  }

  function MapToWorkDetailsDto(values: any) {
    const data: WorkDetailsDto = {
      engagedInPracticeOfMedicine: values.EngagedInPracticeOfMedicine,
      isPostInIreland: values.IsPostInIreland,
      nameOfEmployers: values.NameOfEmployers,
      notEngagedInPracticeOfMedicine: values.NotengagedInPracticeOfMedicine,
      startDate: values.StartDate
    }
    return data;
  }

  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  if (pageLoading === true || staticDataStateStatus === ApiRequestStatus.Pending
    || saveRegistrationDetailsStatus === ApiRequestStatus.Pending
    || updatePersonalDetailsStatus === ApiRequestStatus.Pending
    || saveEducationalDetailsStatus === ApiRequestStatus.Pending
    || updateEducationalDetailsStatus === ApiRequestStatus.Pending) {
    return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
  }

  return (
    <FormProvider {...methods}>
      <Header />
      
      <br></br>

      <Stepper activeStep={activeStep} orientation={isMobile === true ? "vertical" : "horizontal"}>
        {steps.map((label) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <br></br>

      {activeStep !== 0 && (
        <Button variant="contained" onClick={_handleBack}>
          {MESSAGE_BACK_TO_PREVIOUS_STEP}
        </Button>
      )}

      {activeStep < steps.length &&
        <>
          {getStepContent(activeStep)}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Grid container rowSpacing={4}>
              <Grid item xs={12}></Grid>
              <Grid item xs={8}></Grid>
              <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button variant="contained" color="primary" onClick={handleNext}>
                  {activeStep === steps.length - 1 ? MESSAGE_SUBMIT : (isDirty === true ? MESSAGE_SAVE_AND_CONTINUE : MESSAGE_NEXT)}
                </Button>
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          </Box>
        </>
      }
    </FormProvider >
  );
};
