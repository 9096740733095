import { PaymentDto } from "../../../app/models/payment/paymentDto";
import { PaymentProductDto } from "../../../app/models/payment/paymentProductDto";
import PaymentProductJsonDto from "../../../app/models/affiliateOnboarding/paymentProductJsonDto";
import { Document, Page, StyleSheet, Font } from "@react-pdf/renderer";
import ReceiptTitle from "./ReceiptTitle";
import { PaymentProductProps } from "../common/paymentProductProps";
import ReceiptItemsTable from "./ReceiptItemsTable";
import { useState } from "react";
import { PersonalDetailsDto } from "../../../app/models/person/personalDetailsDto";
import { Country } from "../../../app/models/staticData/country";
import { Title } from "../../../app/models/staticData/title";
import ReceiptHeader from "./ReceiptHeader";
import { PaymentMethod } from "../../../app/models/staticData/paymentMethod";
import { Fund } from "../../../app/models/staticData/fund";
import ReceiptFooter from "./ReceiptFooter";
import { Currency } from "../../../app/models/staticData/currency";

Font.register({
    family: 'Open Sans',
    fonts: [
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf', fontWeight: 700 },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf', fontWeight: 800 }
    ]
});

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    }
});

interface Props {
    titles: Title[] | null;
    countries: Country[] | null;
    funds: Fund[] | null;
    currencies: Currency[] | null;
    paymentDetails: PaymentDto | null;
    personalDetails: PersonalDetailsDto | null;
    paymentMethods: PaymentMethod[] | null;
}

export default function PdfDocument(props: Props) {
    const { titles, countries, funds, paymentDetails, personalDetails, paymentMethods, currencies } = props;

    const paymentProducts = () => {

        let paymentProductArray: PaymentProductProps[] = [];

        paymentDetails?.paymentProducts.map((paymentProduct: PaymentProductDto) => {
            if (paymentProduct.jsonValues !== null) {
                let paymentProductJsonObject = JSON.parse(paymentProduct.jsonValues) as PaymentProductJsonDto;
                const fund = funds?.find(x => x.id === paymentProductJsonObject.FundId);

                if (funds && fund !== null && fund !== undefined) {
                    paymentProductArray.push(
                        { year: 'Donation to ' + fund?.value, amount: paymentProduct.cost });
                }
                else if (paymentProductJsonObject.Year) {
                    paymentProductArray.push(
                        { year: paymentProductJsonObject.Year + ' Annual Membership', amount: paymentProduct.cost });
                }
            }
        });

        return paymentProductArray;
    }

    const [paymentProductItems,] = useState<PaymentProductProps[]>(paymentProducts());

    return (
        <>
            <Document>
                <Page style={styles.body}>
                    <ReceiptHeader titles={titles} countries={countries} currencies={currencies} paymentDetails={paymentDetails}
                        paymentMethods={paymentMethods} personalDetails={personalDetails} />
                    <ReceiptTitle title={'Payment receipt'} />
                    <ReceiptItemsTable currencies={currencies} paymentProducts={paymentProductItems} paymentDetails={paymentDetails} />
                    <ReceiptFooter currencies={currencies} paymentDetails={paymentDetails} />
                </Page>
            </Document >
        </>)
}
