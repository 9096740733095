import { FormHelperText, Grid } from "@mui/material";
import { useState } from "react";
import { UseControllerProps, useController } from "react-hook-form";
import AppInformationText from "../../components/AppInformationText";
import { CheckBoxItem } from "../common/checkBoxItem";

interface Props extends UseControllerProps {
    option: CheckBoxItem;
    information_text?: string;
}

const AppDierctDebitDeclarationCheckbox = (props: Props) => {
    const { option, information_text } = props;
    const { fieldState, field } = useController({ ...props });
    const [, setValue] = useState(field.value || []);

    return (
        <>
            <Grid container>

                <Grid item xs={11} sm={11}>
                    <div key={option.id}>
                        <Grid container>
                            <Grid item xs={11.5}>
                                By checking this box, you authorize*<br></br>
                                (A) RCSI to send instructions to your bank to debit your account and<br></br>
                                (B) Your bank to debit your account in accordance with the instructions from RCSI.
                            </Grid>

                            <Grid item xs={0.5} sx={{ alignSelf: "end" }}>
                                <input
                                    {...field}
                                    onChange={(e) => {
                                        if (e.target.checked === true &&
                                            !field.value?.includes(option.id)) {
                                            field.value.push(option.id);
                                        }
                                        else {
                                            field.value = field.value.filter((e: string) => e !== option.id);
                                        }
                                        field.onChange(field.value);
                                        setValue(field.value);
                                    }}
                                    key={option.id}
                                    type="checkbox"
                                    checked={field.value === undefined ? false : field.value?.includes(option.id)}
                                    value={option.value}
                                />
                            </Grid>
                        </Grid>
                        <br></br>
                    </div>
                </Grid>

                <Grid item xs={1} sx={{ alignSelf: "end" }}>
                    <AppInformationText information_text={information_text} />
                </Grid>

                <Grid item xs={11} sm={12}>
                    {fieldState.error &&
                        <FormHelperText sx={{ color: 'primary.main' }}>{fieldState.error.message}</FormHelperText>}
                </Grid>

            </Grid>
        </>
    );
};

export default AppDierctDebitDeclarationCheckbox;