import { Box, Grid } from "@mui/material";
import pcsPlanFormModel from "../../../common/pcsPlanFormModel";
import { ReviewerDetailsDto } from "../../../models/reviewerDetailsDto";
import AppLabelField from "../../../../../app/components/AppLabelField";

interface Props {
    title: string | undefined,
    reviewerDetails?: ReviewerDetailsDto | null;
}

export function ViewReviewer(props: Props) {

    const { Title, FirstName, Surname } = pcsPlanFormModel.formField;

    return <>
        <Box sx={{ width: '100%' }}>
            <Grid container rowSpacing={4}>

                <Grid item xs={12}></Grid>

                <Grid item xs={12}>
                    <AppLabelField value={props.title}
                        label={Title.label} />
                </Grid>

                <Grid item xs={12}>
                    <AppLabelField value={props.reviewerDetails?.firstnames}
                        label={FirstName.label} />
                </Grid>

                <Grid item xs={12}>
                    <AppLabelField value={props.reviewerDetails?.surname}
                        label={Surname.label} />
                </Grid>
                
            </Grid> 
        </Box >
    </>
}