import * as yup from 'yup';
import "yup-phone-lite";
import { 
  LocationAndPreferencesValidationSchema,
  PersonalDetailsValidationSchema, PostalAddressValidationSchema 
} from '../../../app/models/membership/validationSchemas';
import MembershipFormModel from '../../../app/models/membership/membershipFormModel';

const {
  formField: {
    NameOnCertificate,
    Declaration,
    PetitionForElection
  }
} = MembershipFormModel;

export const membershipOnboardingValidationSchema = [
  PersonalDetailsValidationSchema,
  PostalAddressValidationSchema,
  LocationAndPreferencesValidationSchema,
  yup.object().shape({
    [NameOnCertificate.name]: yup.string().required(`${NameOnCertificate.requiredErrorMsg}`)
  }),
  yup.object().shape({
    [Declaration.name]: yup.array().min(1, Declaration.requiredErrorMsg).of(yup.string().required(Declaration.requiredErrorMsg)).required(),
    [PetitionForElection.name]: yup.array().min(1, PetitionForElection.requiredErrorMsg).of(yup.string().required(PetitionForElection.requiredErrorMsg)).required()
})
]
