import { Box, Grid, Paper, Typography } from "@mui/material";

export default function NotFound() {
    return <>
        <Box sx={{ flexGrow: 1 }} component={Paper}>

            <Grid container rowSpacing={4} sx={{ mt: "0", mr: "2%", ml: "2%" }}>

                <Grid item xs={12}>
                    <Typography gutterBottom variant='h4'>
                        We are sorry, the page you are looking for cannot be found.
                    </Typography>
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>
    </>
}