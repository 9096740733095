import { Box, Paper, Grid, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import AppSelectList from "../../components/AppSelectList";
import AppTextInput from "../../components/AppTextInput";
import MembershipFormModel from "../membership/membershipFormModel";
import { PersonalDetailsDto } from "../person/personalDetailsDto";
import { AreaOfPractice } from "../staticData/areaOfPractice";
import { Discipline } from "../staticData/discipline";
import {
    MapDisciplinesToDropdownItems, MapAreaOfPracticesToDropdownItems,
    MapCountriesToDropdownItems, MapCountrySubdivisionToDropdownItems
}
    from "../../slices/convertStaticDataToDropdownItems";
import { Country } from "../staticData/country";
import { CountrySubdivision } from "../staticData/countrySubdivision";
import { MESSAGE_NOT_A_VALUE } from "../../utils/constant";

interface Props {
    countryInfoText: string;
    disciplines: Discipline[] | null;
    areasOfPractice: AreaOfPractice[] | null;
    countries: Country[] | null;
    countrySubdivisions: CountrySubdivision[] | null;
    personalDetails: PersonalDetailsDto | null;
}

export default function VolunteeringPersonalDetails(props: Props) {
    const { countryInfoText, disciplines, areasOfPractice, countries,
        countrySubdivisions, personalDetails } = props;
    const methods = useFormContext();
    const { control, reset, getValues, setValue, watch } = methods;
    methods.watch();

    const {
        formField: {
            PreferredPhoneNumber,
            Discipline,
            AreaOfPracticeMoreDetails,
            LocationCountry,
            CountrySubdivision
        }
    } = MembershipFormModel;

    useEffect(() => {
        if (personalDetails !== undefined && personalDetails !== null
            && disciplines && disciplines?.length > 0
            && areasOfPractice && areasOfPractice?.length > 0
            && countries && countries?.length > 0
            && countrySubdivisions && countrySubdivisions?.length > 0) {

            reset({
                [PreferredPhoneNumber.name]: personalDetails.mobile,
                [Discipline.name]: (personalDetails.disciplineId === null ||
                    personalDetails.disciplineId === undefined) ? '' : personalDetails.disciplineId,
                [AreaOfPracticeMoreDetails.name]: (personalDetails.areaOfPracticeId === null ||
                    personalDetails.areaOfPracticeId === undefined) ? '' : personalDetails.areaOfPracticeId,
                [LocationCountry.name]: (personalDetails.locationCountryId === null ||
                    personalDetails.locationCountryId === undefined) ? '' : personalDetails.locationCountryId,
                [CountrySubdivision.name]: (personalDetails.locationCountrySubdivisionId === null ||
                    personalDetails.locationCountrySubdivisionId === undefined) ? '' : personalDetails.locationCountrySubdivisionId
            });

            if ((!countrySubdivisions || countrySubdivisions?.length === 0) ||
                (personalDetails.locationCountryId !== null && (personalDetails.locationCountrySubdivisionId === null ||
                    personalDetails.locationCountrySubdivisionId === undefined))) {
                setValue(CountrySubdivision.name, MESSAGE_NOT_A_VALUE);
            }

            if (!areasOfPractice || areasOfPractice?.length === 0) {
                setValue(AreaOfPracticeMoreDetails.name, MESSAGE_NOT_A_VALUE);
            }
        }
    }, [personalDetails, disciplines, areasOfPractice, countries, countrySubdivisions, reset]);

    useEffect(() => {
        watch((value, { name, type }) => {
            if (name === LocationCountry.name && type === 'change') {
                if (countrySubdivisions?.filter(x => x.countryId === getValues(LocationCountry.name)) &&
                    countrySubdivisions?.filter(x => x.countryId === getValues(LocationCountry.name)).length > 0) {
                    setValue(CountrySubdivision.name, '');
                }
                else {
                    setValue(CountrySubdivision.name, MESSAGE_NOT_A_VALUE);
                }
            }

            if (name === Discipline.name && type === 'change') {
                if (areasOfPractice?.filter(x => x.disciplineId === getValues(Discipline.name)) &&
                    areasOfPractice?.filter(x => x.disciplineId === getValues(Discipline.name)).length > 0) {
                    setValue(AreaOfPracticeMoreDetails.name, '');
                }
                else {
                    setValue(AreaOfPracticeMoreDetails.name, MESSAGE_NOT_A_VALUE);
                }
            }
        });
    }, [watch]);

    return <>
        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12} sx={{ marginTop: '-1%' }}>
                    <Typography variant='subtitle1' sx={{ fontWeight: "500" }}>Personal details</Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput name={PreferredPhoneNumber.name} label={PreferredPhoneNumber.label} />
                </Grid>

                <Grid item xs={12}>
                    <AppSelectList
                        control={control}
                        name={Discipline.name}
                        label={Discipline.label}
                        items={MapDisciplinesToDropdownItems(disciplines ?? [])}
                    />
                </Grid>

                {areasOfPractice?.filter(x => x.disciplineId === getValues(Discipline.name)) &&
                    areasOfPractice?.filter(x => x.disciplineId === getValues(Discipline.name)).length > 0 &&
                    <Grid item xs={12}>
                        <AppSelectList
                            control={control}
                            name={AreaOfPracticeMoreDetails.name}
                            label={AreaOfPracticeMoreDetails.label}
                            items={MapAreaOfPracticesToDropdownItems(areasOfPractice?.filter(
                                x => x.disciplineId === getValues(Discipline.name)) ?? [])}
                        />
                    </Grid>
                }

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>

        <Box sx={{ flexGrow: 1, minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12} sx={{ marginTop: '-1%' }}>
                    <Typography variant='subtitle1' sx={{ fontWeight: "500" }}>Country of Practice</Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppSelectList
                        control={control}
                        name={LocationCountry.name}
                        label={LocationCountry.label}
                        information_text={countryInfoText}
                        items={MapCountriesToDropdownItems(countries ?? [])}
                    />
                </Grid>

                {countrySubdivisions?.filter(x => x.countryId === getValues(LocationCountry.name)) &&
                    countrySubdivisions?.filter(x => x.countryId === getValues(LocationCountry.name)).length > 0 &&
                    <Grid item xs={12}>
                        <AppSelectList
                            control={control}
                            name={CountrySubdivision.name}
                            label={CountrySubdivision.label}
                            items={MapCountrySubdivisionToDropdownItems(countrySubdivisions?.filter(
                                x => x.countryId === getValues(LocationCountry.name)) ?? [])}
                        />
                    </Grid>
                }

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>
    </>
}