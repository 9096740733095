import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../../app/api/agent";
import { ApiRequestStatus } from "../../app/enums/apiRequestStatus";
import { SummaryDto } from "../../app/models/summary/summaryDto";

export interface SummaryState {
    summaryData: SummaryDto[][] | null;
    status: ApiRequestStatus;
}

export const getSummariesAsync = createAsyncThunk<SummaryState, string>(
    'sumary/getSummaries',
    async (personId, thunkAPI) => {
        try {
            return agent.SixYearSummary.summaries(personId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

const initialState: SummaryState = {
    summaryData: null,
    status: ApiRequestStatus.Idle
}

export const summarySlice = createSlice({
    name: 'summary',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(getSummariesAsync.pending, (state) => {
            state.status = ApiRequestStatus.Pending;
        });
        builder.addCase(getSummariesAsync.fulfilled, (state, action) => {
            state.summaryData = action.payload.summaryData;
            state.status = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(getSummariesAsync.rejected, (state) => {
            state.status = ApiRequestStatus.Rejected;
        });
    })
})
