import { Box, Button, Grid, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import PersonalDetails from './PersonalDetails';
import Payment from './Payment';
import _ from 'lodash';
import { registrationValidationSchema } from './common/registrationValidationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from '../../app/store/configureStore';
import { RegistrationDetailsDto } from '../../app/models/pcsEnrolment/registrationDetailsDto';
import {
  API_REQUEST_FULLFILLED, MESSAGE_DETAILS_SAVED, MESSAGE_SAVE_AND_CONTINUE,
  MESSAGE_NEXT, MESSAGE_SUBMIT, MESSAGE_BACK_TO_PREVIOUS_STEP, MESSAGE_PROCESSING_DETAILS
} from '../../app/utils/constant';
import { getPersonalDetailsAsync, updatePersonalDetailsStateAsync } from '../../app/slices/personalDetailsSlice';
import { PersonalDetailsDto } from '../../app/models/person/personalDetailsDto';
import { getReferenceDataAsync } from '../../app/slices/referenceDataSlice';
import { ApiRequestStatus } from '../../app/enums/apiRequestStatus';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { getRegistrationDetailsAsync, saveRegistrationDetailsAsync } from '../../app/slices/registrationDetailsSlice';
import { getEducationalDetailsAsync, saveEducationalDetailsAsync, updateEducationalDetailsAsync } from '../../app/slices/educationalDetailsSlice';
import { EducationalDetailsDto } from '../../app/models/person/educationalDetailsDto';
import { toast } from 'react-toastify';
import { useMsal } from '@azure/msal-react';
import { UserClaims } from '../../app/enums/userClaims';
import { saveWorkDetailsStateAsync } from '../../app/slices/workDetailsSlice';
import { WorkDetailsDto } from '../../app/models/pcsEnrolment/workDetails/workDetailsDto';
import EducationalDetails from './EducationalDetails';
import RegistrationDetails from './RegistrationDetails';
import WorkDetails from './WorkDetails';
import Header from './Header';

export default function Registration() {
  const steps = ['Registration details', 'Personal details', 'Educational details', 'Work details', 'Payment'];

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <RegistrationDetails registrationDetails={registrationDetails}
          registrationTypes={registrationTypes} specialities={specialities}
          schemes={schemes} />
      case 1:
        return <PersonalDetails titles={titles} genders={genders} countries={countries}
          personalDetails={personalDetails} />
      case 2:
        return <EducationalDetails countries={countries} institutions={educationInstitutions}
          institutionAwards={institutionAwards} educationalDetails={educationalDetails} personalDetails={personalDetails} />
      case 3:
        return <WorkDetails />
      case 4:
        return <Payment />
      default:
        throw new Error('Unknown step');
    }
  }

  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = registrationValidationSchema[activeStep];
  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(currentValidationSchema)
  });
  const { trigger, formState: { isDirty } } = methods;
  const form = methods.watch();

  const { accounts } = useMsal();

  useEffect(() => {
    if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
      setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
    }
  }, [accounts]);

  const [personId, setPersonId] = useState('');

  const dispatch = useAppDispatch();

  const { titles, genders, countries, countrySubdivisions, specialities, disciplines, areasOfPractice, schemes,
    educationInstitutions, institutionAwards, registrationTypes, staticDataStateStatus } = useAppSelector(state => state.ReferenceData);
  const { registrationDetails, saveRegistrationDetailsStatus } = useAppSelector(state => state.register);
  const { personalDetails, updatePersonalDetailsStatus } = useAppSelector(state => state.personalDetail);
  const { educationalDetails, saveEducationalDetailsStatus, updateEducationalDetailsStatus } = useAppSelector(state => state.educationalDetails);

  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    if (personId !== "" && (personalDetails === null || personalDetails === undefined) &&
      (titles === null || titles?.length === 0) &&
      (genders === null || genders?.length === 0) &&
      (countries === null || countries?.length === 0) &&
      (specialities === null || specialities?.length === 0) &&
      (educationInstitutions === null || educationInstitutions?.length === 0) &&
      (institutionAwards === null || institutionAwards?.length === 0)) {

      setPageLoading(true);

      const pageLoadPromise = async () => await Promise.all([
        dispatch(getReferenceDataAsync()),
        dispatch(getRegistrationDetailsAsync(personId)),
        dispatch(getPersonalDetailsAsync(personId)),
        dispatch(getEducationalDetailsAsync())
      ]);

      pageLoadPromise().finally(() => {
        setPageLoading(false);
      });
    }
  }, [personId, personalDetails, titles, genders, countries, countrySubdivisions, specialities,
    disciplines, areasOfPractice, educationInstitutions, institutionAwards, dispatch]);

  const handleNext = async () => {
    const isStepValid = await trigger();

    if (isStepValid) {
      if (isDirty === true) {
        switch (activeStep) {
          case 0:
            let registrationDetails = MapToRegistrationDetailsDto();
            dispatch(saveRegistrationDetailsAsync({ registrationDetails, personId })).then((response: any) => {
              if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                toast.success(MESSAGE_DETAILS_SAVED);
                setActiveStep(prevActiveStep => prevActiveStep + 1);
              }
            });
            break;
          case 1:
            let personalDetailsDto = MapToPersonalDetailsDto();
            dispatch(updatePersonalDetailsStateAsync(personalDetailsDto)).then((response: any) => {
              if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                toast.success(MESSAGE_DETAILS_SAVED);
                setActiveStep(prevActiveStep => prevActiveStep + 1);
              }
            });
            break;
          case 2:
            let educationalDetailsDto = MapToEducationalDetailsDto();
            if (educationalDetails !== null && !educationalDetails.id) {
              dispatch(saveEducationalDetailsAsync(educationalDetailsDto)).then((response: any) => {
                if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                  toast.success(MESSAGE_DETAILS_SAVED);
                  setActiveStep(prevActiveStep => prevActiveStep + 1);
                }
              });
            }
            else {
              dispatch(updateEducationalDetailsAsync(educationalDetailsDto)).then((response: any) => {
                if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                  toast.success(MESSAGE_DETAILS_SAVED);
                  setActiveStep(prevActiveStep => prevActiveStep + 1);
                }
              });
            }
            break;
          case 3:
            let workDetailsDto = MapToWorkDetailsDto();
            dispatch(saveWorkDetailsStateAsync({ workDetailsDto, personId: personId })).then((response: any) => {
              if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                toast.success(MESSAGE_DETAILS_SAVED);
                setActiveStep(prevActiveStep => prevActiveStep + 1);
              }
            });
            break;
          default:
            return "not a valid step";
        }
      }
      else if (isDirty === false) {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      }
    }
  };

  async function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  function MapToRegistrationDetailsDto() {
    const data: RegistrationDetailsDto = {
      imcNumber: form.IMCNumber,
      registrationTypeId: form.RegistrationType,
      schemeId: form.Scheme,
      specialtyId: form.Speciality,
      engagedInPracticeOfMedicine: form.EngagedInPracticeOfMedicine === '1',
      subSpeciality: form.SubSpeciality,
      declaration: form.UserConfirmation.length > 0
    }
    return data;
  }

  function MapToPersonalDetailsDto() {
    const data: PersonalDetailsDto = {
      id: personalDetails?.id,
      titleId: form.Title,
      genderId: form.Gender,
      nationalityId: form.Nationality,
      firstName: form.FirstName,
      lastName: form.LastName,
      preferredEmail: 'sa@s.com',
      dateOfBirth: form.DOB,
      mobile: form.MobileNumber
    }
    return data;
  }

  function MapToEducationalDetailsDto() {
    const data: EducationalDetailsDto = {
      id: educationalDetails?.id,
      personId: personId,
      qualificationCountryId: form.QualificationCountry,
      qualificationInstitutionId: form.PrimaryMedicalDegreeCollege,
      graduationYear: form.QualificationYear,
      otherQualifications: form.OtherQualifications,
      institutionAwardId: form.InstitutionAwardId
    }
    return data;
  }

  function MapToWorkDetailsDto() {
    const data: WorkDetailsDto = {
      engagedInPracticeOfMedicine: form.EngagedInPracticeOfMedicine,
      isPostInIreland: form.IsPostInIreland,
      nameOfEmployers: form.NameOfEmployers,
      notEngagedInPracticeOfMedicine: form.NotengagedInPracticeOfMedicine,
      startDate: form.StartDate
    }
    return data;
  }

  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  if (pageLoading === true || staticDataStateStatus === ApiRequestStatus.Pending
    || saveRegistrationDetailsStatus === ApiRequestStatus.Pending
    || updatePersonalDetailsStatus === ApiRequestStatus.Pending
    || saveEducationalDetailsStatus === ApiRequestStatus.Pending
    || updateEducationalDetailsStatus === ApiRequestStatus.Pending) {
    return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
  }

  return (
    <FormProvider {...methods}>
      <Header />

      <br></br>

      <Stepper activeStep={activeStep} orientation={isMobile === true ? "vertical" : "horizontal"}>
        {steps.map((label) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <br></br>

      {activeStep !== 0 && (
        <Button variant="contained" onClick={_handleBack}>
          {MESSAGE_BACK_TO_PREVIOUS_STEP}
        </Button>
      )}

      {activeStep < steps.length &&
        <>
          {getStepContent(activeStep)}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Grid container rowSpacing={4}>
              <Grid item xs={12}></Grid>
              <Grid item xs={8}></Grid>
              <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button variant="contained" color="primary" onClick={handleNext}>
                  {activeStep === steps.length - 1 ? MESSAGE_SUBMIT : (isDirty === true ? MESSAGE_SAVE_AND_CONTINUE : MESSAGE_NEXT)}
                </Button>
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          </Box>
        </>}
    </FormProvider >
  );
};
