import { StyleSheet, View, Text, Image, Font } from '@react-pdf/renderer';

Font.register({
    family: 'Open Sans',
    fonts: [
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf', fontWeight: 700 },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf', fontWeight: 800 }
    ]
});

const styles = StyleSheet.create({
    image: {
        width: 60,
        height: 70,
        alignContent: 'flex-start',
        paddingLeft: '10px'
    },
    page: {
        flexDirection: "column",
        padding: 25
    },
    table: {
        fontSize: 10,
        width: 550,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignContent: "stretch",
        flexWrap: "nowrap",
        alignItems: "stretch"
    },
    row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignContent: "stretch",
        flexWrap: "nowrap",
        alignItems: "stretch",
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 35
    },
    headerText: {
        fontSize: 9
    },
    boldText: {
        fontFamily: 'Open Sans',
        fontWeight: 800
    },
    width10: {
        width: '10%'
    },
    center: {
        alignContent: 'center',
        width: "40%"
    },
    right: {
        alignContent: 'flex-end',
        width: "30%"
    }
});

export function Header() {
    return (
        <>
            <View style={styles.table}>
                <View style={[styles.row]}>
                    <Image
                        style={[styles.image]}
                        src={`${String(process.env.REACT_APP_STORAGE_ACCOUNT_URL)}/images/rcsi-logo-png.png`}
                    />
                    <View style={[styles.width10]}></View>
                    <Text style={[styles.headerText, styles.center]}>
                        <Text style={[styles.boldText]}>RCSI Development.</Text> Alumni Relations, Fellows and Members.{"\n"}
                        Royal College of Surgeons in Ireland{"\n"}
                        Coláiste Ríoga na Máinleá in Éirinn{"\n"}
                        111 St Stephen's Green{"\n"}
                        Dublin 2, Ireland
                    </Text>
                    <Text style={[styles.headerText, styles.right]}>
                        <Text style={[styles.boldText]}>Tel</Text> +353 1 402 2558{"\n"}
                        <Text style={[styles.boldText]}>Email</Text> fellows@rcsi.ie{"\n"}
                        <Text style={[styles.boldText]}>www</Text>.rcsi.ie
                    </Text>
                </View>
            </View>
        </>
    )
}
