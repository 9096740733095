import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Paper, Typography } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from "react";
import { cpdValidationSchema } from "../../common/cpdValidationSchema";
import { ApiRequestStatus } from "../../../../app/enums/apiRequestStatus";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useAppDispatch, useAppSelector } from "../../../../app/store/configureStore";
import { addCpdStateAsync } from "../../viewCpdSlice";
import { CpdDto } from "../../models/cpdDto";
import AppSelectList from "../../../../app/components/AppSelectList";
import cpdFormModel from "../../common/cpdFormModel";
import AppDatePicker from "../../../../app/components/AppDatePicker";
import AppCheckboxFieldWithLeftAlignedLabel from "../../../../app/components/AppCheckboxFieldWithLeftAlignedLabel";
import { useHistory } from "react-router-dom";
import { CheckBoxItem } from "../../../../app/models/common/checkBoxItem";
import { MapDomainsToCheckboxItems, MapCategoriesToDropdownItems, MapActivityTypesToDropdownItems } from "../../../../app/slices/convertStaticDataToDropdownItems";
import { DropdownItem } from "../../../../app/models/common/dropdownItem";
import { StaticDataResponseState } from "../../../../app/models/staticData/staticDataResponseState";
import { getReferenceDataAsync } from "../../../../app/slices/referenceDataSlice";
import AppSlider from "../../../../app/components/AppSlider";
import { API_REQUEST_FULLFILLED } from "../../../../app/utils/constant";
import AppTextareaInput from "../../../../app/components/AppTextareaInput";
import { useMsal } from "@azure/msal-react";
import { UserClaims } from "../../../../app/enums/userClaims";

const { formField } = cpdFormModel;

export function AddCPD() {

    const { control, handleSubmit } = useForm({
        defaultValues: cpdValidationSchema.cast({
            [formField.ActivityDate.name]: '',
            [formField.Category.name]: '',
            [formField.ActivityType.name]: '',
            [formField.Domains.name]: [],
            [formField.CreditsClaiming.name]: 0,
            [formField.Description.name]: '',
            [formField.DevelopmentPurpose.name]: ''
        }),
        resolver: yupResolver(cpdValidationSchema)
    });

    const history = useHistory();

    async function _submitForm(values: any) {
        dispatch(addCpdStateAsync(MapToCpdDetailsDto(values))).then((response: any) => {
            if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                history.push(`/pcsviewcpdrecord/${response.payload}`);
            }
        });
    }

    function MapToCpdDetailsDto(values: any) {
        const data: CpdDto = {
            activityDate: new Date(new Date(values.ActivityDate).toString().split('+')[0]),
            cpdCategoryId: values.Category,
            activityTypeId: values.ActivityType,
            goodPracticeDomainIds: values.Domains,
            credits: Number(values.CreditsClaiming),
            description: values.Description,
            purpose: values.DevelopmentPurpose,
            personId: personId
        }
        return data;
    }

    const dispatch = useAppDispatch();
    const { cpdCategories, activityTypes, goodPracticeDomains, staticDataStateStatus } = useAppSelector(state => state.ReferenceData);
    const { cpdStateStatus } = useAppSelector(state => state.cpd);

    const { accounts } = useMsal();

    useEffect(() => {
        if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
            setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
        }
    }, [accounts]);

    const [personId, setPersonId] = useState('');
    const [domains, setDomains] = useState<CheckBoxItem[]>([]);
    const [categories, setCategories] = useState<DropdownItem[]>([]);
    const [activityTypeList, setActivityTypeList] = useState<DropdownItem[]>([]);

    useEffect(() => {
        if (!cpdCategories && !activityTypes && !goodPracticeDomains) {
            dispatch(getReferenceDataAsync()).then((response: any) => {
                let responseData = response.payload as StaticDataResponseState;
                setDomains(MapDomainsToCheckboxItems(responseData.goodPracticeDomains ?? []));
                setCategories(MapCategoriesToDropdownItems(responseData.cpdCategories ?? []));
                setActivityTypeList(MapActivityTypesToDropdownItems(responseData.activityTypes ?? []));
            });
            return;
        }

        if (goodPracticeDomains) {
            setDomains(MapDomainsToCheckboxItems(goodPracticeDomains ?? []));
        }

        if (cpdCategories) {
            setCategories(MapCategoriesToDropdownItems(cpdCategories ?? []));
        }

        if (activityTypes) {
            setActivityTypeList(MapActivityTypesToDropdownItems(activityTypes ?? []));
        }
    }, [cpdCategories, activityTypes, goodPracticeDomains, dispatch]);

    function isPageProcessing() {
        return (cpdStateStatus === ApiRequestStatus.Pending || staticDataStateStatus === ApiRequestStatus.Pending);
    }

    return <>
        {isPageProcessing() && <LoadingComponent message="Processing CPD record.." />}

        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <form onSubmit={handleSubmit(_submitForm)}>
                <Box sx={{ width: '100%' }}>
                    <Grid container rowSpacing={4}>

                        <Grid item xs={12}>
                            <Typography variant='h4'>Add CPD</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>Please add your CPD details.</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <AppDatePicker
                                control={control}
                                name={formField.ActivityDate.name}
                                label={formField.ActivityDate.label}
                                information_text={formField.ActivityDate.informationText} />
                        </Grid>

                        <Grid item xs={12}>
                            <AppSelectList
                                control={control}
                                name={formField.Category.name}
                                label={formField.Category.label}
                                items={categories}
                                information_text={formField.Category.informationText} />
                        </Grid>

                        <Grid item xs={12}>
                            <AppSelectList
                                control={control}
                                name={formField.ActivityType.name}
                                label={formField.ActivityType.label}
                                items={activityTypeList}
                                information_text={formField.ActivityType.informationText} />
                        </Grid>

                        <Grid item xs={12}>
                            <AppCheckboxFieldWithLeftAlignedLabel
                                control={control}
                                name={formField.Domains.name}
                                label={formField.Domains.label}
                                options={domains}
                                information_text={formField.Domains.informationText} />
                        </Grid>

                        <Grid item xs={12}>
                            <AppSlider control={control}
                                label={formField.CreditsClaiming.label}
                                name={formField.CreditsClaiming.name}
                                defaultValue={0} step={0.5} min={0} max={40}
                                information_text={formField.CreditsClaiming.informationText} />
                        </Grid>

                        <Grid item xs={12}>
                            <AppTextareaInput control={control}
                                name={formField.Description.name}
                                label={formField.Description.label}
                                information_text_with_textarea={formField.Description.informationText} />
                        </Grid>

                        <Grid item xs={12}>
                            <AppTextareaInput control={control}
                                name={formField.DevelopmentPurpose.name}
                                label={formField.DevelopmentPurpose.label}
                                information_text_with_textarea={formField.DevelopmentPurpose.informationText} />
                        </Grid>

                    </Grid>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <LoadingButton
                        loading={isPageProcessing()}
                        variant="contained"
                        type='submit'
                        sx={{ mt: 3, ml: 1 }}
                    >
                        Submit
                    </LoadingButton>
                </Box>
            </form>
        </Paper >
    </>
}