const DirectDebitFormModel = {
  formField: {
    FirstName: {
      name: 'FirstName',
      label: 'First name*',
      requiredErrorMsg: 'First name is required.'
    },
    Surname: {
      name: 'Surname',
      label: 'Surname*',
      requiredErrorMsg: 'Surname is required.'
    },
    FullName: {
      information_text: 'Please ensure your first name and surname fields above match your bank account name.'
    },
    BIC: {
      name: 'BIC',
      label: 'BIC',
      incorrectBicErrorMsg: 'Please enter valid BIC.'
    },
    IBAN: {
      name: 'IBAN',
      label: 'IBAN*',
      requiredErrorMsg: 'IBAN is required.',
      incorrectIbanErrorMsg: 'Please enter valid IBAN.'
    },
    TypeOfDirectDebit: {
      name: 'TypeOfDirectDebit',
      label: 'Type of direct debit*',
      information_text: 'If you wish to do a once off payment please hit cancel and select card payments.',
      requiredErrorMsg: 'Type of direct debit is required.'
    },
    PaymentFrequency: {
      name: 'PaymentFrequency',
      label: 'Payment frequency*',
      requiredErrorMsg: 'Payment frequency is required.'
    },
    Declaration: {
      name: 'Declaration',
      label: `By checking this box , you authorize (A) RCSI to send instructions to your bank to debit your account 
      and (B) Your bank to debit your account in accordance with the instructions from RCSI.*`,
      requiredErrorMsg: 'Authorization is required.'
    }
  }
};

export default DirectDebitFormModel;
