import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Box, Grid, Typography } from '@mui/material';
import { UseControllerProps, useController, useFormContext } from 'react-hook-form';
import { SwitchButtonItem } from '../../common/switchButtonItem';
import { Fragment, useEffect } from 'react';
import AppTextareaInput from '../../../components/AppTextareaInput';

interface Props extends UseControllerProps {
    options: SwitchButtonItem[];
    label?: string;
}

export default function AppSwitchField(props: Props) {
    const { fieldState, field } = useController({ ...props, defaultValue: [] });
    const { options, control } = props;

    const methods = useFormContext();
    const { reset } = methods;

    useEffect(() => {

        const comments: { [key: string]: string } = {};

        options.map((item) => {
            field.value.push({ id: item.id, checked: item.checked });
            comments["Comments-" + item.id] = item.comment;
        });

        reset(comments);

    }, [options]);

    return (
        <Box sx={{ width: '100%' }}>
            <FormControl fullWidth error={!!fieldState.error}>
                <FormGroup>

                    <Grid container rowSpacing={4}>

                        {options.map((item: SwitchButtonItem) => (
                            <Fragment key={item.id}>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={item.checked} name={item.id}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    field.value.find((x: SwitchButtonItem) => x.id === item.id).checked = e.target.checked;
                                                    field.onChange(field.value);
                                                    item.checked = e.target.checked
                                                }} sx={{ marginLeft: '1px' }}
                                                color='success' />
                                        }
                                        label={item.value}
                                    />
                                </Grid>

                                {item.checked === true && item.canHaveComment === true &&
                                    <Grid item xs={12}>
                                        <AppTextareaInput
                                            control={control}
                                            name={`Comments-${item.id}`}
                                            label="Comments"
                                        />
                                    </Grid>
                                }
                            </Fragment>
                        ))}
                    </Grid >

                </FormGroup>
            </FormControl>
        </Box>
    );
}
