import MembershipFormModel from "../../../app/models/membership/membershipFormModel";

const DonationFormModel = {
  formField: {
    Title: {
      ...MembershipFormModel.formField.Title,
      label: 'Title*',
      requiredErrorMsg: 'Title is required.'
    },
    FirstName: {
      ...MembershipFormModel.formField.FirstName,
      label: 'First name',
      requiredErrorMsg: undefined,
      information_text: undefined
    },
    MiddleName: {
      ...MembershipFormModel.formField.MiddleName,
      label: 'Middle name'
    },
    Surname: {
      ...MembershipFormModel.formField.Surname,
      label: 'Surname',
      requiredErrorMsg: undefined
    },
    PreferredPhoneNumber: {
      ...MembershipFormModel.formField.PreferredPhoneNumber
    },
    Fund: {
      name: 'Fund',
      label: 'I would like my gift to be designated towards*',
      requiredErrorMsg: 'Please choose your gift designated towards.'
    },
    GiftAmount: {
      name: 'GiftAmount',
      label: 'Gift amount (##CURRENCY_PLACE_HOLDER##)*',
      requiredErrorMsg: 'Gift amount is required.',
      typeErrorMsg: 'Gift amount should be numeric.',
      minValueErrorMsg: 'Minimum gift amount is ##CURRENCY_ICON_PLACE_HOLDER##10.',
      maxValueErrorMsg: 'Maximum gift amount is ##CURRENCY_ICON_PLACE_HOLDER##50,000.'
    },
    WillingToLeaveLegacy: {
      name: 'WillingToLeaveLegacy',
      label: 'I am interested in leaving a legacy to RCSI. Please send me further information'
    }
  }
};

export default DonationFormModel;
