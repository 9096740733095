import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { registrationDetailsSlice } from "../slices/registrationDetailsSlice";
import { summarySlice } from "../../features/pcsSummary/summarySlice";
import { viewCpdSlice } from "../../features/pcsViewCpdRecords/viewCpdSlice";
import { referenceDataSlice } from "../slices/referenceDataSlice";
import { personalDetailsSlice } from "../slices/personalDetailsSlice";
import { documentSlice } from "../../features/myDocuments/documentSlice";
import { planSlice } from "../../features/pcsPlan/view/planDetails/planSlice";
import { pcsPlanDashboardSlice } from "../../features/pcsPlan/view/dashboard/pcsPlanDashboardSlice";
import { alumniSlice } from "../slices/alumniSlice";
import { affiliateOnboardingSlice } from "../slices/affiliateOnboardingSlice";
import { famTransferSlice } from "../slices/famTransferSlice";
import { famOnboardingSlice } from "../slices/famOnboardingSlice";
import educationalDetailsSlice from "../slices/educationalDetailsSlice";
import { volunteeringSlice } from "../slices/volunteeringSlice";
import { famSlice } from "../slices/famSlice";
import { paymentSlice } from "../slices/paymentSlice";
import { famEnrolmentSlice } from "../slices/famEnrolmentSlice";
import { donationSlice } from "../slices/donationSlice";
import { requestSlice } from "../slices/requestSlice";
import { workDetailsSlice } from "../slices/workDetailsSlice";
import { directDebitSlice } from "../slices/directDebitMandateSlice";

export const store = configureStore({
    reducer: {
        ReferenceData: referenceDataSlice.reducer,
        affiliateOnboardingDetails: affiliateOnboardingSlice.reducer,
        summary: summarySlice.reducer,
        cpd: viewCpdSlice.reducer,

        register: registrationDetailsSlice.reducer,
        personalDetail: personalDetailsSlice.reducer,
        educationalDetails: educationalDetailsSlice.reducer,
        workDetail: workDetailsSlice.reducer,

        plan: planSlice.reducer,
        pcsPlanDashboard: pcsPlanDashboardSlice.reducer,

        alumniDetails: alumniSlice.reducer,
        famDetails: famSlice.reducer,

        document: documentSlice.reducer,

        famTransfer: famTransferSlice.reducer,
        famOnboarding: famOnboardingSlice.reducer,

        volunteeringInterests: volunteeringSlice.reducer,
        payment: paymentSlice.reducer,
        famEnrolment: famEnrolmentSlice.reducer,
        donations: donationSlice.reducer,
        requests: requestSlice.reducer,
        directDebit: directDebitSlice.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    })
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;