import { PaymentDto } from "../../../app/models/payment/paymentDto";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Grid } from "@mui/material";
import PdfDocument from "./PdfDocument";
import { PersonalDetailsDto } from "../../../app/models/person/personalDetailsDto";
import { Country } from "../../../app/models/staticData/country";
import { Title } from "../../../app/models/staticData/title";
import { formatDateWithMonthName } from "../../../app/utils/util";
import { PaymentMethod } from "../../../app/models/staticData/paymentMethod";
import React from "react";
import { Fund } from "../../../app/models/staticData/fund";
import { Currency } from "../../../app/models/staticData/currency";
import { CurrencyCodeEnum } from "../../../app/enums/currencyCodeEnum";

interface Props {
    titles: Title[] | null;
    countries: Country[] | null;
    funds: Fund[] | null;
    currencies: Currency[] | null;
    paymentsByPerson: PaymentDto[] | null;
    personalDetails: PersonalDetailsDto | null;
    paymentMethods: PaymentMethod[] | null;
}

export default function MyReceipts(props: Props) {
    const { titles, countries, funds, currencies, paymentsByPerson, personalDetails, paymentMethods } = props;

    const payments = paymentsByPerson?.filter(x => x.currencyId !== currencies?.find(y => y.code === CurrencyCodeEnum.USD)?.id)
        .sort(function (a, b) {
            return (b.paymentDate !== null ? new Date(b.paymentDate).getTime() : 0) - (a.paymentDate !== null ? new Date(a.paymentDate).getTime() : 0);
        });

    return (
        <>
            <Grid container rowSpacing={4}>

                <Grid item xs={12}></Grid>

                <Grid item xs={12}></Grid>

                <Grid item xs={4} sx={{ textAlign: 'center' }}><b>File to download</b></Grid>

                <Grid item xs={4} sx={{ textAlign: 'center' }}><b>Payment date</b></Grid>

                <Grid item xs={4} sx={{ textAlign: 'center' }}><b>Reference</b></Grid>

                {payments?.map((paymentDetails) => (
                    <React.Fragment key={paymentDetails.id}>
                        <Grid item xs={4} sx={{ textAlign: 'center', wordWrap: 'break-word' }}>
                            <PDFDownloadLink document={<PdfDocument titles={titles} countries={countries} funds={funds}
                                currencies={currencies} personalDetails={personalDetails} paymentDetails={paymentDetails}
                                paymentMethods={paymentMethods} />}
                                fileName="My receipt.pdf">
                                {({ loading }) =>
                                    loading === true ? 'Loading document...' :
                                        <img src={`${String(process.env.REACT_APP_STORAGE_ACCOUNT_URL)}/images/pdf-icon.png`} style={{ width: '20%' }} />}
                            </PDFDownloadLink>
                        </Grid>

                        <Grid item xs={4} sx={{ textAlign: 'center', wordWrap: 'break-word' }}>
                            {paymentDetails.paymentDate !== null ? formatDateWithMonthName(new Date(paymentDetails.paymentDate)) : ''}
                        </Grid>

                        <Grid item xs={4} sx={{ textAlign: 'center', wordWrap: 'break-word' }}>{paymentDetails.reference}</Grid>
                    </React.Fragment>
                ))}

                {paymentsByPerson?.length === 0 &&
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        No receipts found.
                    </Grid>
                }
            </Grid>

            <Grid item xs={12}></Grid>
        </>
    )
}