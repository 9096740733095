import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { CurrencyCodeIconEnum } from '../../../app/enums/currencyCodeIconEnum';

function createData(
    rowNumber: number,
    itemName: string,
    status: string,
    cost: number
) {
    return { rowNumber, itemName, status, cost };
}

const rows = [
    createData(1, 'Fellows tie or bowtie', 'In Good Standing', 35),
    createData(2, 'Fellows tie or bowtie', 'Not In Good Standing', 75)
];

export default function MerchandisePriceTable() {

    return <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: '#DE1834', color: 'white' }}>
                <TableRow>
                    <TableCell sx={{ color: 'white' }}><b>Item</b></TableCell>
                    <TableCell sx={{ color: 'white' }} align="left"><b>Status</b></TableCell>
                    <TableCell sx={{ color: 'white' }} align="left"><b>Cost</b></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row) => (
                    <TableRow
                        key={row.rowNumber}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            {row.itemName}
                        </TableCell>
                        <TableCell align="left">{row.status}</TableCell>
                        <TableCell align="left">{CurrencyCodeIconEnum.EUR}{row.cost}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
}
