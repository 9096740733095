import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { MESSAGE_PROCESSING_DETAILS } from "../../../app/utils/constant";
import { useAppDispatch, useAppSelector } from "../../../app/store/configureStore";
import { getDirectDebitMandatesAsync } from "../../../app/slices/directDebitMandateSlice";
import { DirectDebitMandate } from "../../../app/models/directDebit/directDebitMandate";
import React from "react";
import { Product } from "../../../app/models/staticData/product";

interface Props {
    products: Product[] | null;
}

export function MyDirectDebits({ products }: Props) {
    const [pageLoading, setPageLoading] = useState(false);

    const dispatch = useAppDispatch();

    const { directDebitMandates } = useAppSelector(state => state.directDebit);

    useEffect(() => {
        if (directDebitMandates === null || directDebitMandates?.length === 0) {
            setPageLoading(true);

            const pageLoadPromise = async () => await Promise.all([
                dispatch(getDirectDebitMandatesAsync())
            ]);

            pageLoadPromise().finally(() => {
                setPageLoading(false);
            });
        }
    }, [directDebitMandates, dispatch]);

    if (pageLoading === true) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return (
        <>
            <Box sx={{ width: '100%' }}>

                <Grid container rowSpacing={4}>

                    <Grid item xs={12}></Grid>

                    <Grid item xs={12}>
                        <Typography variant='h6'>Manage Direct Debits</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant='subtitle1'></Typography>
                    </Grid>

                    {directDebitMandates?.map((item: DirectDebitMandate) => (
                        <React.Fragment key={item.id}>
                            <Grid item xs={4} className="wordWrap">
                                {products?.find(x => x.id === item.productId)?.name}
                            </Grid>

                            <Grid item xs={2} className="wordWrap">
                                {item.id}
                            </Grid>

                            <Grid item xs={4} sm={3} className="wordWrap">
                                Annual
                            </Grid>

                            <Grid item xs={2} sm={3} className="wordWrap">Membership rate</Grid>
                        </React.Fragment>
                    ))}
                </Grid>
            </Box>
        </>
    );
}