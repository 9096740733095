import { Box, Paper, Grid, Typography } from "@mui/material";

export function AlumniVolunteeringHeader() {

    return <>
        <Box sx={{ minWidth: "100%" }} component={Paper}>

            <Grid container rowSpacing={4}>

                <Grid item xs={12} sx={{ marginLeft: '2%', marginTop: '2%' }}>
                    <Typography variant='h4'>Alumni volunteering</Typography>
                </Grid>

                <Grid item xs={12} sx={{ marginLeft: '2%', marginRight: '2%', marginBottom: '2%' }}>
                    <Typography variant='subtitle1'>
                        <>
                            Volunteering is a very rewarding and fulfilling experience. Not only does it provide
                            an opportunity to help others, but it can help volunteers to further develop their own
                            skillsets. Register for the RCSI Alumni Volunteer Programme below by selecting the
                            activities you are willing to participate in. You can update your preferences at any time.
                        </>
                    </Typography>
                </Grid>

            </Grid>
        </Box>
    </>
}
