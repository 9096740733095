import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import agent from "../../../../app/api/agent";
import { ApiRequestStatus } from "../../../../app/enums/apiRequestStatus";
import { PcsEnrolmentYear } from "../../models/pcsEnrolmentYear";

interface pcsPlanDashboardState {
    enrolmentYears: PcsEnrolmentYear[] | null;
    pcsPlanDashboardStatus: ApiRequestStatus;
}

const initialState: pcsPlanDashboardState = {
    enrolmentYears: null,
    pcsPlanDashboardStatus: ApiRequestStatus.Idle
}

export const getEnrolmentYearsAsync = createAsyncThunk<PcsEnrolmentYear[], string>(
    'plan/getEnrolmentYearsAsync',
    async (personId, thunkAPI) => {
        try {
            return agent.PcsPlan.getPcsEnrolmentYears(personId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const pcsPlanDashboardSlice = createSlice({
    name: 'pcsPlanDashboardSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(getEnrolmentYearsAsync.fulfilled, (state, action) => {
            state.enrolmentYears = action.payload.sort((a: PcsEnrolmentYear, b: PcsEnrolmentYear) => {
                return b.year - a.year;
            });
            state.pcsPlanDashboardStatus = ApiRequestStatus.Fulfilled;
        });

        builder.addMatcher(isAnyOf(getEnrolmentYearsAsync.pending), (state) => {
            state.pcsPlanDashboardStatus = ApiRequestStatus.Pending;
        });

        builder.addMatcher(isAnyOf(getEnrolmentYearsAsync.rejected), (state) => {
            state.pcsPlanDashboardStatus = ApiRequestStatus.Rejected;
        });
    })
})
