import { Country } from "../staticData/country";
import { CountrySubdivision } from "../staticData/countrySubdivision";
import { PersonalDetailsDto } from "../person/personalDetailsDto";
import { PublicationPreference } from "../staticData/publicationPreference";
import { FAMDetails } from "./famDetails";
import { useEffect } from "react";
import MembershipFormModel from "./membershipFormModel";
import { useFormContext } from "react-hook-form";
import { Box, Paper, Grid, Typography } from "@mui/material";
import AppSelectList from "../../components/AppSelectList";
import { MapMagazinePreferenceToDropdownItems } from "../../slices/convertStaticDataToDropdownItems";
import MemberLocation from "./MemberLocation";
import { MESSAGE_NOT_A_VALUE } from "../../utils/constant";

interface Props {
    countries: Country[] | null;
    countrySubdivisions: CountrySubdivision[] | null;
    personalDetails: PersonalDetailsDto | null;
    famDetails: FAMDetails | null;
    publicationPreferences: PublicationPreference[] | null;
}

export default function LocationAndPreferencesDetails(props: Props) {
    const methods = useFormContext();
    const { reset, control } = methods;
    const { countries, countrySubdivisions, personalDetails, famDetails, publicationPreferences } = props;

    const {
        formField: {
            LocationCountry,
            CountrySubdivision,
            SurgeonsScopeMagazine,
            TheSurgeonJournal
        }
    } = MembershipFormModel;

    useEffect(() => {
        if (personalDetails && personalDetails !== null
            && countries && countries?.length > 0
            && countrySubdivisions && countrySubdivisions?.length > 0
            && famDetails !== undefined
            && publicationPreferences && publicationPreferences?.length > 0) {
            let countrySubdivision = countrySubdivisions.find(x => x.countryId === personalDetails.locationCountryId);
            reset({
                [LocationCountry.name]: (personalDetails.locationCountryId === null ||
                    personalDetails.locationCountryId === undefined) ? '' : personalDetails.locationCountryId,
                [CountrySubdivision.name]: (countrySubdivision === null ||
                    countrySubdivision === undefined) ? MESSAGE_NOT_A_VALUE : personalDetails.locationCountrySubdivisionId,
                [SurgeonsScopeMagazine.name]: (famDetails?.scopePreferenceId === null ||
                    famDetails?.scopePreferenceId === undefined) ? '' : famDetails?.scopePreferenceId,
                [TheSurgeonJournal.name]: (famDetails?.surgeonJournalPreferenceId === null ||
                    famDetails?.surgeonJournalPreferenceId === undefined) ? '' : famDetails?.surgeonJournalPreferenceId
            })
        }
    }, [personalDetails, famDetails, countries, countrySubdivisions, publicationPreferences, reset]);

    return <>
        <MemberLocation countries={countries} countrySubdivisions={countrySubdivisions} personalDetails={personalDetails} />

        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12} sx={{ marginTop: '-1%' }}>
                    <Typography variant='subtitle1' sx={{ fontWeight: "500" }}>Publication preferences</Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppSelectList
                        control={control}
                        name={SurgeonsScopeMagazine.name}
                        label={SurgeonsScopeMagazine.label}
                        items={MapMagazinePreferenceToDropdownItems(publicationPreferences ?? [])}
                    />
                </Grid>

                <Grid item xs={12}>
                    <AppSelectList
                        control={control}
                        name={TheSurgeonJournal.name}
                        label={TheSurgeonJournal.label}
                        items={MapMagazinePreferenceToDropdownItems(publicationPreferences ?? [])}
                    />
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>
    </>
}