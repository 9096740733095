import { Box, Paper, Grid, Typography, Button } from "@mui/material";
import BackToRequests from "../BackToRequests";
import { FormProvider, useForm } from "react-hook-form";
import RequestFormModel from "../models/requestFormModel";
import AppDeclarationCheckbox from "../models/AppDeclarationCheckbox";
import { useState } from "react";
import { CheckBoxItem } from "../../../app/models/common/checkBoxItem";
import { yupResolver } from "@hookform/resolvers/yup";
import { MerchandiseValidationSchema } from "../models/requestValidationSchemas";
import AppTextareaInput from "../../../app/components/AppTextareaInput";
import { useAppDispatch } from "../../../app/store/configureStore";
import { toast } from "react-toastify";
import { RequestTypesEnum } from "../../../app/enums/requestTypesEnum";
import { CreateRequestTaskCommand } from "../../../app/models/request/createRequestTaskCommand";
import { API_REQUEST_FULLFILLED, MESSAGE_PROCESSING_DETAILS, MESSAGE_SUBMIT } from "../../../app/utils/constant";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { submitRequestAsync } from "../../../app/slices/requestSlice";
import SuccessResponse from "../models/SuccessResponse";
import MerchandisePriceTable from "./MerchandisePriceTable";

export default function Merchandise() {
    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(MerchandiseValidationSchema)
    });
    const { control, trigger, reset, formState: { isDirty } } = methods;
    const formObj = methods.watch();

    const {
        Merchandise: {
            MerchandiseAreTheDetailsCorrect,
            MerchandiseRequestDetails
        }
    } = RequestFormModel;

    const [declarationOption,] = useState<CheckBoxItem>({
        id: MerchandiseAreTheDetailsCorrect.name,
        value: ''
    });

    const dispatch = useAppDispatch();

    const [pageLoading, setPageLoading] = useState(false);
    const [isRequestSubmitted, setIsRequestSubmitted] = useState(false);

    const MapToRequestDetails = () => {
        let requestObj: CreateRequestTaskCommand = {
            requestTypeCode: RequestTypesEnum.Merchandise.toString(),
            details: `${MerchandiseAreTheDetailsCorrect.labelText}: ${formObj.MerchandiseAreTheDetailsCorrect}\r\n${MerchandiseRequestDetails.label}: ${formObj.MerchandiseRequestDetails}`,
            subject: ''
        };

        return requestObj;
    }

    const successMessage = `Thank you for contacting the Fellows and Members Office. 
        A member of the RCSI Fellows and Members Team will contact you directly once your 
        request has been received to take payment and to arrange postage.`;

    const handleSave = async () => {
        const isStepValid = await trigger();

        if (isStepValid) {
            if (isDirty === true) {
                setPageLoading(true);
                dispatch(submitRequestAsync(MapToRequestDetails())).then((requestResponse: any) => {
                    if (requestResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                        reset({
                            [MerchandiseAreTheDetailsCorrect.name]: false,
                            [MerchandiseRequestDetails.name]: ''
                        });
                        setPageLoading(false);
                        setIsRequestSubmitted(true);
                        toast.success(successMessage);
                    }
                });
            }
        }
    };

    if (pageLoading === true) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    if (isRequestSubmitted === true) {
        return <SuccessResponse message={successMessage} />
    }

    return <>
        <FormProvider {...methods}>
            <Box sx={{ minWidth: "100%" }} component={Paper}>
                <Grid container rowSpacing={4} sx={{ ml: '2%', mt: '0%', width: '96%' }}>

                    <Grid item xs={12}>
                        <BackToRequests />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant='h4'>Merchandise</Typography>
                    </Grid>

                    <Grid item xs={12} sx={{ marginRight: '2%', marginBottom: '2%' }}>
                        <Typography variant='subtitle1'>
                            The Fellows tie and bowtie are exclusively available to 
                            surgical Fellows of RCSI (FRCSI) and Honorary Surgical Fellows.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ minWidth: "100%" }} component={Paper}>
                <Grid container rowSpacing={4} sx={{ ml: '2%', mt: '0%', width: '96%' }}>

                    <Grid item xs={12}>
                        <MerchandisePriceTable />
                    </Grid>

                    <Grid item xs={12}></Grid>

                </Grid>
            </Box>

            <Box sx={{ minWidth: "100%" }} component={Paper}>
                <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                    <Grid item xs={12}>
                        <AppDeclarationCheckbox
                            control={control}
                            name={MerchandiseAreTheDetailsCorrect.name}
                            label={MerchandiseAreTheDetailsCorrect.label}
                            option={declarationOption} />
                    </Grid>

                    <Grid item xs={12}>
                        <AppTextareaInput name={MerchandiseRequestDetails.name} label={MerchandiseRequestDetails.label} />
                    </Grid>

                    <Grid item xs={12}></Grid>

                </Grid>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid container rowSpacing={4}>
                    <Grid item xs={12}></Grid>

                    <Grid item xs={8}></Grid>

                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Button variant="contained" color="primary" onClick={handleSave}>
                            {MESSAGE_SUBMIT}
                        </Button>
                    </Grid>

                    <Grid item xs={1}></Grid>
                </Grid>
            </Box>
        </FormProvider>
    </>
}
