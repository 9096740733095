import { Box, Paper, Grid, Typography } from "@mui/material";

export default function Header() {

    return <>
        <Box sx={{ width: '100%' }} component={Paper}>
            
            <Grid container rowSpacing={4}>

                <Grid item xs={12} sx={{ marginLeft: '2%', marginTop: '2%' }}>
                    <Typography variant='h4'>Support us</Typography>
                </Grid>

                <Grid item xs={12} sx={{ marginLeft: '2%', marginRight: '2%', marginBottom: '2%' }}>
                    <Typography variant='subtitle1'>
                        Join us in shaping a healthier future. Every contribution to RCSI translates
                        into real-world impact for students and patients globally.

                        <br></br>
                        <br></br>

                        The RCSI_TOMORROW campaign is our commitment to advancing healthcare solutions
                        for generations to come. Your support today lays the foundation for improved
                        patient outcomes and groundbreaking innovations tomorrow.

                        <br></br>
                        <br></br>

                        To discuss how your support can make a difference, 
                        please contact <a href="mailto:alumnioffice@rcsi.com">alumnioffice@rcsi.com</a>.
                    </Typography>
                </Grid>

            </Grid>
        </Box>
    </>
}
