import { DirectDebitTypeEnum } from "../enums/directDebitTypeEnum";
import { PaymentFrequencyEnum } from "../enums/paymentFrequencyEnum";
import { DropdownItem } from "../models/common/dropdownItem";
import { ActivityType } from "../models/staticData/activityType";
import { AreaOfPractice } from "../models/staticData/areaOfPractice";
import { Country } from "../models/staticData/country";
import { CountrySubdivision } from "../models/staticData/countrySubdivision";
import { CpdCategory } from "../models/staticData/cpdCategory";
import { CpdGoodPracticeDomain } from "../models/staticData/cpdGoodPracticeDomain";
import { CpdStatus } from "../models/staticData/cpdStatus";
import { Discipline } from "../models/staticData/discipline";
import { FamAffiliateCategory } from "../models/staticData/famAffiliateCategory";
import { FamAffiliatePathway } from "../models/staticData/famAffiliatePathway";
import { FamMembershipType } from "../models/staticData/famMembershipType";
import { Fund } from "../models/staticData/fund";
import { Gender } from "../models/staticData/gender";
import { Institution } from "../models/staticData/institution";
import { InstitutionAward } from "../models/staticData/institutionAward";
import { PublicationPreference } from "../models/staticData/publicationPreference";
import { RegistrationType } from "../models/staticData/registrationType";
import { RoyalCollege } from "../models/staticData/royalCollege";
import { Scheme } from "../models/staticData/scheme";
import { Speciality } from "../models/staticData/speciality";
import { Title } from "../models/staticData/title";
import { VolunteeringInterestType } from "../models/staticData/volunteeringInterestType";

export function MapDomainsToCheckboxItems(goodPracticeDomains: CpdGoodPracticeDomain[]) {
    let domainList: DropdownItem[] = [];

    goodPracticeDomains?.map((item: CpdGoodPracticeDomain) => {
        domainList.push({
            id: item.id,
            value: item.value
        });
        return true;
    });

    return domainList;
}

export function MapCategoriesToDropdownItems(categories: CpdCategory[]) {
    let categoryList: DropdownItem[] = [];

    categories?.map((item: CpdCategory) => {
        categoryList.push({
            id: item.id,
            value: item.value
        });
        return true;
    });

    return categoryList;
}

export function MapActivityTypesToDropdownItems(activityTypes: ActivityType[]) {
    let activityTypeList: DropdownItem[] = [];

    activityTypes?.map((item: ActivityType) => {
        activityTypeList.push({
            id: item.id,
            value: item.value
        });
        return true;
    });

    return activityTypeList;
}

export function MapStatusesToDropdownItems(statuses: CpdStatus[]) {
    let statusList: DropdownItem[] = [];

    statuses?.map((item: CpdStatus) => {
        statusList.push({
            id: item.id,
            value: item.value
        });
        return true;
    });

    return statusList;
}

export function MapRegistrationTypesToDropdownItems(registrationTypes: RegistrationType[]) {
    let registrationTypeList: DropdownItem[] = [];

    registrationTypes?.map((item: RegistrationType) => {
        registrationTypeList.push({
            id: item.id,
            value: item.value
        });
        return true;
    });

    return registrationTypeList;
}

export function MapSchemesToDropdownItems(schemes: Scheme[]) {
    let schemeList: DropdownItem[] = [];

    schemes?.map((item: Scheme) => {
        schemeList.push({
            id: item.id,
            value: item.value,
            code: item.code
        });
        return true;
    });

    return schemeList;
}

export function MapSpecialitiesToDropdownItems(specialities: Speciality[]) {
    let specialityList: DropdownItem[] = [];

    specialities?.map((item: Speciality) => {
        specialityList.push({
            id: item.id,
            value: item.value
        });
        return true;
    });

    return specialityList;
}

export function MapTitlesToDropdownItems(titles: Title[]) {
    let titleList: DropdownItem[] = [];

    titles?.map((item: Title) => {
        titleList.push({
            id: item.id,
            value: item.value
        });
        return true;
    });

    return titleList;
}

export function MapGendersToDropdownItems(genders: Gender[]) {
    let genderList: DropdownItem[] = [];

    genders?.map((item: Gender) => {
        genderList.push({
            id: item.id,
            value: item.value
        });
        return true;
    });

    return genderList;
}

export function MapFundsToDropdownItems(funds: Fund[]) {
    let fundList: DropdownItem[] = [];

    funds?.map((item: Fund) => {
        fundList.push({
            id: item.id,
            value: item.value
        });
        return true;
    });

    return fundList;
}

export function MapCountriesToDropdownItems(countries: Country[]) {
    let countryList: DropdownItem[] = [];

    countries?.map((item: Country) => {
        countryList.push({
            id: item.id,
            value: item.value
        });
        return true;
    });

    return countryList;
}

export function MapMembershipTypesToDropdownItems(famMembershipTypes: FamMembershipType[]) {
    let famMembershipTypeList: DropdownItem[] = [];

    famMembershipTypes?.map((item: FamMembershipType) => {
        famMembershipTypeList.push({
            id: item.id,
            value: item.value
        });
        return true;
    });

    return famMembershipTypeList;
}

export function MapMagazinePreferenceToDropdownItems(publicationPreferences: PublicationPreference[]) {
    let publicationPreferenceList: DropdownItem[] = [];

    publicationPreferences?.map((item: PublicationPreference) => {
        publicationPreferenceList.push({
            id: item.id,
            value: item.value
        });
        return true;
    });

    return publicationPreferenceList;
}

export function MapCountrySubdivisionToDropdownItems(countrySubdivisions: CountrySubdivision[]) {
    let countrySubdivisionList: DropdownItem[] = [];

    countrySubdivisions?.map((item: Country) => {
        countrySubdivisionList.push({
            id: item.id,
            value: item.value
        });
        return true;
    });

    return countrySubdivisionList;
}

export function MapInstitutionsToDropdownItems(institutions: Institution[]) {
    let institutionList: DropdownItem[] = [];

    institutions?.map((item: Country) => {
        institutionList.push({
            id: item.id,
            value: item.value
        });
        return true;
    });

    return institutionList;
}

export function MapDisciplinesToDropdownItems(disciplines: Discipline[]) {
    let disciplineList: DropdownItem[] = [];

    disciplines?.map((item: Discipline) => {
        disciplineList.push({
            id: item.id,
            value: item.value
        });
        return true;
    });

    return disciplineList;
}

export function MapVolunteeringInterestTypesToDropdownItems(volunteeringInterestTypes: VolunteeringInterestType[]) {
    let volunteeringInterestTypeList: DropdownItem[] = [];

    volunteeringInterestTypes?.map((item: VolunteeringInterestType) => {
        volunteeringInterestTypeList.push({
            id: item.id,
            value: item.value
        });
        return true;
    });

    return volunteeringInterestTypeList;
}

export function MapAreaOfPracticesToDropdownItems(areaOfPractices: AreaOfPractice[]) {
    let areaOfPracticeList: DropdownItem[] = [];

    areaOfPractices?.map((item: AreaOfPractice) => {
        areaOfPracticeList.push({
            id: item.id,
            value: item.value
        });
        return true;
    });

    return areaOfPracticeList;
}

export function MapInstitutionAwardsToDropdownItems(institutionAwards: InstitutionAward[]) {
    let institutionAwardList: DropdownItem[] = [];

    institutionAwards?.map((item: InstitutionAward) => {
        institutionAwardList.push({
            id: item.id,
            value: item.value
        });
        return true;
    });

    return institutionAwardList;
}

export function MapFamAffiliateCategoriesToDropdownItems(famAffiliateCategories: FamAffiliateCategory[]) {
    let famAffiliateCategoryList: DropdownItem[] = [];

    famAffiliateCategories?.map((item: FamAffiliateCategory) => {
        famAffiliateCategoryList.push({
            id: item.id,
            value: item.value
        });
        return true;
    });

    return famAffiliateCategoryList;
}

export function MapFamAffiliatePathwaysToDropdownItems(famAffiliatePathways: FamAffiliatePathway[]) {
    let famAffiliatePathwayList: DropdownItem[] = [];

    famAffiliatePathways?.map((item: FamAffiliatePathway) => {
        famAffiliatePathwayList.push({
            id: item.id,
            value: item.value
        });
        return true;
    });

    return famAffiliatePathwayList;
}

export function MapRoyalCollegesToDropdownItems(royalColleges: RoyalCollege[]) {
    let royalCollegeList: DropdownItem[] = [];

    royalColleges?.map((item: RoyalCollege) => {
        royalCollegeList.push({
            id: item.id,
            value: item.value
        });
        return true;
    });

    return royalCollegeList;
}

export function MapTypeOfDirectDebitsToDropdownItems() {
    const typeOfDirectDebits: DropdownItem[] = [
        {
            id: DirectDebitTypeEnum.RECURRENT,
            value: DirectDebitTypeEnum.RECURRENT
        },
        {
            id: DirectDebitTypeEnum.ONCE_OFF,
            value: DirectDebitTypeEnum.ONCE_OFF
        }
    ];

    return typeOfDirectDebits;
}

export function MapPaymentFrequencyToDropdownItems() {
    const paymentFrequencies: DropdownItem[] = [
        {
            id: PaymentFrequencyEnum.Monthly,
            value: PaymentFrequencyEnum.Monthly
        },
        {
            id: PaymentFrequencyEnum.Every3Months,
            value: PaymentFrequencyEnum.Every3Months
        },
        {
            id: PaymentFrequencyEnum.Yearly,
            value: PaymentFrequencyEnum.Yearly
        }
    ];

    return paymentFrequencies;
}
