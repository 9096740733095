export enum UserRole {
    ALUMNI = "ALUMNI",
    ALUMNI_BAH = "ALUMNI_BAH",
    FAM_FELLOW = "FAM_FELLOW",
    FAM_MEMBER = "FAM_MEMBER",
    FAM_ASSOCIATE = "FAM_ASSOCIATE",
    FAM_AFFILIATE = "FAM_AFFILIATE",
    PCS_SURGERY = "PCS_SURGERY",
    PCS_OPHTH = "PCS_OPHTH",
    PCS_EM = "PCS_EM",
    PCS_RADIOLOGY = "PCS_RADIOLOGY",
    PCS_SEM = "PCS_SEM"
}