import { Box, Button, Grid, Paper, Step, StepLabel, Stepper } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DeclarationAndPetition from './DeclarationAndPetition';
import { useAppDispatch, useAppSelector } from '../../app/store/configureStore';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { ApiRequestStatus } from '../../app/enums/apiRequestStatus';
import { getPersonalDetailsAsync, updatePersonalDetailsStateAsync } from '../../app/slices/personalDetailsSlice';
import { getReferenceDataAsync } from '../../app/slices/referenceDataSlice';
import {
  API_REQUEST_FULLFILLED, MESSAGE_DETAILS_SAVED, MESSAGE_SAVE_AND_CONTINUE,
  MESSAGE_NEXT, MESSAGE_SUBMIT, MESSAGE_BACK_TO_PREVIOUS_STEP, MESSAGE_NOT_A_VALUE, NO_FURTHER_ACTION_REQUIRED, MESSAGE_PROCESSING_DETAILS, SKIP_TO_UPLOAD
} from '../../app/utils/constant';
import { toast } from 'react-toastify';
import { PersonalDetailsDto } from '../../app/models/person/personalDetailsDto';
import { AddSupportingDocument } from './AddSupportingDocuments';
import MembershipTransferHeader from './MembershipTransferHeader';
import { membershipTransferValidationSchemas } from './common/membershipTransferValidationSchemas';
import TransferInformation from './TransferInformation';
import {
  getFamTransferDocumentsAsync, getFamTransferDetailsAsync, saveFamTransferDetailsAsync,
  submitFamTransferDetailsAsync, updateFamTransferDetailsAsync, basketAsync
} from '../../app/slices/famTransferSlice';
import { FamOnboardingStatus } from '../../app/models/staticData/famOnboardingStatus';
import { MemberTransferStatusCodeEnum } from '../../app/enums/memberTransferStatusCodeEnum';
import { MemberTransferStepEnum } from './common/memberTransferStepsEnum';
import { FAMOnboarding } from '../../app/models/membership/famOnboarding';
import MemberPostalAddress from '../../app/models/membership/MemberPostalAddress';
import MemberPersonalDetails from '../../app/models/membership/MemberPersonalDetails';
import { FAMDetails, LocationPreferences } from '../../app/models/membership/famDetails';
import { getFAMDetails, updateFAMDetails, addFAMDetails, getCurrentMembershipType } from '../../app/slices/famSlice';
import LocationAndPreferencesDetails from '../../app/models/membership/LocationAndPreferencesDetails';
import { getFamOnboardingDetailsAsync } from '../../app/slices/famOnboardingSlice';
import { getPaymentIdFromBasketAsync, getPaymentRedirectAsync, getPaymentSummaryAsync } from '../../app/slices/paymentSlice';
import MembershipPaymentSummary from '../../app/models/membership/MembershipPaymentSummary';
import { PaymentRedirectProps } from '../../app/models/membership/paymentRedirectProps';
import { useMsal } from '@azure/msal-react';
import { UserClaims } from '../../app/enums/userClaims';
import Unauthorized from '../../app/errors/Unauthorized';
import { MemberType } from '../../app/enums/memberType';
import { MemberTransferDocumentTypeEnum } from './common/memberTransferDocumentTypeEnum';
import { SpaName } from '../../app/enums/spaName';
import { MembershipTypeEnum } from '../../app/enums/MembershipTypeEnum';
import { IdTokenClaims } from '../../app/models/account/idTokenClaims';

export default function MembershipTransferForm() {

  const { instance } = useMsal();
  const idTokenClaimsObject = (instance.getActiveAccount()?.idTokenClaims as unknown as IdTokenClaims);

  const steps = [MemberTransferStepEnum.PersonalDetails, MemberTransferStepEnum.PostalAddress, MemberTransferStepEnum.Location,
  MemberTransferStepEnum.TransferInformation, MemberTransferStepEnum.SupportingDocuments, MemberTransferStepEnum.DeclarationAndPetition];

  function getStepContent(step: number) {
    if (pageLoading === false) {
      switch (step) {
        case 0:
          return <MemberPersonalDetails titles={titles} genders={genders} countries={countries} disciplines={disciplines}
            areasOfPractice={areasOfPractice} specialities={specialities} personalDetails={personalDetails} />
        case 1:
          return <MemberPostalAddress countries={countries} personalDetails={personalDetails} />
        case 2:
          return <LocationAndPreferencesDetails countries={countries} countrySubdivisions={countrySubdivisions} publicationPreferences={publicationPreferences}
            personalDetails={personalDetails} famDetails={famDetails} />
        case 3:
          return <TransferInformation royalColleges={royalColleges} famTransferDetails={famTransferDetails} />
        case 4:
          return <AddSupportingDocument documents={famTransferDocuments} famTransferDetails={famTransferDetails}
            unSavedCertificateChange={isUnsavedCertificateChange}
            certificateChange={() => { setIsUnsavedCertificateChange(false) }}
            unSavedExamChange={isUnsavedExamChange}
            examChange={() => { setIsUnsavedExamChange(false) }}
            unSavedCvChange={isUnsavedCvChange}
            cvChange={() => { setIsUnsavedCvChange(false) }}
            documentSubmittedCallback={documentSubmittedCallback} />
        case 5:
          return <DeclarationAndPetition />
        default:
          throw new Error('Unknown step');
      }
    }
  }

  const [activeStep, setActiveStep] = useState(0);
  const [isUnsavedCertificateChange, setIsUnsavedCertificateChange] = useState(false);
  const [isUnsavedExamChange, setIsUnsavedExamChange] = useState(false);
  const [isUnsavedCvChange, setIsUnsavedCvChange] = useState(false);

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(membershipTransferValidationSchemas[activeStep])
  });

  const { trigger, formState: { isDirty } } = methods;
  const formObj = methods.watch();

  const { accounts } = useMsal();

  useEffect(() => {
    if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
      setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
    }
  }, [accounts]);

  const [personId, setPersonId] = useState('');

  const dispatch = useAppDispatch();

  const { titles, genders, countries, countrySubdivisions, specialities, disciplines,
    areasOfPractice, educationInstitutions, publicationPreferences, royalColleges, famMembershipTypes,
    famOnboardingStatuses, staticDataStateStatus } = useAppSelector(state => state.ReferenceData);
  const { personalDetails, updatePersonalDetailsStatus, getPersonalDetailsStatus } = useAppSelector(state => state.personalDetail);
  const { famTransferDetails, famTransferDocuments, saveFamTransferDetailsStatus, submitFamTransferDetailsStatus,
    updateFamTransferDetailsStatus, basketId, basketIdStatus, getFamTransferDetailsStatus, getFamTransferDocumentsStatus
  } = useAppSelector(state => state.famTransfer);
  const { famDetails, currentMembershipType, addFamDetailsStatus, updateFamDetailsStatus,
    currentMembershipTypeStatus, getFamDetailsStatus } = useAppSelector(state => state.famDetails);
  const { famOnboardingDetails, getFamOnboardingDetailsStatus } = useAppSelector(state => state.famOnboarding);
  const { getPaymentRedirectStatus, paymentDetails, getPaymentByPaymentIdStatus, paymentId, paymentIdStatus }
    = useAppSelector(state => state.payment);

  const [pageLoading, setPageLoading] = useState<boolean | undefined>(undefined);
  const [famOnboardingStatus, setFamOnboardingStatus] = useState<FamOnboardingStatus>();
  let supportingDocumentStepIndex = Object.values(MemberTransferStepEnum).indexOf(MemberTransferStepEnum.SupportingDocuments);

  useEffect(() => {
    if ((famOnboardingStatuses !== null && famOnboardingStatuses?.length > 0)
      && famTransferDetails?.famOnboardingStatusId && famTransferDetails?.famOnboardingStatusId !== null) {
      let currentStatus = famOnboardingStatuses?.find(x => x.id === famTransferDetails?.famOnboardingStatusId);
      setFamOnboardingStatus(currentStatus);

      if (currentStatus?.code === MemberTransferStatusCodeEnum.RequiredFurtherDetails) {
        setActiveStep(supportingDocumentStepIndex);
      }
    }
  }, [famTransferDetails, famOnboardingStatuses, famOnboardingStatus, supportingDocumentStepIndex]);

  useEffect(() => {
    if (personId !== "" && ((titles === null || titles?.length === 0) &&
      (genders === null || genders?.length === 0) &&
      (countries === null || countries?.length === 0) &&
      (countrySubdivisions === null || countrySubdivisions?.length === 0) &&
      (specialities === null || specialities?.length === 0) &&
      (disciplines === null || disciplines?.length === 0) &&
      (areasOfPractice === null || areasOfPractice?.length === 0) &&
      (publicationPreferences === null || publicationPreferences?.length === 0) &&
      (educationInstitutions === null || educationInstitutions?.length === 0) &&
      (royalColleges === null || royalColleges?.length === 0) &&
      (famOnboardingStatuses === null || famOnboardingStatuses?.length === 0) &&
      staticDataStateStatus !== ApiRequestStatus.Pending)) {

      setPageLoading(true);

      dispatch(getReferenceDataAsync()).then(() => {
        setPageLoading(false);
      })
    }
  }, [personId, titles, genders, countries, countrySubdivisions, specialities,
    disciplines, areasOfPractice, publicationPreferences, educationInstitutions,
    royalColleges, famOnboardingStatuses, staticDataStateStatus, dispatch]);

  useEffect(() => {
    if (personId !== "" && (famTransferDetails === null || famTransferDetails === undefined)) {

      setPageLoading(true);

      dispatch(getFamTransferDetailsAsync()).then(() => {
        setPageLoading(false);
      })
    }
  }, [personId, famTransferDetails, dispatch]);

  useEffect(() => {
    if (personId !== "" && famTransferDetails !== null &&
      (famOnboardingStatuses !== null && famOnboardingStatuses.length > 0) &&
      (famTransferDocuments === null || famTransferDocuments === undefined) &&
      (famOnboardingStatuses.find(x => x.id === famTransferDetails.famOnboardingStatusId)?.code === MemberTransferStatusCodeEnum.Pending
        || famOnboardingStatuses.find(x => x.id === famTransferDetails.famOnboardingStatusId)?.code === MemberTransferStatusCodeEnum.RequiredFurtherDetails)) {

      setPageLoading(true);

      dispatch(getFamTransferDocumentsAsync()).then(() => {
        setPageLoading(false);
      })
    }
  }, [personId, famTransferDetails, famOnboardingStatuses, famTransferDocuments, dispatch]);

  useEffect(() => {
    if (personId !== "" && famOnboardingDetails === null &&
      (personalDetails === null || personalDetails === undefined) &&
      (famDetails === null || famDetails === undefined)) {

      setPageLoading(true);

      const pageLoadPromise = async () => await Promise.all([
        dispatch(getPersonalDetailsAsync(personId)).then((personalDetailsResponse: any) => {
          if (personalDetailsResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
            dispatch(getFAMDetails(personId)).then((famDetailsresponse) => {
              if (famDetailsresponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                dispatch(getFamOnboardingDetailsAsync());
              }
            })
          }
        })
      ]);

      pageLoadPromise().finally(() => {
        setPageLoading(false);
      });
    }
  }, [personId, personalDetails, famDetails, famOnboardingDetails, dispatch]);

  useEffect(() => {
    if (personId !== "" && (currentMembershipType === null || currentMembershipType === undefined)) {

      setPageLoading(true);

      dispatch(getCurrentMembershipType()).then(() => {
        setPageLoading(false);
      });
    }
  }, [personId, currentMembershipType, dispatch]);

  function MapToPersonalDetails() {
    const data: PersonalDetailsDto = {
      ...personalDetails, id: personId,
      titleId: formObj.Title,
      middleName: personalDetails?.middleName === formObj.MiddleName ? null : formObj.MiddleName,
      prefferedName: formObj.PreferredName,
      genderId: formObj.Gender,
      nationalityId: formObj.CountryOfNationality,
      dateOfBirth: formObj.DateOfBirth,
      mobile: formObj.PreferredPhoneNumber,
      disciplineId: formObj.Discipline,
      areaOfPracticeId: formObj.AreaOfPracticeMoreDetails === MESSAGE_NOT_A_VALUE ? '' : formObj.AreaOfPracticeMoreDetails
    }
    return data;
  }

  function MapToPostalAddressDetails() {
    const data: PersonalDetailsDto = {
      ...personalDetails, id: personId,
      postalAddress: formObj.PostalAddressLines,
      postalAddressCountryId: formObj.PostalAddressCountry
    }
    return data;
  }

  function MapToLocationDetails() {
    const data: PersonalDetailsDto = {
      ...personalDetails, id: personId,
      locationCountryId: formObj.LocationCountry,
      locationCountrySubdivisionId: formObj.CountrySubdivision === MESSAGE_NOT_A_VALUE ? '' : formObj.CountrySubdivision
    }
    return data;
  }

  function MapToFAMOnboarding() {
    const data: FAMOnboarding = {
      famExamId: famTransferDetails?.famExamId ? famTransferDetails?.famExamId : null,
      famOnboardingStatusId: famTransferDetails?.famOnboardingStatusId ? famTransferDetails?.famOnboardingStatusId : '',
      famEntryMethodId: famTransferDetails?.famEntryMethodId ? famTransferDetails?.famEntryMethodId : '',
      famMembershipTypeId: famTransferDetails?.famMembershipTypeId ? famTransferDetails?.famMembershipTypeId : '',
      membershipAwardId: famTransferDetails?.membershipAwardId ? famTransferDetails?.membershipAwardId : '',
      ratificationDate: famTransferDetails?.ratificationDate ? famTransferDetails?.ratificationDate : null,
      declarationDate: famTransferDetails?.declarationDate ? famTransferDetails?.declarationDate : null,
      electionPetitionDate: famTransferDetails?.electionPetitionDate ? famTransferDetails?.electionPetitionDate : null,
      personId: personId,
      royalCollegeId: formObj.WhichRoyalCollegeAreYouTransferringFrom,
      nameOnCert: formObj.NameOnCertificate
    }
    return data;
  }

  const MapToFamDetails = () => {
    let famDetailsObj = {
      ...famDetails,
      scopePreferenceId: formObj.SurgeonsScopeMagazine,
      surgeonJournalPreferenceId: formObj.TheSurgeonJournal,
      personId: personId
    };

    return famDetailsObj;
  }

  const savePublicationPreferences = (locationPreferencesDto: LocationPreferences) => {
    if (famDetails?.famDetailsId && famDetails?.famDetailsId !== null) {
      dispatch(updateFAMDetails(locationPreferencesDto))
        .then((updateFAMDetailsResponse: any) => {
          if (updateFAMDetailsResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
            dispatch(getFAMDetails(personId)).then((getFamDetailsResponse: any) => {
              if (getFamDetailsResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                dispatch(updatePersonalDetailsStateAsync(MapToLocationDetails())).then((personalDetailsResponse: any) => {
                  if (personalDetailsResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                    dispatch(getPersonalDetailsAsync(personId)).then(() => {
                      setActiveStep(prevActiveStep => prevActiveStep + 1);
                      toast.success(MESSAGE_DETAILS_SAVED);
                    });
                  }
                });
              }
            });
          }
        });
    }
    else if (!famDetails?.famDetailsId || famDetails?.famDetailsId === null) {
      let famDetailsObj: FAMDetails = locationPreferencesDto;
      famDetailsObj.membershipTypeId = famMembershipTypes?.find(x => x.code === MemberType.MEMBER)?.id;

      dispatch(addFAMDetails(locationPreferencesDto))
        .then((addFAMDetailsResponse: any) => {
          if (addFAMDetailsResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
            dispatch(getFAMDetails(personId)).then((getFamDetailsResponse: any) => {
              if (getFamDetailsResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                dispatch(updatePersonalDetailsStateAsync(MapToLocationDetails())).then((personalDetailsResponse: any) => {
                  if (personalDetailsResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                    dispatch(getPersonalDetailsAsync(personId)).then(() => {
                      setActiveStep(prevActiveStep => prevActiveStep + 1);
                      toast.success(MESSAGE_DETAILS_SAVED);
                    });
                  }
                });
              }
            });
          }
        });
    }
  }

  const savePersonalDetails = (personalDetailsDto: PersonalDetailsDto) => {
    dispatch(updatePersonalDetailsStateAsync(personalDetailsDto)).then((response: any) => {
      if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
        dispatch(getPersonalDetailsAsync(personId)).then(() => {
          setActiveStep(prevActiveStep => prevActiveStep + 1);
          toast.success(MESSAGE_DETAILS_SAVED);
        });
      }
    });
  }

  const updateTransferInformation = () => {
    dispatch(updateFamTransferDetailsAsync(MapToFAMOnboarding())).then((response: any) => {
      if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
        dispatch(getFamTransferDetailsAsync()).then(() => {
          setActiveStep(prevActiveStep => prevActiveStep + 1);
          toast.success(MESSAGE_DETAILS_SAVED);
        });
      }
    });
  }

  const savefamTransferDetails = () => {
    if (famTransferDetails?.famOnboardingStatusId &&
      famTransferDetails?.famOnboardingStatusId !== null &&
      famTransferDetails?.famOnboardingStatusId !== '') {
      updateTransferInformation();
    }
    else {
      dispatch(saveFamTransferDetailsAsync(MapToFAMOnboarding())).then((response: any) => {
        if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
          dispatch(getFamTransferDetailsAsync()).then(() => {
            setActiveStep(prevActiveStep => prevActiveStep + 1);
            toast.success(MESSAGE_DETAILS_SAVED);
          });
        };
      })
    }
  }

  const submitMemberTransferDetails = () => {
    let famExamId = famTransferDetails?.famExamId ? famTransferDetails?.famExamId : '';
    dispatch(submitFamTransferDetailsAsync(famExamId)).then((response: any) => {
      if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
        dispatch(getFamTransferDetailsAsync()).then(() => {
          setActiveStep(prevActiveStep => prevActiveStep + 1);
          toast.success(MESSAGE_DETAILS_SAVED);
        });
      };
    })
  }

  const documentSubmittedCallback = () => {
    dispatch(getFamTransferDocumentsAsync());
  }

  const handleNext = async () => {
    const certificateDocument = famTransferDocuments?.find(x => x.tags?.FILE_TYPE === MemberTransferDocumentTypeEnum.CERTIFICATE);
    if (certificateDocument && formObj.Certificate && formObj.Certificate !== SKIP_TO_UPLOAD && formObj.Certificate.uri === undefined) {
      setIsUnsavedCertificateChange(true);
      return;
    } else {
      setIsUnsavedCertificateChange(false);
    }

    const examDocument = famTransferDocuments?.find(x => x.tags?.FILE_TYPE === MemberTransferDocumentTypeEnum.EXAM);
    if (examDocument && formObj.Exam && formObj.Exam !== SKIP_TO_UPLOAD && formObj.Exam.uri === undefined) {
      setIsUnsavedExamChange(true);
      return;
    } else {
      setIsUnsavedExamChange(false);
    }

    const curriculumVitaeDocument = famTransferDocuments?.find(x => x.tags?.FILE_TYPE === MemberTransferDocumentTypeEnum.CV);
    if (curriculumVitaeDocument && formObj.CV && formObj.CV !== SKIP_TO_UPLOAD && formObj.CV.uri === undefined) {
      setIsUnsavedCvChange(true);
      return;
    } else {
      setIsUnsavedCvChange(false);
    }

    const isStepValid = await trigger();

    if (isStepValid) {
      if (isDirty === true) {
        switch (activeStep) {
          case 0:
            savePersonalDetails(MapToPersonalDetails());
            break;
          case 1:
            savePersonalDetails(MapToPostalAddressDetails());
            break;
          case 2:
            savePublicationPreferences(MapToFamDetails());
            break;
          case 3:
            savefamTransferDetails();
            break;
          case 4:
            setActiveStep(prevActiveStep => prevActiveStep + 1);
            break;
          case 5:
            submitMemberTransferDetails();
            break;
          default:
            return "not a valid step";
        }
      }
      else if (isDirty === false) {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      }
    }
  }

  useEffect(() => {
    if (!paymentDetails && paymentId !== '' && paymentId !== null) {
      paymentId && dispatch(getPaymentSummaryAsync(paymentId));
    }
  }, [paymentDetails, paymentId, dispatch]);

  useEffect(() => {
    if (famTransferDetails !== null && famTransferDetails?.famOnboardingStatusId &&
      famTransferDetails?.famOnboardingStatusId !== null &&
      famTransferDetails?.famOnboardingStatusId !== '' &&
      famOnboardingStatus && famOnboardingStatus?.code === MemberTransferStatusCodeEnum.Approved &&
      famDetails && countries && famMembershipTypes &&
      (basketId === '' || basketId === null)) {
      dispatch(basketAsync());
    }
  }, [famTransferDetails, famOnboardingStatus, famDetails, paymentDetails, basketId,
    countries, famMembershipTypes, dispatch]);

  useEffect(() => {
    if (basketId !== '' && basketId !== null) {
      dispatch(getPaymentIdFromBasketAsync(basketId));
    }
  }, [basketId, dispatch]);

  const paymentOnClickHandler = () => {
    if (paymentId && paymentId !== null) {
      setPageLoading(true);
      let paymentRedirectProps: PaymentRedirectProps = {
        cancelUrl: `${String(process.env.REACT_APP_REDIRECT_URI)}${String(process.env.REACT_APP_MEMBERSHIP_TRANSFER_URI)}/${window.location.search}&cancelPayment=true`,
        paymentId: paymentId,
        successUrl: `${String(process.env.REACT_APP_REDIRECT_URI)}${String(process.env.REACT_APP_PAYMENT_SUCCESS_URI)}/${window.location.search}&retry-url=${String(process.env.REACT_APP_MEMBERSHIP_TRANSFER_URI)}`
      };
      dispatch(getPaymentRedirectAsync(paymentRedirectProps)).then((paymentRedirectResponse: any) => {
        window.location.replace(paymentRedirectResponse.payload);
      });
    }
  }

  async function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  if (famOnboardingDetails !== null && famOnboardingDetails.length > 0 &&
    (currentMembershipType?.code === MembershipTypeEnum.MEMBER || currentMembershipType?.code === MembershipTypeEnum.FELLOW ||
      idTokenClaimsObject.extension_portalcrm_isFellowOnboarding === true ||
      idTokenClaimsObject.extension_portalcrm_isMemberOnboarding === true)) {
    toast.error(NO_FURTHER_ACTION_REQUIRED);
    return <Unauthorized message={NO_FURTHER_ACTION_REQUIRED} />
  }

  if (pageLoading === true || staticDataStateStatus === ApiRequestStatus.Pending
    || updatePersonalDetailsStatus === ApiRequestStatus.Pending
    || updateFamTransferDetailsStatus === ApiRequestStatus.Pending
    || updateFamDetailsStatus === ApiRequestStatus.Pending
    || getPaymentRedirectStatus === ApiRequestStatus.Pending
    || getPaymentByPaymentIdStatus === ApiRequestStatus.Pending
    || basketIdStatus === ApiRequestStatus.Pending
    || paymentIdStatus === ApiRequestStatus.Pending
    || saveFamTransferDetailsStatus === ApiRequestStatus.Pending
    || addFamDetailsStatus === ApiRequestStatus.Pending
    || submitFamTransferDetailsStatus === ApiRequestStatus.Pending
    || currentMembershipTypeStatus === ApiRequestStatus.Pending
    || getFamTransferDetailsStatus === ApiRequestStatus.Pending
    || getFamTransferDocumentsStatus === ApiRequestStatus.Pending
    || getFamDetailsStatus === ApiRequestStatus.Pending
    || getPersonalDetailsStatus === ApiRequestStatus.Pending
    || getFamOnboardingDetailsStatus === ApiRequestStatus.Pending) {
    return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
  }

  return (
    <FormProvider {...methods}>
      <MembershipTransferHeader famOnboardingStatus={famOnboardingStatus} />
      <br></br>
      {(famOnboardingStatus === undefined || famOnboardingStatus.code === MemberTransferStatusCodeEnum.Pending
        || famOnboardingStatus.code === MemberTransferStatusCodeEnum.RequiredFurtherDetails) &&
        <>
          <Stepper activeStep={activeStep} orientation={isMobile === true ? "vertical" : "horizontal"}>
            {steps.map((label) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <br></br>
          {((activeStep !== 0 && (famOnboardingStatus === undefined || famOnboardingStatus.code !== MemberTransferStatusCodeEnum.RequiredFurtherDetails)) ||
            (activeStep > supportingDocumentStepIndex && famOnboardingStatus && famOnboardingStatus.code === MemberTransferStatusCodeEnum.RequiredFurtherDetails)) && (
              <Button variant="contained" onClick={_handleBack}>
                {MESSAGE_BACK_TO_PREVIOUS_STEP}
              </Button>
            )}

          {activeStep === steps.length ? (
            <></>
          ) : (
            <>
              {getStepContent(activeStep)}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid container rowSpacing={4}>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={8}></Grid>
                  <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Button variant="contained" color="primary" onClick={handleNext}>
                      {activeStep !== supportingDocumentStepIndex &&
                        (activeStep === steps.length - 1 ? MESSAGE_SUBMIT : (isDirty === true ? MESSAGE_SAVE_AND_CONTINUE : MESSAGE_NEXT))}
                      {(activeStep === supportingDocumentStepIndex) && MESSAGE_NEXT}
                    </Button>
                  </Grid>
                  <Grid item xs={1}></Grid>
                </Grid>
              </Box>
            </>
          )}
        </>
      }

      {famOnboardingStatus?.code === MemberTransferStatusCodeEnum.Approved && famDetails &&
        countries && famMembershipTypes &&
        <>
          <br></br>
          <br></br>
          <Box sx={{ minWidth: "100%" }} component={Paper}>

            <Grid container rowSpacing={4}>
              <MembershipPaymentSummary basketId={basketId} paymentDetails={paymentDetails} famDetails={famDetails}
                countries={countries} famMembershipTypes={famMembershipTypes} paymentOnClickHandler={paymentOnClickHandler}
                retryUrl={process.env.REACT_APP_MEMBERSHIP_TRANSFER_URI} SpaName={SpaName.MEMBER_TRANSFER}
                membershipType={famTransferDetails !== null ? famMembershipTypes.find(x => x.id === famTransferDetails.famMembershipTypeId)?.value : ''} />

            </Grid>
          </Box>
        </>
      }
    </FormProvider >
  );
};
