import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import agent from "../../app/api/agent";
import { ApiRequestStatus } from "../../app/enums/apiRequestStatus";
import { CpdDto } from "./models/cpdDto";
import { CPDRecordStatusChange } from "./models/CPDRecordStatusChange";
import { CreditRecord } from "./models/creditRecord";
import { SearchCreditRecordsQuery } from "./models/searchCreditRecordsQuery";
import CpdResponseState from "./models/cpdResponseState";
import { MESSAGE_DETAILS_SAVED, MESSAGE_DLETED_SAVED } from "../../app/utils/constant";

const initialState: CpdResponseState = {
    cpdDetails: null,
    creditRecords: null,
    cpdStatusHistory: null,
    
    cpdStateStatus: ApiRequestStatus.Idle,
}

export const getCpdStateAsync = createAsyncThunk<CpdDto, string>(
    'cpd/getCpdStateAsync',
    async (id, thunkAPI) => {
        try {
            return agent.Cpd.getCpdDetails(id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getCreditRecordsStateAsync = createAsyncThunk<CreditRecord[], SearchCreditRecordsQuery>(
    'cpd/getCreditRecordsStateAsync',
    async (searchCreditRecordsQuery, thunkAPI) => {
        try {
            return agent.Cpd.getCreditRecords(searchCreditRecordsQuery);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const updateCpdStateAsync = createAsyncThunk<void, CpdDto>(
    'cpd/updateCpdStateAsync',
    async (cpdDetails, thunkAPI) => {
        try {
            return agent.Cpd.updateCpdDetails(cpdDetails);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const addCpdStateAsync = createAsyncThunk<void, CpdDto>(
    'cpd/addCpdStateAsync',
    async (cpdDetails, thunkAPI) => {
        try {
            return agent.Cpd.addCpdDetails(cpdDetails);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const cloneCpdStateAsync = createAsyncThunk<void, CpdDto>(
    'cpd/cloneCpdStateAsync',
    async (cpdDetails, thunkAPI) => {
        try {
            return agent.Cpd.cloneCpdDetails(cpdDetails);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const deleteCpdStateAsync = createAsyncThunk<void, string>(
    'cpd/deleteCpdStateAsync',
    async (id, thunkAPI) => {
        try {
            return agent.Cpd.deleteCpdDetails(id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getCpdStatusHistoryAsync = createAsyncThunk<CPDRecordStatusChange[], string>(
    'cpd/getCpdStatusHistoryAsync',
    async (id, thunkAPI) => {
        try {
            return agent.Cpd.getCpdStatusHistory(id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const viewCpdSlice = createSlice({
    name: 'addCpd',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder => {
        builder.addCase(getCpdStateAsync.pending, (state) => {
            state.cpdStateStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getCpdStateAsync.fulfilled, (state, action) => {
            state.cpdStateStatus = ApiRequestStatus.Fulfilled;
            state.cpdDetails = action.payload;
        });
        builder.addCase(getCpdStateAsync.rejected, (state) => {
            state.cpdStateStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getCreditRecordsStateAsync.pending, (state) => {
            state.cpdStateStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getCreditRecordsStateAsync.fulfilled, (state, action) => {
            state.cpdStateStatus = ApiRequestStatus.Fulfilled;
            state.creditRecords = action.payload;
        });
        builder.addCase(getCreditRecordsStateAsync.rejected, (state) => {
            state.cpdStateStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(addCpdStateAsync.pending, (state) => {
            state.cpdStateStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(addCpdStateAsync.fulfilled, (state) => {
            state.cpdStateStatus = ApiRequestStatus.Fulfilled;
            toast.success(MESSAGE_DETAILS_SAVED);
        });
        builder.addCase(addCpdStateAsync.rejected, (state) => {
            state.cpdStateStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(cloneCpdStateAsync.pending, (state) => {
            state.cpdStateStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(cloneCpdStateAsync.fulfilled, (state) => {
            state.cpdStateStatus = ApiRequestStatus.Fulfilled;
            toast.success(MESSAGE_DETAILS_SAVED);
        });
        builder.addCase(cloneCpdStateAsync.rejected, (state) => {
            state.cpdStateStatus = ApiRequestStatus.Rejected;
            toast.error('Error while adding CPD.');
        });

        builder.addCase(updateCpdStateAsync.pending, (state) => {
            state.cpdStateStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(updateCpdStateAsync.fulfilled, (state, action) => {
            state.cpdStateStatus = ApiRequestStatus.Fulfilled;
            toast.success(MESSAGE_DETAILS_SAVED);
        });
        builder.addCase(updateCpdStateAsync.rejected, (state) => {
            state.cpdStateStatus = ApiRequestStatus.Rejected;
            toast.error('Error while updating CPD.');
        });

        builder.addCase(deleteCpdStateAsync.pending, (state) => {
            state.cpdStateStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(deleteCpdStateAsync.fulfilled, (state, action) => {
            state.cpdStateStatus = ApiRequestStatus.Fulfilled;
            toast.success(MESSAGE_DLETED_SAVED);
        });
        builder.addCase(deleteCpdStateAsync.rejected, (state) => {
            state.cpdStateStatus = ApiRequestStatus.Rejected;
            toast.error('Error while deleting CPD.');
        });

        builder.addCase(getCpdStatusHistoryAsync.pending, (state) => {
            state.cpdStateStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getCpdStatusHistoryAsync.fulfilled, (state, action) => {
            state.cpdStateStatus = ApiRequestStatus.Fulfilled;
            state.cpdStatusHistory = action.payload;
        });
        builder.addCase(getCpdStatusHistoryAsync.rejected, (state) => {
            state.cpdStateStatus = ApiRequestStatus.Rejected;
        });
    })
})
