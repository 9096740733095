const cpdFormModel = {
    formId: 'CpdForm',
    formField: {
      Year: {
        name: 'Year',
        label: 'Year'
      },
      CategoryFilter: {
        name: 'Category',
        label: 'Category'
      },
      Category: {
        name: 'Category',
        label: 'Category*',
        informationText: 'Please select a category. If you are unsure of the category your activity relates to, please consult the guides (link on the portal)',
        requiredErrorMsg: 'Category is required'
      },
      Status: {
        name: 'Status',
        label: 'Status'
      },
      ActivityDate: {
        name: 'ActivityDate',
        label: 'Date of activity*',
        informationText: 'Enter the date the activity took place',
        requiredErrorMsg: 'Date of activity is required',
        typeError: 'Invalid date of activity.'
      },
      ActivityType: {
        name: 'ActivityType',
        label: 'Activity type*',
        informationText: 'Please select an activity',
        requiredErrorMsg: 'Activity type is required'
      },
      Domains: {
        name: 'Domains',
        label: 'Domains of good practice*',
        informationText: 'Please select one or multiple domains',
        requiredErrorMsg: 'Atleast one domain is required'
      },
      CreditsClaiming: {
        name: 'CreditsClaiming',
        label: 'Credits*',
        informationText: 'Please select the credits awarded for attending this activity (in increments of half points)',
        requiredErrorMsg: 'Credits are is required',
        minimumValueErrorMessage: 'Minimum value 0.5',
        maximumValueErrorMessage: 'Maximum value 40'
      },
      Description: {
        name: 'Description',
        label: 'Activity description*',
        informationText: 'Please give a brief description of the activity attended',
        requiredErrorMsg: 'Activity description is required'
      },
      DevelopmentPurpose : {
        name: 'DevelopmentPurpose',
        label: 'Development purpose',
        informationText: 'Please give an outline of the development purpose of this activity',
      },
      SupportingDocument: {
        name: 'AttachSupportingDocument',
        label: 'Attach supporting document',
        informationText: 'Please upload proof of attendance / cerificate'
      }
    }
  };
  
  export default cpdFormModel;