import { FormHelperText, Grid } from "@mui/material";
import { UseControllerProps, useController } from "react-hook-form";
import AppInformationText from "../../../app/components/AppInformationText";
import { CheckBoxItem } from "../../../app/models/common/checkBoxItem";

interface Props extends UseControllerProps {
    option: CheckBoxItem;
    information_text?: string;
    label?: any;
}

const AppDeclarationCheckbox = (props: Props) => {
    const { option, information_text } = props;
    const { fieldState, field } = useController({ ...props, defaultValue: false });

    return (
        <>
            <Grid container>

                <Grid item xs={11} sm={11}>
                    <div key={option.id}>
                        <Grid container>
                            <Grid item xs={11.5}>
                                {props.label}
                            </Grid>

                            <Grid item xs={0.5} sx={{ alignSelf: "end" }}>
                                <input
                                    {...field}
                                    onChange={(e) => {
                                        field.onChange(e.target.checked);
                                    }}
                                    key={option.id}
                                    type="checkbox"
                                    checked={field.value === undefined ? false : field.value}
                                    value={option.value}
                                />
                            </Grid>
                        </Grid>
                        <br></br>
                    </div>
                </Grid>

                <Grid item xs={1} sx={{ alignSelf: "end" }}>
                    <AppInformationText information_text={information_text} />
                </Grid>

                <Grid item xs={11} sm={12}>
                    {fieldState.error &&
                        <FormHelperText sx={{ color: 'primary.main' }}>{fieldState.error?.message}</FormHelperText>}
                </Grid>
            </Grid>
        </>
    );
};

export default AppDeclarationCheckbox;