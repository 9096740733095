import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import AppFileInput from "../../app/components/AppFileInput";
import { useFormContext } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { FileDto } from "../../app/models/membership/fileDto";
import { MemberTransferDocumentTypeEnum } from "./common/memberTransferDocumentTypeEnum";
import { FileDocument } from "./FileDocument";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import { deleteFamTransferDocumentAsync, saveFamTransferDocumentAsync }
    from "../../app/slices/famTransferSlice";
import { toast } from "react-toastify";
import { API_REQUEST_FULLFILLED, MESSAGE_FILE_DELETED_SCESSFULLY, MESSAGE_FILE_UPLOADED_SCESSFULLY, MESSAGE_PROCESSING_DETAILS, SKIP_TO_UPLOAD } from "../../app/utils/constant";
import { ApiRequestStatus } from "../../app/enums/apiRequestStatus";
import LoadingComponent from "../../app/layout/LoadingComponent";
import UnsavedChangesDialog from "../../app/components/UnsavedChangesDialog";
import { useEffect, useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import MembershipFormModel from "../../app/models/membership/membershipFormModel";
import { FAMOnboarding } from "../../app/models/membership/famOnboarding";
import { certificateValidationSchema, cvValidationSchema, examValidationSchema } from "./common/membershipTransferValidationSchemas";

interface Props {
    documents: FileDto[] | null;
    famTransferDetails: FAMOnboarding | null;
    unSavedCertificateChange: boolean;
    certificateChange: () => void;
    unSavedExamChange: boolean;
    examChange: () => void;
    unSavedCvChange: boolean;
    cvChange: () => void;
    documentSubmittedCallback: () => void;
}

export function AddSupportingDocument({ documents, famTransferDetails, unSavedCertificateChange,
    certificateChange, unSavedExamChange, examChange, unSavedCvChange, cvChange,
    documentSubmittedCallback }: Props) {
    const methods = useFormContext();
    const { control, getValues, setValue, watch, trigger } = methods;
    const formObj = methods.watch();
    const { getFamTransferDocumentsStatus, saveFamTransferDocumentStatus,
        famTransferDownloadStatus, deleteFamTransferDocumentStatus } =
        useAppSelector(state => state.famTransfer);

    const {
        formField: {
            Certificate,
            Exam,
            CV
        }
    } = MembershipFormModel;

    const certificateDocument = documents?.find(x => x.tags?.FILE_TYPE === MemberTransferDocumentTypeEnum.CERTIFICATE);
    const examDocument = documents?.find(x => x.tags?.FILE_TYPE === MemberTransferDocumentTypeEnum.EXAM);
    const curriculumVitaeDocument = documents?.find(x => x.tags?.FILE_TYPE === MemberTransferDocumentTypeEnum.CV);

    const [showDialog, setShowDialog] = useState(true);

    const [isCertificateUploading, setIsCertificateUploading] = useState(false);
    const [isExamUploading, setIsExamUploading] = useState(false);
    const [isCvUploading, setIsCvUploading] = useState(false);

    const [deleteCertificateConfirmation, setDeleteCertificateConfirmation] = useState(false);
    const [deleteExamConfirmation, setDeleteExamConfirmation] = useState(false);
    const [deleteCvConfirmation, setDeleteCvConfirmation] = useState(false);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (documents && documents?.length > 0) {
            setValue(Certificate.name, certificateDocument);
            setValue(Exam.name, examDocument);
            setValue(CV.name, curriculumVitaeDocument);
            trigger();
        }
    }, [documents, certificateDocument, examDocument, curriculumVitaeDocument, setValue]);

    const populateFileFormData = (famExamId: string, fileType: string, file: File) => {
        const formData = new FormData();
        formData.append("FamExamId", famExamId);
        formData.append("FileType", fileType);
        formData.append("File", file);

        return formData;
    }

    const uploadCertificateFileAsync = async () => {
        const isStepValid = certificateValidationSchema.isValidSync(formObj.Certificate as File);

        if (isStepValid === true) {
            let famExamId = famTransferDetails?.famExamId ? famTransferDetails?.famExamId : '';

            dispatch(saveFamTransferDocumentAsync(populateFileFormData(famExamId,
                MemberTransferDocumentTypeEnum.CERTIFICATE, formObj.Certificate as File))).then((response: any) => {
                    if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                        documentSubmittedCallback();
                        setValue(Certificate.name, formObj.Certificate);
                        setIsCertificateUploading(false);
                        certificateChange();
                        toast.success(MESSAGE_FILE_UPLOADED_SCESSFULLY);
                    };
                });
        }
    }

    const uploadExamFileAsync = async () => {
        const isStepValid = examValidationSchema.isValidSync(formObj.Exam as File);

        if (isStepValid === true) {
            let famExamId = famTransferDetails?.famExamId ? famTransferDetails?.famExamId : '';

            dispatch(saveFamTransferDocumentAsync(populateFileFormData(famExamId,
                MemberTransferDocumentTypeEnum.EXAM, formObj.Exam as File))).then((response: any) => {
                    if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                        documentSubmittedCallback();
                        setValue(Exam.name, formObj.Exam);
                        setIsExamUploading(false);
                        examChange();
                        toast.success(MESSAGE_FILE_UPLOADED_SCESSFULLY);
                    };
                });
        }
    }

    const uploadCurriculumVitaeFileAsync = async () => {

        const isStepValid = cvValidationSchema.isValidSync(formObj.CV as File);

        if (isStepValid === true) {
            let famExamId = famTransferDetails?.famExamId ? famTransferDetails?.famExamId : '';

            dispatch(saveFamTransferDocumentAsync(populateFileFormData(famExamId,
                MemberTransferDocumentTypeEnum.CV, formObj.CV as File))).then((response: any) => {
                    if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                        documentSubmittedCallback();
                        setValue(CV.name, formObj.CV);
                        setIsCvUploading(false);
                        cvChange();
                        toast.success(MESSAGE_FILE_UPLOADED_SCESSFULLY);
                    };
                });
        }
    }

    const certificateUploadFilter = () => {
        return getValues([Certificate.name])[0] && documents && (documents?.filter(x => x.name?.trim() ===
            (getValues([Certificate.name])[0]).name.trim()).length > 0);
    }

    const examUploadFilter = () => {
        return getValues([Exam.name])[0] && documents && (documents?.filter(x => x.name?.trim() ===
            (getValues([Exam.name])[0]).name.trim()).length > 0);
    }

    const cvUploadFilter = () => {
        return getValues([CV.name])[0] && documents && (documents?.filter(x => x.name?.trim() ===
            (getValues([CV.name])[0]).name.trim()).length > 0);
    }

    useEffect(() => {
        watch((value, { name, type }) => {
            setShowDialog(true);
            if (name === MembershipFormModel.formField.Certificate.name && type === 'change') {
                setIsCertificateUploading(true);
            }

            if (name === MembershipFormModel.formField.Exam.name && type === 'change') {
                setIsExamUploading(true);
            }

            if (name === MembershipFormModel.formField.CV.name && type === 'change') {
                setIsCvUploading(true);
            }
        });
    }, [MembershipFormModel.formField.Certificate.name, watch]);

    const noButtonClick = () => {
        setShowDialog(false);

        if (isCertificateUploading === true) {
            setValue(Certificate.name, undefined);
        }

        if (isExamUploading === true) {
            setValue(Exam.name, undefined);
        }

        if (isCvUploading === true) {
            setValue(CV.name, undefined);
        }
    }

    const deleteCertificateAsync = () => {
        if (certificateDocument?.tags?.FILE_TYPE) {
            dispatch(deleteFamTransferDocumentAsync(certificateDocument?.tags?.FILE_TYPE)).then((response: any) => {
                if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                    documentSubmittedCallback();
                    setValue(Certificate.name, undefined);
                    toast.success(MESSAGE_FILE_DELETED_SCESSFULLY);
                };
            });
        }
    }

    const deleteExamAsync = () => {
        if (examDocument?.tags?.FILE_TYPE) {
            dispatch(deleteFamTransferDocumentAsync(examDocument?.tags?.FILE_TYPE)).then((response: any) => {
                if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                    documentSubmittedCallback();
                    setValue(Exam.name, undefined);
                    toast.success(MESSAGE_FILE_DELETED_SCESSFULLY);
                };
            });
        }
    }

    const deleteCurriculumVitaeAsync = () => {
        if (curriculumVitaeDocument?.tags?.FILE_TYPE) {
            dispatch(deleteFamTransferDocumentAsync(curriculumVitaeDocument?.tags?.FILE_TYPE)).then((response: any) => {
                if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                    documentSubmittedCallback();
                    setValue(CV.name, undefined);
                    toast.success(MESSAGE_FILE_DELETED_SCESSFULLY);
                };
            });
        }
    }

    if (getFamTransferDocumentsStatus === ApiRequestStatus.Pending || famTransferDownloadStatus === ApiRequestStatus.Pending ||
        deleteFamTransferDocumentStatus === ApiRequestStatus.Pending || saveFamTransferDocumentStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12} sx={{ marginTop: '-1%' }}>
                    <Typography variant='subtitle1' sx={{ fontWeight: "500" }}>{Certificate.label}</Typography>
                </Grid>

                <Grid item xs={8}>
                    <AppFileInput control={control} label={""} name={Certificate.name} />
                </Grid>

                <Grid item xs={4} sx={{ marginTop: '8px' }}>
                    {isCertificateUploading === true && getValues([Certificate.name])[0] !== undefined &&
                        <LoadingButton variant="contained" onClick={uploadCertificateFileAsync}>
                            {certificateDocument ? 'Replace certificate' : 'Upload certificate'}
                        </LoadingButton>}
                </Grid>

                {certificateDocument &&
                    <>
                        <FileDocument fileDocument={certificateDocument} />

                        <Grid item xs={12}>
                            <Button variant="outlined" startIcon={<DeleteIcon />} sx={{ "& .MuiButton-startIcon": { marginRight: "0px" }, marginLeft: '0' }}
                                onClick={() => certificateDocument?.name && setDeleteCertificateConfirmation(true)} >
                                Delete
                            </Button>
                        </Grid>
                    </>
                }
                <Grid item xs={12}></Grid>

            </Grid>
        </Box>

        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12} sx={{ marginTop: '-1%' }}>
                    <Typography variant='subtitle1' sx={{ fontWeight: "500" }}>{Exam.label}</Typography>
                </Grid>

                <Grid item xs={8}>
                    <AppFileInput control={control} label={""} name={Exam.name} />
                </Grid>

                <Grid item xs={4} sx={{ marginTop: '8px' }}>
                    {isExamUploading === true && getValues([Exam.name])[0] !== undefined &&
                        <LoadingButton variant="contained" onClick={uploadExamFileAsync}>
                            {examDocument ? 'Replace file' : 'Upload file'}
                        </LoadingButton>}
                </Grid>

                {examDocument &&
                    <>
                        <FileDocument fileDocument={examDocument} />

                        <Grid item xs={2}>
                            <Button sx={{ "& .MuiButton-startIcon": { marginRight: "0px", marginLeft: '0' } }} variant="outlined" startIcon={<DeleteIcon />}
                                onClick={() => examDocument?.name && setDeleteExamConfirmation(true)} >
                                Delete
                            </Button>
                        </Grid>
                    </>
                }

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>

        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12} sx={{ marginTop: '-1%' }}>
                    <Typography variant='subtitle1' sx={{ fontWeight: "500" }}>{CV.label}</Typography>
                </Grid>

                <Grid item xs={8}>
                    <AppFileInput control={control} label={""} name={CV.name} />
                </Grid>

                <Grid item xs={4} sx={{ marginTop: '8px' }}>
                    {isCvUploading === true && getValues([CV.name])[0] !== undefined &&
                        <LoadingButton variant="contained" onClick={uploadCurriculumVitaeFileAsync}>
                            {curriculumVitaeDocument ? 'Replace curriculum vitae' : 'Upload curriculum vitae'}
                        </LoadingButton>}
                </Grid>

                {curriculumVitaeDocument &&
                    <>
                        <FileDocument fileDocument={curriculumVitaeDocument} />

                        <Grid item xs={2}>
                            <Button variant="outlined" startIcon={<DeleteIcon />} sx={{ "& .MuiButton-startIcon": { marginRight: "0px" } }}
                                onClick={() => curriculumVitaeDocument?.name && setDeleteCvConfirmation(true)} >
                                Delete
                            </Button>
                        </Grid>
                    </>
                }

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>

        {((isCertificateUploading === true && certificateUploadFilter()) ||
            (isExamUploading === true && examUploadFilter()) ||
            (isCvUploading === true && cvUploadFilter())) &&
            <UnsavedChangesDialog showDialog={showDialog}
                title={'Duplicate file found.'}
                subTitle={`File you uploaded is already in the system. You can either 
                    replace this file or rename the file and upload again.`}
                skipButtonClick={noButtonClick}
                showYesNoButtons={false} />
        }

        {unSavedCertificateChange === true &&
            <UnsavedChangesDialog showDialog={showDialog}
                title={'Changes not saved.'}
                subTitle={`Certificate is not saved, do you wan to save the changes?`}
                yesButtonClick={uploadCertificateFileAsync}
                noButtonClick={() => {
                    setValue(Certificate.name, SKIP_TO_UPLOAD);
                    setIsCertificateUploading(false);
                    certificateChange();
                    trigger();
                }}
                showYesNoButtons={true} />
        }

        {unSavedExamChange === true &&
            <UnsavedChangesDialog showDialog={showDialog}
                title={'Changes not saved.'}
                subTitle={`Exam document is not saved, do you wan to save the changes?`}
                yesButtonClick={uploadExamFileAsync}
                noButtonClick={() => {
                    setValue(Exam.name, SKIP_TO_UPLOAD);
                    setIsExamUploading(false);
                    examChange();
                    trigger();
                }}
                showYesNoButtons={true} />
        }

        {unSavedCvChange === true &&
            <UnsavedChangesDialog showDialog={showDialog}
                title={'Changes not saved.'}
                subTitle={`CV is not saved, do you wan to save the changes?`}
                yesButtonClick={uploadCurriculumVitaeFileAsync}
                noButtonClick={() => {
                    setValue(CV.name, SKIP_TO_UPLOAD);
                    setIsCvUploading(false);
                    cvChange();
                    trigger();
                }}
                showYesNoButtons={true} />
        }

        {deleteCertificateConfirmation &&
            <UnsavedChangesDialog showDialog={showDialog}
                title={'Are you sure you want to delete Certificate?'}
                subTitle={`You will loose the changes on clicking "YES".`}
                yesButtonClick={() => {
                    deleteCertificateAsync();
                    setDeleteCertificateConfirmation(false);
                }}
                noButtonClick={() => setDeleteCertificateConfirmation(false)}
                showYesNoButtons={true} />}

        {deleteExamConfirmation &&
            <UnsavedChangesDialog showDialog={showDialog}
                title={'Are you sure you want to delete Exam document?'}
                subTitle={`You will loose the changes on clicking "YES".`}
                yesButtonClick={() => {
                    deleteExamAsync();
                    setDeleteExamConfirmation(false);
                }}
                noButtonClick={() => setDeleteExamConfirmation(false)}
                showYesNoButtons={true} />}

        {deleteCvConfirmation &&
            <UnsavedChangesDialog showDialog={showDialog}
                title={'Are you sure you want to delete CV?'}
                subTitle={`You will loose the changes on clicking "YES".`}
                yesButtonClick={() => {
                    deleteCurriculumVitaeAsync();
                    setDeleteCvConfirmation(false);
                }}
                noButtonClick={() => setDeleteCvConfirmation(false)}
                showYesNoButtons={true} />}
    </>
}
