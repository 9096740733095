import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import agent from "../api/agent";
import { AlumnusDetails } from "../../features/updateProfile/models/alumniDetails";

interface updateProfileState {
    alumnusDetails: AlumnusDetails | null,

    getAlumnusDetailsStatus: ApiRequestStatus;
    updateAlumnusDetailsStatus: ApiRequestStatus;
}

const initialState: updateProfileState = {
    alumnusDetails: null,
    getAlumnusDetailsStatus: ApiRequestStatus.Idle,
    updateAlumnusDetailsStatus: ApiRequestStatus.Idle
}

export const getAlumniDetails = createAsyncThunk<AlumnusDetails, string>(
    'alumniSlice/getAlumniDetails',
    async (personId, thunkAPI) => {
        try {
            return agent.UpdateProfile.getAlumniDetails(personId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const updateAlumniDetails = createAsyncThunk<boolean, AlumnusDetails>(
    'alumniSlice/updateAlumniDetails',
    async (alumnusDetails, thunkAPI) => {
        try {
            return agent.UpdateProfile.updateAlumniDetails(alumnusDetails);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const alumniSlice = createSlice({
    name: 'alumniSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(getAlumniDetails.pending, (state) => {
            state.getAlumnusDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getAlumniDetails.fulfilled, (state, action) => {
            state.getAlumnusDetailsStatus = ApiRequestStatus.Fulfilled;
            state.alumnusDetails = action.payload;
        });
        builder.addCase(getAlumniDetails.rejected, (state) => {
            state.getAlumnusDetailsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(updateAlumniDetails.pending, (state, action) => {
            state.updateAlumnusDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(updateAlumniDetails.fulfilled, (state, action) => {
            state.updateAlumnusDetailsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(updateAlumniDetails.rejected, (state, action) => {
            state.updateAlumnusDetailsStatus = ApiRequestStatus.Rejected;
        });
    })
})
