import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store/configureStore";
import { useState, useEffect } from "react";
import { getEnrolmentYearsAsync } from "./pcsPlanDashboardSlice";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { PcsEnrolmentYear } from "../../models/pcsEnrolmentYear";
import { Link } from "react-router-dom";
import { getReferenceDataAsync } from "../../../../app/slices/referenceDataSlice";
import { useMsal } from "@azure/msal-react";
import { UserClaims } from "../../../../app/enums/userClaims";

export function PcsPlanDashboard() {
    const dispatch = useAppDispatch();

    const { enrolmentYears } = useAppSelector(state => state.pcsPlanDashboard);
    const { pcsPdpStatuses } = useAppSelector(state => state.ReferenceData);

    const { accounts } = useMsal();

    useEffect(() => {
        if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
            setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
        }
    }, [accounts]);

    const [personId, setPersonId] = useState('');
    const [pageLoading, setPageLoading] = useState(false);

    useEffect(() => {
        if (personId !== "") {

            setPageLoading(true);

            const allPromise = async () => await Promise.all([
                dispatch(getReferenceDataAsync()),
                dispatch(getEnrolmentYearsAsync(personId))
            ]);

            allPromise().finally(() => {
                setPageLoading(false);
            });
        }
    }, [personId, dispatch]);

    const getStatusName = (pdpStatudId: string) => {
        return pcsPdpStatuses?.find(x => x.id === pdpStatudId)?.value;
    }

    if (pageLoading === true) {
        return <LoadingComponent message='Loading...' />
    }

    return <>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Grid container rowSpacing={2}>

                <Grid item xs={12}></Grid>

                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant='h4'>PCS Annual Plans</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='subtitle1'>
                        Please click on view to access your PCS plan for the relevant PCS year.
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>PCS Year</TableCell>
                                    <TableCell align="left">Status</TableCell>
                                    <TableCell align="left"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {enrolmentYears?.map((item: PcsEnrolmentYear, index: number) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="left">{`${item.year} - ${item.year + 1}`}</TableCell>
                                        <TableCell align="left">{item.pcsPdpReviewStatusId !== null && getStatusName(item.pcsPdpReviewStatusId)}</TableCell>
                                        <TableCell align="left">
                                            <Button component={Link} to={`/pcsplandetails/${item.enrolmentYearId}`} size="small">View</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {enrolmentYears?.length === 0 &&
                                    <TableRow>
                                        <TableCell colSpan={9} align="center">
                                            You have no active enrolments, please go to the home page to enrol.
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

            </Grid>
        </Paper>
    </>
}