import { Box, Grid, Paper, Typography } from "@mui/material";
import PaymentUnsuccessful from "../../app/components/PaymentUnsuccessful";
import { AppPathEnum } from "../../app/enums/appPathEnum";

export default function DonationPaymentSuccessful() {
    const queryParams = new URLSearchParams(window.location.search);
    const responseCode = queryParams.get("Rc");
    const ref = encodeURIComponent(queryParams.get("ref") ?? '');
    const retryUrl = `${String(process.env.REACT_APP_REDIRECT_URI)}${AppPathEnum.DONATION_DASHBOARD}?ref=${ref}`;

    return (
        <>
            <Box sx={{ width: '100%' }} component={Paper}>

                <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                    {responseCode !== "C" && <>

                        <Grid item xs={12}>
                            <Typography variant='h4'>Payment successful</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>
                                Thank you for your donation. Once you receive an email confirming payment,
                                please logout and login again to access your receipt.
                            </Typography>
                        </Grid>

                        <Grid item xs={12}></Grid>

                    </>}
                </Grid>

                {responseCode === "C" && retryUrl !== null && retryUrl !== '' &&
                    <Grid container rowSpacing={4} sx={{ marginLeft: '2%' }}>
                        <PaymentUnsuccessful retryUrl={retryUrl} />
                    </Grid>
                }

            </Box>
        </>
    )
}