import { Box, Grid, Paper, Typography } from '@mui/material';
import { useEffect } from 'react';
import registrationFormModel from './common/registrationFormModel';
import AppSelectList from '../../app/components/AppSelectList';
import { useFormContext } from 'react-hook-form';
import AppTextInput from '../../app/components/AppTextInput';
import AppDatePicker from '../../app/components/AppDatePicker';
import { PersonalDetailsDto } from '../../app/models/person/personalDetailsDto';
import { Title } from '../../app/models/staticData/title';
import { Gender } from '../../app/models/staticData/gender';
import { Country } from '../../app/models/staticData/country';

interface Props {
  titles: Title[] | null;
  genders: Gender[] | null;
  countries: Country[] | null;
  personalDetails: PersonalDetailsDto | null;
}

export default function PersonalDetails({ titles, genders, countries, personalDetails }: Props) {

  const methods = useFormContext();
  const { control, reset } = methods;

  const { personalDetailsFormField: {
    Title,
    FirstName,
    LastName,
    DOB,
    Gender,
    Nationality,
    MobileNumber
  } } = registrationFormModel;

  useEffect(() => {
    if (personalDetails && personalDetails !== null
      && titles !== null && titles.length > 0
      && genders !== null && genders.length > 0
      && countries !== null && countries.length > 0) {
      reset({
        [Title.name]: personalDetails.titleId,
        [FirstName.name]: personalDetails.firstName,
        [LastName.name]: personalDetails.lastName,
        [DOB.name]: personalDetails.dateOfBirth,
        [Gender.name]: personalDetails.genderId,
        [Nationality.name]: personalDetails.nationalityId,
        [MobileNumber.name]: personalDetails.mobile
      })
    }
  }, [titles, genders, countries]);

  return (
    <>
      <Box sx={{ minWidth: "100%" }} component={Paper}>
        <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

          <Grid item xs={12}>
            <AppSelectList control={control}
              name={Title.name}
              label={Title.label}
              items={titles === null ? [] : titles} />
          </Grid>

          <Grid item xs={12}>
            <AppTextInput control={control}
              name={FirstName.name}
              label={FirstName.label} />
          </Grid>

          <Grid item xs={12}>
            <AppTextInput control={control}
              name={LastName.name}
              label={LastName.label} />
          </Grid>

          <Grid item xs={12}>
            <AppDatePicker
              control={control}
              name={DOB.name}
              label={DOB.label} />
          </Grid>

          <Grid item xs={12}>
            <AppSelectList control={control}
              name={Gender.name}
              label={Gender.label}
              items={genders === null ? [] : genders} />
          </Grid>

          <Grid item xs={12}>
            <AppSelectList control={control}
              name={Nationality.name}
              label={Nationality.label}
              items={countries === null ? [] : countries} />
          </Grid>

          <Grid item xs={12}>
            <AppTextInput control={control}
              name={MobileNumber.name}
              label={MobileNumber.label} />
          </Grid>

          <Grid item xs={12}></Grid>

        </Grid>
      </Box>
    </>
  );
}
