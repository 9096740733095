import { FormControl, InputLabel, Select, MenuItem, FormHelperText, Grid } from "@mui/material";
import { useController, UseControllerProps } from "react-hook-form";
import { DropdownItem } from "../models/common/dropdownItem";

interface Props extends UseControllerProps {
    label: string;
    items: DropdownItem[];
    information_text?: string;
}

export default function AppSelectListNoHelptext(props: Props) {
    const { fieldState, field } = useController({ ...props, defaultValue: '' });

    return (
        <>
            <Grid container rowSpacing={4}>

                <Grid item xs={12}>
                    <FormControl fullWidth error={!!fieldState.error}>
                        <InputLabel>{props.label}</InputLabel>
                        <Select
                            {...field}
                            {...props}
                        >
                            {props.items.map((item: DropdownItem, index: number) => (
                                <MenuItem value={item.id} key={index}>{item.value}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{fieldState.error?.message}</FormHelperText>
                    </FormControl>
                </Grid>

            </Grid>
        </>
    )
}