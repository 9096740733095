import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";

export function AddSupportingDocument() {
    return <>
        <Grid container rowSpacing={4}>
            <Grid item xs={12}></Grid>

            <Grid item xs={3}>
                Supporting Document*
            </Grid>

            <Grid item xs={3}>
                <input
                    accept="image/*"
                    id="raised-button-file"
                    multiple
                    type="file"
                />
            </Grid>

            <Grid item xs={6}></Grid>

            <Grid item xs={3}></Grid>

            <Grid item xs={9}>
                <LoadingButton variant="contained" component="span"
                    onClick={() => { }}
                    color='error'
                >
                    Add This Document
                </LoadingButton>
            </Grid>

        </Grid>

    </>
}