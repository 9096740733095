import { Grid } from "@mui/material";
import AppInformationText from "./AppInformationText";

interface Props {
    label: string;
    value?: string;
    information_text?: string | React.ReactNode;
}

export default function AppLabelField(props: Props) {

    return (
        <>
            <Grid container rowSpacing={4}>

                <Grid item xs={12} sm={3}>
                    {props.label.trim().endsWith("*") ?
                        props.label.trim().substring(0, (props.label.trim().length - 1)) :
                        props.label}
                </Grid>

                <Grid item xs={1} sm={1} sx={{paddingTop: '20px !important'}}>
                    {props.information_text && props.information_text !== '' &&
                        <AppInformationText information_text={props.information_text} placement={'right'} />}
                </Grid>

                <Grid item xs={11} sm={8}>
                    {props.value}
                </Grid>

            </Grid>
        </>
    )
}