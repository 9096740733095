import { Button, Grid, Typography } from "@mui/material";

interface Props {
    retryUrl: string;
}

export default function PaymentUnsuccessful({ retryUrl }: Props) {
    const queryParams = new URLSearchParams(window.location.search);
    const responseCode = queryParams.get("Rc");

    return (
        <>
            {responseCode !== "C" &&
                <Grid item xs={12}>
                    <Typography variant='h4'>Payment unsuccessful</Typography>
                </Grid>
            }

            {responseCode === "C" &&
                <Grid item xs={12}>
                    <Typography variant='h4'>Payment cancelled</Typography>
                </Grid>
            }

            <Grid item xs={12}>
                <Typography variant='subtitle1'>
                    Your payment was not completed. Please use the button below to return
                    to the payments summary screen and try again.
                </Typography>
            </Grid>

            <Grid item xs={12} sx={{ marginBottom: '2%' }}>
                {retryUrl && <Button variant="contained" color="primary"
                    onClick={_ => window.location.href = retryUrl}>
                    Try again
                </Button>}
            </Grid>
        </>
    )
}