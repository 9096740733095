import { Box, Paper, Grid, Typography } from "@mui/material";
import { FamOnboardingStatus } from "../../app/models/staticData/famOnboardingStatus";
import { MemberTransferStatusCodeEnum } from "../../app/enums/memberTransferStatusCodeEnum";

interface Props {
    famOnboardingStatus: FamOnboardingStatus | undefined;
}

export default function MembershipOnboardingHeader({ famOnboardingStatus }: Props) {

    return <>
        <Box sx={{ flexGrow: 1 }} component={Paper}>

            <Grid container rowSpacing={4}>

                <Grid item xs={12} sx={{ marginLeft: '2%', marginTop: '2%' }}>
                    <Typography variant='h4'>Member and Fellow onboarding</Typography>
                </Grid>

                <Grid item xs={12} sx={{ marginLeft: '2%', marginRight: '2%', marginBottom: '2%' }}>
                    <Typography variant='subtitle1'>
                        {(!famOnboardingStatus || famOnboardingStatus.code === MemberTransferStatusCodeEnum.Pending
                            || famOnboardingStatus.code === MemberTransferStatusCodeEnum.FmOnboarding) &&
                            <>
                                Congratulations on your recent examination success.

                                <br></br>
                                <br></br>

                                You are now eligible to be admitted to the Royal College of Surgeons in Ireland (RCSI),
                                <b> with permission to use the associated post-nominals</b> and access to a global professional
                                surgical network of RCSI Fellows and Members worldwide.

                                <br></br>
                                <br></br>

                                The process of joining the College is straightforward and involves completing the 
                                application below and payment of your annual subscription. Once these are complete 
                                your name will be put forward to RCSI Council for ratification as a Member/Fellow of 
                                RCSI.
                            </>
                        }

                        {famOnboardingStatus?.code === MemberTransferStatusCodeEnum.Paid &&
                            <>
                                Thank you for your application and payment please check your email for next steps.
                            </>
                        }

                        {famOnboardingStatus?.code === MemberTransferStatusCodeEnum.Ratified &&
                            <>
                                Your application has now been ratified. You should now have access to the
                                RCSI Members and Fellows Portal.
                            </>
                        }

                        {famOnboardingStatus?.code === MemberTransferStatusCodeEnum.Declined &&
                            <>
                                Your application has been declined. You should have received communications from the RCSI
                                Membership team with more details. If you have not, please contact us at <a href="mailto:fellows@rcsi.com">fellows@rcsi.com</a>.
                            </>
                        }

                        {famOnboardingStatus?.code === MemberTransferStatusCodeEnum.Conferred &&
                            <>
                                Your have been conferred you should now have access to the Member portal and have no further
                                need for this application form.
                            </>
                        }
                    </Typography>
                </Grid>

            </Grid>
        </Box>
    </>
}
