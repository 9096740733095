import { Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import AppTextInput from "../../app/components/AppTextInput";
import AppDatePicker from "../../app/components/AppDatePicker";
import AppCheckboxField from "../../app/components/AppCheckboxField";

export default function Payment(props: any) {
  const {
    formField: {
      NameOnCard,
      CardNumber,
      ExpiryDate,
      CVV,
      SaveCard
    }
  } = props;

  const { control, handleSubmit } = useForm();

  async function handleSubmitData(values: any, actions: any) {
  }

  return (
    <>
      <form onSubmit={handleSubmit(handleSubmitData)}>
        <Typography variant="h6" gutterBottom>
          Payment method
        </Typography>

        <Grid container spacing={3}>

          <Grid item xs={12}>
            <AppTextInput control={control} label={NameOnCard.label} name={NameOnCard.name} />
          </Grid>

          <Grid item xs={12} md={6}>
            <AppTextInput control={control} label={CardNumber.label} name={CardNumber.name} />
          </Grid>

          <Grid item xs={12}>
            <AppDatePicker
              control={control}
              name={ExpiryDate.name}
              label={ExpiryDate.label}
              views={['year', 'month']} opento="year" format="MMM YYYY" />
          </Grid>

          <Grid item xs={12} md={6}>
            <AppTextInput control={control} label={CVV.label} name={CVV.name} />
          </Grid>

          <Grid item xs={12}>
            <AppCheckboxField
              control={control}
              name={SaveCard.name}
              label={SaveCard.label}
              options={[SaveCard.label]} />
          </Grid>

        </Grid>
      </form>
    </>
  );
}
