import { AppPathEnum } from '../../app/enums/appPathEnum';
import MediaCard from './MediaCard';
import { RequestSpaMetadata } from './models/requestSpaMetadata';
import { Box, Paper, Grid, Typography } from '@mui/material';
import { useMsal } from '@azure/msal-react';
import { IdTokenClaims } from '../../app/models/account/idTokenClaims';

export function RequestForm() {
    const requestSpas: RequestSpaMetadata[] = [
        { title: 'Request Portal access', description: 'No access? Confirm your RCSI identity with the team.', url: AppPathEnum.RequestPortalAccess, allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_Request_Portal_Access?.split(',') },
        { title: 'Change of name', description: 'Submit a request to change your name on our records.', url: AppPathEnum.CHANGE_OF_NAME, allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_CHANGE_OF_NAME?.split(',') },
        { title: 'Reductions and exemptions', description: 'Apply for a fee discount or exemption.', url: AppPathEnum.Reductions_And_Exemptions, allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_REDUCTIONS_AND_EXEMPTIONS?.split(',') },
        { title: 'Merchandise', description: 'Purchase an RCSI Fellows tie, bowtie or scarf - exclusively available to RCSI Fellows.', url: AppPathEnum.MERCHANDISE, allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_MERCHANDISE?.split(',') },
        { title: 'F&M LinkedIn', description: 'Join RCSI Fellows and Members LinkedIn Group for exclusive content, updates and resources.', url: AppPathEnum.LINKEDIN, allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_LINKEDIN?.split(',') },
        { title: 'PasTest discount code', description: 'Secure a 20% discount off your PasTest online subscription.', url: AppPathEnum.PASTEST, allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_PASTEST?.split(',') },
        { title: 'Submit class notes', description: 'Share your professional or personal updates with the alumni community.', url: AppPathEnum.CLASS_NOTES, allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_CLASS_NOTES?.split(',') },
        { title: 'Request Alumni Tour', description: 'Take a guided tour of our Dublin campus with a member of our Porter Team.', url: AppPathEnum.TOURS, allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_TOURS?.split(',') },
        { title: 'Contact a classmate', description: 'Submit a request to reconnect with a classmate.', url: AppPathEnum.CONTACT_CLASSMATES, allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_CONTACT_CLASSMATES?.split(',') },
        { title: 'Alumni Yearbook', description: 'Get a digital copy of your yearbook - exclusively for graduates of the School of Medicine (1970 - present).', url: AppPathEnum.YEAR_BOOK, allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_YEAR_BOOK?.split(',') },
        { title: 'RCSI campus access card - Alumni ', description: 'Apply for access to the 1784 café, the Dispensary coffee shop and RCSI\'s Library in 26 York Street.', url: AppPathEnum.ACCESS_CARD_ALUMNI, allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_ACCESS_CARD_ALUMNI?.split(',') },
        { title: 'RCSI campus access card - Fellows and Members', description: 'Apply for access to the 1784 café, the Dispensary coffee shop and RCSI\'s Library in 26 York Street.', url: AppPathEnum.ACCESS_CARD_FELLOW_AND_MEMBER, allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_ACCESS_CARD_FELLOW_AND_MEMBER?.split(',') },
        { title: 'Absence from Practice', description: 'Absence from Practice.', url: AppPathEnum.ABSENCE_FROM_PRACTICE_PCS, allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_ABSENCE_FROM_PRACTICE_PCS?.split(',') },
        { title: 'Request to withdraw', description: 'Request to withdraw.', url: AppPathEnum.REQUEST_TO_WITHDRAW_PCS, allowedRoles: process.env.REACT_APP_ALLOWED_ROLES_REQUEST_TO_WITHDRAW_PCS?.split(',') }
    ];

    const { instance } = useMsal();
    const userRoles = (instance.getActiveAccount()?.idTokenClaims as unknown as IdTokenClaims).extension_portalcrm_userType.split(',');
    
    return <>
        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ ml: '1.75%', mt: '0%' }}>

                <Grid item xs={12}>
                    <Typography variant='h4'>Request</Typography>
                </Grid>

                {requestSpas.filter(x => x.allowedRoles && x.allowedRoles?.filter(item => userRoles.includes(item)).length > 0).map((item: RequestSpaMetadata) => (
                    <Grid key={item.title} item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <MediaCard title={item.title}
                            description={item.description}
                            redirectUrl={item.url} />
                    </Grid>
                ))}

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>
    </>
}