import { Grid, Select, MenuItem, ListItemText, Checkbox } from "@mui/material";
import { UseControllerProps, useController } from "react-hook-form";
import { FormControl, InputLabel, OutlinedInput } from "@mui/material";
import { DropdownItem } from "../models/common/dropdownItem";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: "250px",
            margin: '1px !important'
        },
    },
};

interface Props extends UseControllerProps {
    label: string;
    items: DropdownItem[];
}

export default function AppMultiSelectList(props: Props) {
    const { fieldState, field } = useController({ ...props, defaultValue: '' });

    return (
        <>
            <Grid container rowSpacing={4}>
                <Grid item xs={12}>
                    <FormControl fullWidth error={!!fieldState.error}>
                        <InputLabel {...field}>{props.label}</InputLabel>
                        <Select
                            {...field}
                            multiple
                            input={<OutlinedInput label={props.label} />}
                            renderValue={(selected: any) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {props.items.map((item: DropdownItem, index: number) => (
                                <MenuItem key={index} value={item.value}>
                                    <Checkbox checked={field.value.indexOf(item.value) > -1} />
                                    <ListItemText primary={item.value} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
}
