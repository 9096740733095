import "yup-phone-lite";
import { PostalAddressValidationSchema } from '../../../app/models/membership/validationSchemas';
import { countryCodes } from '../../../app/utils/util';
import { CountryCode } from 'libphonenumber-js/types';
import * as yup from 'yup';
import DonationFormModel from "./donationFormModel";
import { CountryNameEnum } from "../../../app/enums/countryNameEnum";
import { CurrencyCodeIconEnum } from "../../../app/enums/currencyCodeIconEnum";

const {
  formField: {
    Title,
    PreferredPhoneNumber,
    Fund,
    GiftAmount
  }
} = DonationFormModel;

const getCurrencyIcon = () => {
  const url = window.location.href;
  const region = url.split("?")[0].split("/")[url.split("?")[0].split("/").length - 1];
  if (region.toLowerCase() === CountryNameEnum.UK.toLowerCase()) {
    return CurrencyCodeIconEnum.UK;
  }
  else if (region.toLowerCase() === CountryNameEnum.USA.toLowerCase()) {
    return CurrencyCodeIconEnum.USA;
  }
  else {
    return CurrencyCodeIconEnum.EUR;
  }
}

export const DonationValidationSchemas = [
  yup.object().shape({
    [Title.name]: yup.string().required(`${Title.requiredErrorMsg}`),
    [PreferredPhoneNumber.name]: yup.string().phone(countryCodes as CountryCode[], `${PreferredPhoneNumber.typeError}`)
      .required(`${PreferredPhoneNumber.requiredErrorMsg}`)
  }),
  PostalAddressValidationSchema,
  yup.object().shape({
    [Fund.name]: yup.string().required(`${Fund.requiredErrorMsg}`),
    [GiftAmount.name]: yup.number()
      .min(10, `${GiftAmount.minValueErrorMsg.replace("##CURRENCY_ICON_PLACE_HOLDER##", getCurrencyIcon())}`)
      .max(50000, `${GiftAmount.maxValueErrorMsg.replace("##CURRENCY_ICON_PLACE_HOLDER##", getCurrencyIcon())}`)
      .required(`${GiftAmount.requiredErrorMsg}`)
      .typeError(`${GiftAmount.typeErrorMsg}`)
  })
]
