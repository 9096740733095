import { Grid, Typography } from "@mui/material";
import React from "react";
import { convertToPrecesion } from "../../utils/util";
import PaymentProductJsonDto from "../affiliateOnboarding/paymentProductJsonDto";
import { PaymentDto } from "../payment/paymentDto";
import { PaymentProductDto } from "../payment/paymentProductDto";
import { CurrencyCodeIconEnum } from "../../enums/currencyCodeIconEnum";

interface Props {
    paymentDetails: PaymentDto | null;
}

interface PaymentProductProps {
    year: string;
    amount: number | null;
}

export default function SubscriptionFeesDue(props: Props) {
    const { paymentDetails } = props;

    const paymentProducts = () => {
        let paymentProductArray: PaymentProductProps[] = [];

        paymentDetails?.paymentProducts.map((paymentProduct: PaymentProductDto) => {
            if (paymentProduct.jsonValues !== null) {
                let paymentProductJsonObject = JSON.parse(paymentProduct.jsonValues) as PaymentProductJsonDto;

                paymentProductJsonObject.Year && paymentProductArray.push(
                    { year: paymentProductJsonObject.Year + ' Annual Membership', amount: paymentProduct.cost });
            }
        });

        return paymentProductArray;
    }

    return <>
        <Grid item xs={12} sx={{ paddingTop: '1% !important' }}>
            <Typography variant='subtitle1' sx={{ fontWeight: "500" }}>Subscription Fees Due</Typography>
        </Grid>

        <Grid container rowSpacing={4}>

            <Grid item sm={12}></Grid>

            {
                paymentProducts().map((item: PaymentProductProps) => (
                    <React.Fragment key={item.year}>

                        <Grid item xs={5} md={6} sm={5}>
                            {item.year}
                        </Grid>

                        <Grid item xs={0} md={1} sm={1}></Grid>

                        <Grid item xs={7} md={5} sm={6}>
                            {item.amount && <>{CurrencyCodeIconEnum.EUR} {convertToPrecesion(item.amount?.toString())}</>}
                        </Grid>

                    </React.Fragment>
                ))
            }

            <>
                <Grid item xs={12}></Grid>

                <Grid item xs={5} md={6} sm={5}>
                    <b>Total Due</b>
                </Grid>

                <Grid item xs={0} md={1} sm={1}></Grid>

                <Grid item xs={7} md={5} sm={6}>
                    {CurrencyCodeIconEnum.EUR} {convertToPrecesion(paymentDetails?.paymentProducts?.reduce((sum, current) => sum + (current.cost === null ? 0 : current.cost), 0))}
                </Grid>
            </>

        </Grid>
    </>
}