import { Document, View, Page, StyleSheet, Font } from '@react-pdf/renderer';
import { Header } from './Header';
import { Footer } from './Footer';
import { Body } from './Body';
import { FAMDetails } from '../../app/models/membership/famDetails';
import { FAMOnboarding } from '../../app/models/membership/famOnboarding';
import { FamMembershipType } from '../../app/models/staticData/famMembershipType';

Font.register({
    family: 'Open Sans',
    fonts: [
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf', fontWeight: 700 },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf', fontWeight: 800 }
    ]
});

const styles = StyleSheet.create({
    page: {
        flexDirection: "column",
        padding: 25
    },
    row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignContent: "stretch",
        flexWrap: "nowrap",
        alignItems: "stretch",
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 35
    }
});

interface Props {
    famMembershipTypes: FamMembershipType[] | null;
    famOnboardingDetails: FAMOnboarding | null;
    currentYear: number | null;
    famDetails: FAMDetails | null;
}

export function PdfDocument({ famMembershipTypes, famOnboardingDetails, currentYear, famDetails }: Props) {
    return (
        <>
            <Document>
                <Page style={styles.page} size="A4" wrap>
                    <Header />
                    <View style={styles.row}></View>
                    <View style={styles.row}></View>
                    <Body famMembershipTypes={famMembershipTypes} famOnboardingDetails={famOnboardingDetails} currentYear={currentYear}
                        famDetails={famDetails} />
                    <View style={styles.row}></View>
                    <View style={styles.row}></View>
                    <Footer />
                </Page>
            </Document>
        </>
    )
}
