import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { AppPathEnum } from "../../app/enums/appPathEnum";
import { useHistory } from "react-router-dom";

interface Props {
    message: string;
}

export default function PayInAdvance({ message }: Props) {

    const history = useHistory();

    return <>
        <Box sx={{ flexGrow: 1 }} component={Paper}>

            <Grid container rowSpacing={4} sx={{ mt: "0", mr: "2%", ml: "2%" }}>

                <Grid item xs={12}>
                    <Typography gutterBottom variant='h4'>{message === undefined
                        ? 'You are not authorized to access this page' : message}</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={() => {
                        history.push(`${AppPathEnum.MEMBERSHIP_RENEWAL}${window.location.search}&payInAdvance=true`);
                    }}>
                        Pay Next Years Annual Membership</Button>
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>

        </Box>
    </>
}