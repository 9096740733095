import { Box, Paper, Grid, Typography } from '@mui/material';
import InGoodStanding from './InGoodStanding';
import NotInGoodStanding from './NotInGoodStanding';
import { useMsal } from '@azure/msal-react';
import { IdTokenClaims } from '../../app/models/account/idTokenClaims';

export default function MemberInGoodStanding() {

  const { instance } = useMsal();
  const userClaims = instance.getActiveAccount()?.idTokenClaims as unknown as IdTokenClaims;

  return <>
    <Box sx={{ minWidth: "100%" }} component={Paper}>
      <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

        <Grid item xs={12} sx={{ marginTop: '-1%' }}>
          <Typography variant='h4'>RCSI Letter of Good Standing</Typography>
        </Grid>

        {userClaims?.extension_portalcrm_famIGS === true &&
          <Grid item xs={12} sx={{ marginRight: '3%' }}>
            <InGoodStanding />
          </Grid>
        }

        {userClaims?.extension_portalcrm_famIGS === false &&
          <Grid item xs={12} sx={{ marginRight: '3%' }}>
            <NotInGoodStanding />
          </Grid>
        }

        <Grid item xs={12}></Grid>

      </Grid>
    </Box>
  </>
}