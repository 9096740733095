import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { FamEnrolmentYear } from "../models/membership/famEnrolmentYear";

interface famEnrolmentState {
    basketId: string | null;
    famEnrolmentYears: FamEnrolmentYear[] | null;
    basketIdStatus: ApiRequestStatus;
    nextYearBasketIdStatus: ApiRequestStatus;
    getEnrolmentYearsStatus: ApiRequestStatus;
}

const initialState: famEnrolmentState = {
    basketId: null,
    famEnrolmentYears: null,
    basketIdStatus: ApiRequestStatus.Idle,
    getEnrolmentYearsStatus: ApiRequestStatus.Idle,
    nextYearBasketIdStatus: ApiRequestStatus.Idle
}

export const basketAsync = createAsyncThunk<string>(
    'famEnrolment/basketAsync',
    async (_, thunkAPI) => {
        try {
            return agent.FamEnrolment.basketAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const nextYearEnrolBasketAsync = createAsyncThunk<string>(
    'famEnrolment/NextYearEnrolBasket',
    async (_, thunkAPI) => {
        try {
            return agent.FamEnrolment.nextYearEnrolBasketAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getEnrolmentYearsAsync = createAsyncThunk<FamEnrolmentYear[]>(
    'famEnrolment/getEnrolmentYearsAsync',
    async (_, thunkAPI) => {
        try {
            return agent.FamEnrolment.getEnrolmentYearsAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const famEnrolmentSlice = createSlice({
    name: 'famEnrolmentSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(basketAsync.pending, (state) => {
            state.basketIdStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(basketAsync.fulfilled, (state, action) => {
            state.basketId = action.payload;
            state.basketIdStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(basketAsync.rejected, (state) => {
            state.basketIdStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(nextYearEnrolBasketAsync.pending, (state) => {
            state.nextYearBasketIdStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(nextYearEnrolBasketAsync.fulfilled, (state, action) => {
            state.basketId = action.payload;
            state.nextYearBasketIdStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(nextYearEnrolBasketAsync.rejected, (state) => {
            state.nextYearBasketIdStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getEnrolmentYearsAsync.pending, (state) => {
            state.getEnrolmentYearsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getEnrolmentYearsAsync.fulfilled, (state, action) => {
            state.famEnrolmentYears = action.payload;
            state.getEnrolmentYearsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(getEnrolmentYearsAsync.rejected, (state) => {
            state.getEnrolmentYearsStatus = ApiRequestStatus.Rejected;
        });
    })
})
