import * as yup from 'yup';
import cpdFormModel from './cpdFormModel';

const {
  formField: {
    ActivityDate,
    Category,
    ActivityType,
    Domains,
    CreditsClaiming,
    Description
  }
} = cpdFormModel;

export const cpdValidationSchema = yup.object().shape({
  [ActivityDate.name]: yup.string().nullable().required(`${ActivityDate.requiredErrorMsg}`),
  [Category.name]: yup.string().required(`${Category.requiredErrorMsg}`),
  [ActivityType.name]: yup.string().required(`${ActivityType.requiredErrorMsg}`),
  [Domains.name]: yup.array().min(1).of(yup.string().required(Domains.requiredErrorMsg)).required(Domains.requiredErrorMsg),
  [CreditsClaiming.name]: yup.number().min(0.5, `${CreditsClaiming.minimumValueErrorMessage}`)
    .max(40, `${CreditsClaiming.maximumValueErrorMessage}`)
    .required(`${CreditsClaiming.requiredErrorMsg}`),
  [Description.name]: yup.string().required(`${Description.requiredErrorMsg}`)
})
