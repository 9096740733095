import { Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { ApiRequestStatus } from "../../../../app/enums/apiRequestStatus";
import { OperationStatus } from "../../../../app/enums/operationStatus";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useAppDispatch, useAppSelector } from "../../../../app/store/configureStore";
import { formatDate, getValueFromId, getValueFromIdList } from "../../../../app/utils/util";
import { CpdDto } from "../../models/cpdDto";
import { useForm } from "react-hook-form";
import cpdFormModel from "../../common/cpdFormModel";
import { getReferenceDataAsync } from "../../../../app/slices/referenceDataSlice";
import { getCpdStateAsync, deleteCpdStateAsync } from "../../viewCpdSlice";
import { CpdStatus } from "../../../../app/models/staticData/cpdStatus";
import { API_REQUEST_FULLFILLED } from "../../../../app/utils/constant";

export function ViewCpdRecord() {

    const { formField } = cpdFormModel;
    const { handleSubmit, reset } = useForm();
    const { creditRecordId } = useParams<{ creditRecordId: string }>();

    const dispatch = useAppDispatch();

    const { cpdCategories, activityTypes, cpdRecordStatuses, goodPracticeDomains, staticDataStateStatus } = useAppSelector(state => state.ReferenceData);
    const { cpdDetails, cpdStateStatus } = useAppSelector(state => state.cpd);

    const history = useHistory();
    const [deleteStatus, setDeleteStatus] = useState<OperationStatus | null>(null);
    const [selectedCpdStatus, setSelectedCpdStatus] = useState<CpdStatus | null>(null);
    const [pageLoad, setPageLoad] = useState(true);

    useEffect(() => {
        if (!cpdCategories && !activityTypes &&
            !goodPracticeDomains && !cpdRecordStatuses) {
            dispatch(getReferenceDataAsync());
        }
    }, [cpdCategories, activityTypes, goodPracticeDomains, cpdRecordStatuses, dispatch]);

    useEffect(() => {
        if ((!cpdDetails || pageLoad === true) && creditRecordId !== "" && cpdCategories && cpdRecordStatuses &&
            activityTypes && goodPracticeDomains && cpdRecordStatuses) {
            dispatch(getCpdStateAsync(creditRecordId)).then((response) => {
                let cpdDetails = response.payload as CpdDto;
                reset(PopulateCpdDetailsObject(cpdDetails));
                setSelectedCpdStatus(cpdRecordStatuses.find(x => x.id === cpdDetails.cpdRecordStatusId) ?? null);
                setPageLoad(false);
            });
        }
    }, [cpdDetails, pageLoad, creditRecordId, cpdCategories, cpdRecordStatuses,
        activityTypes, goodPracticeDomains, cpdRecordStatuses, reset, dispatch]);

    function handleDelete() {
        setDeleteStatus(OperationStatus.Pending);
        dispatch(deleteCpdStateAsync(cpdDetails?.creditRecordId!))
            .then((response: any) => {
                if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                    history.push(`/pcsviewcpdrecords`);
                }
                setDeleteStatus(OperationStatus.Fulfilled);
            }).catch(() => setDeleteStatus(OperationStatus.Rejected));
    }

    function PopulateCpdDetailsObject(cpdDetailsObject: CpdDto) {
        return {
            [formField.ActivityDate.name]: formatDate(new Date(cpdDetailsObject.activityDate)),
            [formField.Category.name]: cpdDetailsObject.cpdCategoryId,
            [formField.ActivityType.name]: cpdDetailsObject.activityTypeId,
            [formField.Domains.name]: cpdDetailsObject.goodPracticeDomainIds,
            [formField.CreditsClaiming.name]: cpdDetailsObject.credits,
            [formField.Description.name]: cpdDetailsObject.description,
            [formField.DevelopmentPurpose.name]: cpdDetailsObject.purpose
        };
    }

    function getLabelFromValue(data: any, id: string) {
        return data?.filter((x: any) => x.id === id)[0]?.value;
    }

    function isPageProcessing() {
        return (cpdStateStatus === ApiRequestStatus.Pending || staticDataStateStatus === ApiRequestStatus.Pending);
    }

    return <>
        {isPageProcessing() && <LoadingComponent message="Loading CPD details.." />}

        <form onSubmit={handleSubmit(() => { })}>
            <Grid container rowSpacing={4}>

                <Grid item xs={12} sm={12}>
                    <Button variant="contained"
                        component={Link} to={`/pcsviewcpdrecords`}
                        color='error'
                    >
                        Search CPD Records
                    </Button>
                </Grid>

                <Grid item sm={5}></Grid>

                <Grid item xs={12} sm={7}><Typography variant='h4'>View CPD record</Typography></Grid>

                <Grid item xs={3}>
                    {formField.ActivityDate.label}
                </Grid>

                <Grid item xs={0.5}></Grid>

                <Grid item xs={8.5}>
                    {cpdDetails && formatDate(new Date(cpdDetails?.activityDate))}
                </Grid>

                <Grid item xs={3}>
                    {formField.Category.label}
                </Grid>

                <Grid item xs={0.5}></Grid>

                <Grid item xs={8.5}>
                    {cpdDetails && cpdCategories && getLabelFromValue(cpdCategories, cpdDetails?.cpdCategoryId)}
                </Grid>

                <Grid item xs={3}>
                    {formField.ActivityType.label}
                </Grid>

                <Grid item xs={0.5}></Grid>

                <Grid item xs={8.5}>
                    {cpdDetails && activityTypes && getLabelFromValue(activityTypes, cpdDetails?.activityTypeId)}
                </Grid>

                <Grid item xs={3}>
                    {formField.Domains.label}
                </Grid>

                <Grid item xs={0.5}></Grid>

                <Grid item xs={8.5}>
                    <ul>
                        {cpdDetails && goodPracticeDomains && getValueFromIdList(goodPracticeDomains ?? [],
                            cpdDetails?.goodPracticeDomainIds!).map(function (name, index) {
                                return <li key={index}>{name}</li>
                            })}
                    </ul>
                </Grid>

                <Grid item xs={3}>{formField.CreditsClaiming.label}</Grid>

                <Grid item xs={0.5}></Grid>

                <Grid item xs={8.5}>
                    {cpdDetails?.credits}
                </Grid>

                <Grid item xs={3}>{formField.Description.label}</Grid>

                <Grid item xs={0.5}></Grid>

                <Grid item xs={8.5}>
                    {cpdDetails?.description}
                </Grid>

                <Grid item xs={3}>{formField.DevelopmentPurpose.label}</Grid>

                <Grid item xs={0.5}></Grid>

                <Grid item xs={8.5}>
                    {cpdDetails?.purpose}
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>

            <Grid container columns={16}>
                <Grid item xs={4}>
                    <Button variant="contained"
                        component={Link} to={`/pcsupdatecpdrecord/${cpdDetails?.creditRecordId}`}
                        disabled={selectedCpdStatus?.code === 'VF' ? true : false}
                        color='error' sx={{ width: '90%' }}
                    >
                        Edit
                    </Button>
                </Grid>

                <Grid item xs={4}>
                    <Button variant="contained"
                        component={Link} to={`/pcsclonecpdrecord/${cpdDetails?.creditRecordId}`}
                        color='error' sx={{ width: '90%' }}
                    >
                        Clone
                    </Button>
                </Grid>

                <Grid item xs={4}>
                    <LoadingButton variant="contained"
                        loading={deleteStatus === OperationStatus.Pending}
                        onClick={handleDelete}
                        disabled={selectedCpdStatus?.code === 'VF' ? true : false}
                        color='error' sx={{ width: '90%' }}
                    >
                        Delete
                    </LoadingButton>
                </Grid>
            </Grid>
        </form>
    </>
}
