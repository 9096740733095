import { Box, Paper, Grid, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import AppSelectList from "../../app/components/AppSelectList";
import AppTextInput from "../../app/components/AppTextInput";
import DonationFormModel from "./common/donationFormModel";
import { MapFundsToDropdownItems } from "../../app/slices/convertStaticDataToDropdownItems";
import { Fund } from "../../app/models/staticData/fund";
import AppCheckboxField from "../../app/components/AppCheckboxField";
import { CurrencyCodeEnum } from "../../app/enums/currencyCodeEnum";

interface Props {
    funds: Fund[] | null;
    currency: CurrencyCodeEnum;
}

export default function DonationDetails({ funds, currency }: Props) {
    const methods = useFormContext();
    const { control } = methods;

    const {
        formField: {
            Fund,
            GiftAmount,
            WillingToLeaveLegacy
        }
    } = DonationFormModel;

    return <>
        <Box sx={{ width: '100%' }} component={Paper}>

            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12} sx={{ marginTop: '-1%' }}>
                    <Typography variant='subtitle1' sx={{ fontWeight: "500" }}>Donation details</Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppSelectList control={control} name={Fund.name} label={Fund.label}
                        items={MapFundsToDropdownItems(funds?.filter(x => x.active === true) ?? [])} />
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput control={control} name={GiftAmount.name} label={GiftAmount.label.replace("##CURRENCY_PLACE_HOLDER##", currency)} />
                </Grid>

                <Grid item xs={12}>
                    <AppCheckboxField
                        control={control}
                        name={WillingToLeaveLegacy.name}
                        label={WillingToLeaveLegacy.label}
                        options={[{
                            id: WillingToLeaveLegacy.name,
                            value: WillingToLeaveLegacy.label
                        }]} />
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>
    </>
}