import { Tooltip } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

interface Props {
    information_text?: string | React.ReactNode;
    placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}

export default function AppInformationText(props: Props) {
    return (
        <>
            {props.information_text && props.information_text !== ''
                && props.information_text !== null &&
                <Tooltip title={props.information_text} placement={
                    (!props.placement || props.placement === null) ? 'right' : props.placement} arrow enterTouchDelay={0}>
                    <InfoIcon style={{ fontSize: '25px', margin: '12px', alignSelf: 'end' }} />
                </Tooltip>}
        </>
    )
}

export function AppInformationHtmlText(props: Props) {
    return (
        <>
            {props.information_text && props.information_text !== ''
                && props.information_text !== null &&
                <Tooltip title={props.information_text} placement={!props.placement ? 'left' : 'right'} arrow enterTouchDelay={0}>
                    <InfoIcon style={{ fontSize: '25px', margin: '12px', alignSelf: 'end' }} />
                </Tooltip>}
        </>
    )
}