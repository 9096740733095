import { Box, Grid, Paper, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import AppRadioButtonsGroup from "../../../app/components/AppRadioButtonsGroup";
import { RadioButtonValue } from "../../../app/models/radioButtonValue";
import { Country } from "../../../app/models/staticData/country";
import { PersonalDetailsDto } from "../../../app/models/person/personalDetailsDto";
import { useEffect } from "react";
import { CountrySubdivision } from "../../../app/models/staticData/countrySubdivision";
import { PublicationPreference } from "../../../app/models/staticData/publicationPreference";
import { FAMDetails } from "../../../app/models/membership/famDetails";
import { AlumnusDetails } from "../models/alumniDetails";
import membershipFormModel from "../../../app/models/membership/membershipFormModel";
import MemberLocation from "../../../app/models/membership/MemberLocation";
import AppSelectList from "../../../app/components/AppSelectList";
import { MapMagazinePreferenceToDropdownItems } from "../../../app/slices/convertStaticDataToDropdownItems";
import { useMsal } from "@azure/msal-react";
import { IdTokenClaims } from "../../../app/models/account/idTokenClaims";
import { alumniFilter, famFilter, affiliateAssociateFilter } from "../../../app/utils/util";
import { MESSAGE_NOT_A_VALUE } from "../../../app/utils/constant";

interface Props {
    countries: Country[] | null;
    countrySubdivisions: CountrySubdivision[] | null;
    publicationPreferences: PublicationPreference[] | null;
    personalDetails: PersonalDetailsDto | null;
    alumnusDetails: AlumnusDetails | null;
    famDetails: FAMDetails | null;
}

export function LocationAndPreferences(props: Props) {
    const { countries, countrySubdivisions, personalDetails, publicationPreferences,
        alumnusDetails, famDetails } = props;
    const {
        AlumniMagazinePreference,
        SurgeonsScopeMagazine,
        TheSurgeonJournal,
        OptIntoDirectMarketing,
        ShareMyEmailWithMyClassmates,
        LocationCountry,
        CountrySubdivision,
        CurrentUserRoles
    } = membershipFormModel.formField;

    const { control, reset } = useFormContext();

    const yesNoArray: RadioButtonValue[] = [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' }
    ]

    const { instance } = useMsal();
    const userRoles = (instance.getActiveAccount()?.idTokenClaims as unknown as IdTokenClaims).extension_portalcrm_userType.split(',');

    useEffect(() => {
        if (countries && personalDetails && (famDetails || alumnusDetails)) {
            reset({
                [LocationCountry.name]: (personalDetails.locationCountryId === undefined || personalDetails.locationCountryId === null)
                    ? '' : personalDetails.locationCountryId,
                [CountrySubdivision.name]: (personalDetails.locationCountrySubdivisionId === undefined ||
                    personalDetails.locationCountrySubdivisionId === null)
                    ? MESSAGE_NOT_A_VALUE : personalDetails.locationCountrySubdivisionId,
                [SurgeonsScopeMagazine.name]: famDetails?.scopePreferenceId,
                [TheSurgeonJournal.name]: famDetails?.surgeonJournalPreferenceId,
                [AlumniMagazinePreference.name]: alumnusDetails?.alumniMagPreferenceId === null ? '' : alumnusDetails?.alumniMagPreferenceId,
                [OptIntoDirectMarketing.name]: (alumnusDetails?.directMarketingPreference &&
                    alumnusDetails?.directMarketingPreference === true) ? "Yes" : "No",
                [ShareMyEmailWithMyClassmates.name]: (alumnusDetails?.shareEmailPreference &&
                    alumnusDetails?.shareEmailPreference === true) ? "Yes" : "No",
                [CurrentUserRoles.name]: userRoles
            });
        }
    }, [countries, personalDetails, alumnusDetails, famDetails, reset]);

    return (
        <>
            {(alumniFilter(userRoles) || famFilter(userRoles) || affiliateAssociateFilter(userRoles)) &&
                <MemberLocation countries={countries} countrySubdivisions={countrySubdivisions} personalDetails={personalDetails} />
            }

            {(alumniFilter(userRoles) || famFilter(userRoles)) &&
                <Box sx={{ width: "100%" }} component={Paper}>
                    <Grid container rowSpacing={4} sx={{ margin: "2%" }}>
                        
                        <Grid item xs={12} sx={{ marginTop: '-1%' }}>
                            <Typography variant='subtitle1' sx={{ fontWeight: "500" }}>Publication preferences</Typography>
                        </Grid>

                        <Grid item xs={12}></Grid>

                        {alumniFilter(userRoles) &&
                            <Grid container rowSpacing={4} sx={{ marginLeft: "2%" }}>

                                <Grid item xs={12}>
                                    <Typography variant='body2' sx={{ fontWeight: "500" }}>Alumni Publication Preferences</Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <AppSelectList
                                        control={control}
                                        name={AlumniMagazinePreference.name}
                                        label={AlumniMagazinePreference.label}
                                        items={MapMagazinePreferenceToDropdownItems(publicationPreferences ?? [])}
                                    />
                                </Grid>

                                <Grid item xs={12}></Grid>

                            </Grid>
                        }

                        {famFilter(userRoles) &&
                            <Grid container rowSpacing={4} sx={{ marginLeft: "2%" }}>

                                <Grid item xs={12}>
                                    <Typography variant='body2' sx={{ fontWeight: "500" }}>Member Publication Preferences</Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <AppSelectList
                                        control={control}
                                        name={SurgeonsScopeMagazine.name}
                                        label={SurgeonsScopeMagazine.label}
                                        items={MapMagazinePreferenceToDropdownItems(publicationPreferences ?? [])}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <AppSelectList
                                        control={control}
                                        name={TheSurgeonJournal.name}
                                        label={TheSurgeonJournal.label}
                                        items={MapMagazinePreferenceToDropdownItems(publicationPreferences ?? [])}
                                    />
                                </Grid>

                                <Grid item xs={12}></Grid>

                            </Grid>
                        }

                        {alumniFilter(userRoles) &&
                            <>
                                <Grid item xs={12}>
                                    <AppRadioButtonsGroup
                                        control={control}
                                        name={OptIntoDirectMarketing.name}
                                        label={OptIntoDirectMarketing.label}
                                        items={yesNoArray}
                                        information_text={OptIntoDirectMarketing.information_text}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <AppRadioButtonsGroup
                                        control={control}
                                        name={ShareMyEmailWithMyClassmates.name}
                                        label={ShareMyEmailWithMyClassmates.label}
                                        items={yesNoArray}
                                    />
                                </Grid>
                            </>
                        }

                        <Grid item xs={12}></Grid>

                    </Grid>
                </Box>
            }
        </>
    );
}