import { Grid } from "@mui/material";
import { useState } from "react";

export function ReviewerDetails(props: any) {
    const {
        formField: {
            Name,
            Title,
            IMCNumber,
            EmailAddress
        }
    } = props;

    const [loading,] = useState(false);

    const [selectedName, setSelectedName] = useState('');
    const [selectedTitle, setSelectedTitle] = useState('');
    const [selectedIMCNumber, setSelectedIMCNumber] = useState('');
    const [selectedEmailAddress, setSelectedEmailAddress] = useState('');

    return <>
        <Grid container rowSpacing={4}>

            <Grid item xs={12}></Grid>

            <Grid item xs={4}>
                {Title.label}
            </Grid>

            <Grid item xs={8}></Grid>

            <Grid item xs={4}>
                {Name.label}
            </Grid>

            <Grid item xs={8}></Grid>

            <Grid item xs={4}>
                {IMCNumber.label}
            </Grid>

            <Grid item xs={8}></Grid>

            <Grid item xs={4}>
                {EmailAddress.label}
            </Grid>

            <Grid item xs={8}></Grid>

        </Grid>
    </>
}