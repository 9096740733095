import { Box, Paper, Grid, Typography } from "@mui/material";
import BackToRequests from "../BackToRequests";

interface Props {
    message: React.ReactNode;
}

export default function SuccessResponse(props: Props) {
    return <>
        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ ml: '2%', mt: '0%', width: '96%' }}>

                <Grid item xs={12}>
                    <BackToRequests />
                </Grid>

                <Grid item xs={12}>
                    <Typography gutterBottom variant="h5" component="span">
                        {props.message}
                    </Typography>
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>
    </>
}