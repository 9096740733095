import { Box, Paper, Typography } from "@mui/material";
import { RecordStatusHistory } from "./RecordStatusHistory";
import { SupportingDocuments } from "./SupportingDocuments";
import { ViewCpdRecord } from "./ViewCpdRecord";
import { AddSupportingDocument } from "./AddSupportingDocument";

export function ViewCpdRecordDetails() {
    return <>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Box sx={{ width: '100%' }}>
                <ViewCpdRecord />
            </Box>
        </Paper>

        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Typography variant='h4'>Supporting documents</Typography>
            <Box sx={{ width: '100%' }}>
                <SupportingDocuments />
            </Box>
        </Paper>

        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Typography variant='h4'>Add supporting document</Typography>
            <Box sx={{ width: '100%' }}>
                <AddSupportingDocument />
            </Box>
        </Paper>

        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Typography variant='h4'>Status history</Typography>
            <Box sx={{ width: '100%' }}>
                <RecordStatusHistory />
            </Box>
        </Paper>
    </>
}
