export default function NotInGoodStanding() {

  return <>
    Our records show that you are <b>not In Good Standing</b> for the current membership year. To 
    renew your annual membership please access the Membership Renewal form on the portal homepage 
    and make a payment.

    <br></br>
    <br></br>

    Once you are ratified by RCSI Council, your Letter of Good Standing will be made available here, 
    and annually thereafter, following payment of your annual subscription. A Letter of Good Standing 
    can be used to confirm your Good Standing status with RCSI for the current subscription year, 
    your entitlement to use the FRCSI or MRCSI post-nominals and to verify your award.

  </>
}