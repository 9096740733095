import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    titleContainer: {
        flexDirection: 'row',
        marginTop: 24,
        fontSize: 15
    },
    reportTitle: {
        color: '#DE1834',
        fontSize: 25,
        textAlign: 'center'
    }
});

interface Props {
    title: string;
}
const ReceiptTitle = ({ title }: Props) => (
    <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}>{title}</Text>
    </View>
);

export default ReceiptTitle;