import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import AffiliateOnboardingDto from "../models/affiliateOnboarding/affiliateOnboardingDto";
import UpdateAffiliateOnboardingDto from "../models/affiliateOnboarding/updateAffiliateOnboardingDto";

interface AffiliateOnboardingDetailsSliceState {
    affiliateOnboardingDetails: AffiliateOnboardingDto | null;
    basketId: string | null;
    affiliateOnboardingCanPayStatusCodes: string[] | null;

    getAffiliateOnboardingDetailsStatus: ApiRequestStatus;
    updateAffiliateOnboardingDetailsStatus: ApiRequestStatus;
    basketIdStatus: ApiRequestStatus;
    affiliateOnboardingCanPayStatusCodesStatus: ApiRequestStatus;
}

const initialState: AffiliateOnboardingDetailsSliceState = {
    affiliateOnboardingDetails: null,
    basketId: null,
    affiliateOnboardingCanPayStatusCodes: null,

    getAffiliateOnboardingDetailsStatus: ApiRequestStatus.Idle,
    updateAffiliateOnboardingDetailsStatus: ApiRequestStatus.Idle,
    basketIdStatus: ApiRequestStatus.Idle,
    affiliateOnboardingCanPayStatusCodesStatus: ApiRequestStatus.Idle
}

export const getAffiliateOnboardingDetailsAsync = createAsyncThunk<AffiliateOnboardingDto>(
    'affiliateOnboarding/getAffiliateOnboardingDetailsAsync',
    async (_, thunkAPI) => {
        try {
            return agent.AffiliateOnboarding.getAffiliateOnboarding();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getAffiliateOnboardingCanPayStatusCodesAsync = createAsyncThunk<string[]>(
    'affiliateOnboarding/getAffiliateOnboardingCanPayStatusCodesAsync',
    async (_, thunkAPI) => {
        try {
            return agent.AffiliateOnboarding.getAffiliateOnboardingCanPayStatusCodesAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const updateAffiliateOnboardingDetailsAsync = createAsyncThunk<void, UpdateAffiliateOnboardingDto>(
    'affiliateOnboarding/updateAffiliateOnboardingDetailsAsync',
    async (affiliateOnboardingDetails, thunkAPI) => {
        try {
            return agent.AffiliateOnboarding.updateAffiliateOnboarding(affiliateOnboardingDetails);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const basketAsync = createAsyncThunk<string>(
    'affiliateOnboarding/basketAsync',
    async (_, thunkAPI) => {
        try {
            return agent.AffiliateOnboarding.basketAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const affiliateOnboardingSlice = createSlice({
    name: 'affiliateOnboardingSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(getAffiliateOnboardingDetailsAsync.pending, (state) => {
            state.getAffiliateOnboardingDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getAffiliateOnboardingDetailsAsync.fulfilled, (state, action) => {
            state.getAffiliateOnboardingDetailsStatus = ApiRequestStatus.Fulfilled;
            state.affiliateOnboardingDetails = action.payload;
        });
        builder.addCase(getAffiliateOnboardingDetailsAsync.rejected, (state) => {
            state.getAffiliateOnboardingDetailsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getAffiliateOnboardingCanPayStatusCodesAsync.pending, (state) => {
            state.affiliateOnboardingCanPayStatusCodesStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getAffiliateOnboardingCanPayStatusCodesAsync.fulfilled, (state, action) => {
            state.affiliateOnboardingCanPayStatusCodesStatus = ApiRequestStatus.Fulfilled;
            state.affiliateOnboardingCanPayStatusCodes = action.payload;
        });
        builder.addCase(getAffiliateOnboardingCanPayStatusCodesAsync.rejected, (state) => {
            state.affiliateOnboardingCanPayStatusCodesStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(updateAffiliateOnboardingDetailsAsync.pending, (state) => {
            state.updateAffiliateOnboardingDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(updateAffiliateOnboardingDetailsAsync.fulfilled, (state, action) => {
            state.updateAffiliateOnboardingDetailsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(updateAffiliateOnboardingDetailsAsync.rejected, (state) => {
            state.updateAffiliateOnboardingDetailsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(basketAsync.pending, (state) => {
            state.basketIdStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(basketAsync.fulfilled, (state, action) => {
            state.basketId = action.payload;
            state.basketIdStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(basketAsync.rejected, (state) => {
            state.basketIdStatus = ApiRequestStatus.Rejected;
        });
    })
})
