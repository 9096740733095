import { Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { GetColorCode } from "../../../../app/utils/util";
import AppInformationText from "../../../../app/components/AppInformationText";
import { CreditSummaryHelpText } from "../../common/CreditSummaryHelpText";
import { SummaryDto } from "../../../../app/models/summary/summaryDto";

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiPaper-root": {
            width: "unset",
            marginLeft: "0"
        }
    },
    alignCenter: {
        textAlign: 'center'
    }
}));

interface Props {
    summaryData: SummaryDto[] | null;
}

export function CreditSummary(props: Props) {
    const { summaryData } = props;
    const classes = useStyles();

    return (
        <>
            <div className={classes.root}>
                <Grid container rowSpacing={4}>

                    <Grid item xs={12}></Grid>

                    <Grid item xs={6} md={2} sm={3}>
                        <Typography variant='h6'>Credit summary</Typography>
                    </Grid>

                    <Grid item xs={1} md={1} sm={1} sx={{ marginTop: '-7px' }}>
                        <AppInformationText information_text={CreditSummaryHelpText()} placement={"top"} />
                    </Grid>

                    <Grid item xs={5} md={9} sm={8}></Grid>
                </Grid>

                <Box sx={{ flexGrow: 1, backgroundColor: "#616161", minWidth: "650px" }} component={Paper}>
                    <Grid container component={Paper} sx={{
                        backgroundColor: '#616161',
                        color: "#fff",
                        padding: 2,
                        textAlign: 'left',
                        width: '100%',
                        minWidth: '1100px'
                    }}>
                        <Grid item xs={1} className={classes.alignCenter}>
                            PCS Year
                        </Grid>

                        <Grid item xs={2} className={classes.alignCenter}>
                            Engaged in  Parctice of Medicine
                        </Grid>

                        <Grid item xs={1.5} className={classes.alignCenter}>
                            External
                        </Grid>

                        <Grid item xs={1.5} className={classes.alignCenter}>
                            Workbased Learning
                        </Grid>

                        <Grid item xs={1.5} className={classes.alignCenter}>
                            Practice Review
                        </Grid>

                        <Grid item xs={1.5} className={classes.alignCenter}>
                            PDP
                        </Grid>

                        <Grid item xs={1.5} className={classes.alignCenter}>
                            On Target
                        </Grid>

                        <Grid item xs={1.5} className={classes.alignCenter}>
                            Verification Status
                        </Grid>
                    </Grid>


                    <Grid container component={Paper}
                        sx={{
                            textAlign: 'center',
                            padding: 1,
                            width: '100%',
                            minWidth: '1100px'
                        }}
                    >
                        <Grid item xs={1} sx={{
                            color: 'primary.main',
                            paddingTop: 2
                        }}>
                            {summaryData && summaryData[0].text}
                        </Grid>

                        <Grid item xs={2} sx={{ padding: 2 }}>
                            {summaryData && summaryData[1].text}
                        </Grid>

                        <Grid item xs={1.5} sx={{ padding: 1 }}>
                            <Grid sx={{
                                backgroundColor: summaryData && GetColorCode(summaryData[2].status.toUpperCase()),
                                textAlign: 'center',
                                padding: 1
                            }}>
                                {summaryData && summaryData[2].text}
                            </Grid>
                        </Grid>

                        <Grid item xs={1.5} sx={{ padding: 1 }}>
                            <Grid sx={{
                                backgroundColor: summaryData && GetColorCode(summaryData[3].status?.toUpperCase()),
                                textAlign: 'center',
                                padding: 1
                            }}>
                                {summaryData && summaryData[5].text}
                            </Grid>
                        </Grid>

                        <Grid item xs={1.5} sx={{ padding: 1 }}>
                            <Grid sx={{
                                backgroundColor: summaryData && GetColorCode(summaryData[4].status?.toUpperCase()),
                                textAlign: 'center',
                                padding: 1
                            }}>
                                {summaryData && summaryData[4].text}
                            </Grid>
                        </Grid>

                        <Grid item xs={1.5} sx={{ padding: 1 }}>
                            <Grid sx={{
                                backgroundColor: summaryData && GetColorCode(summaryData[5].status?.toUpperCase()),
                                textAlign: 'center',
                                padding: 1
                            }}>
                                {summaryData && summaryData[3].text}
                            </Grid>
                        </Grid>

                        <Grid item xs={1.5} sx={{ padding: 1 }}>
                            <Grid sx={{
                                backgroundColor: summaryData && GetColorCode(summaryData[6].status?.toUpperCase()),
                                textAlign: 'center',
                                padding: 1
                            }}>
                                {summaryData && summaryData[6].text}
                            </Grid>
                        </Grid>

                        <Grid item xs={1.5} sx={{
                            paddingTop: 2
                        }}>
                            {summaryData && summaryData[7].text}
                        </Grid>
                    </Grid>
                </Box>
            </div >
        </>
    )
}