import { Box, Button, Grid, Typography } from "@mui/material";
import { WorkDetails } from "./WorkDetails";
import { Link } from "react-router-dom";
import AppLabelField from "../../../../app/components/AppLabelField";
import { PcsPlanStatuses } from "../../common/PcsPlanStatuses";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { PcsPdpStatus } from "../../../../app/models/staticData/pcsPdpStatus";
import { WorkPostDto } from "../../models/workPostDto";
import { WorkPostLevel } from "../../../../app/models/staticData/workPostLevel";
import pcsPlanFormModel from "../../common/pcsPlanFormModel";
import { ActivitiesAcrossDomains } from "./ActivitiesAcrossDomains";
import { CreditSummary } from "./CreditSummary";
import { SummaryDto } from "../../../../app/models/summary/summaryDto";
import { DomainSummaryDto } from "../../models/domainSummaryDto";

interface Props {
    pcsPdpStatus: PcsPdpStatus | undefined;
    currentWorkDetails: WorkPostDto | null;
    isCurrentYearPlan: boolean;
    workPostLevels: WorkPostLevel[] | null;
    summaryData: SummaryDto[] | null;
    activitiesAcrossDomains: DomainSummaryDto | null;
    currentYear: number | null
}

export function PlannedActivitiesHeader(props: Props) {
    const { pcsPdpStatus, currentWorkDetails, isCurrentYearPlan, workPostLevels,
        summaryData, activitiesAcrossDomains, currentYear } = props;
    const { AnnualPlanStatus } = pcsPlanFormModel.formField;

    return <>
        <Box sx={{ width: '100%' }}>
            <Grid container rowSpacing={4}>

                <Grid item xs={12}></Grid>

                <Grid item xs={12} sm={12}>
                    <Button component={Link} to={`/pcsplan`} variant="contained">
                        <ArrowBackIcon style={{ fontSize: '25px', marginRight: '12px', alignSelf: 'end' }} />
                        Annual Plans
                    </Button>
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Typography component="h1" variant="h4" alignItems="left">
                        PCS Plan
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppLabelField value={pcsPdpStatus?.value}
                        label={AnnualPlanStatus.label}
                        information_text={pcsPdpStatus?.code && PcsPlanStatuses(pcsPdpStatus?.code)} />
                </Grid>

                {isCurrentYearPlan === true &&
                    <>
                        <Grid item xs={12}>
                            <WorkDetails currentWorkDetails={currentWorkDetails}
                                workPostLevels={workPostLevels} />
                        </Grid>

                        <Box sx={{ width: '100%' }}>

                            <Grid container rowSpacing={4}>

                                {summaryData && summaryData?.length > 0 &&
                                    <Grid item xs={12}>
                                        <CreditSummary summaryData={summaryData} />
                                    </Grid>
                                }

                                {activitiesAcrossDomains?.summaryData?.length !== 0 &&
                                    <Grid item xs={12}>
                                        <ActivitiesAcrossDomains activitiesAcrossDomains={activitiesAcrossDomains} currentYear={currentYear} />
                                    </Grid>
                                }

                            </Grid>
                        </Box></>
                }

                <Grid item xs={12}><Typography variant='h6'>Planned activities</Typography></Grid>
            </Grid>
        </Box>
    </>
}