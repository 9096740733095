import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { FileDto } from "../models/membership/fileDto";
import { FAMOnboarding } from "../models/membership/famOnboarding";

interface FamTransferSliceState {
    famTransferDetails: FAMOnboarding | null;
    famTransferDocuments: FileDto[] | null;
    basketId: string | null;
    famTransferCanPayStatusCodes: string[] | null;

    getFamTransferDetailsStatus: ApiRequestStatus;
    saveFamTransferDetailsStatus: ApiRequestStatus;
    updateFamTransferDetailsStatus: ApiRequestStatus;
    saveFamTransferDocumentStatus: ApiRequestStatus;
    submitFamTransferDetailsStatus: ApiRequestStatus;
    getFamTransferDocumentsStatus: ApiRequestStatus;
    famTransferDownloadStatus: ApiRequestStatus;
    deleteFamTransferDocumentStatus: ApiRequestStatus;
    basketIdStatus: ApiRequestStatus;
    canPayStatusCodesStatus: ApiRequestStatus;
}

const initialState: FamTransferSliceState = {
    famTransferDetails: null,
    famTransferDocuments: null,
    basketId: null,
    famTransferCanPayStatusCodes: null,

    getFamTransferDetailsStatus: ApiRequestStatus.Idle,
    saveFamTransferDetailsStatus: ApiRequestStatus.Idle,
    updateFamTransferDetailsStatus: ApiRequestStatus.Idle,
    saveFamTransferDocumentStatus: ApiRequestStatus.Idle,
    submitFamTransferDetailsStatus: ApiRequestStatus.Idle,
    getFamTransferDocumentsStatus: ApiRequestStatus.Idle,
    famTransferDownloadStatus: ApiRequestStatus.Idle,
    deleteFamTransferDocumentStatus: ApiRequestStatus.Idle,
    basketIdStatus: ApiRequestStatus.Idle,
    canPayStatusCodesStatus: ApiRequestStatus.Idle
}

export const getFamTransferDetailsAsync = createAsyncThunk<FAMOnboarding>(
    'famTransfer/getFamTransferDetailsAsync',
    async (_, thunkAPI) => {
        try {
            return agent.MemberTransfer.getTransferInformation();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getFamTransferCanPayStatusCodesAsync = createAsyncThunk<string[]>(
    'famTransfer/getFamTransferCanPayStatusCodesAsync',
    async (_, thunkAPI) => {
        try {
            return agent.MemberTransfer.getFamTransferCanPayStatusCodesAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const saveFamTransferDetailsAsync = createAsyncThunk<void, FAMOnboarding>(
    'famTransfer/saveFamTransferDetailsAsync',
    async (transferInformationDetails, thunkAPI) => {
        try {
            return agent.MemberTransfer.saveTransferInformation(transferInformationDetails);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const updateFamTransferDetailsAsync = createAsyncThunk<void, FAMOnboarding>(
    'famTransfer/updateFamTransferDetailsAsync',
    async (updateTransferInformationDetails, thunkAPI) => {
        try {
            return agent.MemberTransfer.updateTransferInformation(updateTransferInformationDetails);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const submitFamTransferDetailsAsync = createAsyncThunk<void, string>(
    'famTransfer/submitFamTransferDetailsAsync',
    async (famExamId, thunkAPI) => {
        try {
            return agent.MemberTransfer.submitTransferDetails(famExamId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getFamTransferDocumentsAsync = createAsyncThunk<FileDto[]>(
    'famTransfer/getFamTransferDocumentsAsync',
    async (_, thunkAPI) => {
        try {
            return agent.MemberTransfer.getFiles();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const famTransferDownloadFileAsync = createAsyncThunk<FormData, string>(
    'famTransfer/famTransferDownloadFileAsync',
    async (fileName, thunkAPI) => {
        try {
            return agent.MemberTransfer.downloadFile(fileName);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const saveFamTransferDocumentAsync = createAsyncThunk<boolean, FormData>(
    'famTransfer/saveFamTransferDocumentAsync',
    async (formData, thunkAPI) => {
        try {
            return agent.MemberTransfer.saveDocument(formData);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const deleteFamTransferDocumentAsync = createAsyncThunk<FormData, string>(
    'famTransfer/deleteFamTransferDocumentAsync',
    async (fileType, thunkAPI) => {
        try {
            return agent.MemberTransfer.deleteFile(fileType);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const basketAsync = createAsyncThunk<string>(
    'famTransfer/basketAsync',
    async (_, thunkAPI) => {
        try {
            return agent.MemberTransfer.basketAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const famTransferSlice = createSlice({
    name: 'famTransferSlice',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder => {
        builder.addCase(getFamTransferDetailsAsync.pending, (state) => {
            state.getFamTransferDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getFamTransferDetailsAsync.fulfilled, (state, action) => {
            state.getFamTransferDetailsStatus = ApiRequestStatus.Fulfilled;
            state.famTransferDetails = action.payload;
        });
        builder.addCase(getFamTransferDetailsAsync.rejected, (state) => {
            state.getFamTransferDetailsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getFamTransferCanPayStatusCodesAsync.pending, (state) => {
            state.canPayStatusCodesStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getFamTransferCanPayStatusCodesAsync.fulfilled, (state, action) => {
            state.canPayStatusCodesStatus = ApiRequestStatus.Fulfilled;
            state.famTransferCanPayStatusCodes = action.payload;
        });
        builder.addCase(getFamTransferCanPayStatusCodesAsync.rejected, (state) => {
            state.canPayStatusCodesStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(saveFamTransferDetailsAsync.pending, (state) => {
            state.saveFamTransferDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(saveFamTransferDetailsAsync.fulfilled, (state) => {
            state.saveFamTransferDetailsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(saveFamTransferDetailsAsync.rejected, (state) => {
            state.saveFamTransferDetailsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(updateFamTransferDetailsAsync.pending, (state) => {
            state.updateFamTransferDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(updateFamTransferDetailsAsync.fulfilled, (state) => {
            state.updateFamTransferDetailsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(updateFamTransferDetailsAsync.rejected, (state) => {
            state.updateFamTransferDetailsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(saveFamTransferDocumentAsync.pending, (state) => {
            state.saveFamTransferDocumentStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(saveFamTransferDocumentAsync.fulfilled, (state) => {
            state.saveFamTransferDocumentStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(saveFamTransferDocumentAsync.rejected, (state) => {
            state.saveFamTransferDocumentStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(submitFamTransferDetailsAsync.pending, (state) => {
            state.submitFamTransferDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(submitFamTransferDetailsAsync.fulfilled, (state) => {
            state.submitFamTransferDetailsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(submitFamTransferDetailsAsync.rejected, (state) => {
            state.submitFamTransferDetailsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getFamTransferDocumentsAsync.pending, (state) => {
            state.getFamTransferDocumentsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getFamTransferDocumentsAsync.fulfilled, (state, action) => {
            state.famTransferDocuments = action.payload;
            state.getFamTransferDocumentsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(getFamTransferDocumentsAsync.rejected, (state) => {
            state.getFamTransferDocumentsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(famTransferDownloadFileAsync.pending, (state) => {
            state.famTransferDownloadStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(famTransferDownloadFileAsync.fulfilled, (state) => {
            state.famTransferDownloadStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(famTransferDownloadFileAsync.rejected, (state) => {
            state.famTransferDownloadStatus = ApiRequestStatus.Rejected;
        });
        
        builder.addCase(deleteFamTransferDocumentAsync.pending, (state) => {
            state.deleteFamTransferDocumentStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(deleteFamTransferDocumentAsync.fulfilled, (state) => {
            state.deleteFamTransferDocumentStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(deleteFamTransferDocumentAsync.rejected, (state) => {
            state.deleteFamTransferDocumentStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(basketAsync.pending, (state) => {
            state.basketIdStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(basketAsync.fulfilled, (state, action) => {
            state.basketId = action.payload;
            state.basketIdStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(basketAsync.rejected, (state) => {
            state.basketIdStatus = ApiRequestStatus.Rejected;
        });
    })
})
