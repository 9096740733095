import { Box, Paper, Grid, Typography, Button } from "@mui/material";
import BackToRequests from "./BackToRequests";
import { useForm } from "react-hook-form";
import RequestFormModel from "./models/requestFormModel";
import { DropdownItem } from "../../app/models/common/dropdownItem";
import AppTextareaInput from "../../app/components/AppTextareaInput";
import AppSelectList from "../../app/components/AppSelectList";
import { useState } from "react";
import { toast } from "react-toastify";
import { RequestTypesEnum } from "../../app/enums/requestTypesEnum";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { CreateRequestTaskCommand } from "../../app/models/request/createRequestTaskCommand";
import { submitRequestAsync } from "../../app/slices/requestSlice";
import { useAppDispatch } from "../../app/store/configureStore";
import { API_REQUEST_FULLFILLED, MESSAGE_PROCESSING_DETAILS, MESSAGE_SUBMIT } from "../../app/utils/constant";
import SuccessResponse from "./models/SuccessResponse";

export default function RequestPortalAccess() {
    const methods = useForm({
        mode: 'all'
    });
    const { control, trigger, reset, formState: { isDirty } } = methods;
    const formObj = methods.watch();

    const {
        RequestPortalAccess: {
            IAm,
            FurtherDetails
        }
    } = RequestFormModel;

    const dispatch = useAppDispatch();

    const [pageLoading, setPageLoading] = useState(false);
    const [isRequestSubmitted, setIsRequestSubmitted] = useState(false);

    const MapToRequestDetails = () => {
        let requestObj: CreateRequestTaskCommand = {
            requestTypeCode: RequestTypesEnum.RequestPortalAccess.toString(),
            details: `${IAm.label}: ${formObj.IAm}\r\n${FurtherDetails.label}: ${formObj.FurtherDetails}`,
            subject: ''
        };

        return requestObj;
    }

    const successMessage = 'Thank you for contacting us. A member of the team will process your request shortly.';

    const handleSave = async () => {
        const isStepValid = await trigger();

        if (isStepValid) {
            if (isDirty === true) {
                setPageLoading(true);
                dispatch(submitRequestAsync(MapToRequestDetails())).then((requestResponse: any) => {
                    if (requestResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                        reset({
                            [IAm.name]: '',
                            [FurtherDetails.name]: ''
                        });
                        setPageLoading(false);
                        setIsRequestSubmitted(true);
                        toast.success(successMessage);
                    }
                });
            }
        }
    };

    if (pageLoading === true) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    if (isRequestSubmitted === true) {
        return <SuccessResponse message={successMessage} />
    }

    const roles: DropdownItem[] = [
        { id: 'Alumni', value: 'Alumni', code: 'Alumni' },
        { id: 'F&M', value: 'F&M', code: 'F&M' }
    ];

    return <>
        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ ml: '2%', mt: '0%', width: '96%' }}>

                <Grid item xs={12}>
                    <BackToRequests />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='h4'>Request Portal access</Typography>
                </Grid>

                <Grid item xs={12}>
                    You may be an existing member of the RCSI community, but we are missing the necessary
                    contact details to grant you access to our portal. Please provide us with some further
                    details below so we can assist you:
                </Grid>
                
                <Grid item xs={12}>
                    If you are an <b>alumnus or alumna of RCSI</b>, please provide your graduation year and course.
                </Grid>

                <Grid item xs={12}>
                    If you are an existing <b>Fellow/Member of RCSI</b>, please provide your year of ratification and qualification (Membership or Fellowship).
                </Grid>

                <Grid item xs={12}>
                    If you are enrolled in the <b>Professional Competency Scheme (PCS)</b>, please provide your 
                    IMC number.
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>

        <Box sx={{ width: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12} sx={{ marginTop: '-1%' }}>
                    <Typography variant='subtitle1' sx={{ fontWeight: "500" }}>Your details</Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppSelectList control={control} name={IAm.name} label={IAm.label} items={roles} />
                </Grid>

                <Grid item xs={12}>
                    <AppTextareaInput control={control} name={FurtherDetails.name} label={FurtherDetails.label} />
                </Grid>

                <Grid item xs={12}></Grid>
            </Grid>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Grid container rowSpacing={4}>
                <Grid item xs={12}></Grid>

                <Grid item xs={8}></Grid>

                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        {MESSAGE_SUBMIT}
                    </Button>
                </Grid>

                <Grid item xs={1}></Grid>
            </Grid>
        </Box>
    </>
}