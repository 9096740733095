import { Box, Grid, Typography } from "@mui/material";

export function CurrentWorkDetails() {

    return <>
        <Box sx={{ width: '100%' }}>
            <Grid container rowSpacing={4}>

                <Grid item xs={12}></Grid>

                <Grid item xs={9}><Typography variant='h6'>Current work details</Typography></Grid>

                <Grid item xs={3}></Grid>

                <Grid item xs={2}>
                    Start Date
                </Grid>

                <Grid item xs={2}>

                </Grid>

                <Grid item xs={8}></Grid>

                <Grid item xs={2}>
                    Post Level
                </Grid>

                <Grid item xs={2}>

                </Grid>

                <Grid item xs={8}></Grid>

                <Grid item xs={2}>
                    Name of Employer
                </Grid>

                <Grid item xs={2}>

                </Grid>

                <Grid item xs={8}></Grid>

            </Grid>
        </Box>
    </>
}