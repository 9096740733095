import { Box, Button, Grid, Typography } from "@mui/material";
import AppTextareaInput from "../../../../../app/components/AppTextareaInput";
import { useForm } from "react-hook-form";
import pcsPlanFormModel from "../../../common/pcsPlanFormModel";
import { PdpStatusEnum } from "../../../models/pdpStatusEnum";
import { useEffect, useState } from "react";
import { PcsPdpStatus } from "../../../../../app/models/staticData/pcsPdpStatus";
import { useAppDispatch, useAppSelector } from "../../../../../app/store/configureStore";
import LoadingComponent from "../../../../../app/layout/LoadingComponent";
import { ApiRequestStatus } from "../../../../../app/enums/apiRequestStatus";
import { getReferenceDataAsync } from "../../../../../app/slices/referenceDataSlice";
import { getPlanDetailsAsync, saveRevieweeCommentsAsync } from "../planSlice";
import { Link, useHistory, useParams } from "react-router-dom";
import { API_REQUEST_FULLFILLED, MESSAGE_DETAILS_SAVED, MESSAGE_PROCESSING_DETAILS } from "../../../../../app/utils/constant";
import { toast } from "react-toastify";
import { RevieweeCommentDto } from "../../../models/revieweeCommentDto";
import { yupResolver } from "@hookform/resolvers/yup";
import { feedbackValidationSchema } from "../../../common/pcsPlanValidationSchema";
import { useMsal } from "@azure/msal-react";
import { UserClaims } from "../../../../../app/enums/userClaims";

export function Feedback() {

    const { ReviewerComments, PractionerComments } = pcsPlanFormModel.formField;

    const { control, getValues, reset } = useForm({
        resolver: yupResolver(feedbackValidationSchema)
    });
    const [pcsPdpStatus, setPcsPdpStatus] = useState<PcsPdpStatus>();

    const { accounts } = useMsal();

    useEffect(() => {
        if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
            setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
        }
    }, [accounts]);

    const [personId, setPersonId] = useState('');
    const { enrolmentYearId } = useParams<{ enrolmentYearId: string }>();
    const history = useHistory();

    const dispatch = useAppDispatch();

    const { pcsPdpStatuses, staticDataStateStatus } = useAppSelector(state => state.ReferenceData);
    const { currentYear, enrolmentYearDetails, planStateStatus } = useAppSelector(state => state.plan);

    useEffect(() => {
        if (pcsPdpStatuses === null || pcsPdpStatuses?.length === 0 && personId !== "") {
            dispatch(getReferenceDataAsync());
        }
    }, [pcsPdpStatuses, personId, dispatch]);

    useEffect(() => {
        if ((!enrolmentYearDetails || enrolmentYearDetails?.enrolmentYearId !== enrolmentYearId)
            && personId !== "") {
            dispatch(getPlanDetailsAsync({ personId, enrolmentYearId }));
        }
    }, [enrolmentYearDetails, personId, enrolmentYearId, dispatch]);

    useEffect(() => {
        if (pcsPdpStatuses && enrolmentYearDetails) {
            setPcsPdpStatus(pcsPdpStatuses
                .find(x => x.id === enrolmentYearDetails.pcsPdpReviewStatusId));
            reset({
                [ReviewerComments.name]: enrolmentYearDetails.pdpReviewerComments,
                [PractionerComments.name]: enrolmentYearDetails.pdpRevieweeComments
            })
        }
    }, [pcsPdpStatuses, enrolmentYearDetails]);

    const canAddComments = () => {
        return (isCurrentYearPlan() && pcsPdpStatus?.code === PdpStatusEnum[PdpStatusEnum.RD]);
    }

    const isCurrentYearPlan = () => {
        return enrolmentYearDetails?.year === currentYear;
    }

    const addCommentsAsync = async () => {

        let revieweeCommentDto: RevieweeCommentDto = {
            comments: getValues([PractionerComments.name]).toString(),
            enrolmentYearId: enrolmentYearId
        };
        dispatch(saveRevieweeCommentsAsync(revieweeCommentDto)).then((response: any) => {
            if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                dispatch(getPlanDetailsAsync({ personId, enrolmentYearId })).then((response) => {
                    if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                        history.push(`/pcsplandetails/${enrolmentYearId}`);
                        toast.success(MESSAGE_DETAILS_SAVED);
                    }
                });
            }
        });
    };

    if (staticDataStateStatus === ApiRequestStatus.Pending ||
        planStateStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        <Box sx={{ width: '100%' }}>
            <Grid container rowSpacing={4}>

                <Grid item xs={12}></Grid>

                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant='h4'>Feedback</Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppTextareaInput
                        control={control}
                        name={ReviewerComments.name}
                        label={ReviewerComments.label}
                        disabled
                    />
                </Grid>

                <Grid item xs={12}>
                    <AppTextareaInput
                        control={control}
                        name={PractionerComments.name}
                        label={PractionerComments.label}
                        information_text_with_label={'Please note these comments are not shared with your reviewer, they are for self-reflection purposes.'}
                    />
                </Grid>

            </Grid>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>

            <Grid container rowSpacing={4}>

                <Grid item xs={12}></Grid>

                <Grid item xs={3} sm={2} sx={{ display: 'flex', justifyContent: 'start' }}>
                    <Button component={Link} to={`/pcsplandetails/${enrolmentYearId}`}>
                        Cancel
                    </Button>
                </Grid>

                {canAddComments() === true &&
                    <Grid item xs={9} sm={3} sx={{ display: 'flex', justifyContent: 'start' }}>
                        <Button variant="contained" color="primary" onClick={() => addCommentsAsync()}>
                            Add comments
                        </Button>
                    </Grid>
                }

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>
    </>
}