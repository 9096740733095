import { Grid } from "@mui/material";
import { useState } from "react";

export function PlannedActivities(props: any) {
    const {
        formField: {
            External,
            WorkBasedLearning,
            PracticeReview
        }
    } = props;

    const [selectedExternal, setSelectedExternal] = useState('');
    const [selectedWorkBasedLearning, setSelectedWorkBasedLearning] = useState('');
    const [selectedPracticeReview, setSelectedPracticeReview] = useState('');
    const [loading,] = useState(false);

    return <>
        <Grid container rowSpacing={4}>

            <Grid item xs={12}></Grid>

            <Grid item xs={2}>
                {External.label}
            </Grid>

            <Grid item xs={10}>

            </Grid>

            <Grid item xs={2}>
                {WorkBasedLearning.label}
            </Grid>

            <Grid item xs={10}>

            </Grid>

            <Grid item xs={2}>
                {PracticeReview.label}
            </Grid>

            <Grid item xs={10}>

            </Grid>

        </Grid>
    </>
}