import { Box, Paper, Grid, Typography, Button } from "@mui/material";
import BackToRequests from "./BackToRequests";
import AppDeclarationCheckbox from "./models/AppDeclarationCheckbox";
import { useForm } from "react-hook-form";
import RequestFormModel from "./models/requestFormModel";
import { useState } from "react";
import { CheckBoxItem } from "../../app/models/common/checkBoxItem";
import { yupResolver } from "@hookform/resolvers/yup";
import { LinkedinValidationSchema } from "./models/requestValidationSchemas";
import { toast } from "react-toastify";
import { RequestTypesEnum } from "../../app/enums/requestTypesEnum";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { CreateRequestTaskCommand } from "../../app/models/request/createRequestTaskCommand";
import { submitRequestAsync } from "../../app/slices/requestSlice";
import { useAppDispatch } from "../../app/store/configureStore";
import { API_REQUEST_FULLFILLED, MESSAGE_PROCESSING_DETAILS, MESSAGE_SUBMIT } from "../../app/utils/constant";
import SuccessResponse from "./models/SuccessResponse";

export default function Linkedin() {
    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(LinkedinValidationSchema)
    });
    const { control, trigger, reset, formState: { isDirty } } = methods;
    const formObj = methods.watch();

    const {
        LinkedIn: {
            LinkedInAreTheDetailsCorrect
        }
    } = RequestFormModel;

    const [declarationOption,] = useState<CheckBoxItem>({
        id: LinkedInAreTheDetailsCorrect.name,
        value: ''
    });

    const dispatch = useAppDispatch();

    const [pageLoading, setPageLoading] = useState(false);
    const [isRequestSubmitted, setIsRequestSubmitted] = useState(false);

    const MapToRequestDetails = () => {
        let requestObj: CreateRequestTaskCommand = {
            requestTypeCode: RequestTypesEnum.FMLinkedIn.toString(),
            details: `${LinkedInAreTheDetailsCorrect.labelText}: ${formObj.LinkedInAreTheDetailsCorrect}`,
            subject: ''
        };

        return requestObj;
    }

    const rcsiGroupLinkedInUrl = "https://www.linkedin.com/groups/4825240/";

    const successMessage = <>
        Thank you for contacting the Fellows and Members Office. <br /><br />
        We are delighted to invite you to join our private LinkedIn group for RCSI surgical Fellows, Members and Affiliate Members.
        Here is the link to join: <a href={rcsiGroupLinkedInUrl} target="_blank">{rcsiGroupLinkedInUrl}</a>
    </>

    const handleSave = async () => {
        const isStepValid = await trigger();

        if (isStepValid) {
            if (isDirty === true) {
                setPageLoading(true);
                dispatch(submitRequestAsync(MapToRequestDetails())).then((requestResponse: any) => {
                    if (requestResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                        reset({
                            [LinkedInAreTheDetailsCorrect.name]: false
                        });
                        setPageLoading(false);
                        setIsRequestSubmitted(true);
                        toast.success('Thank you for contacting the Fellows and Members Office.');
                    }
                });
            }
        }
    };

    if (pageLoading === true) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    if (isRequestSubmitted === true) {
        return <SuccessResponse message={successMessage} />
    }

    return <>
        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ ml: '2%', mt: '0%' }}>

                <Grid item xs={12}>
                    <BackToRequests />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='h4'>Fellows and Members: LinkedIn</Typography>
                </Grid>

                <Grid item xs={12} sx={{ marginRight: '2%', marginBottom: '2%', width: '96%' }}>
                    <Typography variant='subtitle1'>
                        RCSI Fellows and Members host a closed LinkedIn group for RCSI surgical
                        Fellows, Members and Affiliate Members, giving you access to surgically
                        relevant content.
                        <br></br>
                        <br></br>
                        Please confirm your details are up-to-date and provide a link to your
                        LinkedIn to request to join the private RCSI Fellows and Members LinkedIn
                        Group.
                    </Typography>
                </Grid>
            </Grid>
        </Box>

        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12}>
                    <AppDeclarationCheckbox
                        control={control}
                        name={LinkedInAreTheDetailsCorrect.name}
                        label={LinkedInAreTheDetailsCorrect.label}
                        option={declarationOption} />
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Grid container rowSpacing={4}>
                <Grid item xs={12}></Grid>

                <Grid item xs={8}></Grid>

                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        {MESSAGE_SUBMIT}
                    </Button>
                </Grid>

                <Grid item xs={1}></Grid>
            </Grid>
        </Box>
    </>
}