import { FormControl, FormHelperText, Grid, TextField } from "@mui/material";
import { useController, UseControllerProps } from "react-hook-form";
import AppInformationText from "./AppInformationText";
import { useCallback } from "react";

interface Props extends UseControllerProps {
    label: string;
    multiline?: boolean;
    rows?: number;
    type?: string;
    information_text?: string;
    variant?: "outlined"
}

export default function AppFileInput(props: Props) {
    const { fieldState, field } = useController({ ...props });

    const onAvatarChange = useCallback(async (event: any) => {
        if (event.target.files?.[0]) {
            field.onChange(event.target.files[0]);
        }
    }, []);

    return (
        <>
            <Grid container rowSpacing={4}>

                <Grid item xs={11}>
                    <FormControl fullWidth error={!!fieldState.error}>
                        <TextField
                            name={props.name}
                            label={props.label}
                            type="file"
                            fullWidth
                            className="rcsiTextField"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            onChange={onAvatarChange}
                        />
                        <FormHelperText>{fieldState.error?.message}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={1}>
                    <AppInformationText information_text={props.information_text} />
                </Grid>

            </Grid>
        </>
    )
}