import * as yup from 'yup';
import "yup-phone-lite";
import MembershipFormModel from '../../../app/models/membership/membershipFormModel';
import {
  LocationAndPreferencesValidationSchema, PersonalDetailsValidationSchema,
  PostalAddressValidationSchema
} from '../../../app/models/membership/validationSchemas';
import { getFileExtension } from '../../../app/utils/util';
import { MAX_FILE_SIZE, SKIP_TO_UPLOAD } from '../../../app/utils/constant';

const {
  formField: {
    NameOnCertificate,
    WhichRoyalCollegeAreYouTransferringFrom,
    Certificate,
    Exam,
    CV,
    Declaration,
    PetitionForElection
  }
} = MembershipFormModel;

export const certificateValidationSchema = yup.mixed()
  .test({
    message: Certificate.requiredErrorMsg,
    test: (file: any) => {
      return file !== undefined;
    }
  })
  .test({
    message: Certificate.invalidFileTypeErrorMsg,
    test: (file: any, context) => {
      if (file === SKIP_TO_UPLOAD) return true;

      const extension = getFileExtension(file ? file?.name : '');
      if (extension !== undefined) {
        const isValid = file && ['png', 'pdf'].includes(extension);
        if (!isValid) context?.createError();

        return isValid;
      }
      context?.createError();
    }
  })
  .test({
    message: Certificate.invalidFileSizeErrorMsg,
    test: (file: any) => {
      if (file === SKIP_TO_UPLOAD) return true;

      const isValid = file?.size < MAX_FILE_SIZE;
      return isValid;
    }
  });

export const examValidationSchema = yup.mixed()
  .test({
    message: Exam.requiredErrorMsg,
    test: (file: any) => {
      return file !== undefined;
    }
  })
  .test({
    message: Exam.invalidFileTypeErrorMsg,
    test: (file: any, context) => {
      if (file === SKIP_TO_UPLOAD) return true;

      const extension = getFileExtension(file ? file?.name : '');
      if (extension !== undefined) {
        const isValid = file && ['png', 'pdf'].includes(extension);
        if (!isValid) context?.createError();

        return isValid;
      }
      context?.createError();
    }
  })
  .test({
    message: Exam.invalidFileSizeErrorMsg,
    test: (file: any) => {
      if (file === SKIP_TO_UPLOAD) return true;

      const isValid = file?.size < MAX_FILE_SIZE;
      return isValid;
    }
  });

export const cvValidationSchema = yup.mixed()
  .test({
    message: CV.requiredErrorMsg,
    test: (file: any) => {
      return file !== undefined;
    }
  })
  .test({
    message: CV.invalidFileTypeErrorMsg,
    test: (file: any, context) => {
      if (file === SKIP_TO_UPLOAD) return true;

      const extension = getFileExtension(file ? file?.name : '');
      if (extension !== undefined) {
        const isValid = file && ['png', 'pdf'].includes(extension);
        if (!isValid) context?.createError();

        return isValid;
      }
      context?.createError();
    }
  })
  .test({
    message: CV.invalidFileSizeErrorMsg,
    test: (file: any) => {
      if (file === SKIP_TO_UPLOAD) return true;

      const isValid = file?.size < MAX_FILE_SIZE;
      return isValid;
    }
  });

export const membershipTransferValidationSchemas = [
  PersonalDetailsValidationSchema,
  PostalAddressValidationSchema,
  LocationAndPreferencesValidationSchema,
  yup.object().shape({
    [WhichRoyalCollegeAreYouTransferringFrom.name]: yup.string()
      .required(`${WhichRoyalCollegeAreYouTransferringFrom.requiredErrorMsg}`),
    [NameOnCertificate.name]: yup.string().required(`${NameOnCertificate.requiredErrorMsg}`)
  }),
  yup.object().shape({
    [Certificate.name]: certificateValidationSchema.test({
      message: Certificate.fileNotUploaded,
      test: (file: any, context) => {
        if (file === SKIP_TO_UPLOAD || (context.from && context.from[0].value.Certificate && context.from[0].value.Certificate.uri)) {
          return true;
        } else {
          return false;
        }
      }
    }),
    [Exam.name]: examValidationSchema.test({
      message: Exam.fileNotUploaded,
      test: (file: any, context) => {
        if (file === SKIP_TO_UPLOAD || (context.from && context.from[0].value.Exam && context.from[0].value.Exam.uri)) {
          return true;
        } else {
          return false;
        }
      }
    }),
    [CV.name]: cvValidationSchema.test({
      message: CV.fileNotUploaded,
      test: (file: any, context) => {
        if (file === SKIP_TO_UPLOAD || (context.from && context.from[0].value.CV && context.from[0].value.CV.uri)) {
          return true;
        } else {
          return false;
        }
      }
    })
  }),
  yup.object().shape({
    [Declaration.name]: yup.array().min(1, Declaration.requiredErrorMsg).of(yup.string()
      .required(Declaration.requiredErrorMsg)).required(),
    [PetitionForElection.name]: yup.array().min(1, PetitionForElection.requiredErrorMsg)
      .of(yup.string().required(PetitionForElection.requiredErrorMsg)).required()
  })
]
