import { Grid, TextField } from "@mui/material";
import { useController, UseControllerProps } from "react-hook-form";
import AppInformationText from "./AppInformationText";

interface Props extends UseControllerProps {
    label: string;
    multiline?: boolean;
    rows?: number;
    type?: string;
    information_text?: string |React.ReactNode;
    variant?: "outlined";
    disabled?: boolean;
}

export default function AppTextInput(props: Props) {
    const { fieldState, field } = useController({ ...props, defaultValue: '' });

    return (
        <>
            <Grid container rowSpacing={4}>

                <Grid item xs={11}>
                    <TextField
                        {...props}
                        {...field}
                        fullWidth
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        className="rcsiTextField"
                        sx={props.disabled === true ? {backgroundColor: '#f1f1f1', color: '#858282'} : {}}
                    />
                </Grid>

                <Grid item xs={1}>
                    <AppInformationText information_text={props.information_text} />
                </Grid>

            </Grid>
        </>
    )
}