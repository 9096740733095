export enum PdpStatusEnum {
    DT = "DT",
    NS = "NS",
    RRC = "RRC",
    RRD = "RRD",
    RRI = "RRI",
    RP = "RP",
    RD = "RD",
    RV = "RV",
    SD = "SD",
    UR = "UR"
}