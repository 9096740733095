import { View, StyleSheet } from "@react-pdf/renderer";
import ReceiptTableHeader from "./ReceiptTableHeader";
import ReceiptTableRow from "./ReceiptTableRow";
import ReceiptTableFooter from "./ReceiptTableFooter";
import { PaymentProductProps } from "../common/paymentProductProps";
import { PaymentDto } from "../../../app/models/payment/paymentDto";
import { Currency } from "../../../app/models/staticData/currency";

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#bff0fd',
    }
});

interface Props {
    paymentProducts: PaymentProductProps[];
    paymentDetails: PaymentDto | null;
    currencies: Currency[] | null;
}

const ReceiptItemsTable = ({ paymentProducts, paymentDetails, currencies }: Props) => {
    return (
        <View style={styles.tableContainer}>
            <ReceiptTableHeader />
            <ReceiptTableRow currencies={currencies} paymentProducts={paymentProducts} paymentDetails={paymentDetails} />
            <ReceiptTableFooter currencies={currencies} paymentProducts={paymentProducts} paymentDetails={paymentDetails} />
        </View>
    )
};

export default ReceiptItemsTable;