import { Box, Paper, Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { CheckBoxItem } from "../../app/models/common/checkBoxItem";
import { useEffect, useState } from "react";
import MembershipFormModel from "../../app/models/membership/membershipFormModel";
import AppDeclarationCheckbox from "../../app/models/membership/AppDeclarationCheckbox";
import AppPetitionForElectionCheckbox from "../../app/models/membership/AppPetitionForElectionCheckbox";

export default function DeclarationAndPetition() {
    const methods = useFormContext();
    const { control, reset } = methods;

    const {
        formField: {
            Declaration,
            PetitionForElection
        }
    } = MembershipFormModel;

    const [declarationOption,] = useState<CheckBoxItem>({
        id: Declaration.name,
        value: ''
    });

    const [petitionForElectionOption,] = useState<CheckBoxItem>({
        id: PetitionForElection.name,
        value: PetitionForElection.label
    });

    useEffect(() => {
        reset({
            [Declaration.name]: [],
            [PetitionForElection.name]: []
        })
    }, []);

    return <>
        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12}>
                    <AppDeclarationCheckbox
                        control={control}
                        name={Declaration.name}
                        option={declarationOption} />
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>

        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12}>
                    <AppPetitionForElectionCheckbox
                        control={control}
                        name={PetitionForElection.name}
                        option={petitionForElectionOption} />
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>
    </>
}