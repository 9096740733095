import {
    Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid,
    Paper, Tab, Tabs, Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { PCSDetails } from "./PCSDetails";
import { PersonalDetails } from "./PersonalDetails";
import { LocationAndPreferences } from "./LocationAndPreferences";
import { toast } from "react-toastify";
import { getPersonalDetailsAsync, updatePersonalDetailsStateAsync }
    from "../../../app/slices/personalDetailsSlice";
import { useAppDispatch, useAppSelector } from "../../../app/store/configureStore";
import { PersonalDetailsDto } from "../../../app/models/person/personalDetailsDto";
import { API_REQUEST_FULLFILLED, MESSAGE_DETAILS_SAVED, MESSAGE_NOT_A_VALUE, MESSAGE_PROCESSING_DETAILS } from "../../../app/utils/constant";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateProfileValidationSchemas } from "../common/updateProfileValidationSchema";
import { getReferenceDataAsync } from "../../../app/slices/referenceDataSlice";
import { ApiRequestStatus } from "../../../app/enums/apiRequestStatus";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { getAlumniDetails, updateAlumniDetails } from "../../../app/slices/alumniSlice";
import MemberPostalAddress from "../../../app/models/membership/MemberPostalAddress";
import { getFAMDetails, updateFAMDetails } from "../../../app/slices/famSlice";
import { useMsal } from "@azure/msal-react";
import { UserClaims } from "../../../app/enums/userClaims";
import { IdTokenClaims } from "../../../app/models/account/idTokenClaims";
import { alumniFilter, famFilter, affiliateAssociateFilter, pcsFilter } from "../../../app/utils/util";

export function UpdateProfile() {
    const PERSONAL_DETAILS = 'Personal details';
    const POSTAL_ADDRESS = 'Postal address';
    const LOCATION_AND_PREFERENCES = 'Location & preferences';
    const PCS_DETAILS = 'PCS details';

    const [tabs, setTabs] = useState<string[]>([PERSONAL_DETAILS]);

    const { instance, accounts } = useMsal();
    const userRoles = (instance.getActiveAccount()?.idTokenClaims as unknown as IdTokenClaims).extension_portalcrm_userType.split(',');

    useEffect(() => {
        let tabList = [PERSONAL_DETAILS];
        if (alumniFilter(userRoles) || famFilter(userRoles) || affiliateAssociateFilter(userRoles)) {
            tabList.push(POSTAL_ADDRESS);
            tabList.push(LOCATION_AND_PREFERENCES);
        }

        if (pcsFilter(userRoles)) {
            tabList.push(PCS_DETAILS);
        }
        setTabs(tabList);
    }, []);

    const [arePageValuesChanged, setArePageValuesChanged] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    useEffect(() => {
        if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
            setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
        }
    }, [accounts]);

    const [personId, setPersonId] = useState('');
    const [pageLoading, setPageLoading] = useState(false);

    const dispatch = useAppDispatch();

    const { titles, genders, countries, countrySubdivisions, disciplines, areasOfPractice,
        publicationPreferences } = useAppSelector(state => state.ReferenceData);
    const { personalDetails, updatePersonalDetailsStatus } =
        useAppSelector(state => state.personalDetail);
    const { alumnusDetails } = useAppSelector(state => state.alumniDetails);
    const { famDetails, updateFamDetailsStatus } = useAppSelector(state => state.famDetails);

    useEffect(() => {
        if (personId !== "" || updatePersonalDetailsStatus === ApiRequestStatus.Fulfilled) {
            setPageLoading(true);

            if (alumniFilter(userRoles)) {
                const pageLoadPromise = async () => await Promise.all([
                    dispatch(getReferenceDataAsync()),
                    dispatch(getPersonalDetailsAsync(personId)),
                    dispatch(getAlumniDetails(personId)),
                    dispatch(getFAMDetails(personId))
                ]);

                pageLoadPromise().finally(() => {
                    setPageLoading(false);
                });
            }

            if (famFilter(userRoles) || affiliateAssociateFilter(userRoles)) {
                const famPromise = async () => await Promise.all([
                    dispatch(getReferenceDataAsync()),
                    dispatch(getPersonalDetailsAsync(personId)),
                    dispatch(getFAMDetails(personId))
                ]);

                famPromise().finally(() => {
                    setPageLoading(false);
                });
            }

            if (pcsFilter(userRoles)) {
                const pageLoadPromise = async () => await Promise.all([
                    dispatch(getReferenceDataAsync()),
                    dispatch(getPersonalDetailsAsync(personId)),
                    dispatch(getFAMDetails(personId))
                ]);

                pageLoadPromise().finally(() => {
                    setPageLoading(false);
                });
            }
        }
    }, [personId, updatePersonalDetailsStatus, dispatch]);

    function getStepContent(step: string) {
        switch (step) {
            case PERSONAL_DETAILS:
                return <PersonalDetails titles={titles} genders={genders} countries={countries}
                    disciplines={disciplines} areasOfPractice={areasOfPractice}
                    personalDetails={personalDetails} />
            case POSTAL_ADDRESS:
                return <MemberPostalAddress countries={countries} personalDetails={personalDetails} />
            case LOCATION_AND_PREFERENCES:
                return <LocationAndPreferences countries={countries}
                    personalDetails={personalDetails} countrySubdivisions={countrySubdivisions}
                    publicationPreferences={publicationPreferences}
                    alumnusDetails={alumnusDetails} famDetails={famDetails} />
            case PCS_DETAILS:
                return <PCSDetails />
            default:
                throw new Error('Unknown step');
        }
    }

    const [activeStep, setActiveStep] = useState(0);
    const [expectedStep, setExpectedStep] = useState(0);
    const currentValidationSchema = updateProfileValidationSchemas[activeStep];

    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(currentValidationSchema)
    });
    const { trigger, watch } = methods;
    const form = methods.watch();

    useEffect(() => {
        watch((value, { name, type }) => {
            if (type === 'change') {
                setArePageValuesChanged(true);
            }
        });
    }, [watch]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (arePageValuesChanged === true) {
            setShowDialog(true);
            setExpectedStep(newValue);
        }
        else {
            setActiveStep(newValue);
        }
    };

    const handleClose = () => {
        setShowDialog(false);
    };

    const handleCloseAndContinue = () => {
        setShowDialog(false);
        setArePageValuesChanged(false);
        setActiveStep(expectedStep);
    };

    const mapToPersonalDetails = (values: any) => {
        const preferredEmail = values?.PreferredEmail1 === "" ? null : values?.PreferredEmail1;
        const email2 = values?.AlternateEmail2 === "" ? null : values?.AlternateEmail2;
        const email3 = values?.AlternateEmail3 === "" ? null : values?.AlternateEmail3;

        let isEmailUpdated = personalDetails?.preferredEmail === preferredEmail
            && personalDetails?.email2 === email2 && personalDetails?.email3 === email3;

        const personalDetailsObj: PersonalDetailsDto = {
            id: personId,
            firstName: values?.FirstName,
            lastName: values?.Surname,
            middleName: values?.MiddleName,
            prefferedName: values?.PreferredName,
            preferredEmail: values?.PreferredEmail1,
            email2: values?.AlternateEmail2,
            email3: values?.AlternateEmail3,
            dateOfBirth: values?.DateOfBirth,
            titleId: values?.Title,
            genderId: values?.Gender,
            nationalityId: values?.CountryOfNationality,
            mobile: values?.PreferredPhoneNumber,
            alternatePhoneNumber: values?.AlternatePhoneNumber,
            disciplineId: values?.Discipline,
            areaOfPracticeId: values?.AreaOfPracticeMoreDetails,
            linkedin: values?.LinkedInUrl,
            emailchanged: !isEmailUpdated
        }

        return personalDetailsObj;
    }

    const mapToPostalAddress = (values: any) => {
        let personalDetailsObj = {
            ...personalDetails,
            postalAddress: values?.PostalAddressLines,
            postalAddressCountryId: values?.PostalAddressCountry
        };

        return personalDetailsObj;
    }

    const mapToLocationAndPreferences = () => {
        const data: PersonalDetailsDto = {
            ...personalDetails, id: personId,
            locationCountryId: form.LocationCountry,
            locationCountrySubdivisionId: form.CountrySubdivision === MESSAGE_NOT_A_VALUE ? '' : form.CountrySubdivision
        }
        return data;
    }

    const mapToFamDetails = (values: any) => {
        let famDetailsObj = {
            ...famDetails,
            scopePreferenceId: values?.SurgeonsScopeMagazine,
            surgeonJournalPreferenceId: values?.TheSurgeonJournal,
            personId: personId
        };

        return famDetailsObj;
    }

    const mapToAlumniDetails = (values: any) => {
        let alumniDetailsObj = {
            ...alumnusDetails,
            alumniMagPreferenceId: values?.AlumniMagazinePreference,
            directMarketingPreference: values?.OptIntoDirectMarketing === "Yes" ? true : false,
            shareEmailPreference: values?.ShareMyEmailWithMyClassmates === "Yes" ? true : false,
            personId: personId
        };

        return alumniDetailsObj;
    }

    const handleSave = async () => {
        const isStepValid = await trigger();

        if (isStepValid) {

            switch (tabs[activeStep]) {
                case PERSONAL_DETAILS:
                    if (form) {
                        dispatch(updatePersonalDetailsStateAsync(mapToPersonalDetails(form)))
                            .then((response) => {
                                if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                                    dispatch(getPersonalDetailsAsync(personId)).then((personalDetailsResponse) => {
                                        if (personalDetailsResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                                            setArePageValuesChanged(false);
                                            toast.success(MESSAGE_DETAILS_SAVED);
                                        }
                                    });
                                }
                            });
                    }
                    break;
                case POSTAL_ADDRESS:
                    if (form) {
                        dispatch(updatePersonalDetailsStateAsync(mapToPostalAddress(form)))
                            .then((response) => {
                                if (response.meta.requestStatus.toLowerCase() ===
                                    API_REQUEST_FULLFILLED) {
                                    dispatch(getPersonalDetailsAsync(personId)).then((personalDetailsResponse) => {
                                        if (personalDetailsResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                                            setArePageValuesChanged(false);
                                            toast.success(MESSAGE_DETAILS_SAVED);
                                        }
                                    });
                                }
                            });
                    }
                    break;
                case LOCATION_AND_PREFERENCES:
                    if (form) {
                        if (alumniFilter(userRoles) && (famFilter(userRoles) || affiliateAssociateFilter(userRoles))) {
                            const pageLoadPromise = async () => await Promise.all([
                                dispatch(updatePersonalDetailsStateAsync(mapToLocationAndPreferences())),
                                dispatch(updateAlumniDetails(mapToAlumniDetails(form))),
                                dispatch(updateFAMDetails(mapToFamDetails(form)))
                            ]);

                            pageLoadPromise().finally(() => {
                                const famAlumniPromise = async () => await Promise.all([
                                    dispatch(getAlumniDetails(personId)),
                                    dispatch(getFAMDetails(personId)),
                                    dispatch(getPersonalDetailsAsync(personId))
                                ]);

                                famAlumniPromise().finally(() => {
                                    setArePageValuesChanged(false);
                                    toast.success(MESSAGE_DETAILS_SAVED);
                                });
                            });
                        }
                        else if (alumniFilter(userRoles)) {
                            const pageLoadPromise = async () => await Promise.all([
                                dispatch(updatePersonalDetailsStateAsync(mapToLocationAndPreferences())),
                                dispatch(updateAlumniDetails(mapToAlumniDetails(form)))
                            ]);

                            pageLoadPromise().finally(() => {
                                const alumniPromise = async () => await Promise.all([
                                    dispatch(getAlumniDetails(personId)),
                                    dispatch(getPersonalDetailsAsync(personId))
                                ]);

                                alumniPromise().finally(() => {
                                    setArePageValuesChanged(false);
                                    toast.success(MESSAGE_DETAILS_SAVED);
                                });
                            });
                        }
                        else if (famFilter(userRoles) || affiliateAssociateFilter(userRoles)) {
                            const pageLoadPromise = async () => await Promise.all([
                                dispatch(updatePersonalDetailsStateAsync(mapToLocationAndPreferences())),
                                dispatch(updateFAMDetails(mapToFamDetails(form)))
                            ]);

                            pageLoadPromise().finally(() => {
                                const famPromise = async () => await Promise.all([
                                    dispatch(getFAMDetails(personId)),
                                    dispatch(getPersonalDetailsAsync(personId))
                                ]);

                                famPromise().finally(() => {
                                    setArePageValuesChanged(false);
                                    toast.success(MESSAGE_DETAILS_SAVED);
                                });
                            });
                        }
                    }
                    break;
                case PCS_DETAILS:
                    break;
                default:
                    return "not a valid step";
            }
            handleClose();
        }
    };

    if (pageLoading === true
        || updatePersonalDetailsStatus === ApiRequestStatus.Pending
        || updateFamDetailsStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        <FormProvider {...methods}>
            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                <Typography component="h1" variant="h4">
                    Update profile
                </Typography>

                <Grid item xs={12}></Grid>

                <Grid item xs={12} sx={{ margin: '2%' }}>
                    <Typography variant='subtitle1'>
                        Please review your details below. If they are incorrect, please update and click 'Save'.
                        If the information is correct, please verify them by clicking 'Save'.
                    </Typography>
                </Grid>

                <Tabs value={activeStep}
                    onChange={handleChange}
                    textColor="primary"
                    indicatorColor="primary"
                    aria-label="primary tabs example"
                    sx={{
                        '& .MuiTabs-flexContainer': {
                            flexWrap: 'wrap',
                        },
                    }}>
                    {tabs.map((label: string, inedex: number) => (
                        <Tab key={label} value={inedex} label={label} />
                    ))}
                </Tabs>
                {getStepContent(tabs[activeStep])}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid container rowSpacing={4}>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={8}></Grid>
                        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end' }}>
                            <Button variant="contained" color="primary" onClick={handleSave}>
                                Save
                            </Button>
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </Box>
            </Paper>

            <Dialog
                open={showDialog}
                onClose={handleClose}
                aria-labelledby="unsaved-changes-dialog"
                aria-describedby="unsaved-changes-dialog"
            >
                <DialogTitle id="unsaved-changes-dialog">
                    {"Unsaved changes?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="unsaved-changes-dialog">
                        This page contains unsaved changes. Do you still wish to leave this page?
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button variant="contained" color="primary"
                        onClick={handleCloseAndContinue} autoFocus>
                        Yes
                    </Button>
                    <Button onClick={handleClose}>No</Button>
                </DialogActions>
            </Dialog>
        </FormProvider>
    </>
}
