import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDateWithMonthName } from '../../../../app/utils/util';
import { CreditRecord } from '../../models/creditRecord';
import { ActivityType } from '../../../../app/models/staticData/activityType';
import { CpdCategory } from '../../../../app/models/staticData/cpdCategory';
import { CpdStatus } from '../../../../app/models/staticData/cpdStatus';

interface Props {
  categories: CpdCategory[] | null;
  statuses: CpdStatus[] | null;
  activityTypes: ActivityType[] | null;
  creditRecordList: any[] | null;
}

export default function CpdDataList(props: Props) {

  return (
    <>
      <Grid container rowSpacing={2}>

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Date of activity</TableCell>
                  <TableCell align="left">Category</TableCell>
                  <TableCell align="left">Activity type</TableCell>
                  <TableCell align="left">Activity description</TableCell>
                  <TableCell align="left">Credits</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="left">No. of documents attached</TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.creditRecordList?.map((row: CreditRecord) => (
                  <TableRow
                    key={row.creditRecordId}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>
                      {new Date(row.activityDate).getFullYear() !== 1 ?
                        formatDateWithMonthName(new Date(row.activityDate)) :
                        ''}
                    </TableCell>
                    <TableCell align="left">{row.cpdCategory}</TableCell>
                    <TableCell align="left">{row.activityType}</TableCell>
                    <TableCell align="left">{row.description}</TableCell>
                    <TableCell align="left">{row.credits}</TableCell>
                    <TableCell align="left">{row.cpdRecordStatus}</TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left">
                      <Button component={Link} to={`/pcsviewcpdrecord/${row.creditRecordId}`} size="small">View</Button>
                    </TableCell>
                  </TableRow>
                ))}
                {props.creditRecordList?.length === 0 &&
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      No data found
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

      </Grid>
    </>
  );
}