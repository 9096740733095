import { Text, StyleSheet } from '@react-pdf/renderer';
import { PaymentDto } from '../../../app/models/payment/paymentDto';
import { Currency } from '../../../app/models/staticData/currency';
import { CurrencyCodeEnum } from '../../../app/enums/currencyCodeEnum';

const styles = StyleSheet.create({
    footer: {
        position: 'absolute',
        fontSize: 7,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    }
});

interface Props {
    paymentDetails: PaymentDto | null;
    currencies: Currency[] | null;
}

const ReceiptFooter = ({ paymentDetails, currencies }: Props) => {
    return (
        <Text style={styles.footer} fixed>
            {paymentDetails?.currencyId === currencies?.find(x => x.code === CurrencyCodeEnum.GBP)?.id &&
                <>
                    FRIENDS OF RCSI{"\n"}
                    Registered Charity No. 1183659{"\n"}
                    Registered in England & Wales{"\n"}
                    Registered Company No. 11571364{"\n"}
                    Registered Office 10 Queens Street Place, London, EC4R 1BE, United Kingdom
                </>
            }

            {paymentDetails?.currencyId === currencies?.find(x => x.code === CurrencyCodeEnum.EUR)?.id &&
                <>
                    Royal College of Surgeons in Ireland{"\n"}
                    123 St. Stephen's Green, Dublin 2, D02 YN77, Ireland{"\n"}
                    CRN: 20001957{"\n"}
                    CHY Number: 1277
                </>
            }
        </Text>
    )
}

export default ReceiptFooter;