import { Box, Grid, Paper, Typography } from "@mui/material";

interface Props {
    message: string;
}

export default function Unauthorized({ message }: Props) {

    return <>
        <Box sx={{ flexGrow: 1 }} component={Paper}>

            <Grid container rowSpacing={4} sx={{ mt: "0", mr: "2%", ml: "2%" }}>

                <Grid item xs={12}>
                    <Typography gutterBottom variant='h4'>{message === undefined
                        ? 'You are not authorized to access this page' : message}</Typography>
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>
    </>
}